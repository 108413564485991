import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MapsGeoSalesComponent } from './maps-geo-sales.component';

@NgModule({
  declarations: [MapsGeoSalesComponent],
  imports: [CommonModule, AgmCoreModule, NgbModule],
  exports: [MapsGeoSalesComponent],
})
export class MapsGeoSalesModule {}
