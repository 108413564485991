import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OperationType } from '../../../../menu-tabs/finances/finoperations/finoperations.interface';
import { Nullable } from '../../../interfaces/common.type';

@Component({
  selector: 'app-footer-modal',
  templateUrl: './footer-modal.component.html',
  styleUrls: ['./footer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterModalComponent {
  @Input()
  operationType: OperationType | null = null;

  @Input()
  isDisabled = false;

  @Input()
  isCreate = true;

  @Input()
  isSave = false;

  @Input()
  isCancel = false;

  @Input()
  isClose = false;

  @Output()
  submitEmitter = new EventEmitter<Nullable<OperationType>>();

  @Output()
  submitEmitterJustAction = new EventEmitter();

  @Output()
  cancelEmitter = new EventEmitter();

  submit(): void {
    this.submitEmitter.emit(this.operationType);
    this.submitEmitterJustAction.emit();
  }

  cancel(): void {
    this.cancelEmitter.emit();
  }
}
