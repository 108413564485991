import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-btn-cell-renderer',
  template: `{{ value}}`,
})
export class FinoperationSpecificCellsComponent implements ICellRendererAngularComp {
  private params: any;

  value: number;

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
  }

  refresh(params: any): boolean {
    this.params = params;
    this.value = params.value;
    return true;
  }
}
