<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
    <div class="col-sm-12 col-md-12">
        <div class="mb-3 card text-white card-body bg-warning">
            <h5 class="card-title">Идет Beta тестирование</h5>
        </div>
        <br/>
    </div>
    <div class="app-page-title mb-0">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
                </div>
                <div>
                    Список контрагентов&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="page-title-actions">
                <button (click)="openModalCreatePartner(createPartnerModal)"
                        class="btn-shadow d-inline-flex align-items-center btn btn-success"
                        type="button">
                    Добавить контрагента
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="rowData?.length === 0" class="text-center mbg-3">
        <button (click)="openModalCreatePartner(createPartnerModal)"
                class="btn-wide btn-pill fsize-1 btn-shadow d-inline-flex align-items-center btn-lg btn btn-success"
                type="button">
        <span class="mr-2 opacity-7">
            <i class="icon pe-7s-plus"></i>
        </span>
            Добавить контрагента
        </button>
    </div>
    <div>
        <div *ngIf="rowsForRemoving.length > 0" class="button-wrapp">
            <p>Выбрано: {{ rowsForRemoving.length }}</p>
            <button (click)="removeSelected()" class="icon-button" label="">
                <i class="lnr-trash"></i>
                Удалить
            </button>
        </div>
        <div *ngIf="rowData?.length !== 0" class="main-card mb-3 card">
            <div style="width: 100%;height: 67vh;">
                <ag-grid-angular
                        (rowSelected)="onRowSelected($event)"
                        [columnDefs]="finPartnersColumnDF"
                        [defaultColDef]="defaultColDef"
                        [enableRangeSelection]="true"
                        [gridOptions]="gridOptions"
                        [localeText]="localeTextForFilters"
                        [pagination]="true"
                        [rowData]="rowData"
                        class="ag-theme-alpine"
                        rowSelection="multiple"
                        style="width: 100%; height: 100%;">
                </ag-grid-angular>
            </div>
        </div>
    </div>
</div>

<ng-template #createPartnerModal>
    <app-header-modal (closeEmitter)="closeModal()"
                      [modalTitle]="isEdit ? 'Изменить' : 'Добавить' + ' контрагента'"
    />
    <form [formGroup]="partnerForm"
          class="needs-validation"
          novalidate>
        <p-toast></p-toast>
        <div class="modal-body">
            <div class="position-relative row form-group">
                <label class="col-sm-3 col-form-label">Юр. лицо</label>
                <div class="col-sm-9">
                    <!--                    <select class="form-control"-->
                    <!--                            formControlName="shop"-->
                    <!--                            name="shop">-->
                    <!--                        <option *ngFor="let pf of shop_list" value="id">{{ pf.name }}</option>-->
                    <!--                    </select>-->
                    <p-dropdown [filter]="true"
                                [options]="shop_list"
                                [showClear]="true"
                                [style]="{ display: 'flex', alignItems: 'center', height: '34px', fontSize: '13px'}"
                                formControlName="shop"
                                id="shop"
                                optionLabel="name" optionValue="id"
                                placeholder="Выберите Юр. лицо"/>
                </div>
                <div *ngIf="partnerForm.controls['shop'].invalid && partnerForm.controls['shop'].touched"
                     class="alert alert-danger">
                    Не корректно заполнено поле
                </div>
            </div>
            <div class="position-relative row form-group">
                <label class="col-sm-3 col-form-label">Наименование<span
                        style="color:red">*</span></label>
                <div class="col-sm-9">
                    <input class="form-control" formControlName="name" name="name"
                           placeholder="Введите наименование"
                           type="text">
                    <div *ngIf="partnerForm.controls['name'].invalid && partnerForm.controls['name'].touched"
                         class="alert alert-danger">Не
                        корректно
                        заполнено поле
                    </div>
                </div>
            </div>

            <div class="position-relative row form-group">
                <label class="col-sm-3 col-form-label">ИНН</label>
                <div class="col-sm-9">
                    <input class="form-control"
                           formControlName="inn"
                           name="inn"
                           placeholder="ИНН"
                           type="text">
                    <div *ngIf="partnerForm.controls['inn'].invalid && partnerForm.controls['inn'].touched"
                         class="alert alert-danger">
                        Введите только цифры
                    </div>
                </div>
            </div>

            <div class="position-relative row form-group">
                <label class="col-sm-3 col-form-label" for="kpp_lable">КПП</label>
                <div class="col-sm-9">
                    <input class="form-control"
                           formControlName="kpp"
                           id="kpp_lable" name="kpp"
                           placeholder="КПП"
                           type="text">
                    <div *ngIf="partnerForm.controls['kpp'].invalid && partnerForm.controls['kpp'].touched"
                         class="alert alert-danger">
                        Введите только цифры
                    </div>
                </div>
            </div>

            <div class="position-relative row form-group">
                <label class="col-sm-3 col-form-label">Р/С</label>
                <div class="col-sm-9">
                    <input class="form-control" formControlName="checking_account"
                           name="checking_account" placeholder="Расчетный счет"
                           type="text">
                    <div *ngIf="partnerForm.controls['checking_account'].invalid && partnerForm.controls['checking_account'].touched"
                         class="alert alert-danger">
                        Не корректно заполнено поле
                    </div>
                </div>
            </div>
            <div class="position-relative row form-group">
                <label class="col-sm-3 col-form-label" for="income_item">Доходная статья</label>
                <div class="col-sm-9" style="margin-top: 4px;">
                    <p-dropdown [filter]="true" [options]="itemsIncomeList" [showClear]="true"
                                [style]="{ display: 'flex', alignItems: 'center', height: '34px', fontSize: '13px'}"
                                formControlName="income_item"
                                id="income_item"
                                optionLabel="name" optionValue="id"
                                placeholder="Нераспределенный доход"/>
                </div>
            </div>
            <div class="position-relative row form-group">
                <label class="col-sm-3 col-form-label" for="expenses_item">Расходная статья</label>
                <div class="col-sm-9" style="margin-top: 4px;">
                    <p-dropdown [filter]="true" [options]="itemsExpensesList"
                                [showClear]="true" [style]="{ display: 'flex', alignItems: 'center', height: '34px'}"
                                formControlName="expenses_item"
                                id="expenses_item"
                                optionLabel="name" optionValue="id"
                                placeholder="Нераспределенный расход"/>
                </div>
            </div>

            <div class="position-relative row form-group">
                <label class="col-sm-3 col-form-label">Комментарий</label>
                <div class="col-sm-9">
                <textarea class="form-control" formControlName="description"
                          name="description">
                </textarea>
                    <div *ngIf="partnerForm.controls['description'].invalid && partnerForm.controls['description'].touched"
                         class="alert alert-danger">
                        Не корректно заполнено поле
                    </div>
                </div>
            </div>
        </div>
        <app-footer-modal (cancelEmitter)="closeModal()"
                          (submitEmitter)="createUpdateFinPartner()"
                          [isClose]="true"
                          [isCreate]="!isEdit"
                          [isDisabled]="partnerForm.invalid || partnerForm.disabled"/>
    </form>
</ng-template>