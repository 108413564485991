import { IOrderOptions } from './product-id.interface';

export const SLIDES_CONFIG_5 = {
  className: 'slider variable-width',
  dots: true,
  infinite: true,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
};

export const ORDERS_OPTIONS: IOrderOptions[] = [
  { name: 'Заказы', value: 1, action: 'getOrders', actionChart: 'getChartOrders', type: 'Orders' },
  { name: 'Продажи', value: 2, action: 'getSales', actionChart: 'getChartSales', type: 'Sales' },
  { name: 'Возвраты', value: 3, action: 'getSales', actionChart: 'getChartSales', type: 'Sales' },
];
