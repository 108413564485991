<div class="app-page-title">
    <div class="page-title-wrapper d-flex justify-content-between">
        <div class="page-title-icon">
            <i class="pe-7s-user icon-gradient bg-mean-fruit"></i>
            <p class="page-title-subheading title-users">Пользователи</p>
        </div>
        <div class="right-side-header">
            <button (click)="openModal('technical__access', purchase)"
                    class="button-add-purchase btn-shadow d-inline-flex align-items-center btn btn-outline-primary me-2"
                    type="button">
                Доступ для технической поддержки
            </button>
            <button (click)="openModal('create', purchase)"
                    class="button-add-purchase btn-shadow d-inline-flex align-items-center btn btn-success"
                    type="button">
                Добавить пользователя
            </button>
        </div>
    </div>
</div>
<div class="main-card mb-3 card">
    <div class="card-header">
        Пользователи
    </div>
    <div class="table-responsive">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
            <thead>
            <tr>
                <th class="text-center">#</th>
                <th class="text-center">Телефон</th>
                <th class="text-center">Имя</th>
                <th class="text-center">Должность</th>
                <th class="text-center">Статус</th>
                <th class="text-center">Поставщики</th>
                <th class="text-center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of usersList; let i = index;">
                <td class="text-center text-muted">#{{ i + 1 }}</td>
                <td class="text-center">{{ user.username }}</td>
                <td class="text-center">{{ user.tech_user_name }}</td>
                <td class="text-center">{{ user.tech_user_job_title }}</td>
                <td class="text-center">
                    <div class="badge bg-success">{{ user.status }}</div>
                </td>
                <td class="text-center" style="width: 37%">
          <span *ngFor="let supplier of user.suppliers">
            {{ supplier.name }}
          </span>
                </td>
                <td class="text-center">
                    <div *ngIf="i !== 0" class="action-btns">
                        <p-toast></p-toast>
                        <button (click)="openModal('edit', purchase, user)"
                                class="btn btn-primary btn-sm btn-action"
                                type="button">
                            Изменить
                        </button>
                        <button (click)="deleteUser(user.username)" class="btn btn-danger btn-sm btn-action"
                                type="button">
                            Удалить
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #purchase>
    <app-header-modal
            (closeEmitter)="closeModal()"
            [modalTitle]="(isEdit ? 'Изменение' : 'Добавление') + ' пользователя'"/>
    <div class="modal-body">
        <form [formGroup]="FormUser" autocomplete="off">
            <div class="d-flex flex-column gap-3">
                <p-inputMask formControlName="user_phone"
                             mask="+99999999999"
                             placeholder="+7 (002) 555-01-02">
                </p-inputMask>
                <input class="input__field"
                       formControlName="tech_user_name"
                       pInputText
                       placeholder="Введите имя" type="text"/>
                <input class="input__field"
                       formControlName="tech_user_job_title"
                       pInputText
                       placeholder="Введите должность" type="text"/>
                <div class="block-users-checkbox"
                     formArrayName="suppliers">
                    <h5>В какие юр лица добавить доступ для пользователя:</h5>
                    <div *ngFor="let supplier of suppliersControls.controls; let i = index;" class="array">
                        <div [formGroupName]="i" class="group">
                            <input class="checkbox-part pointer" formControlName="checked" id="ny-{{i}}"
                                   name="rrra" type="checkbox">
                            <label class="pointer" for="ny-{{i}}">{{ supplier.value.name_supplier }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <app-footer-modal
            (submitEmitter)="createUpdateUser()"
            [isCreate]="!isEdit"
            [isDisabled]="buttonIsInValid"/>
</ng-template>
