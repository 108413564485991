import {Component, OnInit} from '@angular/core';
import * as moment from 'moment/moment';
import {SECOND_RANGE_TYPE} from '../../../shared/common-variables/time-ranges-date-picker';
import {
  AVERAGE_ORDERS_DAY_,
  COMMON_RATES,
  ICommonRates,
  ISalesBalanceChart, ISalesDynamicChart, ISalesDynamicChartSeries,
  SALES_BALANCE,
  SALES_BALANCE_CHART, SALES_DYNAMIC_CHART, STRUCTURE_SALES_CHART
} from '../../../shared/mocks/rates-mock';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.sass']
})
export class RatesComponent implements OnInit {
  constructor() {
  }

  get DatePickerRange() {
    return SECOND_RANGE_TYPE;
  }

  disabledDatePicker = false;
  selectedDate: any = {startDate: moment(), endDate: moment()};
  minDate = moment().add(-91, 'days');
  maxDate = moment();
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  allBrands: any;
  selectedBrand: any;
  allItems: any;
  selectedItem: any;
  allArticlesWB: any;
  selectedArticleWB: any;
  allMyArticles: any;
  selectedMyArticle: any;
  dataForCommonRatesTable1: ICommonRates[] = COMMON_RATES;
  dataForSalesBalanceChart: ISalesBalanceChart = SALES_BALANCE_CHART;
  dataForSalesBalance = SALES_BALANCE;
  dataForAverageOrders = AVERAGE_ORDERS_DAY_;
  chartSeriesData: ISalesDynamicChart[] = [];
  chartSeriesFilteredData: ISalesDynamicChartSeries[] = [];
  apxChartSalesDynamicCategories: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
  dataForSalesStructureChart: ISalesBalanceChart = STRUCTURE_SALES_CHART;
  rowSalesSubjectChartTitlesByBrand: string[] = ['Luis Vuitton', 'Calvin Klein'];
  seriesChartSaleStructureByBrand: number[] = [77, 33];
  rowSalesSubjectChartTitlesBySubjects: string[] = ['Шапки', 'Варежки', 'Брюки', 'Куртки'];
  seriesChartSaleStructureBySubjects: number[] = [33, 22, 33, 22];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'));
  }

  ngOnInit(): void {
    this.loadDataForSalesDynamic();
  }

  loadDataWithNewDate() {
  }

  selectedBrandChange(data: any) {
  }

  selectedItemChange(data: any) {
  }

  selectedArticleWbChange(data: any) {
  }

  selectedMyArticleChange(data: any) {
  }

  // SalesDynamic
  private loadDataForSalesDynamic() {
    // TODO: get data from the Serve - SALES_DYNAMIC_CHART
    this.chartSeriesData = SALES_DYNAMIC_CHART;
    //
    this.filterSalesDynamicChart();
  }

  public toggleSaleDynamic(sale: ISalesDynamicChart, index: number) {
    sale.btnStatus = !sale.btnStatus;
    this.chartSeriesData.splice(index, 1, sale);
    this.filterSalesDynamicChart();
  }

  private filterSalesDynamicChart(): ISalesDynamicChartSeries[] {
    this.chartSeriesFilteredData = [];
    this.chartSeriesData.forEach((sale) => {
      if (sale.btnStatus) {
        const newSale: ISalesDynamicChartSeries = {name: sale.name, data: sale.data};
        this.chartSeriesFilteredData.push(newSale);
      }
    });
    return this.chartSeriesFilteredData;
  }
}
