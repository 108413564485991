import { ColDef, ColGroupDef } from 'ag-grid-community';
import { CELL_CLASS } from '../../guides/products/products.mock';
import { CustomTooltipComponent } from '../../guides/products/components/prdcustomTooltip';
import { WBImgFormatter } from '../../../shared/utils/functions/wb-img-formatter';

export let REMAINS_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Фото',
    headerClass: 'header-cell-pink-light-1',
    pinned: 'left',
    field: 'photo_new',
    cellRenderer: WBImgFormatter,
    sortable: true,
    resizable: true,
    width: 100,
    filter: false,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS,
    tooltipField: 'photo_new',
    tooltipComponentParams: {
      type: 'success',
      photoField: 'photo_new',
    },
    tooltipComponent: CustomTooltipComponent,
  },
  {
    headerName: 'АВС',
    headerClass: 'header-cell-pink-light-1',
    field: 'remain_abc',
    pinned: 'left',
    floatingFilter: true,
    sortable: true,
    filter: 'agNumberColumnFilter',
    resizable: true,
    width: 150,
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Название товара',
    headerClass: 'header-cell-pink-light-1',
    field: 'product_name',
    floatingFilter: true,
    sortable: true,
    filter: 'agNumberColumnFilter',
    resizable: true,
    width: 200,
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Баркод',
    headerClass: 'header-cell-pink-light-1',
    field: 'barcode',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Артикул ВБ',
    headerClass: 'header-cell-pink-light-1',
    field: 'article_wb',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Бренд',
    headerClass: 'header-cell-pink-light-1',
    field: 'remain_brand',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Категория',
    headerClass: 'header-cell-pink-light-1',
    field: 'remain_category',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerClass: 'header-cell-pink-light-1',
    headerName: 'Дата последней продажи',
    field: 'last_sale_date',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Доступно для заказа АПИ WB (FBO)',
    headerClass: 'header-cell-green-light-1',
    field: 'available_for_order_wb',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'В пути (FBO)',
    headerClass: 'header-cell-green-dark-1',
    field: 'on_way_fbo',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Всего на складе ВБ (FBO)',
    headerClass: 'header-cell-green-dark-2',
    field: 'total_wh_wbo',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'FBS остатки',
    headerClass: 'header-cell-green-dark-3',
    field: 'remains_fbs',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'FBO + FBS итого',
    field: 'remains_total_fbo_fbs',
    headerClass: 'header-cell-green-dark-3',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Остатки от ФФ',
    headerClass: 'header-cell-pink-light-2',
    field: 'remains_ff',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Расхождения ФФ - FBS',
    headerClass: 'header-cell-pink-light-2',
    field: 'remains_ff_fbs',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'FBO + ФФ итого',
    field: 'remain_total_fbo_ff',
    headerClass: 'header-cell-pink-light-3',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Лист Склады',
    field: 'wh_list',
    headerClass: 'header-cell-green-light-2',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'СВХ',
    field: 'remains_cbx',
    headerClass: 'header-cell-green-light-0',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'ВБ (не в продаже)',
    headerClass: 'header-cell-green-light-0',
    field: 'outside_sale_wb',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'В пути из Китая',
    field: 'on_the_way_china',
    headerClass: 'header-cell-green-light-0',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Сток в с/с последней партии',
    headerClass: 'header-cell-grey-light-1',
    field: 'stok_cc_last_part',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Сток в с/с + фикс',
    headerClass: 'header-cell-grey-light-1',
    field: 'stok_cc_fix',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Сток в ценах продажи',
    field: 'stok_sales_prices',
    headerClass: 'header-cell-grey-light-1',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'с/с последней партии, за шт.',
    field: 'cc_last_income_item',
    headerClass: 'header-cell-blue-light-1',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'с/с + фикс, за шт.',
    field: 'cc_fix_item',
    headerClass: 'header-cell-blue-light-1',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Цена продажи (вычет СПП - по фильтру выше)',
    field: 'price_sale_cpp',
    headerClass: 'header-cell-pink-light-1',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Заказов* за 7 дней',
    field: 'order_seven_days',
    headerClass: 'header-cell-green-light-1',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Заказов* за 14 дней',
    field: 'order_fourteen_days',
    headerClass: 'header-cell-green-light-1',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Заказов* за 30 дней ',
    field: 'order_thirty_days',
    headerClass: 'header-cell-green-light-1',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Скорость заказов средняя, шт в день',
    field: 'order_speed_average_per_day',
    headerClass: 'header-cell-green-light-2',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: '% выкупа за 30 дней',
    field: 'percent_sale_30_days',
    floatingFilter: true,
    headerClass: 'header-cell-green-light-0',
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Остатки TOTAL',
    field: 'remains_total',
    headerClass: 'header-cell-green-dark-2',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'На сколько дн. хватит товара ВСЕГО',
    field: 'total_days_enough',
    headerClass: 'header-cell-green-dark-2',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Дата 0 остатков ВСЕГО',
    field: 'date_0_remains_all',
    headerClass: 'header-cell-green-dark-2',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'На сколько дн. хватит товара на FBO',
    field: 'day_enough_item_fbo',
    headerClass: 'header-cell-pink-light-2',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Дата 0 остатков FBO',
    field: 'date_0_remains_fbo',
    headerClass: 'header-cell-pink-light-2',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
    flex: 0,
  },
];

export const ROW_DATA_REMAINS_MOCK: any[] = [
  {
    photo_new: 'https://basket-05.wb.ru/vol870/part87020/87020176/images/big/1.jpg',
    remain_abc: 123123123,
    product_name: 'Apple',
    barcode: 123123123,
    article_wb: 122223,
    remain_brand: 'GOOCHI',
    remain_category: 'Bags',
    last_sale_date: '11.11.2023',
    available_for_order_wb: 2,
    on_way_fbo: 23,
    total_wh_wbo: 44,
    remains_fbs: 99,
    remains_total_fbo_fbs: 988,
    remains_ff: 2,
    remains_ff_fbs: 988,
    remain_total_fbo_ff: 9,
    wh_list: 77,
    remains_cbx: 6,
    outside_sale_wb: 27,
    on_the_way_china: 9,
    stok_cc_last_part: 80,
    stok_cc_fix: 1,
    stok_sales_prices: 102,
    cc_last_income_item: 344,
    cc_fix_item: 232,
    price_sale_cpp: 88,
    order_seven_days: 99,
    order_fourteen_days: 772,
    order_thirty_days: 2990,
    order_speed_average_per_day: 123,
    percent_sale_30_days: 200,
    remains_total: 1992,
    total_days_enough: 88,
    date_0_remains_all: 123,
    day_enough_item_fbo: 200,
    date_0_remains_fbo: 22,
  },
  {
    photo_new: 'https://basket-05.wb.ru/vol870/part87020/87020176/images/big/1.jpg',
    remain_abc: 123123123,
    product_name: 'Apple',
    barcode: 123123123,
    article_wb: 122223,
    remain_brand: 'GOOCHI',
    remain_category: 'Bags',
    last_sale_date: '11.11.2023',
    available_for_order_wb: 2,
    on_way_fbo: 23,
    total_wh_wbo: 44,
    remains_fbs: 99,
    remains_total_fbo_fbs: 988,
    remains_ff: 2,
    remains_ff_fbs: 988,
    remain_total_fbo_ff: 9,
    wh_list: 77,
    remains_cbx: 6,
    outside_sale_wb: 27,
    on_the_way_china: 9,
    stok_cc_last_part: 80,
    stok_cc_fix: 1,
    stok_sales_prices: 102,
    cc_last_income_item: 344,
    cc_fix_item: 232,
    price_sale_cpp: 88,
    order_seven_days: 99,
    order_fourteen_days: 772,
    order_thirty_days: 2990,
    order_speed_average_per_day: 123,
    percent_sale_30_days: 200,
    remains_total: 1992,
    total_days_enough: 88,
    date_0_remains_all: 123,
    day_enough_item_fbo: 200,
    date_0_remains_fbo: 22,
  },
];
