import { CustomTooltipComponent } from '../../guides/products/components/prdcustomTooltip';
import { KeyQueriesManageCellComponent } from './key-queries-components/key-queries-manage-cell.component';
import { KeyQueriesDisplayCellComponent } from './key-queries-components/key-queries-display-cell.component';
import { WBProductsLinkEmitter } from '../../../shared/utils/functions/wb-products-link-emitter';
import { WBImgFormatter } from '../../../shared/utils/functions/wb-img-formatter';

export const KEY_QUERIES_COLUMN_DEFS: any[] = [
  {
    headerName: 'Фото',
    cellRenderer: WBImgFormatter,
    pinned: 'left',
    field: 'thumb',
    width: 100,
    filter: 'agTextColumnFilter',
    tooltipField: 'thumb',
    floatingFilter: false,
    tooltipComponentParams: {
      type: 'thumb',
      photoField: 'thumb',
    },
    tooltipComponent: CustomTooltipComponent,
  },
  {
    headerName: 'Артикул',
    field: 'articles',
    width: 150,
    pinned: 'left',
    filter: 'agTextColumnFilter',
    cellRenderer: WBProductsLinkEmitter,
  },
  {
    headerName: 'Динамика за последние сутки',
    field: 'dynamics',
    width: 150,
    pinned: 'left',
    filter: 'agTextColumnFilter',
    cellRenderer: KeyQueriesDisplayCellComponent,
  },
  {
    headerName: 'Ключевой запрос',
    field: 'key_query',
    width: 150,
    pinned: 'left',
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Действия',
    pinned: 'right',
    field: '',
    filter: false,
    cellRenderer: KeyQueriesManageCellComponent,
    width: 150,
  },
];
export const DefaultKeyParent = {
  headerName: 'Даты',
  headerClass: 'header-centered',
  children: [],
};

export const DefaultKeyQueryColumn = {
  headerName: '20.07',
  headerClass: 'header-centered',
  field: '2007',
  sortable: true,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  cellStyle: { textAlign: 'center' },
  columnGroupShow: 'open',
};

export const KeyPopUpMessages = {
  created: 'Ключевой запрос создан',
  updated: 'Ключевой запрос изменен',
  removed: 'Ключевой запрос удален',
  failed: 'Не удалось создать ключевой запрос. Проверьте введеные данные и повторите попытку!',
  remove: 'Вы действительно хотите удалить ключевой запрос?',
};
