import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../shared/services/api.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ISupplierList, ISuppliers, IUserResponse } from './users.interface';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass'],
  providers: [MessageService],
})
export class UsersComponent implements OnInit {
  shopUsers: ISupplierList[] = [];

  FormUser: FormGroup;

  usersList: IUserResponse[] = [];

  suppliersList = [];

  blockInput = false;

  isEdit = false;

  constructor(
    private modalService: NgbModal,
    private messageService: MessageService,
    private api: ApiService,
    private formBuilder: FormBuilder,
  ) {
    this.FormUser = this.formBuilder.group({
      user_phone: new FormControl(null, [Validators.required, Validators.minLength(12)]),
      tech_user_name: new FormControl(null, [Validators.required, Validators.maxLength(150)]),
      tech_user_job_title: new FormControl(null, [Validators.maxLength(150)]),
      suppliers: this.formBuilder.array([]),
    });
  }

  get suppliersControls(): FormArray {
    return this.FormUser.controls.suppliers as FormArray;
  }

  get buttonIsInValid(): boolean {
    let suppliersData = this.FormUser.getRawValue();
    let phone = this.FormUser.controls.user_phone.getRawValue();
    let name = this.FormUser.controls.tech_user_name.getRawValue();
    if (phone && name && suppliersData.suppliers.length !== 0) {
      suppliersData = suppliersData.suppliers.map((x) => x.checked);
      if (!suppliersData.includes(true)) {
        return true;
      }
      phone = phone.replace('_', '');
      return phone.length !== 12 && !suppliersData.includes(true);
    }
    return true;
  }

  ngOnInit(): void {
    this.loadUsers();
    this.loadSuppliers();
  }

  createUpdateUser() {
    const data = this.FormUser.getRawValue();
    const activeSupplier = [];
    data.suppliers.forEach((user) => {
      if (user.checked) {
        activeSupplier.push(user.id);
      }
    });
    const body = {
      phone: data.user_phone,
      tech_user_name: data.tech_user_name,
      tech_user_job_title: data.tech_user_job_title,
      shop_id: activeSupplier,
    };
    this.api.userRegister('accountAddUser', localStorage.getItem('token'), body).subscribe(
      (response: any) => {
        if (response.is_error === 0) {
          this.loadUsers();
          this.modalService.dismissAll();
          this.showPopUpMessage(
            'success',
            this.isEdit ? PopUpMessages.updateSuccessSummary : PopUpMessages.createSuccessSummary,
            '',
          );
        }
        if (response.is_error === 1) {
          this.showPopUpMessage('error', 'Ошибка добавления пользователя', '');
        }
      },
      () => {
        this.showPopUpMessage('error', 'Ошибка добавления пользователя', '');
      },
    );
  }

  openModal(parameter: string, modal: any, user?: any) {
    this.isEdit = parameter === 'edit';
    if (parameter === 'create') {
      this.loadSuppliers();
      this.suppliersControls.clear();
    } else if (parameter === 'technical__access') {
      this.FormUser.controls.user_phone.setValue('+79032632693');
      this.suppliersControls.controls.forEach((control: FormGroup) => {
        control.controls.checked.setValue(true);
      });
    } else {
      this.FormUser.controls.user_phone.setValue(user.username);
      const activeSuppliersFromServerID = user.suppliers.map((user: ISuppliers) => user.id);
      this.suppliersControls.controls.forEach((control: FormGroup) => {
        if (activeSuppliersFromServerID.includes(control.value.id)) {
          control.controls.checked.setValue(true);
        }
      });
      this.blockInput = true;
    }

    this.modalService.open(modal);
  }

  closeModal() {
    this.modalService.dismissAll();
    // this.FormUser.reset();
  }

  deleteUser(phone: string) {
    if (confirm('Вы уверены, что хотите удалить пользователя ?')) {
      const body = { phone };
      this.api.userRegister('accountUserDelete', localStorage.getItem('token'), body).subscribe(
        (response: any) => {
          if (response.is_error === 0) {
            this.loadUsers();
            this.showPopUpMessage('success', PopUpMessages.deleteSuccessSummary, 'Пользователь удален');
          }
        },
        (err) => {
          this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
        },
      );
    }
  }

  protected addStaticUser(): void {
    const phoneNumber = JSON.parse(localStorage.getItem('profilePhoneNumber'));
    if (phoneNumber) {
      this.usersList.unshift({
        username: phoneNumber,
        status: 'ACTIVE',
        suppliers: [],
        tech_user_name: '',
        tech_user_job_title: '',
      });
    }
  }

  private loadSuppliers() {
    this.FormUser.reset();
    this.api.userRegister('getDataShopWB', localStorage.getItem('token'), '').subscribe(
      (shopList: any[]) => {
        shopList.forEach((item) => {
          this.suppliersControls.push(this.getRenderedFormGroup(item));
          const supplier = {
            id: item.id,
            name_supplier: item.name,
          };
          this.shopUsers.push(supplier);
        });
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  private getRenderedFormGroup(groupData: any): FormGroup {
    return new FormGroup({
      id: new FormControl(groupData.id),
      name_supplier: new FormControl(groupData.name),
      checked: new FormControl(false),
    });
  }

  private loadUsers() {
    const body = { params: null };
    this.api.userRegister('accountUserList', localStorage.getItem('token'), body).subscribe(
      async (response: IUserResponse[]) => {
        this.usersList = response;
        this.addStaticUser();
      },
      (error) => {
        console.error(error);
      },
    );
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
