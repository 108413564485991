import { Component, HostBinding, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptions } from '../../shared/theme-options/theme-options';
import { FILTER_PARAMS_FIELDS } from '../../shared/utils/constants/filter-params-controller';
import { FormControl, FormGroup } from '@angular/forms';
import { IAllSupplier, ILabelSupplier } from './header.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @select('config') public config$: Observable<any>;

  isActive: boolean;

  allSuppliers: ILabelSupplier[] = [];

  readonly form = new FormGroup({
    activeSupplier: new FormControl(null),
  });

  readonly faEllipsisV = faEllipsisV;

  constructor(public globals: ThemeOptions) {}

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  ngOnInit() {
    this.checkWidth();
    if (localStorage.getItem('supplier')) {
      const supplier = JSON.parse(localStorage.getItem('supplier')) as IAllSupplier[];
      this.allSuppliers = supplier.map((item: any) => ({ label: item.name, value: item }));
    }
    if (localStorage.getItem('mpall_shop')) {
      const item = this.allSuppliers.find(
        (element) => element.value.id === JSON.parse(localStorage.getItem('mpall_shop')).shop,
      );
      this.form.controls.activeSupplier.patchValue(item.value);
    }
  }

  onSelectedChange() {
    const supplierData = this.form.getRawValue().activeSupplier;
    localStorage.setItem(
      'mpall_shop',
      JSON.stringify({
        shop: supplierData?.id,
        name: supplierData?.name,
      }),
    );
    sessionStorage.removeItem(FILTER_PARAMS_FIELDS.supplierManagerFilterParams);
    sessionStorage.removeItem(FILTER_PARAMS_FIELDS.purchaseFilterParams);
    window.location.reload();
  }

  checkWidth() {
    if (window.innerWidth < 800) {
      return true;
    }
  }

  checkWidthLogo() {
    if (window.innerWidth < 400) {
      return true;
    }
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }
}
