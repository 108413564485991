import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../shared/services/api.service';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDataShopWB, IFinItemList2, IFinPartnersList } from './finpartners.interface';
import { FINPARTNERS_COLUMN_DEFS } from './finpartners.mock';
import { ColDef } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { SharedDataService } from '../../../../shared/services/shared-data.service';
import { LOCALE_TEXT_FOR_FILTERS } from '../../../guides/products/products.mock';
import { UntilDestroy } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { PopUpStatus } from '../../../../shared/interfaces/common.type';
import { MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../../shared/mocks/pop-up-messages.mock';
import { IResponseCommon } from '../../../settings/shop/shop.interface';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-finpartners',
  templateUrl: './finpartners.component.html',
  styleUrls: ['./finpartners.component.sass'],
  providers: [MessageService],
})
export class FinpartnersComponent implements OnInit {
  //  AG_GRID
  rowData: IFinPartnersList[] = [];
  rowsForRemoving: any[] = [];

  readonly finPartnersColumnDF = FINPARTNERS_COLUMN_DEFS;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    floatingFilter: true,
    cellStyle: { textAlign: 'center' },
  };
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly gridOptions: GridOptions;
  readonly options: GlobalConfig;
  readonly partnerForm: FormGroup;
  itemsIncomeList: IFinItemList2[] = [];
  itemsExpensesList: IFinItemList2[] = [];
  isLoading = false;
  shop_list: IDataShopWB[] = [];
  modal_partner_id: any;
  items: any;

  isEdit = false;
  activeUID = null;
  @ViewChild('createPartnerModal', { read: TemplateRef }) createPartnerModal: TemplateRef<any>;
  @ViewChild('updatePartnerModal', { read: TemplateRef }) updatePartnerModal: TemplateRef<any>;

  constructor(
    private api: ApiService,
    public toastr: ToastrService,
    private sharedDataService: SharedDataService,
    private messageService: MessageService,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    this.options = this.toastr.toastrConfig;
    this.partnerForm = this.fb.group({
      shop: new FormControl(null),
      expenses_item: new FormControl(''),
      income_item: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
      inn: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      kpp: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      checking_account: new FormControl(''),
      description: new FormControl(''),
    });
    this.gridOptions = {
      context: { componentParent: this },
      enableCellTextSelection: true,
      suppressRowClickSelection: true,
    } as GridOptions;
  }

  ngOnInit(): void {
    this.loadPartners();
    this.getShopList();
    this.getListOfItems();
  }

  onRowSelected(event: any) {
    const selectedUID = event.data.UID;
    const selectedStatus = event.node.selected;
    if (selectedStatus) {
      this.rowsForRemoving.push(selectedUID);
    } else {
      this.rowsForRemoving = this.rowsForRemoving.filter((row) => row !== selectedUID);
    }
  }

  removeSelected() {
    if (confirm('Вы уверены, что хотите удалить запись ?')) {
      const requests = this.rowsForRemoving.map((row) => {
        const body = { uid: row, shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop };
        return this.api.userRegisterFin('getFinPartnersDelete', localStorage.getItem('token'), body);
      });
      this.isLoading = true;
      forkJoin(requests)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          () => {
            this.loadPartners();
            this.rowsForRemoving.length = 0;
            this.showPopUpMessage('success', PopUpMessages.deleteSuccessSummary, PopUpMessages.deleteSuccessMessage);
          },
          () => {
            this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
          },
        );
    }
  }

  openModalCreatePartner(content) {
    this.partnerForm.reset();
    this.isEdit = false;
    this.modalService.open(content);
  }

  openModalUpdatePartner(rowData: IFinPartnersList) {
    this.activeUID = rowData.UID;
    const body = { shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop, uid: rowData.UID };
    this.api.userRegisterFin('getFinPartnersOne', localStorage.getItem('token'), body).subscribe(
      (data: any) => {
        this.modal_partner_id = data.UID;
        this.partnerForm.patchValue({
          shop: data.user_shop_id,
          name: data.name,
          inn: data.INN,
          kpp: data.KPP,
          checking_account: data.checking_account,
          description: data.description,
          expenses_item: data.expenses_item,
          income_item: data.income_item,
        });
        this.isEdit = true;
        this.modalService.open(this.createPartnerModal, { backdrop: 'static' });
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  createUpdateFinPartner() {
    this.isLoading = true;
    const body = {
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop,
      params: this.partnerForm.getRawValue(),
      uid: this.isEdit ? this.activeUID : undefined,
    };
    const action = this.isEdit ? 'getFinPartnersUpdate' : 'getFinPartnersAdd';
    this.api
      .userRegisterFin(action, localStorage.getItem('token'), body)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: IResponseCommon) => {
          if (+response.is_error === 0) {
            this.isEdit
              ? this.showPopUpMessage('success', PopUpMessages.updateSuccessSummary, 'Контрагент изменен')
              : this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Контрагент создан');
            this.partnerForm.reset();
            this.modalService.dismissAll();
            this.loadPartners();
          } else {
            this.showPopUpMessage(
              'error',
              this.isEdit ? PopUpMessages.updateFailedSummary : PopUpMessages.createFailedSummary,
              response.msg,
            );
          }
        },
        () => {
          this.isEdit
            ? this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage)
            : this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
        },
      );
  }

  deletePartner(rowData: IFinPartnersList) {
    if (confirm('Вы уверены, что хотите удлаить запись ?')) {
      const body = { uid: rowData.UID, shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop };
      this.api.userRegisterFin('getFinPartnersDelete', localStorage.getItem('token'), body).subscribe(
        (response: any) => {
          if (+response.is_error === 0) {
            this.showPopUpMessage('success', response.msg, '');
            this.loadPartners();
          } else {
            this.showPopUpMessage('error', response.msg, '');
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.deleteSuccessSummary, PopUpMessages.deleteFailedMessage);
        },
      );
    }
  }

  private loadPartners() {
    this.isLoading = true;
    const body = { shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop };
    this.api
      .userRegisterFin('getFinPartners', localStorage.getItem('token'), body)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: IFinPartnersList[]) => {
          this.rowData = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private getShopList() {
    this.isLoading = true;
    this.api
      .userRegister('getDataShopWB', localStorage.getItem('token'), '')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: IDataShopWB[]) => {
          this.shop_list = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private getListOfItems() {
    // tslint:disable-next-line:variable-name
    const shop_id = JSON.parse(localStorage.getItem('mpall_shop')).shop;
    this.api
      .userRegisterFin('getFinItemList2', localStorage.getItem('token'), { shop_id })
      .subscribe((response: IFinItemList2[]) => {
        this.itemsIncomeList = response.filter((item) => +item.item_type === 1);
        this.itemsExpensesList = response.filter((item) => +item.item_type === 2);
        this.sharedDataService.setUpData(response, 'articles');
      });
  }

  private getFullNameOfItems(itemId: number, status: string): string {
    if (itemId) {
      if (status === 'income') {
        return this.itemsIncomeList.filter((item) => +item.id === itemId)[0]?.name ?? '--';
      }
      if (status === 'expenses') {
        return this.itemsExpensesList.filter((item) => +item.id === itemId)[0]?.name ?? '--';
      }
    }
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
