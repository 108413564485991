<div *ngIf="view_google_table_info" class="col-sm-6 col-md-6">
    <div class="mb-3 card text-white card-body bg-warning">
        <h5 class="card-title">Обращаем внимание, веб сервис и гугл таблица - разные продукты от MP Surf</h5>
        <div class="col-sm-4 col-md-4">
            <button (click)="closeGoogleTableMsg(1)" class="btn btn-primary mt-2 mr-2">Все понятно</button>
        </div>
    </div>
    <br/>
</div>
<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-diamond icon-gradient bg-mean-fruit"></i>
            </div>
            <h4 class="me-4">
                Мои товары
            </h4>
            <div class="btn-actions-pane-left d-flex gap-3">
                <p-toast/>
                <button (click)="switchProductsAvailibility(1)" *ngIf="OBOROT === 0" class=" mr-2 btn btn-danger">
                    Показать выведенные из оборота
                </button>
                <button (click)="switchProductsAvailibility(0)" *ngIf="OBOROT === 1" class=" mr-2 btn btn-success">
                    Скрыть выведенные из оборота
                </button>
                <p-fileUpload #uploadedFiles
                              (onError)="onBasicUploadError($event,uploadedFiles)"
                              (onSend)="onSendData()"
                              (onUpload)="onBasicUploadAuto($event,uploadedFiles)"
                              [auto]="true"
                              [maxFileSize]="10000000"
                              [style]="{ height: '37px', fontSize: '12px'}" accept=".xls, .xlsx, .csv"
                              chooseLabel="Загрузить из excel"
                              class="mr-4 inline-block"
                              label="Import"
                              mode="basic"
                              name="file"
                              url="{{URL_NEW}}products/uploaded?token={{TOKEN}}&shop_id={{SHOP_ID}}"/>
                <button (click)="openModal()" class="mr-2 btn btn-success">
                    Статусы товаров
                </button>
            </div>
        </div>
    </div>
</div>
<div class="main-card mb-3 card">
    <div *ngIf="loading" style="width: 100%;height: calc(92vh - 167px)">
        <ag-grid-angular
                (cellValueChanged)="onCellValueChanged($event)"
                (gridReady)="onGridReady($event)"
                [columnDefs]="columnDefsSales"
                [defaultColDef]="defaultColDef"
                [enableRangeSelection]="true"
                [getRowStyle]="getRowStyle"
                [gridOptions]="gridOptions"
                [localeText]="localeTextForFilters"
                [pagination]="true"
                [rowData]="rowData"
                [rowDragManaged]="true"
                [rowHeight]="40"
                [tooltipHideDelay]="tooltipHideDelay"
                [tooltipShowDelay]="tooltipShowDelay"
                class="ag-theme-alpine"
                rowSelection="multiple"
                style="width: 100%; height: 100%;"/>
        <button (click)="exportExcel()"
                class="mb-2 btn btn-success add-operation-btn button-export-fin"
                type="button">
            Экспорт в Excel
        </button>
    </div>
</div>

<ng-template #productStatuesModal>
    <app-header-modal (closeEmitter)="closeModal()"
                      [modalTitle]="'Добавление статусов товаров'">
    </app-header-modal>
    <form [formGroup]="statusesForm">
        <div class="modal-body">
            <div class="separate__block"
                 formArrayName="statuses">
                <div *ngFor="let statuses of FormArrayStatuses.controls; let i = index;"
                     class="wrapp-operation">
                    <div [formGroupName]="i" class="wrap__expenses__block mb-2">
                        <input [style]="{width: '100%'}" formControlName="name" pInputText type="text"/>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <app-footer-modal
            (close)="closeModal()"
            (submitEmitter)="createStatuses()"
            [isCancel]="true"
            [isCreate]="true"
            [isDisabled]="isDisabledModal">
    </app-footer-modal>
</ng-template>
<p-toast/>

