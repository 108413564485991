import {AdvertNameCode, CardDetailsPic} from './advert-management-card.interface';

export const ADVERT_AUCTION: CardDetailsPic[] = [
  {id: '', title: 'Максимальная ставка', value: 0},
  {id: '', title: 'Текущая ставка', value: 0},
  {id: '', title: 'Целевая позиция', value: 0}
];
export const CARD_DETAILS_STAT_GRAY: CardDetailsPic[] = [
  {id: 'views', title: 'Просмотры', value: 0},
  {id: 'frq', title: 'Частоты', value: 0},
  {id: 'clicks', title: 'Клики', value: 0},
  {id: 'cpc', title: 'CPC', value: 0},
  {id: 'ctr', title: 'CTR', value: 0},
  {id: 'sum', title: 'Затраты', value: 0},
];
export const companyAdvertAdditionalMOCK: string[] = [
  'Сумка спортивна', 'Сумка не спортивна', 'Сумка крутая'
];
export const CompanyRegionMock: AdvertNameCode[] = [
  {name: 'New York', code: 'NY'},
  {name: 'Rome', code: 'RM'},
  {name: 'London', code: 'LDN'},
  {name: 'Istanbul', code: 'IST'},
  {name: 'Paris', code: 'PRS'}
];
