import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
    title = 'MpSurf.ru сервис аналитики и статистики';

    online = true;

    constructor(public router: Router, @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit(): void {
        this.isOnlineUser();
    }

    private isOnlineUser(): void {
        this.online = navigator.onLine;
        window.addEventListener('online', () => {
            this.online = true;
        });
        window.addEventListener('offline', () => {
            confirm('Пожалуйста, проверьте свое интернет соединение!');
            this.online = false;
        });
    }
}

