import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseLayoutComponent } from './general-components/base-layout/base-layout.component';
import { LoginComponent } from './general-components/auth/login/login.component';
import { SupplierSelectionComponent } from './general-components/auth/components/supplier-selection/supplier-selection.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard/orders',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('./general-components/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: '',
        component: BaseLayoutComponent,
        children: [
            {
                path: 'external-analysis',
                loadChildren: () =>
                    import('./menu-tabs/external-analysis/external-analysis.module').then((m) => m.ExternalAnalysisModule),
            },
            {
                path: 'analytic',
                loadChildren: () => import('./menu-tabs/analytic/analytic.module').then((m) => m.AnalyticModule),
            },
            {
                path: 'admin',
                loadChildren: () => import('./menu-tabs/admin/admin.module').then((m) => m.AdminModule),
            },
            {
                path: 'adv',
                loadChildren: () => import('./menu-tabs/advert/advert.module').then((m) => m.AdvertModule),
            },
            {
                path: 'wb',
                loadChildren: () =>
                    import('./menu-tabs/wb-search/wb-search.module').then((m) => m.WbSearchModule),
            },
            {
                path: 'guides',
                loadChildren: () => import('./menu-tabs/guides/guides.module').then((m) => m.GuidesModule),
            },
            {
                path: 'fin',
                loadChildren: () => import('./menu-tabs/finances/finances.module').then((m) => m.FinancesModule),
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./menu-tabs/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./menu-tabs/settings/settings.module').then((m) => m.SettingsModule),
            },
            {
                path: 'profile',
                loadChildren: () => import('./general-components/profile/profile.module').then((m) => m.ProfileModule),
            },
        ],
    },
    {
        path: 'supplier-selection',
        component: SupplierSelectionComponent
    },
    {
        path: 'user',
        component: LoginComponent,
        data: {extraParameter: ''}
    },
    {
        path: '**',
        redirectTo: '/dashboard/orders',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
