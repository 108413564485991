<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"/>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
    <div *ngIf="isGoogleTableInfoVisible" class="col-sm-6 col-md-6">
        <div class="mb-3 card text-white card-body bg-warning">
            <h5 class="card-title">Обращаем внимание, веб сервис и гугл таблица - разные продукты от MP Surf</h5>

            <div class="col-sm-4 col-md-4">
                <button (click)="closeGoogleTableMsg(1)" class="btn btn-primary mt-2 mr-2">Все понятно</button>
            </div>
        </div>
        <br/>
    </div>
    <div [ngClass]="{'active' : isHintDialogOpenIds.length > 0}" class="hint-block"></div>
    <div [ngClass]="{'display-active-block-hint' : this.currentHintId === 1}" class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="d-flex align-items-center">
                    <div class="page-title-icon">
                        <i class="lnr-cart icon-gradient bg-mean-fruit"></i>
                    </div>
                    <div class="icons-wrapper">
                        Мои заказы &nbsp;&nbsp;
                        <ng-container
                                [ngTemplateOutletContext]="{hintId : 0, arrowPosition: 'top', dialogOrder: 'dialog-1'}"
                                [ngTemplateOutlet]="hintDialogMyOrders">
                        </ng-container>
                    </div>

                    <app-mp-ngb-range-datepicker [rangeType]="ranges"
                                                 (selectedDateEmitter)="dateChanged($event)"/>
                </div>

                <div class="d-flex align-items-center gap-3 filterBlock">
                    <button *ngIf="showCancelOrders == 1; else showAll"
                            (click)="loadCanceledOrders(1)"
                            class="btn-action-elem mr-2 btn btn-danger">
                        Показать только отмены
                    </button>
                    <ng-template #showAll>
                        <button (click)="loadCanceledOrders(0)"
                                class="btn-action-elem mr-2 btn btn-success">
                            Показать все
                        </button>
                    </ng-template>
                    <p-dropdown (onChange)="selectedParameters()"
                                [(ngModel)]="selectedFBOFBS"
                                [options]="fboFbsList"
                                [showClear]="false"
                                [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                                dataKey="1"
                                emptyMessage="Список пуст"
                                optionLabel="name"
                                placeholder="Тип доставки"/>
                </div>
                <div class="d-flex align-items-center gap-3 filterBlock">
                    <p-multiSelect (onChange)="selectedParameters()"
                                   (onClear)="onClearParameters('article')"
                                   [(ngModel)]="selectedNm"
                                   [maxSelectedLabels]="1"
                                   [options]="nmidList"
                                   [showClear]="true"
                                   [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                                   defaultLabel="Выберите артикул"
                                   emptyMessage="Артикулов не найдено"
                                   itemSize="30"
                                   optionLabel="name"
                                   selectedItemsLabel="Выбрано {0}"/>
                    <p-multiSelect (onChange)="selectedParameters()" (onClear)="onClearParameters('warehouse')"
                                   [(ngModel)]="selectedWH"
                                   [maxSelectedLabels]="1"
                                   [options]="wareHousesList"
                                   [showClear]="true"
                                   [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                                   defaultLabel="Выберите склад" emptyMessage="Складов не найдено" itemSize="30"
                                   optionLabel="name" selectedItemsLabel="Выбрано {0}">
                    </p-multiSelect>
                </div>
                <div class="d-flex align-items-center gap-3 filterBlock">
                    <p-multiSelect (onChange)="selectedParameters()" (onClear)="onClearParameters('category')"
                                   [(ngModel)]="selectedCategory"
                                   [maxSelectedLabels]="1"
                                   [options]="categoriesList"
                                   [showClear]="true"
                                   [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                                   defaultLabel="Выберите категорию" emptyMessage="Категорий не найдено"
                                   itemSize="30"
                                   optionLabel="name" selectedItemsLabel="Выбрано {0}">
                    </p-multiSelect>
                    <p-multiSelect (onChange)="selectedParameters()" (onClear)="onClearParameters('subject')"
                                   [(ngModel)]="selectedSubject"
                                   [maxSelectedLabels]="1"
                                   [options]="subjectsList"
                                   [showClear]="true"
                                   [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                                   defaultLabel="Выберите предмет" emptyMessage="Предметов не найдено" itemSize="30"
                                   optionLabel="name" selectedItemsLabel="Выбрано {0}">
                    </p-multiSelect>
                </div>
            </div>
        </div>
    </div>
    <div class="main-card mb-3 card">
        <ng-container [ngTemplateOutletContext]="{hintId : 1, arrowPosition: 'top', dialogOrder: 'dialog-2'}"
                      [ngTemplateOutlet]="hintDialogMyOrders">
        </ng-container>
        <div *ngIf="isLoading" class="font-icon-wrapper float-left mr-12 mb-12">
            <div class="col-md-12 col-lg-12 col-xl-12">
                <div class="main-card mb-3 card">
                    <div class="loader-wrapper d-flex justify-content-center align-items-center">
                        <div class="loader">
                            <div class="ball-pulse">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!isLoading">
            <div [ngClass]="{'display-active-block-hint' : currentHintId === 2}" class="card-header">
                Заказы ({{ paginationItemsCount }} шт)&nbsp;&nbsp;&nbsp;ср. в день: {{ avgItemsCount }}&nbsp;&nbsp;&nbsp;
                отмена
                ({{ totalOrderCancel }} шт) {{ cancelPercentNum }} %
            </div>
            <div class="row">
                <div class="col-sm-10 col-md-10">
                    <div [ngClass]="{'display-active-block-hint' : this.currentHintId === 2}" class="card-body">
                        <div id="chart">
                            <ng-container *ngIf="ordersChartData">
                                <apx-chart [chart]="ordersChartData.chart"
                                           [dataLabels]="ordersChartData.dataLabels"
                                           [series]="ordersChartData.series"
                                           [stroke]="ordersChartData.stroke"
                                           [xaxis]="ordersChartData.xaxis"
                                           [yaxis]="ordersChartData.yaxis">
                                </apx-chart>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div [ngClass]="{'display-active-block-hint' : this.currentHintId === 3}" class="col-sm-2 col-md-2">
                    <ng-container
                            [ngTemplateOutletContext]="{hintId : 2, arrowPosition: 'right', dialogOrder: 'dialog-3'}"
                            [ngTemplateOutlet]="hintDialogMyOrders">
                    </ng-container>
                    <div class="col-sm-12 col-md-12">
                        <div class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
                            <div class="widget-chat-wrapper-outer">
                                <div class="widget-chart-content">
                                    <h6 class="widget-subheading block-chart-title-amounts">Заказы</h6>
                                    <div class="widget-chart-flex">
                                        <div class="widget-numbers mb-0 w-100">
                                            <div class="widget-chart-flex">
                                                <div class="priceAmount">
                                                    <small class="opacity-5">₽</small>
                                                    {{ totalOrderMoney | number: '1.0-0' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
                            <div class="widget-chat-wrapper-outer">
                                <div class="widget-chart-content">
                                    <h6 class="widget-subheading block-chart-title-amounts">Заказы</h6>
                                    <div class="widget-chart-flex">
                                        <div class="widget-numbers mb-0 w-100">
                                            <div class="widget-chart-flex">
                                                <div class="priceAmount">
                                                    <small class="opacity-5">ШТ</small>
                                                    {{ totalOrderQty }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
                            <div class="widget-chat-wrapper-outer">
                                <div class="widget-chart-content">
                                    <h6 class="widget-subheading block-chart-title-amounts">Отмены</h6>
                                    <div class="widget-chart-flex">
                                        <div class="widget-numbers mb-0 w-100">
                                            <div class="widget-chart-flex">
                                                <div class="priceAmount">
                                                    <small class="opacity-5">ШТ</small>
                                                    {{ totalOrderCancel | number: '1.0-0' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="isLoading" class="font-icon-wrapper float-left mr-12 mb-12">
            <div class="col-md-12 col-lg-12 col-xl-12">
                <div class="main-card mb-3 card">
                    <div class="loader-wrapper d-flex justify-content-center align-items-center">
                        <div class="loader">
                            <div class="ball-pulse">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div [ngClass]="{'display-active-block-hint' : this.currentHintId === 4}"
                 class="col-md-12 col-lg-12 col-xl-12">
                <ng-container [ngTemplateOutletContext]="{hintId : 3, arrowPosition: 'bottom', dialogOrder: 'dialog-4'}"
                              [ngTemplateOutlet]="hintDialogMyOrders">
                </ng-container>
                <div *ngIf="!isLoading" class="table-responsive" style="background: #FFF;">
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                        <thead>
                        <tr>
                            <th class="text-center">Фото</th>
                            <th class="text-center">Название</th>
                            <th class="text-center">Ариткул</th>
                            <th class="text-center">Дата заказа</th>
                            <th class="text-center">Цена</th>
                            <th class="text-center">Склад</th>
                            <th class="text-center">Регион доставки</th>
                            <th class="text-left">№ заказа</th>
                            <th class="text-left">Самовыкуп</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let item of ordersDataTable; let i = index">
                            <tr *ngIf="item.is_cancel == 1" style="color: white;background-color: #ffcccc;">
                                <td *ngIf="item.photo_new == null" class="text-center text-muted">
                                    <img alt="" width="40"/></td>
                                <td *ngIf="item.photo_new?.length > 10" class="text-center text-muted">
                                    <img alt=""
                                         src="{{item.photo_new}}"
                                         width="40"/>
                                    <!--<td class="text-center text-muted" *ngIf="item.nmid?.length == 8"><img width="40"
                                                                                                           src="https://img1.wbstatic.net/tm/new/{{item.nmid.substr(0,4)}}0000/{{item.nmid}}-1.jpg"
                                                                                                           alt=""/>
                                    </td>
                                    <td class="text-center text-muted" *ngIf="item.nmid?.length == 9"><img width="40"
                                                                                                           src="https://img1.wbstatic.net/tm/new/{{item.nmid.substr(0,5)}}0000/{{item.nmid}}-1.jpg"
                                                                                                           alt=""/>
                                    </td>-->
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left flex2">
                                                <div class="widget-subheading opacity-7">
                                                    <b>Предмет: {{ item.subject }}</b></div>
                                                <div class="widget-subheading opacity-7">Размер: {{ item.techSize }}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    Категория: {{ item.category }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">SKU: <a
                                                        routerLink="/guides/products/{{item.nmid}}"
                                                        target="new">{{ item.nmid }}</a></div>
                                                <div class="widget-subheading opacity-7">Баркод: {{ item.barcode }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center"><i
                                        class="pe-7s-plus"> </i>{{ item.date | date: 'dd.MM.yyyy HH:mm' }}<br><i
                                        class="pe-7s-close-circle"
                                        style="color: red;"> </i>{{ item.cancel_dt | date: 'dd.MM.yyyy HH:mm' }}
                                </td>
                                <td class="text-center text-muted">{{ item.sum_sales | number:'1.0-0' }}</td>
                                <td class="text-center">{{ item.warehouseName }}</td>
                                <td class="text-center">{{ item.oblast }}</td>
                                <td class="text-left text-muted">{{ item.idfbsfbo }}</td>
                                <td class="text-muted text-center">
                                    <p-toast></p-toast>
                                    <input (click)="changeBuyoutStatus($event,item.idfbsfbo, item.buyout_id)"
                                           [checked]="+item.buyout_status"
                                           [ngModel]="+item.buyout_status"
                                           class="form-check-input"
                                           type="checkbox" value="">
                                </td>
                            </tr>
                            <tr *ngIf="item.is_cancel == 0">
                                <td *ngIf="item.photo_new == null" class="text-center text-muted">
                                    <img alt="" width="40"/></td>
                                <td *ngIf="item.photo_new?.length > 10" class="text-center text-muted">
                                    <img alt="" src="{{item.photo_new}}" width="40"/>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left flex2">
                                                <div class="widget-subheading opacity-7">
                                                    <b>Предмет: {{ item.subject }}</b></div>
                                                <div class="widget-subheading opacity-7">Размер: {{ item.techSize }}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    Категория: {{ item.category }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">SKU: <a
                                                        routerLink="/guides/products/{{item.nmid}}"
                                                        target="new">{{ item.nmid }}</a></div>
                                                <div class="widget-subheading opacity-7">Баркод: {{ item.barcode }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">{{ item.date | date: 'dd.MM.yyyy HH:mm' }}</td>
                                <td class="text-center text-muted">{{ item.sum_sales | number:'1.0-0' }}</td>
                                <td class="text-center text-muted">{{ item.warehouseName }}</td>
                                <td class="text-center text-muted">{{ item.oblast }}</td>
                                <td class="text-left text-muted">{{ item.idfbsfbo }}</td>
                                <td class="text-center text-muted">
                                    <p-toast></p-toast>
                                    <input (click)="changeBuyoutStatus($event, item.idfbsfbo, item.buyout_id)"
                                           [checked]="+item.buyout_status"
                                           class="form-check-input"
                                           id="flexCheckChecked" type="checkbox" value="">
                                </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="d-block text-center card-footer" style="background: #FFF;">
                    <div class="card-body">
                        <ngb-pagination (pageChange)="selectedParameters(paginationPage)"
                                        [(page)]="paginationPage"
                                        [boundaryLinks]="true"
                                        [collectionSize]="paginationItemsCount"
                                        [maxSize]="10"
                                        [pageSize]="100"
                                        [rotate]="true"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #hintDialogMyOrders let-arrowPosition="arrowPosition" let-dialogOrder="dialogOrder"
                 let-hintId="hintId">
        <div [ngClass]="dialogOrder" class="hint-dialog dialog-management">
            <div [ngClass]="{'arrow-block-top': arrowPosition === 'top'}" class="arrow-block"></div>
            <div [ngClass]="{'arrow-block-right': arrowPosition === 'right'}" class="arrow-block"></div>
            <div [ngClass]="{'arrow-block-left': arrowPosition === 'left'}" class="arrow-block"></div>
            <div [ngClass]="{'arrow-block-bottom': arrowPosition === 'bottom'}" class="arrow-block"></div>
            <div class="hint-header">
                <h3>{{ myOrdersHintDataArr[hintId].hintTitle }}</h3>
                <i (click)="closeHintModal()" class="pi pi-times-circle" style="font-size: 1.3rem"></i>
            </div>
            <div class="hint-main">
                <p>
                    {{ myOrdersHintDataArr[hintId].hintIssue }}
                </p>
            </div>
            <div class="hint-footer">
                <div class="hint-step">
                    {{ myOrdersHintDataArr[hintId].hintStep }} из 5
                </div>
                <div class="hint-btn">
                    <p-toast></p-toast>
                    <button (click)="manageDialogsForHint(myOrdersHintDataArr[hintId].hintNextStep)"
                            class="p-button-info"
                            label="Далее"
                            pButton type="button"></button>
                </div>
            </div>
        </div>
    </ng-template>
</div>