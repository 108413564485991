<p-progressSpinner *ngIf="isLoading"
                   [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="col-sm-12 col-md-12">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Идет Beta тестирование</h5>
    </div>
    <br/>
  </div>
  <div *ngIf="view_google_table_info" class="col-sm-6 col-md-6">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Обращаем внимание, веб сервис и гугл таблица - разные продукты от MP Surf</h5>
      <div class="col-sm-4 col-md-4">
        <button (click)="closeGoogleTableMsg(1)" class="btn btn-primary mt-2 mr-2">Все понятно</button>
      </div>
    </div>
    <br/>
  </div>
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="block-order-ftype">
          <div class="page-title-icon">
            <i class="fa fa-bar-chart icon-gradient bg-mean-fruit"></i>
          </div>
          <div>
            Отчет о движении денежных средств
          </div>
          <div class="btn-actions-pane-left">
            <input (change)="newValue()" [(ngModel)]="selected"
                   [alwaysShowCalendars]="true"
                   [isInvalidDate]="DatePicker.isInvalidDate" [linkedCalendars]="true" [locale]="DatePicker.localParamsRU"
                   [maxDate]='DatePicker.maxDate' [minDate]='DatePicker.minDate' [ranges]="ranges"
                   [showClearButton]="false" [showCustomRangeLabel]="true" ngxDaterangepickerMd
                   placeholder="Выберите даты" type="text"/>
          </div>
        </div>
        <div class="block-order-ftype">
          <p-toast></p-toast>
          <p-selectButton (onChange)="changeType($event)" [(ngModel)]="value3"
                          [options]="justifyOptions" [style]="{ height: '40px' }" optionLabel="name"
                          optionValue="value" styleClass="dark-panel required-height"></p-selectButton>
        </div>
        <div class="block-order-ftype">
          <div class="btn-actions-pane-left">
            <p-multiSelect (onChange)="selectParameter()"
                           (onClear)="clearFilters(1)"
                           [(ngModel)]="selectedAcc" [options]="accounts" [showClear]="true"
                           [style]="{ display: 'flex', alignItems: 'center', height: '37px', maxWidth: '250px' }"
                           optionLabel="name" placeholder="Юр. лица и счета"></p-multiSelect>
            <p-multiSelect (onChange)="selectParameter()"
                           (onClear)="clearFilters(2)"
                           [(ngModel)]="selectedPartners" [options]="partners" [showClear]="true"
                           [style]="{ display: 'flex', alignItems: 'center', height: '37px', maxWidth: '250px' }"
                           optionLabel="name" placeholder="Контрагенты"></p-multiSelect>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <p-treeTable [(selection)]="selectedNodes" [columns]="cols?.columns" [frozenColumns]="frozenCols" [metaKeySelection]="true"
                 [scrollable]="true"
                 [value]="cols?.data" frozenWidth="320px" scrollHeight="65vh" selectionMode="single"
                 styleClass="p-treetable-sm">
      <ng-template let-columns pTemplate="colgroup">
        <colgroup>
          <col *ngFor="let col of columns" style="width:115px;">
        </colgroup>
      </ng-template>
      <ng-template let-columns pTemplate="header">
        <tr style="height: 50px;">
          <th *ngFor="let col of columns" style="font-size:12px;">
            <b>{{col.header}}</b>
          </th>
        </tr>
      </ng-template>
      <ng-template let-columns="columns" let-rowData="rowData" pTemplate="body">
        <tr [ngClass]="{'height' : '50px'}">
          <td *ngFor="let col of columns; let i = index" class="columns-wrap">
          <span
            [ngStyle]="{'color' : rowData[col.field] === 0 || rowData[col.field]?.toString()?.includes('%') ? 'black' : rowData[col.field] < 0 ? 'red' : 'green'}">
            {{ rowData && ((rowData[col.field]?.toString()?.includes('%')) ? rowData[col.field] : (rowData[col.field] | currency:'':'')) || '' }}
          </span>
          </td>
        </tr>
      </ng-template>
      <ng-template let-rowData="rowData" let-rowNode pTemplate="frozenbody">
        <tr style="height: 50px;">
          <td>
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            <span [pTooltip]="rowData.name_all" style="font-size:12px;">{{rowData.name}}</span>
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </div>
</div>
