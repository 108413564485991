<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="col-sm-12 col-md-12">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Идет Beta тестирование и данные могут быть не точными</h5>
    </div>
    <br/>
  </div>
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          Список счетов
        </div>
      </div>
      <div class="page-title-actions">
        <button (click)="openCreateAccountModal(createUpdateAccountModalNew)"
                class="btn-shadow d-inline-flex align-items-center btn btn-success"
                type="button">
          Добавить счет
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="rowData?.length === 0" class="text-center mbg-3">
    <button (click)="openCreateAccountModal(createUpdateAccountModalNew)"
            class="btn-wide btn-pill fsize-1 btn-shadow d-inline-flex align-items-center btn-lg btn btn-success"
            type="button">
        <span class="mr-2 opacity-7">
            <i class="icon pe-7s-plus"></i>
        </span>
      Добавить счет
    </button>
  </div>
  <div *ngIf="rowData?.length !== 0" class="main-card mb-3 card">
    <div style="width: 100%;height: 67vh;">
      <ag-grid-angular
        (gridReady)="onGridReady($event)"
        [columnDefs]="finAccountsColumnDF"
        [defaultColDef]="defaultColDef"
        [enableRangeSelection]="true"
        [gridOptions]="gridOptions"
        [localeText]="localeTextForFilters"
        [pagination]="true"
        [rowData]="rowData"
        class="ag-theme-alpine"
        style="width: 100%; height: 100%;">
      </ag-grid-angular>
    </div>
  </div>

  <!-- НОВАЯ ФОРМА СОЗДАНИЯ СЧЕТА-->
  <ng-template #createUpdateAccountModalNew
               let-c="close"
               let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">
        {{isEditModal ? 'Изменение' : 'Добавление'}} счета
      </h4>
      <p (click)="d('Cross click')" class="m-0">
        <i aria-hidden="true" class="fa fa-times" style="font-size: 24px;cursor: pointer;"></i>
      </p>
    </div>
    <form [formGroup]="accountForm"
          class="needs-validation"
          novalidate>
      <p-toast></p-toast>
      <div class="modal-body">
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label1">
            Название
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-9">
            <input class="form-control" formControlName="name"
                   id="name_label1"
                   name="name"
                   placeholder="Введите название"
                   type="text">
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="type_lable">
            Тип счета
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-9 mt-2">
            <select (change)="autoSetupCredentials($event.target.value)"
                    class="form-control"
                    formControlName="type"
                    id="type_lable"
                    name="type">
              <option *ngFor="let pf of accountType"
                      [ngValue]="pf.id">
                {{ pf.name }}
              </option>
            </select>
          </div>
        </div>

        <div *ngIf="creditLoanSelected"
             class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label1">
            Доступные средства
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-9 mt-2">
            <input class="form-control"
                   formControlName="credit_limit"
                   name="name"
                   placeholder="Введите сумму доступных средств"
                   type="number">
          </div>
        </div>

        <!--<div *ngIf="creditLimitSelected"
             class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label1">
            Доступные средства
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-9 mt-2">
            <input class="form-control"
                   formControlName="credit_limit"
                   name="name"
                   placeholder="Введите сумму доступных средств"
                   type="number">
          </div>
        </div>-->
        <div *ngIf="creditLimitSelected"
             class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label1">
            Кредитный лимит
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-9 mt-2">
            <input class="form-control"
                   formControlName="credit_limit"
                   name="name"
                   placeholder="Введите кредитный лимит"
                   type="text">
          </div>
        </div>

        <div
          *ngIf="creditLoanSelected"
          class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="balance_label">
            Основной долг
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-9" style="margin-top: 10px;">
            <div class="column-date-part" style="display: flex;">
              <input class="form-control"
                     formControlName="balance_init"
                     name="balance_init"
                     placeholder="Основной долг" type="text">
              <input #d1="ngbDatepicker"
                     (click)="d1.toggle()"
                     autocomplete="off"
                     class="form-control"
                     formControlName="date_balance_init" id="date_balance_init"
                     name="dp1" ngbDatepicker
                     placeholder="DD.MM.YYYY">
            </div>
          </div>
        </div>

        <div *ngIf="!creditLoanSelected" class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="balance_label">
            Начальный остаток
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-9" style="margin-top: 10px;">
            <div class="column-date-part" style="display: flex;">
              <input class="form-control"
                     formControlName="balance_init"
                     id="balance_label"
                     name="balance_init"
                     placeholder="Начальный остаток" type="text">
              <input #d1="ngbDatepicker"
                     (click)="d1.toggle()"
                     autocomplete="off"
                     class="form-control"
                     formControlName="date_balance_init"
                     name="dp1" ngbDatepicker
                     placeholder="DD.MM.YYYY">
            </div>
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="is_visible1">Скрыть счет</label>
          <div class="col-sm-9" style="margin-top: 4px;">
            <input formControlName="is_visible" id="is_visible1"
                   name="checkbox"
                   type="checkbox">
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="is_visible2">Основной счет</label>
          <div class="col-sm-9" style="margin-top: 4px;">
            <input formControlName="is_main_account"
                   id="is_visible2"
                   name="checkbox"
                   type="checkbox">
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="description_l">
            Описание
          </label>
          <div class="col-sm-9">
            <textarea class="form-control"
                      formControlName="description"
                      id="description_l"
                      name="description">
            </textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="c('Close click')"
                class="btn btn-secondary"
                type="button">
          Закрыть
        </button>
        <button (click)="createNewAccount()"
                *ngIf="!isEditModal"
                [disabled]="accountForm.invalid || accountForm.disabled"
                class="btn btn-primary"
                type="submit">
          Добавить
        </button>
        <button (click)="updateAccount(activeUID)"
                *ngIf="isEditModal"
                [disabled]="accountForm.invalid || accountForm.disabled"
                class="btn btn-primary"
                type="submit">
          Изменить
        </button>
      </div>
    </form>
  </ng-template>
</div>
<p-confirmDialog [baseZIndex]="10000" [style]="{width: '30vw'}"></p-confirmDialog>


<!--&lt;!&ndash; ФОРМА СОЗДАНИЯ СЧЕТА&ndash;&gt;-->
<!--<ng-template #createAccountModal let-c="close" let-d="dismiss">-->
<!--  <div class="modal-header">-->
<!--    <h4 class="modal-title">Добавление счета</h4>-->
<!--    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">-->
<!--      <span aria-hidden="true">&times;</span>-->
<!--    </button>-->
<!--  </div>-->
<!--  <form *ngIf="!chsuccess" -->
<!--        [formGroup]="accountForm" -->
<!--        class="needs-validation" novalidate-->
<!--        (validSubmit)="createNewAccount()">-->
<!--    <div class="modal-body">-->
<!--      <div class="position-relative row form-group">-->
<!--        <label for="name_label" class="col-sm-3 col-form-label">Название<span style="color:red">*</span></label>-->
<!--        <div class="col-sm-9">-->
<!--          <input name="name" id="name_label" placeholder="Введите название" formControlName="name" type="text"-->
<!--                 class="form-control">-->
<!--          <div class="alert alert-danger"-->
<!--               *ngIf="accountForm.controls['name'].invalid && accountForm.controls['name'].touched">Не-->
<!--            корректно-->
<!--            заполнено поле-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="position-relative row form-group">-->
<!--        <label for="type_lable" class="col-sm-3 col-form-label">Тип счета<span style="color:red">*</span></label>-->
<!--        <div class="col-sm-9">-->
<!--          <select name="type" id="type_lable" formControlName="type"-->
<!--                  (change)="autoSetupCredentials($event.target.value)"-->
<!--                  class="form-control">-->
<!--            <option *ngFor="let pf of accountType" [ngValue]="pf.id">{{ pf.name }}</option>-->
<!--          </select>-->

<!--          <div class="alert alert-danger"-->
<!--               *ngIf="accountForm.controls['type'].invalid && accountForm.controls['type'].touched">Не-->
<!--            корректно-->
<!--            заполнено поле-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <span *ngIf="isShowCredentialsTitle" (click)="isShowBankCredentials = !isShowBankCredentials"-->
<!--            class="credentials-header">Реквизиты</span>-->
<!--      <ng-container *ngIf="isShowBankCredentials">-->
<!--        <div class="position-relative row form-group">-->
<!--          <label for="name_label" class="col-sm-3 col-form-label">Бик</label>-->
<!--          <div class="col-sm-9">-->
<!--            <input name="name" id="name_label" placeholder="Введите Бик" formControlName="account_bik" type="text"-->
<!--                   class="form-control">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="position-relative row form-group">-->
<!--          <label for="name_label" class="col-sm-3 col-form-label">Банк</label>-->
<!--          <div class="col-sm-9">-->
<!--            <input name="name" id="name_label" placeholder="Введите Банк" formControlName="account_bank" type="text"-->
<!--                   class="form-control">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="position-relative row form-group">-->
<!--          <label for="name_label" class="col-sm-3 col-form-label">Расч. счет №</label>-->
<!--          <div class="col-sm-9">-->
<!--            <input name="name" id="name_label" placeholder="Введите расчетный счет" formControlName="account_settlement"-->
<!--                   type="text"-->
<!--                   class="form-control">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="position-relative row form-group">-->
<!--          <label for="name_label" class="col-sm-3 col-form-label">Кор. счет №</label>-->
<!--          <div class="col-sm-9">-->
<!--            <input name="name" id="name_label" placeholder="Введите корректирующий счет"-->
<!--                   formControlName="account_corrective" type="text"-->
<!--                   class="form-control">-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--      <div class="position-relative row form-group">-->
<!--        <label for="balance_label" class="col-sm-3 col-form-label">Начальный остаток<span-->
<!--          style="color:red">*</span></label>-->
<!--        <div class="col-sm-9" style="margin-top: 10px;">-->
<!--          <div class="column-date-part" style="display: flex;">-->
<!--            <input name="balance_init" id="balance_label" placeholder="Начальный остаток" formControlName="balance_init"-->
<!--                   type="text" class="form-control">-->
<!--            <input class="form-control"-->
<!--                   formControlName="date_balance_init" (click)="d1.toggle()" placeholder="DD.MM.YYYY"-->
<!--                   name="dp1" id="date_balance_init" ngbDatepicker #d1="ngbDatepicker" autocomplete="off">-->
<!--          </div>-->
<!--          <div class="alert alert-danger"-->
<!--               *ngIf="accountForm.controls['balance_init'].invalid && accountForm.controls['balance_init'].touched">-->
<!--            Ошибка заполнения. пример: 2500.15-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="position-relative row form-group">-->
<!--        <label for="description_l" class="col-sm-3 col-form-label">Описание</label>-->
<!--        <div class="col-sm-9">-->
<!--          <textarea name="description" id="description_l" formControlName="description" class="form-control"></textarea>-->
<!--          <div class="alert alert-danger"-->
<!--               *ngIf="accountForm.controls['description'].invalid && accountForm.controls['description'].touched">-->
<!--            Не-->
<!--            корректно заполнено поле-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="modal-footer">-->
<!--        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Закрыть</button>-->
<!--        <button type="submit" [disabled]="accountForm.invalid || accountForm.disabled" class="btn btn-primary">-->
<!--          Добавить-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </form>-->
<!--</ng-template>-->

<!--&lt;!&ndash; ФОРМА ИЗМЕНЕНИЯ СЧЕТА&ndash;&gt;-->
<!--<ng-template #updateAccountModal let-c="close" let-d="dismiss">-->
<!--  <div class="modal-header">-->
<!--    <h4 class="modal-title">Изменение счета</h4>-->
<!--    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">-->
<!--      <span aria-hidden="true">&times;</span>-->
<!--    </button>-->
<!--  </div>-->
<!--  <form *ngIf="!chsuccess" -->
<!--        [formGroup]="accountForm" -->
<!--        class="needs-validation" novalidate-->
<!--        (validSubmit)="updateAccount(modal_acc_id)">-->
<!--    <div class="modal-body">-->
<!--      <div class="position-relative row form-group">-->
<!--        <label for="name_label" class="col-sm-3 col-form-label">Название<span style="color:red">*</span></label>-->
<!--        <div class="col-sm-9">-->
<!--          <input name="name" id="name_label" placeholder="введите наименование" formControlName="name" type="text"-->
<!--                 class="form-control">-->
<!--          <div class="alert alert-danger"-->
<!--               *ngIf="accountForm.controls['name'].invalid && accountForm.controls['name'].touched">Не-->
<!--            корректно-->
<!--            заполнено поле-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="position-relative row form-group">-->
<!--        <label for="type_lable" class="col-sm-3 col-form-label">Тип счета<span style="color:red">*</span></label>-->
<!--        <div class="col-sm-9">-->
<!--          <select name="type" id="type_lable" formControlName="type"-->
<!--                  (change)="autoSetupCredentials($event.target.value)"-->
<!--                  class="form-control">-->
<!--            <option *ngFor="let pf of accountType" [ngValue]="pf.id">{{ pf.name }}</option>-->
<!--          </select>-->
<!--        </div>-->
<!--        <div class="alert alert-danger"-->
<!--             *ngIf="accountForm.controls['type'].invalid && accountForm.controls['type'].touched">Не корректно-->
<!--          заполнено поле-->
<!--        </div>-->
<!--      </div>-->
<!--      <span *ngIf="isShowCredentialsTitle" (click)="isShowBankCredentials = !isShowBankCredentials"-->
<!--            class="credentials-header">Реквизиты</span>-->
<!--      <ng-container *ngIf="isShowBankCredentials">-->
<!--        <div class="position-relative row form-group">-->
<!--          <label for="name_label" class="col-sm-3 col-form-label">Бик</label>-->
<!--          <div class="col-sm-9">-->
<!--            <input name="name" id="name_label" placeholder="Введите Бик" formControlName="account_bik" type="text"-->
<!--                   class="form-control">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="position-relative row form-group">-->
<!--          <label for="name_label" class="col-sm-3 col-form-label">Банк</label>-->
<!--          <div class="col-sm-9">-->
<!--            <input name="name" id="name_label" placeholder="Введите Банк" formControlName="account_bank" type="text"-->
<!--                   class="form-control">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="position-relative row form-group">-->
<!--          <label for="name_label" class="col-sm-3 col-form-label">Расч. счет №</label>-->
<!--          <div class="col-sm-9">-->
<!--            <input name="name" id="name_label" placeholder="Введите расчетный счет" formControlName="account_settlement"-->
<!--                   type="text"-->
<!--                   class="form-control">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="position-relative row form-group">-->
<!--          <label for="name_label" class="col-sm-3 col-form-label">Кор. счет №</label>-->
<!--          <div class="col-sm-9">-->
<!--            <input name="name" id="name_label" placeholder="Введите корректирующий счет"-->
<!--                   formControlName="account_corrective" type="text"-->
<!--                   class="form-control">-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--      <div class="position-relative row form-group">-->
<!--        <label for="balance_label" class="col-sm-3 col-form-label">Начальный остаток<span-->
<!--          style="color:red">*</span></label>-->
<!--        <div class="col-sm-9" style="margin-top: 10px;">-->
<!--          <div class="column-date-part" style="display: flex;">-->
<!--            <input name="balance_init" id="balance_label" placeholder="Начальный остаток" formControlName="balance_init"-->
<!--                   type="text" class="form-control">-->
<!--            <input class="form-control"-->
<!--                   formControlName="date_balance_init" (click)="d1.toggle()" placeholder="DD.MM.YYYY"-->
<!--                   name="dp1" id="date_balance_init" ngbDatepicker #d1="ngbDatepicker" autocomplete="off">-->
<!--          </div>-->
<!--          <div class="alert alert-danger"-->
<!--               *ngIf="accountForm.controls['balance_init'].invalid && accountForm.controls['balance_init'].touched">-->
<!--            Ошибка заполнения. пример: 2500.15-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="position-relative row form-group">-->
<!--        <label for="description_l" class="col-sm-3 col-form-label">Описание</label>-->
<!--        <div class="col-sm-9">-->
<!--          <textarea name="description" id="description_l" formControlName="description" class="form-control"></textarea>-->
<!--          <div class="alert alert-danger"-->
<!--               *ngIf="accountForm.controls['description'].invalid && accountForm.controls['description'].touched">-->
<!--            Не-->
<!--            корректно заполнено поле-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="modal-footer">-->
<!--        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Закрыть</button>-->
<!--        <button type="submit" [disabled]="accountForm.invalid || accountForm.disabled" class="btn btn-primary">-->
<!--          Изменить-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </form>-->
<!--</ng-template>-->
