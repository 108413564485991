import {AfterViewInit, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ColDef, ColGroupDef, GridApi} from 'ag-grid-community';
import {GridOptions} from 'ag-grid';
import {ApiService} from '../../../../../shared/services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AgGridAngular} from 'ag-grid-angular';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  ACCOUNT_TYPES,
  accountsList,
  accountsListOriginal,
  agentList,
  FINOPERATION_COLUMN_DEFS_EXCEL,
  itemsList,
  PaymentTypes
} from './finoperations-excel.mock';
import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {finalize} from 'rxjs/operators';
import {PopUpStatus} from "../../../../../shared/interfaces/common.type";
import {MessageService} from "primeng/api";
import {PopUpMessages} from "../../../../../shared/mocks/pop-up-messages.mock";
import {IFinOperationsShopList} from "../../finoperations.interface";

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-finoperations-excel',
  templateUrl: './finoperations-excel.component.html',
  styleUrls: ['./finoperations-excel.component.sass'],
  providers: [MessageService]
})
export class FinoperationsExcelComponent implements OnInit, AfterViewInit {
  isLoading = false;

  gridOptions: GridOptions = {};
  rowDataSubject: any[] = [];
  readonly FormNewAgent: FormGroup;
  readonly FormNewAccount: FormGroup;
  shopsList: IFinOperationsShopList[] = [];
  dataForColumnDefsExcel: (ColDef | ColGroupDef)[] = FINOPERATION_COLUMN_DEFS_EXCEL;
  defaultColDefExcel: ColDef = {sortable: true, resizable: true, filter: true,};
  accountTypes = ACCOUNT_TYPES;
  shopId: any;
  itemsIncomeList: any[] = [];
  itemsExpensesList: any[] = [];
  rowsForRemoving: any[] = [];
  isShowBankCredentials = false;
  isShowCredentialsTitle = false;
  @Output() dataIsSaved = new EventEmitter<boolean>();
  @ViewChild('agGrid', {static: true}) agGrid: AgGridAngular;
  @ViewChild('newAccount', {read: TemplateRef}) newAccount: TemplateRef<any>;
  @ViewChild('newAgent', {read: TemplateRef}) newAgent: TemplateRef<any>;
  private gridApi: GridApi;

  constructor(private api: ApiService,
              private fb: FormBuilder,
              private messageService: MessageService,
              private modalService: NgbModal) {
    this.FormNewAgent = fb.group({
      shop: new FormControl(null),
      name: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(500)]),
      inn: new FormControl(null, [Validators.pattern('^[0-9]*$')]),
      kpp: new FormControl(null, [Validators.pattern('^[0-9]*$')]),
      checking_account: new FormControl(null),
      income_item: new FormControl(null),
      expenses_item: new FormControl(null),
      description: new FormControl(null),
    });
    this.FormNewAccount = fb.group({
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]),
      type: new FormControl('', Validators.required),
      account_bik: new FormControl(''),
      account_bank: new FormControl(''),
      account_settlement: new FormControl(''),
      account_corrective: new FormControl(''),
      balance_init: new FormControl('', [Validators.required, Validators.pattern('^[+]?([0-9]{0,})*[.]?([0-9]{0,2})?$')]),
      date_balance_init: new FormControl('', Validators.required),
      description: new FormControl(''),
    });
    this.gridOptions = ({
      context: {
        componentParent: this,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
      suppressRowClickSelection: true,
    } as GridOptions);
  }

  ngOnInit(): void {
    this.shopId = JSON.parse(localStorage.getItem('mpall_shop')).shop;
  }

  ngAfterViewInit() {
    if (this.agGrid) {
      this.gridApi = this.agGrid.api;
    }
  }

  onGridReadyExcel(params?: any) {
    if (params) {
      this.gridApi = params.api;
    }
  }

  refreshGrid() {
    if (this.gridApi) {
      this.gridApi.refreshCells();
    }
  }

  removeSelected() {
    if (confirm('Вы уверены, что хотите удалить запись ?')) {
      this.rowDataSubject = this.rowDataSubject.filter((_, index) => !this.rowsForRemoving.includes(index));
      this.rowsForRemoving.length = 0;
      this.refreshGrid();
      this.updateRowData(this.rowDataSubject);
    }
  }

  onRowSelected(event: any) {
    const rowIndex = event.rowIndex;
    const selectedStatus = event.node.selected;
    if (selectedStatus) {
      this.rowsForRemoving.push(rowIndex);
    } else {
      this.rowsForRemoving = this.rowsForRemoving.filter(row => row !== rowIndex);
    }
  }

  dataSaves() {
    this.dataIsSaved.emit(true);
  }

  updateRowData(rowData: any[]) {
    this.rowDataSubject = rowData;
    this.gridApi.setRowData(this.rowDataSubject);
  }

  discardChanges(): void {
    if (confirm('Вы уверены, что хотети отменить редактирование?')) {
      this.dataSaves();
    }
  }

  saveCurrentChanges(): void {
    this.isLoading = true;
    const body = {
      shop_id: this.shopId,
      operations: this.rowDataSubject
    };
    this.api.userRegisterFin('getFinOperationAddExcel', localStorage.getItem('token'), body)
      .pipe(untilDestroyed(this), finalize(() => this.isLoading = false))
      .subscribe(
        (response: any) => {
          if (+response.is_error === 0) {
            this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Операции добавлены');
            this.dataSaves();
          } else {

            this.showPopUpMessage('error', PopUpMessages.createFailedSummary, response.msg);
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
        });
  }

  // ADD NEW AGENT
  triggerAgentModal(data: any): void {
    this.FormNewAgent.get('name').setValue(data.finOperationExcelContrAgent.value);
    this.modalService.open(this.newAgent);
  }

  createNewAgent(): void {
    this.isLoading = true;
    const body = {
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop ?? '',
      params: this.FormNewAgent.value
    };
    this.api.userRegisterFin('getFinPartnersAdd', localStorage.getItem('token'), body)
      .pipe(untilDestroyed(this), finalize(() => this.isLoading = false))
      .subscribe((data: any) => {
        if (+data.is_error === 0) {
          this.modalService.dismissAll();
          this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, "Поставщик добавлен");
          this.FormNewAgent.reset();
          this.getAgentList();
        } else {
          this.showPopUpMessage('warning', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
        }
      }, () => {
        this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
      });
  }

  // ADD NEW ACCOUNT
  triggerAccountModal(data: any, actionName: string): void {
    if (actionName === 'agent') {
      this.FormNewAgent.get('name').setValue(data.finOperationExcelContrAgent.value);
    }
    if (actionName === 'account') {
      this.FormNewAccount.get('name').setValue(data.finOperationExcelAccountCart.value);
    }
    this.modalService.open(this.newAccount);
  }

  createNewAccount(): void {
    const body = {
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop ?? '',
      params: this.FormNewAccount.getRawValue()
    };
    this.api.userRegisterFin('getFinAccountAdd', localStorage.getItem('token'), body)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        if (+response.is_error === 0) {
          this.modalService.dismissAll();
          this.getAccountList();
          this.loadArticlesListOfExpensesAndArrival();
          this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Счет добавлен');
          this.FormNewAccount.reset();
        } else {
          this.showPopUpMessage('warning', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
        }
      }, () => {
        this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
      });
  }

  autoSetupCredentials(parameter: string): void {
    this.isShowBankCredentials = false;
    const value = parameter.split(' ')[1] ?? '';
    if (value) {
      this.isShowCredentialsTitle = [2, 3].includes(+value);
    }
  }

//   TODO - AUTO SETUP FROM THE PARENT
  setUpFinPartners(finPartners: any) {
    const agentNames = finPartners.map(agent => agent.name);
    agentList.length = 0;
    agentNames.forEach(agent => agentList.push(agent));
    this.setDataToColumn(agentNames, 6);
  }

  setupFinAccounts(finAccounts): void {
    const accountName = finAccounts.map(account => account.name);
    const accountFullName = finAccounts.map(account => `${account.name} - ${PaymentTypes[account.type - 1]}`);
    accountsList.length = 0;
    accountsListOriginal.length = 0;
    accountName.forEach(account => accountsListOriginal.push(account));
    accountFullName.forEach(account => accountsList.push(account));
    this.setDataToColumn(accountFullName, 4);
  }

  setupDataShopWBList(wbList): void {
    const dinamic = {id: '0', name: 'Все'};
    this.shopsList = wbList;
    this.shopsList.unshift(dinamic as IFinOperationsShopList);
  }

  setupFinItems(finItems): void {
    this.itemsIncomeList = finItems.filter(item => +item.item_type === 1);
    this.itemsExpensesList = finItems.filter(item => +item.item_type === 2);
    const listOfItems = [...finItems.map(item => item.name)];
    itemsList.length = 0;
    listOfItems.forEach(account => itemsList.push(account));
    this.setDataToColumn(listOfItems, 10);
  }

  private loadArticlesListOfExpensesAndArrival() {
    this.getArticlesListOfExpensesAndArrival()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        const listOfItems = response;
        itemsList.length = 0;
        listOfItems.forEach(account => itemsList.push(account));
        this.setDataToColumn(listOfItems, 10);
      });
  }

  // LOAD ALL
  private getAccountList() {
    const body = {shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop ?? ''};
    this.api.userRegisterFin('getFinAccountList', localStorage.getItem('token'), body)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any[]) => {
          this.setupFinAccounts(response);
        }, (err) => {
          alert(err.message);
        });
  }

  private getArticlesListOfExpensesAndArrival(): Observable<any> {
    const shop_id = JSON.parse(localStorage.getItem('mpall_shop')).shop;
    return this.api.userRegisterFin('getFinItemList2', localStorage.getItem('token'), {shop_id});
  }

  private getAgentList() {
    const body = {shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop};
    this.api.userRegisterFin('getFinPartners', localStorage.getItem('token'), body)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any[]) => {
          this.setUpFinPartners(response);
        }
      );
  }

  private setDataToColumn(columnData: string[], columnId: number): void {
    if (columnId === 4) {
      columnData.forEach(name => accountsList.push(name));
    }
    if (columnId === 6) {
      columnData.forEach(name => agentList.push(name));
    }
    if (columnId === 10) {
      columnData.forEach(name => itemsList.push(name));
    }
    this.refreshGrid();
    if (FINOPERATION_COLUMN_DEFS_EXCEL[columnId].cellEditorParams) {
      FINOPERATION_COLUMN_DEFS_EXCEL[columnId].cellEditorParams.values = columnData;
      FINOPERATION_COLUMN_DEFS_EXCEL[columnId].cellEditorParams.defaultValue = columnData[1];
      this.gridOptions.api.setColumnDefs(FINOPERATION_COLUMN_DEFS_EXCEL);
      this.refreshGrid();
    }
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
