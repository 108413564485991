import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberFormat]'
})
export class NumberFormatDirective {
  constructor(private el: ElementRef<HTMLInputElement>) { }

  @HostListener('input')
  onInputChange() {
    // Get the input element value
    let value = this.el.nativeElement.value;

    // Remove any existing non-digit characters from the input value
    value = value.replace(/\D/g, '');

    // Format the value with spaces
    value = this.formatNumberWithSpaces(value);

    // Set the formatted value back to the input element
    this.el.nativeElement.value = value;
  }

  formatNumberWithSpaces(number: string): string {
    // Split the number into groups of three digits from the right
    let groups = [];
    while (number.length > 3) {
      groups.unshift(number.slice(-3));
      number = number.slice(0, -3);
    }
    groups.unshift(number); // Add the remaining digits

    // Join the groups with spaces
    return groups.join(' ');
  }
}
