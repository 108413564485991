import {FinOperationActionComponent} from '../aggrid-components/fin-operation-action.component';
import {
    FinoperationExcelRenderCellsComponent
} from '../aggrid-components/finoperation-excel-render-cells.component';

export enum PaymentTypes {'Безналичный', 'Наличный', 'Карта физ лица'}

export enum PaymentTypesWithOperations {
    'Поступление' = 1,
    'Списание',
    'Перемещение',
    'Удержание WB',
    'Погашение кредита/займа'
}

export let agentList = ['1', '2'];
export let accountType = ['Поступление', 'Списание'];
export const accountsList = ['123', '312', '123', '123', '123'];
export const accountsListOriginal = ['123', '312', '123', '123', '123'];
export let itemsList = ['Item1', 'Item2', '123', '123', '123'];
export const ACCOUNT_TYPES = [{id: 1, name: 'Наличный'}, {id: 2, name: 'Безналичный'}, {id: 3, name: 'Карта физ лица'}];

export const FINOPERATION_COLUMN_DEFS_EXCEL: any[] = [
    {
        field: 'selection',
        headerName: '',
        headerCheckboxSelection: true,
        sortable: true,
        filter: true,
        width: 50,
        checkboxSelection: true,
    },
    {
        headerName: 'Дата начисления',
        headerClass: 'header-centered',
        field: 'finOperationExcelMonth.value',
        editable: true,
        maxWidth: 120,
        filter: 'agNumberColumnFilter',
        sortable: true, resizable: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellRenderer: params => {
            const span = document.createElement('span');
            if (params.data.finOperationExcelMonth) {
                span.style.color = params.data.finOperationExcelMonth.style;
                span.innerText = params.value;
                span.addEventListener('click', () => span.style.color = '');
            }
            return span;
        }
    },
    {
        headerName: 'Дата платежа',
        headerClass: 'header-centered',
        field: 'finOperationExcelDate.value',
        maxWidth: 120,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellRenderer: params => {
            const span = document.createElement('span');
            if (params.data.finOperationExcelDate) {
                span.style.color = params.data.finOperationExcelDate.style;
                span.innerText = params.value;
                span.addEventListener('click', () => span.style.color = '');
                return span;
            }
        }
    },
    {
        headerName: 'Тип операции',
        headerClass: 'header-centered',
        field: 'finOperationExcelTypeOperation.value',
        maxWidth: 220,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: accountType,
            defaultValue: 'Поступление',
        },
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Кошелек, карта, счет',
        headerClass: 'header-centered',
        field: 'finOperationExcelAccountCart.value',
        tooltipField: 'finOperationExcelAccountCart.warningTooltip',
        maxWidth: 220,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: accountsList,
            defaultValue: accountsList[1],
            afterGuiAttached: (params) => {
                params.values = accountsList;
                params.refresh();
            },
        },
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Добавить счет',
        headerClass: 'header-centered',
        field: '',
        maxWidth: 120,
        editable: false,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRendererFramework: FinOperationActionComponent,
        cellEditorParams: {
            operationStatus: 'account'
        }
    },
    {
        headerName: 'Контрагент',
        headerClass: 'header-centered',
        field: 'finOperationExcelContrAgent.value',
        maxWidth: 220,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: agentList,
            defaultValue: agentList[1],
        },
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Добавить контрагента',
        headerClass: 'header-centered',
        field: '',
        maxWidth: 130,
        editable: false,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellRendererFramework: FinOperationActionComponent,
        cellEditorParams: {
            operationStatus: 'agent'
        }
    },
    {
        headerName: 'Сумма',
        headerClass: 'header-centered',
        field: 'finOperationExcelAmount.value',
        maxWidth: 120,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'За что, комментарий',
        headerClass: 'header-centered',
        field: 'finOperationExcelComment.value',
        maxWidth: 250,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellRenderer: FinoperationExcelRenderCellsComponent
    },
    {
        headerName: 'Статья ДДС',
        headerClass: 'header-centered',
        field: 'finOperationExcelDDS.value',
        maxWidth: 220,
        editable: true,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: itemsList,
            defaultValue: itemsList[1],
        },
        cellRenderer: FinoperationExcelRenderCellsComponent
    }
];

export function formatValue(value: string): string {
    if (value) {
        return (value.split('-')[0]).trim();
    }
    return '';
}
