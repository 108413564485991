import { Component, OnInit } from '@angular/core';
import { Nullable } from '../../../../shared/interfaces/common.type';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss'],
})
export class MegamenuComponent implements OnInit {
  value = false;
  showBlocksForProd = false;
  isAdmin: boolean;

  constructor() {}

  ngOnInit() {
    const isTestStatus = JSON.parse(localStorage.getItem('is_test')) as Nullable<number>;
    if (typeof isTestStatus !== 'number') {
      this.showBlocksForProd = false;
    } else {
      this.showBlocksForProd = isTestStatus === 1;
    }

    this.isAdmin = JSON.parse(localStorage.getItem('is_admin')) === 1;
  }
}
