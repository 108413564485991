import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-header-modal',
  templateUrl: './header-modal.component.html',
  styleUrls: ['./header-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderModalComponent {
  @Input()
  modalTitle: string = '';

  @Output()
  closeEmitter = new EventEmitter();

  closeModal(): void {
    this.closeEmitter.emit();
  }
}
