import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';

export type HeatMapCharts = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  fill: any;
  colors: any;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
  grid: ApexGrid;
};

export const HeatMapChartOptionsGeneral: HeatMapChartOptionsGeneral = {
  stroke: { show: true, width: 2, colors: ['transparent'] },
  xaxisWithTime: {
    type: 'category',
    categories: [
      '0:00 - 1:00',
      '1:00 - 2:00',
      '2:00 - 3:00',
      '3:00 - 4:00',
      '4:00 - 5:00',
      '5:00 - 6:00',
      '6:00 - 7:00',
      '7:00 - 8:00',
      '8:00 - 9:00',
      '9:00 - 10:00',
      '10:00 - 11:00',
      '11:00 - 12:00',
      '12:00 - 13:00',
      '13:00 - 14:00',
      '14:00 - 15:00',
      '15:00 - 16:00',
      '16:00 - 17:00',
      '17:00 - 18:00',
      '18:00 - 19:00',
      '19:00 - 20:00',
      '20:00 - 21:00',
      '21:00 - 22:00',
      '22:00 - 23:00',
      '23:00 - 00:00',
    ],
  },
  xaxisWithDays: { type: 'category', categories: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'] },
};

export type HeatMapChartOptionsGeneral = {
  xaxisWithTime: any;
  xaxisWithDays: any;
  stroke: any;
};
