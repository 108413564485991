export const environment = {
  production: false,

  //TODO - Return
  // PROD OLD API
  apiOld: 'https://apiprodold.mpsurf.ru/api/v1/', // https://api.mpall.ru/basic/api/v1/

  // PROD NEW API
  apiNew: 'https://apiprod.mpsurf.ru/api/v1/', // https://apiprod.mpall.ru/api/v1/

  // PROD ADVERTS API
  apiAdverts: 'https://apiadv.mpsurf.ru/api/v1', // https://apiadv.mpall.ru/api/v1

  // // TEST OLD API
  // apiOld: 'https://apitestold.mpsurf.ru/api/v1/', // 'https://apitestold.mpall.ru/api/v1/'
  //
  // //  TEST NEW API
  // apiNew: 'https://apitest.mpsurf.ru/api/v1/', // 'https://apitest.mpall.ru/api/v1/'
  //
  // //  TEST ADVERTS API
  // apiAdverts: 'https://apiadvtest.mpsurf.ru/api/v1/', // 'https://api2.mpall.ru/api/v1/'
};
