<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
    <div class="col-sm-12 col-md-12">
        <div class="mb-3 card text-white card-body bg-warning">
            <h5 class="card-title">Идет Beta тестирование</h5>
        </div>
        <br/>
    </div>
    <div class="app-page-title">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="btn-actions-pane-left">
                    <div class="block-order-ftype">
                        <div class="page-title-icon">
                            <i class="fa fa-bar-chart icon-gradient bg-mean-fruit"></i>
                        </div>
                        <div>
                            Отчет о прибылях и убытках
                        </div>
                        <input (change)="newValue()" [(ngModel)]="selected"
                               [alwaysShowCalendars]="true"
                               [isInvalidDate]="DateCommon.isInvalidDate" [linkedCalendars]="true"
                               [locale]="DateCommon.localParamsRU"
                               [maxDate]="DateCommon.maxDate"
                               [minDate]='DateCommon.minDate' [ranges]="ranges"
                               [showClearButton]="false"
                               [showCustomRangeLabel]="true" ngxDaterangepickerMd
                               placeholder="Выберите даты" type="text"/>

                    </div>
                    <p-selectButton (onChange)="changeType($event)"
                                    [(ngModel)]="value3"
                                    [options]="justifyOptions"
                                    optionLabel="name"
                                    optionValue="value"
                                    selectionMode="single"
                                    styleClass="dark-panel">
                    </p-selectButton>
                </div>
                <p-toast></p-toast>
                <div class="block-order-ftype">
                    <div class="btn-actions-pane-left">
                        <p-multiSelect (onChange)="selectedParameters()" (onClear)="onClearParameters('article')"
                                       [(ngModel)]="selectedNm"
                                       [maxSelectedLabels]="1" [options]="barcodesList" [showClear]="true"
                                       [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                                       defaultLabel="Выберите артикул"
                                       emptyMessage="Артикулов не найдено" itemSize="30" optionLabel="name"
                                       selectedItemsLabel="Выбрано {0}">
                        </p-multiSelect>
                        <!-- вернем уже из справочника товаров "мои товары"<p-multiSelect [options]="categoriesList" [(ngModel)]="selectedCategory" [showClear]="true"
                                       (onClear)="onClearParameters('category')"
                                       [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                                       (onChange)="selectedParameters()"
                                       [maxSelectedLabels]="1" optionLabel="name" itemSize="30" emptyMessage="Категорий не найдено"
                                       defaultLabel="Выберите категорию" selectedItemsLabel="Выбрано {0}">
                        </p-multiSelect>-->
                        <p-multiSelect (onChange)="selectedParameters()" (onClear)="onClearParameters('subject')"
                                       [(ngModel)]="selectedSubject"
                                       [maxSelectedLabels]="1"
                                       [options]="subjectsList"
                                       [showClear]="true"
                                       [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                                       defaultLabel="Выберите предмет" emptyMessage="Предметов не найдено" itemSize="30"
                                       optionLabel="name" selectedItemsLabel="Выбрано {0}">
                        </p-multiSelect>
                    </div>
                </div>
            </div>
            <!-- СКРЫТО НА ВРЕМЯ ДОРАБОТК И ВЫСТАВКИ<div class="block-order-ftype">
              <div class="btn-actions-pane-left">
                <p-dropdown [options]="accounts" [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                            [(ngModel)]="selectedAcc" (onChange)="selectAcc($event)" placeholder="Юр. лица и счета"
                            optionLabel="name" [showClear]="true"></p-dropdown>
              </div>
              <div class="btn-actions-pane-left" style="font-size:10px">
                <p-dropdown [options]="partners" [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                            [(ngModel)]="selectedPartners" (onChange)="selectPart($event)" placeholder="Контрагенты"
                            optionLabel="name" [showClear]="true"></p-dropdown>
              </div>
            </div>-->
        </div>
    </div>
    <div class="card">
        <p-treeTable [(selection)]="selectedNodes" [columns]="cols?.columns" [frozenColumns]="frozenCols"
                     [metaKeySelection]="true"
                     [scrollable]="true"
                     [value]="cols?.data" frozenWidth="320px" scrollHeight="65vh" selectionMode="single"
                     styleClass="p-treetable-sm">

            <ng-template let-columns pTemplate="colgroup">
                <colgroup>
                    <col *ngFor="let col of columns" style="width:115px">
                </colgroup>
            </ng-template>
            <ng-template let-columns pTemplate="header">
                <tr style="height: 50px">
                    <th *ngFor="let col of columns" style="font-size:12px;">
                        {{ col.header }}
                    </th>
                </tr>
            </ng-template>
            <ng-template let-columns="columns" let-rowData="rowData" pTemplate="body">
                <tr [ngClass]="{'height' : '50px'}">
                    <td *ngFor="let col of columns; let i = index" class="columns-wrap">
          <span
                  [ngStyle]="{'color' : rowData[col.field] === 0 || rowData[col.field]?.toString()?.includes('%') ? 'black' : rowData[col.field] < 0 ? 'red' : 'green'}">
          {{ rowData && ((rowData[col.field]?.toString()?.includes('%')) ? rowData[col.field] : (rowData[col.field] | currency:'':'')) || '' }}
          </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template let-rowData="rowData" let-rowNode pTemplate="frozenbody">
                <tr [ngClass]="{'height' : '50px'}">
                    <td>
                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                        <span [pTooltip]="rowData.name_all" style="font-size:12px;">{{ rowData.name }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-treeTable>
    </div>
</div>
