import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import { AbstractGeneralTabComponent } from "../../abstract-general-tab.component";
import { ConfirmationService, MessageService } from "primeng/api";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { finalize, switchMap } from "rxjs/operators";
import { PopUpMessages } from "../../../shared/mocks/pop-up-messages.mock";
import { CommonResponseOperation1 } from "../../../shared/interfaces/common.interface";

@UntilDestroy({checkProperties: true})
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.sass'],
    providers: [MessageService, ConfirmationService]
})
export class RegisterComponent extends AbstractGeneralTabComponent {

    isUserCreated = false;

    readonly formRegister = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email]),
        checkrule: new FormControl(null, [Validators.requiredTrue]),
    });

    readonly formConfirm = new FormGroup({
        confirm: new FormControl(null, [Validators.required])
    })

    constructor(
        private api: ApiService,
    ) {
        super();
    }

    createUser() {
        this.isLoading = true;
        this.api.userRegister('createUser', '', this.formRegister.value)
            .pipe(untilDestroyed(this), finalize(() => this.isLoading = false))
            .subscribe((data: CommonResponseOperation1) => {
                    if (data.is_error == 0) {
                        this.isUserCreated = true;
                        this.showPopUpMessage('success', 'Успешно', data.msg);
                    }
                    if (data.is_error == 1) {
                        this.showPopUpMessage('error', 'Ошибка регистрации', data.msg);
                    }
                },
                () => {
                    this.showPopUpMessage('error', 'Ошибка регистрации', 'Проверьте введенные данные и повторите попытку');
                });
    }

    confirmUserRegistration() {
        this.isLoading = true;
        this._route.queryParams
            .pipe(
                switchMap((params: any) => {
                    const body = {
                        code: this.formConfirm.get('confirm').value,
                        email: !params.email ? this.formRegister.get('email').value : params.email
                    }
                    return this.api.userRegister('confirmUser', '', body)
                }),
                untilDestroyed(this),
                finalize(() => this.isLoading = false)
            )
            .subscribe((data: CommonResponseOperation1) => {
                    if (data.is_error == 1) {
                        this.showPopUpMessage('error', 'Ошибка', data.msg);
                    }
                    if (data.is_error == 0) {
                        this._router.navigate(['/auth/login'], {queryParams: {confirmaccess: 1,}});
                    }
                },
                () => {
                    this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
                });
    }
}
