<p-progressSpinner *ngIf="isLoadingStatus"
                   [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoadingStatus ? 'none' : 'block'}">
  <div class="col-sm-12 col-md-12">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Идет Beta тестирование</h5>
    </div>
    <br/>
  </div>
  <div class="app-page-title" style="margin-bottom: 0">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-keypad icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          Управление рекламой
        </div>
      </div>
      <div ngbDropdown>
        <button class="button-add-purchase mb-2 btn btn-success add-operation-btn" ngbDropdownToggle
                style="height: 37px;"
                type="button">
          Создать компанию
        </button>
        <div class="dropdown-menu-right-rounded" ngbDropdownMenu>
          <button (click)="openModalCreateCompany(createCompanyModal, 6)" class="dropdown-item"><span
            style="color:green">Поиск </span>
          </button>
          <button (click)="openModalCreateCompany(createCompanyModal, 9)" class="dropdown-item"><span
            style="color:#920092">Поиск + Каталог </span>
          </button>
          <button (click)="openModalCreateCompany(createCompanyModal, 5)" class="dropdown-item"
                  style="color:#0015ff">Карточка товара
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="main-card mb-3 card" style="height: calc(92vh - 270px)">
    <ag-grid-angular #agGrid
                     (gridReady)="onGridReady($event)"
                     (rowClicked)="onRowClicked($event)"
                     [columnDefs]="dataForColumnDefs"
                     [defaultColDef]="defaultColDef"
                     [enableRangeSelection]="true"
                     [gridOptions]="gridOptions"
                     [localeText]="localRuFilters"
                     [pagination]="true"
                     [rowData]="rowData"
                     [rowDragManaged]="true"
                     [rowHeight]="40"
                     [suppressRowTransform]="true"
                     [tooltipHideDelay]="tooltipHideDelay"
                     [tooltipShowDelay]="tooltipShowDelay"
                     class="ag-theme-alpine"
                     rowSelection="multiple"
                     style="width: 100%; height: 100%;">
    </ag-grid-angular>
  </div>
  <ng-template #createCompanyModal>
    <form [formGroup]="CreateCompanyFG" class="needs-validation" novalidate>
      <p-toast></p-toast>
      <div class="modal-header">
        <h3 class="modal-title">Создание новой компании</h3>
        <i (click)="closeModal()" class="pi pi-times-circle" style="cursor: pointer; font-size: 2rem"></i>
      </div>
      <div class="modal-body">
        <div class="position-relative" style="margin-top: 20px;">
          <h5 class="">Название компании<span style="color:red">*</span></h5>
          <div class="">
            <input formControlName="company_name" pInputText style="width: 29em" type="text"/>
          </div>
        </div>
        <div class="position-relative" style="margin-top: 20px;">
          <h5 class="">Артикул компании<span style="color:red">*</span></h5>
          <div class="">
            <p-inputNumber [style]="{width: '29em'}" [useGrouping]="false" formControlName="company_article">
            </p-inputNumber>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <p-toast></p-toast>
        <p-button (click)="closeModal()" label="Отмена"
                  styleClass="p-button-raised p-button-text p-button-secondary"></p-button>
        <button
          (click)="createCompany()"
          [disabled]="CreateCompanyFG.invalid"
          class="p-button-raised"
          label="Создать"
          pButton
          type="button">
        </button>
      </div>
    </form>
  </ng-template>
</div>
