<div *ngIf="!visible_list_pay" class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <!--          <i class="icon {{icon}}"></i>-->
      </div>
      <div>
        <a href="/profile/bill">Вернуться к списку счетов</a>
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>
<div *ngIf="visible_list_pay" class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <!--          <i class="icon {{icon}}"></i>-->
      </div>
      <div>
        <a href="/profile/tariff">Перейти к тарифам</a>
        <div class="page-title-subheading">
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="payment" class="row">
  <div class="col-md-6">
    <div *ngIf="payment_status" class="mb-3 card text-white card-body bg-success">
      <h5 class="text-white card-title">Счет оплачен поздравляем</h5>Статус платежа обычно обновлется в течении 5 минут
    </div>
    <div *ngIf="!payment_status" class="mb-3 card text-white card-body bg-danger">
      <h5 class="text-white card-title">Счет не оплчен повторите попытку заново</h5>
    </div>
  </div>
</div>

<div *ngIf="!visible_list_pay" class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-header">Счет на оплату тарифа "Базовый пакет на месяц"</div>
      <div class="card-body">
        <div class="input-group" role="group">
          <input [(ngModel)]="promokod" class="form-control" name="promokod" placeholder="Введите Промокод для скидки"
                 type="text">
          <div class="input-group-append">
            <button (click)="setPromokod()" class="btn btn-outline-secondary" type="button">ОК</button>
          </div>
        </div>
        <div><br/>Сумма к оплате</div>
        <div class="card no-shadow rm-border bg-transparent widget-chart text-left mt-2">
          <div class="widget-chart-content">
            <div *ngIf="price_new" class="widget-numbers text-success"><span>{{price_new}} ₽</span></div>
            <div *ngIf="!price_new" class="widget-numbers text-success"><span>{{params_bill?.cost}} ₽</span></div>
          </div>
        </div>
      </div>
      <div class="d-block text-center card-footer">
        <a href="/profile/bill?id={{params_bill.hash}}&pay=1" target="_blank">
          <button class="btn-wide mb-2 mr-2 btn btn-success">Оплатить онлайн</button>
        </a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="visible_list_pay" class="main-card mb-3 card">
  <div class="card-header">Счета на оплату</div>
  <div class="table-responsive">
    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
      <p-toast></p-toast>
      <thead>
      <tr>
        <th class="text-center">#</th>
        <th class="text-center">Статус</th>
        <th class="text-center">Дата счета</th>
        <th class="text-center">Дата оплаты</th>
        <th class="text-center">Название тарифа<br>сумма</th>
        <th class="text-center">Промокод</th>
        <th class="text-center">Сумма к оплате</th>
        <th class="text-center" colspan="2">Действия</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of list_pay; let i = index">
        <td class="text-center text-muted">#{{i + 1}}</td>
        <td *ngIf="item.status_pay == 1" class="text-center text-muted">
          <div class="badge bg-success">Оплачен</div>
        </td>
        <td *ngIf="item.status_pay == 0" class="text-center text-muted">
          <div class="badge bg-warning">В процессе</div>
        </td>
        <td>{{item.date_create | date:'dd.MM.yyyy'}}</td>
        <td *ngIf="item.date_pay == '0000-00-00 00:00:00'" class="text-center text-muted">------</td>
        <td *ngIf="item.date_pay != '0000-00-00 00:00:00'"
            class="text-center text-muted">{{item.date_pay | date:'dd.MM.yyyy'}}</td>
        <td>
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left flex2">
                <div class="widget-heading">{{item.tariff_name}}</div>
                <div class="widget-subheading opacity-7">
                  сумма {{item.tariff_price | currency:'RUB':'symbol-narrow'}}</div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div *ngIf="item.promokod !=''" class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left flex2">
                <div class="widget-heading">{{item.promokod}}</div>
                <div *ngIf="item.promokod_pecent > 0" class="widget-subheading opacity-7">
                  скидка {{item.promokod_pecent}}%
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="item.promokod ==''" class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left flex2">
                <div><a href="/profile/bill?id={{item.hash}}">
                  <button class="btn btn-info btn-sm" type="button">применить</button>
                </a></div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">{{item.cost | currency:'₽ '}}</td>
        <td *ngIf="item.status_pay == 0" class="text-center">
          <p-toast></p-toast>
          <div *ngIf="item.promokod !=''"><a href="/profile/bill?id={{item.hash}}&pay=1" target="_blank">
            <button class="btn btn-success btn-sm" type="button">Оплатить</button>
          </a></div>
          <div *ngIf="item.promokod ==''"><a href="/profile/bill?id={{item.hash}}&pay=1" target="_blank">
            <button class="btn btn-success btn-sm" type="button">Оплатить</button>
          </a></div>
        </td>
        <td *ngIf="item.status_pay == 0" class="text-center">
          <p-toast></p-toast>
          <button (click)="deleteBill(item.hash)" class="btn btn-danger btn-sm" type="button">Удалить</button>
        </td>
        <td *ngIf="item.status_pay == 1" class="text-center"></td>
        <td *ngIf="item.status_pay == 1" class="text-center"></td>
      </tr>
      <tr *ngIf="list_pay?.length == 0">
        <td class="text-center text-muted" colspan="10"><b>Счетов на оплату еще нет</b></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
