import { Component, OnInit } from '@angular/core';
import { ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment/moment';
import { ApiService } from '../../../shared/services/api.service';
import { DatePickerCommon } from '../../../shared/common-variables/date-picker-common';
import { FIRST_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { DB_UNIT_COL_DEF, TAXES_PERCENTS_LIST } from './dbunit.mock';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { DbUnitList, IBodyRequestChangedFilter } from './dbunit.interface';
import {
  CommonResponseGeneral1,
  CommonValueWithIDName,
  IBarcodeItem,
} from '../../../shared/interfaces/common.interface';
import { MessageService } from 'primeng/api';
import { Nullable, PopUpStatus } from '../../../shared/interfaces/common.type';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-dbunit',
  templateUrl: './dbunit.component.html',
  styleUrls: ['./dbunit.component.sass'],
  providers: [MessageService],
})
export class DbunitComponent implements OnInit {
  DatePickerStatic: DatePickerCommon;
  loading = true;
  isLoadingStatus = false;
  date_dis = false;
  selected: any = { startDate: moment().add(-20, 'days'), endDate: moment() };
  ranges: any = FIRST_RANGE_TYPE;
  columnDefs: (ColDef | ColGroupDef)[] = DB_UNIT_COL_DEF;
  defaultColDef: ColDef = { sortable: true, resizable: true, filter: true };
  rowData: DbUnitList[] = null;
  nmid: any[];
  wareHouses: any[];
  typeOfTaxes: any[];
  dataperiod = 1;
  rowHeight = 30;
  shop_id: number;
  items_count: any;
  items_count_avg: any;
  selectedNmid: Nullable<IBarcodeItem[]> = null;
  selectedWareHouse: Nullable<CommonValueWithIDName> = null;
  selectedTaxPercent: Nullable<string> = null;
  selectedTaxes: Nullable<CommonValueWithIDName> = null;
  taxesPercentList = TAXES_PERCENTS_LIST;
  private gridApi;

  constructor(
    private api: ApiService,
    private messageService: MessageService,
  ) {
    this.DatePickerStatic = new DatePickerCommon();
  }

  get generalRequest() {
    return {
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      shop_id: this.shop_id,
    };
  }

  ngOnInit(): void {
    this.shop_id = JSON.parse(localStorage.getItem('mpall_shop') || '{}').shop;
    this.getDBUnitTaxWh();
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  uploadNmidWithData() {
    this.loadDBUnit();
    this.getBarcodeNmid();
  }

  clearFilterParameter(body: any): void {
    this.loadDBUnit(body);
  }

  changedFilterParameter(selectedCode: string, value: any): void {
    const bodyRequest = {
      barcode: this.selectedNmid,
      wareHouse: this.selectedWareHouse,
      taxes: this.selectedTaxes,
      taxPercent: this.selectedTaxPercent,
    } as IBodyRequestChangedFilter;
    switch (selectedCode) {
      case 'Tax':
        bodyRequest.taxes = value;
        break;
      case 'WareHouse':
        bodyRequest.wareHouse = value;
        break;
      case 'Nm':
        bodyRequest.barcode = value;
        break;
      case 'TaxPercent':
        bodyRequest.taxPercent = value;
        break;
    }
    this.loadDBUnit(bodyRequest);
  }

  protected loadDBUnit(params = null): void {
    this.isLoadingStatus = true;
    const bodyRequest = {
      ...this.generalRequest,
      params,
    };
    this.api
      .userRegister('getDBUnit', localStorage.getItem('token'), bodyRequest)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoadingStatus = false)),
      )
      .subscribe(
        (response: CommonResponseGeneral1<DbUnitList[]>) => {
          if (response.is_error === 1) {
            this.showPopUpMessage(
              'error',
              'Ошибка загрузки данных',
              'Не удалось загрузить данные. Проверьте введеные параметры и повторите попытку',
            );
            this.rowData = [];
          } else {
            this.rowData = response.data;
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  protected getBarcodeNmid(): void {
    const b = moment(this.selected.startDate.format('YYYY-MM-DD'));
    const a = moment(this.selected.endDate.format('YYYY-MM-DD'));
    const res = a.diff(b, 'days'); // 1
    const body = this.generalRequest;
    this.api
      .userRegister('getDBUnititems', localStorage.getItem('token'), body)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoadingStatus = false)),
      )
      .subscribe(
        (data: any) => {
          this.nmid = data.data;
          this.items_count = data.count;
          if (res === 0) {
            data.count === '0' ? (this.items_count_avg = 0) : (this.items_count_avg = Math.round(data.count / 1));
          } else {
            this.items_count_avg = Math.round(data.count / a.diff(b, 'days'));
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  protected getDBUnitTaxWh(): void {
    const bodyRequest = this.generalRequest;
    forkJoin([this.loadRequestTax(bodyRequest), this.loadRequestWH(bodyRequest)])
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoadingStatus = false)),
      )
      .subscribe(
        ([taxes, warehouses]) => {
          this.typeOfTaxes = taxes.data.filter((a) => a.type === 1);
          this.wareHouses = warehouses.data;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private loadRequestWH(bodyRequest): Observable<any> {
    return this.api.userRegister('getDBUnitWH', localStorage.getItem('token'), bodyRequest);
  }

  private loadRequestTax(bodyRequest): Observable<any> {
    return this.api.userRegister('getDBUnittax', localStorage.getItem('token'), bodyRequest);
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
