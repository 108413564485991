import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Params} from '@angular/router';

@Component({
  selector: 'app-btn-fin-partners-cell-renderer',
  template: `
      <button label="" (click)="editPartner()" class="icon-button" style="border: none; background: transparent; margin-right: 15px;">
          <i class="lnr-pencil"></i>
      </button>
      <button label="" (click)="deletePartner()" class="icon-button" style="border: none; background: transparent;">
          <i class="lnr-trash"></i>
      </button>
  `
})
export class BtnFinpartnersCellRendererComponent implements ICellRendererAngularComp {
  private params: Params;
  componentParent: any;
  agInit(params: Params): void {
    this.params = params;
    this.componentParent = this.params.context.componentParent;
  }
  editPartner() {
    this.componentParent.openModalUpdatePartner(this.params.data);
  }
  deletePartner() {
    this.componentParent.deletePartner(this.params.data);
  }
  refresh() {
    return false;
  }
}
