import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DOCUMENT} from '@angular/common';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    urlLink = environment.apiOld;

    constructor(private http: HttpClient, @Inject(DOCUMENT) private document: any) {
    }

    userRegister(action, token, params): Observable<any> {
        const body = {action, token, params};
        return this.http.post<any>(this.urlLink + 'data', body);
    }

    userRegisterFin(action, token, params): Observable<any> {
        const body = {action, token, params};
        return this.http.post(this.urlLink + 'fin', body);
    }

    userRegisterClick(action, token, params): Observable<any> {
        const body = {action, token, params};
        return this.http.post(this.urlLink + 'cl', body);
    }

    postWithLoadOrUpdate<T>(action, token, params, URL: string): Observable<T> {
        const body = {action, token, params};
        return this.http.post<T>(`${this.urlLink}${URL}`, body);
    }

    // TODO - ADVERT REQUESTS
    getAdvertData<T>(bodyRequest: any, URLParameter: string): Observable<T> {
        const httpParams = new HttpParams()
            .set('id', bodyRequest.id)
            .set('token', bodyRequest.token)
            .set('shop_id', bodyRequest.shop_id);
        return this.http.get<T>(`${environment.apiAdverts}/${URLParameter}`, {params: httpParams});
    }

    updateAdvertData(bodyRequest, URLParameter): Observable<any> {
        return this.http.post(`${environment.apiAdverts}/${URLParameter}`, bodyRequest);
    }
}