import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-primeng-datepicker',
  templateUrl: './primeng-datepicker.component.html',
  styleUrls: ['./primeng-datepicker.component.scss']
})
export class PrimengDatepickerComponent {
  @Input()
  styleOptions: object;

  @Input()
  controlName?: FormControl;

  constructor() {
    this.controlName?.valueChanges.subscribe((newValue) => {
      console.log('New Value:', newValue);
    });
  }

}
