<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div *ngIf="view_google_table_info" class="col-sm-6 col-md-6">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Обращаем внимание, веб сервис и гугл таблица - разные продукты от MP Surf</h5>
      <div class="col-sm-4 col-md-4">
        <button (click)="closeGoogleTableMsg(1)" class="btn btn-primary mt-2 mr-2">Все понятно</button>
      </div>
    </div>
    <br/>
  </div>
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="block-order-ftype">
          <div class="page-title-icon">
            <i class="pe-7s-wallet icon-gradient bg-mean-fruit"></i>
          </div>
          <div>
            Мои продажи c
          </div>
          <div class="btn-actions-pane-left">
            <input (change)="newValue()"
                   [(ngModel)]="DatePicker.selectedData"
                   [alwaysShowCalendars]="true"
                   [disabled]="dataDisabled"
                   [isInvalidDate]="DatePicker.isInvalidDate"
                   [linkedCalendars]="true"
                   [locale]="DATE_PICKER_LOCALE"
                   [maxDate]='DatePicker.maxDate'
                   [minDate]='DatePicker.minDate'
                   [ranges]="ranges"
                   [showClearButton]="false"
                   [showCustomRangeLabel]="true"
                   ngxDaterangepickerMd
                   placeholder="Выберите даты"
                   type="text"/>
          </div>
          <div class="btn-actions-pane-left">
            <button (click)="view_only_cancel(1)" *ngIf="visibleBtn == 1" class="btn-action-elem mr-2 btn btn-danger">
              Показать только
              возвраты
            </button>
            <button (click)="view_only_cancel(0)" *ngIf="visibleBtn == 0" class="btn-action-elem mr-2 btn btn-success">
              Показать
              все
            </button>
          </div>
        </div>
        <div class="block-order-ftype">
          <div class="btn-actions-pane-left">
            <p-multiSelect (onChange)="selectedParameters()" (onClear)="onClearParameters('article')"
                           [(ngModel)]="selectedNm" [maxSelectedLabels]="1" [options]="nmid"
                           [showClear]="true" [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                           defaultLabel="Выберите артикул" emptyMessage="Артикулов не найдено"
                           itemSize="30" optionLabel="name"
                           selectedItemsLabel="Выбрано {0}">
            </p-multiSelect>
          </div>
          <div class="btn-actions-pane-left">
            <p-multiSelect (onChange)="selectedParameters()" (onClear)="onClearParameters('warehouse')"
                           [(ngModel)]="selectedWH"
                           [maxSelectedLabels]="1"
                           [options]="wareHousesList"
                           [showClear]="true" [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                           defaultLabel="Выберите склад" emptyMessage="Складов не найдено"
                           itemSize="30"
                           optionLabel="name" selectedItemsLabel="Выбрано {0}">
            </p-multiSelect>
          </div>
          <div class="btn-actions-pane-left">
            <p-multiSelect (onChange)="selectedParameters()" (onClear)="onClearParameters('category')"
                           [(ngModel)]="selectedCategory"
                           [maxSelectedLabels]="1"
                           [options]="categoryList"
                           [showClear]="true" [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                           defaultLabel="Выберите категорию" emptyMessage="Категорий не найдено"
                           itemSize="30"
                           optionLabel="name" selectedItemsLabel="Выбрано {0}">
            </p-multiSelect>
          </div>
          <div class="btn-actions-pane-left">
            <p-multiSelect (onChange)="selectedParameters()" (onClear)="onClearParameters('subject')"
                           [(ngModel)]="selectedSubject"
                           [maxSelectedLabels]="1"
                           [options]="subjectList"
                           [showClear]="true" [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                           defaultLabel="Выберите предмет" emptyMessage="Предметов не найдено"
                           itemSize="30"
                           optionLabel="name" selectedItemsLabel="Выбрано {0}">
            </p-multiSelect>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-card mb-3 card">
    <div *ngIf="isLoading" class="font-icon-wrapper float-left mr-12 mb-12">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="main-card mb-3 card">
          <div class="loader-wrapper d-flex justify-content-center align-items-center">
            <div class="loader">
              <div class="ball-pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isLoading">
      <div class="card-header">
        Продаж ({{itemsCount}} шт)&nbsp;&nbsp;&nbsp;&nbsp;среднее количество в день: {{itemsCountAvg}}
      </div>
      <div class="row">
        <div class="col-sm-10 col-md-10">
          <div class="card-body">
            <div id="chart">
              <ng-container *ngIf="chartOptions">
                <apx-chart [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
                           [series]="chartOptions.series"
                           [stroke]="chartOptions.stroke" [tooltip]="chartOptions.tooltip" [xaxis]="chartOptions.xaxis"
                           [yaxis]="chartOptions.yaxis"></apx-chart>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-sm-2 col-md-2">
          <div class="col-sm-12 col-md-12">
            <div class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
              <div class="widget-chat-wrapper-outer">
                <div class="widget-chart-content">
                  <h6 class="widget-subheading">Продажи ₽</h6>
                  <div class="widget-chart-flex">
                    <div class="widget-numbers mb-0 w-100">
                      <div class="widget-chart-flex">
                        <div class="fsize-4">
                          <small class="opacity-5">₽</small>
                          {{salesMoneyTotal | number: '1.0-0'}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
              <div class="widget-chat-wrapper-outer">
                <div class="widget-chart-content">
                  <h6 class="widget-subheading">Продажи ШТ</h6>
                  <div class="widget-chart-flex">
                    <div class="widget-numbers mb-0 w-100">
                      <div class="widget-chart-flex">
                        <div class="fsize-4">
                          <small class="opacity-5">ШТ</small>
                          {{salesQtyTotal}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
              <div class="widget-chat-wrapper-outer">
                <div class="widget-chart-content">
                  <h6 class="widget-subheading">Возвраты ₽</h6>
                  <div class="widget-chart-flex">
                    <div class="widget-numbers mb-0 w-100">
                      <div class="widget-chart-flex">
                        <div class="fsize-4">
                          <small class="opacity-5">₽</small>
                          {{salesReturnMoneyTotal | number: '1.0-0'}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="isLoading" class="font-icon-wrapper float-left mr-12 mb-12">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="main-card mb-3 card">
          <div class="loader-wrapper d-flex justify-content-center align-items-center">
            <div class="loader">
              <div class="ball-pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading" class="table-responsive">
      <table class="align-middle mb-0 table table-borderless table-striped table-hover">
        <thead>
        <tr>
          <th class="text-center">Фото</th>
          <th class="text-center">Название</th>
          <th class="text-center">Ариткул</th>
          <th class="text-center">Дата продажи</th>
          <th class="text-center">Цена продажи</th>
          <!--<th class="text-center">Размер</th>-->
          <th class="text-center">Суммы</th>
          <!--<th class="text-center">Количество</th>-->
          <!--<th class="text-center">Прибыль</th>-->
          <th class="text-center">Склад</th>
          <th class="text-center">Регион доставки</th>
          <th class="text-center">№ Продажи</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of items; let i = index">
          <tr *ngIf="item.return_record == 1" style="color: white;background-color: #ffcccc;">
            <td *ngIf="item.photo_new == null" class="text-center text-muted"><img alt="" width="40"/>{{item.photo_new}}
            </td>
            <td *ngIf="item.photo_new?.length > 10" class="text-center text-muted"><img alt=""
                                                                                        src="{{item.photo_new}}"
                                                                                        width="40"/>
              <!--<td class="text-center text-muted" *ngIf="item.nmid?.length == 8"><img width="40"
                                                                                     src="https://img1.wbstatic.net/tm/new/{{item.nmid.substr(0,4)}}0000/{{item.nmid}}-1.jpg"
                                                                                     alt=""/>
              </td>
              <td class="text-center text-muted" *ngIf="item.nmid?.length == 9"><img width="40"
                                                                                     src="https://img1.wbstatic.net/tm/new/{{item.nmid.substr(0,5)}}0000/{{item.nmid}}-1.jpg"
                                                                                     alt=""/>
              </td>-->
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-subheading opacity-7"><b>Предмет: {{item.subject}}</b></div>
                    <div class="widget-subheading opacity-7">Размер: {{item.techSize}}</div>
                    <div class="widget-subheading opacity-7">Категория: {{item.category}}</div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">SKU: <a routerLink="/guides/products/{{item.nmId}}"
                                                        target="new">{{item.nmId}}</a></div>
                    <div class="widget-subheading opacity-7">Баркод: {{item.barcode}}</div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">{{item.date | date: 'dd.MM.yyyy HH:mm'}}</td>
            <td class="text-center">{{item.priceWithDisc}}</td>
            <!--<td class="text-center">{{item.techSize}}</td>-->
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">Закупка: {{item.cost_price}}</div>
                    <div class="widget-subheading opacity-7">Поставщику: {{item.forPay}}</div>
                    <div class="widget-subheading opacity-7">Заплатил клиент: {{item.finishedPrice}}</div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">{{item.warehouseName}}</td>
            <td class="text-center">{{item.regionName}}</td>
            <td class="text-left">№ {{item.saleID}}<br/>№ {{item.odid}} </td>
          </tr>
          <tr *ngIf="item.return_record == 0">
            <td *ngIf="item.photo_new == null" class="text-center text-muted">
              <img alt="" width="40"/>{{item.photo_new}}
            </td>
            <td *ngIf="item.photo_new?.length > 10" class="text-center text-muted">
              <img alt=""
                   src="{{item.photo_new}}"
                   width="40"/>
            </td>
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-subheading opacity-7"><b>Предмет: {{item.subject}}</b></div>
                    <div class="widget-subheading opacity-7">Размер: {{item.techSize}}</div>
                    <div class="widget-subheading opacity-7">Категория: {{item.category}}</div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">SKU: <a routerLink="/guides/products/{{item.nmId}}"
                                                        target="new">{{item.nmId}}</a></div>
                    <div class="widget-subheading opacity-7">Баркод: {{item.barcode}}</div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">{{item.date | date: 'dd.MM.yyyy HH:mm'}}</td>
            <td class="text-center">{{item.priceWithDisc}}</td>
            <td>
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">Закупка: {{item.cost_price}}</div>
                    <div class="widget-subheading opacity-7">Поставщику: {{item.forPay}}</div>
                    <div class="widget-subheading opacity-7">Заплатил клиент: {{item.finishedPrice}}</div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">{{item.warehouseName}}</td>
            <td class="text-center">{{item.regionName}}</td>
            <td class="text-left">№ {{item.saleID}}<br/>№ {{item.odid}} </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
    <div class="d-block text-center card-footer">
      <div class="card-body">
        <ngb-pagination (pageChange)="selectedParameters(page)" [(page)]="page" [boundaryLinks]="true"
                        [collectionSize]="itemsCount" [maxSize]="10"
                        [pageSize]="100" [rotate]="true"></ngb-pagination>
      </div>
    </div>
  </div>
</div>
