<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"/>
<p-toast/>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
    <div class="h-100 bg-premium-dark">
        <div class="d-flex h-100 justify-content-center align-items-center">
            <div class="mx-auto app-login-box col-md-8">
                <div *ngIf="!isUserCreated; else confirmation;"
                     class="modal-dialog w-100">
                    <form [formGroup]="formRegister"
                          class="needs-validation"
                          novalidate>
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="modal-title">
                                    <h1 class="mt-2">
                                        Добро пожаловать
                                    </h1>
                                    <h5>Создание учетной записи займет всего несколько секунд</h5>
                                </div>
                                <div class="d-flex flex-column gap-2">
                                    <input formControlName="email"
                                           pInputText
                                           placeholder="Введите email"
                                           type="email"
                                    />
                                    <div class="d-flex align-items-center gap-2">
                                        <p-checkbox binary="true"
                                                    formControlName="checkrule"
                                                    id="consent"
                                                    inputId="consent">
                                        </p-checkbox>
                                        <label for="consent">
                                            Я принимаю условия.
                                        </label>
                                    </div>
                                </div>
                                <div class="divider"></div>
                                <h6 class="mb-0">
                                    Уже есть аккаунт?
                                    <a class="text-primary"
                                       href="/auth/login/">
                                        Войти
                                    </a>
                                    |
                                    <a class="text-primary"
                                       href="/auth/reset-password">
                                        Восстановить пароль
                                    </a>
                                </h6>
                            </div>
                            <div class="modal-footer d-block text-center">
                                <button type="submit"
                                        (click)="createUser()"
                                        [disabled]="formRegister.invalid || formRegister.disabled"
                                        class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-primary btn-lg">
                                    Зарегистрироваться
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="text-center text-white opacity-8 mt-3">
                    Copyright © mpsurf.ru 2020
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #confirmation>
    <div class="modal-dialog w-100">
        <form [formGroup]="formConfirm"
              class="needs-validation"
              novalidate>
            <div class="modal-content">
                <div class="modal-body">
                    <h5 class="modal-title">
                        <h2 class="mt-2">
                            Подтверждение
                        </h2>
                    </h5>
                    <div class="divider"></div>
                    <div class="form-group">
                        <div class="d-flex flex-column">
                            <p-inputNumber formControlName="confirm"
                                           mode="decimal"
                                           [style]="{'width': '100%'}"
                                           placeholder="Введите код подтверждения"
                                           inputId="withoutgrouping"
                                           [useGrouping]="false"/>
                        </div>
                        <div class="divider"></div>
                        <h6 class="mb-0">
                            Уже есть аккаунт?
                            <a class="text-primary"
                               href="/auth/login/">
                                Войти
                            </a>
                            |
                            <a class="text-primary"
                               href="/auth/login?lostpassword=1">
                                Восстановить пароль
                            </a>
                        </h6>
                    </div>
                </div>
                <div class="modal-footer d-block text-center">
                    <button type="submit"
                            (click)="confirmUserRegistration()"
                            [disabled]="formConfirm.invalid || formConfirm.disabled"
                            class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-primary btn-lg">
                        Подтвердить
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>