export function FilterParamsController<T>(tabID: string, jsonRequest: any): any {
    if (!IsSessionStorageHasFilters(tabID)) {
        sessionStorage.setItem(tabID, jsonRequest);
    }
    let request = {};
    if (sessionStorage.getItem(tabID)) {
        request = JSON.parse(sessionStorage.getItem(tabID)) as T;
    }
    return request;
}

function IsSessionStorageHasFilters(tabID: string): boolean {
    return !!sessionStorage.getItem(tabID);
}
