import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MpSurfApiService } from '../../../shared/services/mpsurf-api.service';
import {
  ABS_ANALYST_BRIEF_COLUMN_DEFS,
  ABS_ANALYST_BRIEF_MOCK,
  ABS_ANALYST_FULL_COLUMN_DEFS,
  ABS_ANALYST_FULL_MOCK,
  ABS_TABLE_DATA,
  ANALISISE_STATUSES,
  ARTICLE_BARCODE_SEPARATORS,
  INDICATORS,
  WB_PARAMS,
} from './abs-analyst.mock';
import { LOCALE_TEXT_FOR_FILTERS } from '../../guides/products/products.mock';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';

@Component({
  selector: 'app-abc-analyst',
  templateUrl: './abc-analyst.component.html',
  styleUrls: ['./abc-analyst.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbcAnalystComponent {
  isLoading = false;
  selectedDate: any;
  INDICATORS = INDICATORS;
  ARTICLE_BARCODE_SEPARATORS = ARTICLE_BARCODE_SEPARATORS;
  WB_PARAMS = WB_PARAMS;
  ANALISISE_STATUSES = ANALISISE_STATUSES;
  ABS_TABLE_DATA = ABS_TABLE_DATA;

  selectedAnaliseStatus = 1;
  selectedIndicator: number;
  selectedArticleBarcode: number;
  selectedWbParams: number;

  rowDataGeneral = null;
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly gridOptions: GridOptions;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };
  private gridApi!: GridApi<any>;
  private readonly _messageService = inject(MessageService);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _mpSurfApiService = inject(MpSurfApiService);

  constructor() {
    this.gridOptions = {
      columnDefs: [...ABS_ANALYST_BRIEF_COLUMN_DEFS],
      context: { componentParent: this },
      enableCellTextSelection: true,
    } as GridOptions;
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.loadAbsAnalyst();
  }

  loadAbsAnalyst() {
    if (this.selectedAnaliseStatus === 1) {
      this.rowDataGeneral = ABS_ANALYST_BRIEF_MOCK;
      this.gridOptions.api.setColumnDefs(ABS_ANALYST_BRIEF_COLUMN_DEFS);
    } else {
      this.rowDataGeneral = ABS_ANALYST_FULL_MOCK;
      this.gridOptions.api.setColumnDefs(ABS_ANALYST_FULL_COLUMN_DEFS);
    }
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
  }

  analystStatusChanged() {
    this.loadAbsAnalyst();
  }
}
