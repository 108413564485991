<p-progressSpinner *ngIf="isLoadingStatus"
                   [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<div [ngStyle]="{'display': isLoadingStatus ? 'none' : 'block'}">
  <div class="card">
    <p-table #dt1 [value]="auctionTableMocks" dataKey="id"
             [rows]="10"
             [scrollable]="true"
             [showCurrentPageReport]="true"
             [rowsPerPageOptions]="[10, 25, 50]"
             [paginator]="true"
             (sortFunction)="customSort($event)"
             [customSort]="true"
             currentPageReportTemplate="Страницы пагинации"
             [globalFilterFields]="['request', 'category',]">
      <ng-template pTemplate="caption">
        <div class="flex">
          <button pButton label="Очистить" class="p-button-outlined" icon="pi pi-filter-slash"
                  (click)="clear(dt1)"></button>
          <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                           placeholder="Поиск по ключевым словам"/>
                </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width:15rem" pSortableColumn="request">
            <div class="flex align-items-center">
              Запрос
              <!--            <p-sortIcon field="request"></p-sortIcon>-->
              <!--            <p-columnFilter type="text" field="request" display="menu"></p-columnFilter>-->
            </div>
          </th>
          <th style="min-width:15rem" pSortableColumn="category">
            <div class="flex align-items-center">
              Категория
              <!--            <p-sortIcon field="frequency"></p-sortIcon>-->
              <!--            <p-columnFilter type="number" field="category" display="menu"></p-columnFilter>-->
            </div>
          </th>
          <th *ngFor="let title of titlePlaces">
            <div class="flex align-items-center">
              {{title}}
              <!--            <p-sortIcon field="places"></p-sortIcon>-->
              <!--            <p-columnFilter type="number" field="places" display="menu"></p-columnFilter>-->
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-positiveData>
        <tr>
          <td style="min-width:15rem">
            {{ positiveData.request }}
          </td>
          <td style="min-width:15rem">
            {{ positiveData.category }}
          </td>
          <td style="min-width:15rem; justify-content: center;" *ngFor="let place of positiveData.places">
            {{ place }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">Статистики по аукциону не найдено</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
