<p-progressSpinner *ngIf="isLoadingStatus"
                   [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoadingStatus ? 'none' : 'block'}">
  <form [formGroup]="advertManageFG">
    <div class="container-fluid">
      <div class="product-detail-block">
        <div class="header-product-detail">
          <div class="header-pd-left">
            <div *ngIf="!isShowCardTitleInput" style="display: flex;align-items: center;gap: 10px;">
              <h4 class="hpd-product-name">{{getFormControlValue('advertManageFg', 'card_title')}}</h4>
              <i (click)="isShowCardTitleInput = true" aria-hidden="true"
                 class="fa fa-pencil-square-o fa-icon-edit"></i>
            </div>
            <div *ngIf="isShowCardTitleInput" class="additional-title-block">
              <input formControlName="card_title" pInputText type="text"/>
              <i (click)="renameCompany()" aria-hidden="true" class="fa fa-check-circle"></i>
              <i (click)="cancelRenaming()" aria-hidden="true" class="fa fa-times"></i>
            </div>
            <p class="hpd-active-statuc">
              <p-inputSwitch formControlName="card_status"></p-inputSwitch>
              <span [ngStyle]="{'color' : getFormControlValue('advertManageFg','card_status') ? '#22C55E' : '#ff0000'}">
              {{getFormControlValue('advertManageFg', 'card_status')
                ? 'Активно'
                : 'Не активно'}}
            </span>
            </p>
          </div>
          <div class="header-pd-right">
            <p-confirmPopup></p-confirmPopup>
            <p-button (click)="finishCompany($event)" label="Завершить компанию"
                      styleClass="p-button-outlined p-button-secondary"></p-button>
            <p-button (click)="saveCompany()"
                      label="Сохранить"
                      styleClass="p-button-raised p-button-success"></p-button>
          </div>
        </div>
        <div class="main-product-detail">
          <div class="mpd-top">
            <div class="mpd-top-left">
              <img [src]="getFormControlValue('advertManageFg','card_img')" alt="" width="90">
              <div class="mpd-top-detail">
                <p class="mpdtd-title">{{getFormControlValue('advertManageFg', 'card_name')}}</p>
                <p class="mpdtd-article">
                  <span class="mpdtd-article-t">Артикул: </span>
                  <span class="mpdtd-article-v">{{getFormControlValue('advertManageFg', 'card_article')}}</span>
                  <i (click)="copyArticle(getFormControlValue('advertManageFg','card_article'))" aria-hidden="true"
                     class="fa fa-clone fa-article"></i>
                </p>
                <p class="mpdtd-subject">
                  <span class="mpdtd-article-t">Предмет: </span>
                  <span
                    class="mpdtd-subject-v">{{getFormControlValue('advertManageFg', 'card_subject')}}</span>
                </p>
              </div>
            </div>
            <p-button (click)="openModal('auctionStatisticModal')" label="Статистика по аукциону"
                      styleClass="p-button-outlined"></p-button>
          </div>
          <div class="mpd-bottom">
            <div *ngFor="let cardDetail of cardDetailsPicMock" class="mdp-bottom-card">
              <p>{{cardDetail.title}}</p>
              <span>{{cardDetail.value}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="product-main-info-block"></div>
    </div>
    <div class="d-flex">
      <div class="container-fluid">
        <div class="product-into">
          <h2>Основная информация</h2>
          <h5>Бюджет</h5>
          <div class="d-flex">
            <h3>{{+getFormControlValue('replenish', 'replenish_amount') | currency:'RUB'}}</h3>
            <i (click)="openModal('replenishBalanceModal')" aria-hidden="true"
               class="fa fa-pencil-square-o fa-icon-edit"></i>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="product-aditional-info">
          <h2>Дополнительная информация</h2>
          <div class="btns-manage-part">
            <p-button (click)="openModal('additionalKeyWords', 'negative')" [label]="minusWordsString"
                      styleClass="p-button-raised p-button-text p-button-danger"></p-button>
            <p-button (click)="openModal('additionalKeyWords', 'positive')" [label]="fixedWordsString"
                      styleClass="p-button-raised p-button-text p-button-info">
            </p-button>
          </div>
          <div class="product-add-desc">
            <div class="flex-aligned">
              <label for="card_fix_plus_request">Зафиксировать плюс запросы</label>
              <p-inputSwitch (click)="fixPositiveRequests()"
                             [disabled]="isFixPositiveRequestsInvalid"
                             formControlName="card_fix_plus_request"
                             id="card_fix_plus_request"></p-inputSwitch>
              <i class="pi pi-question-circle" style="font-size: 1rem; margin-top: 4px;"></i>
            </div>
            <p *ngIf="getFormControlValue('advertManageFg','card_fix_plus_request')">Используйте этот функионал
              после того, как в <br> в ракламном кабинете на Wildberries к этой рекламной <br>
              компании подтянутся все интересующие ключевые фразы <br>
              <span>Узнать подробнее</span>
            </p>

          </div>
          <div class="">
            <h5 class="add-info-title">Текущая ставка
              <i class="pi pi-question-circle" style="font-size: 1rem; margin-top: 4px;"></i>
            </h5>
            <p-inputNumber [disabled]="advertManageFG.controls.card_company_type.value === 2" [style]="{width: '350px'}"
                           formControlName="card_current_rate"
                           id="replenish_amount"
                           inputId="currency-us">
            </p-inputNumber>
          </div>
          <div class="">
            <h5 class="add-info-title">Тип компании
            </h5>
            <div *ngFor="let category of companyType" class="field-checkbox-line">
              <p-radioButton [inputId]="category.id" [value]="category.id"
                             formControlName="card_company_type"></p-radioButton>
              <label [for]="category.id" class="ml-2">{{ category.title }}</label>
              <i class="pi pi-question-circle" style="font-size: 1rem; margin-top: 4px;"></i>
            </div>
          </div>

          <div *ngIf="isShowAdditionalBlock()"
               class="additional-company">
            <div class="">
              <h5 class="add-info-title">Регион показа рекламы</h5>
              <p-dropdown [options]="companyRegion"
                          [style]="{width: '350px'}" formControlName="card_region"
                          optionLabel="name"
                          optionValue="id"></p-dropdown>
            </div>
            <div class="">
              <h5 class="add-info-title">Дельта ставки
              </h5>
              <p-inputNumber [style]="{width: '350px'}"
                             formControlName="card_delta_rate"
                             inputId="currency-us">
              </p-inputNumber>
            </div>
            <div class="">
              <h5 class="add-info-title">Максимальная ставка
              </h5>
              <p-inputNumber [style]="{width: '350px'}"
                             formControlName="card_max_rate"
                             inputId="currency-us">
              </p-inputNumber>
            </div>
            <div class="">
              <h5 class="add-info-title">Введите позицию
              </h5>
              <p-inputNumber [style]="{width: '350px'}"
                             formControlName="card_position">
              </p-inputNumber>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="container-fluid">
    <div class="static-block">
      <div class="header-static">
        <div>
          <h3>Статистика по плюс запросам</h3>
          <span>Статистика по плюс запросам по которым рекламируется товар</span>
        </div>
      </div>
      <app-advert-management-statistic-positive></app-advert-management-statistic-positive>
    </div>
  </div>
  <div class="container-fluid">
    <div class="static-block">
      <div class="header-static">
        <div>
          <h3>Статистика по минус словам</h3>
          <span>Статистика по минус фразам, по которым рекламировался ваш товар</span>
        </div>
      </div>
      <app-advert-management-statistic-negative></app-advert-management-statistic-negative>
    </div>
  </div>
  <ng-template #replenishBalanceModal>
    <form [formGroup]="ReplenishBalanceFormGroup" class="needs-validation" novalidate>
      <p-toast></p-toast>
      <div class="modal-header">
        <h3 class="modal-title">Пополнение баланса</h3>
        <i (click)="closeModal()" class="pi pi-times-circle" style="font-size: 2rem"></i>
      </div>
      <div class="modal-body">
        <div class="position-relative" style="margin-top: 20px;">
          <h4 class="">Сумма пополнения<span style="color:red">*</span></h4>
          <div class="">
            <p-inputNumber decimal="none"
                           formControlName="replenish_amount">
            </p-inputNumber>
          </div>
        </div>
        <div class="position-relative" style="margin-top: 20px;">
          <h4 class="">Откуда списать средства<span style="color:red">*</span></h4>
          <div *ngFor="let category of whereWriteOffMoney" class="field-checkbox-line">
            <p-radioButton [inputId]="category.id" [value]="category"
                           formControlName="write_off_money_balance"></p-radioButton>
            <label [for]="category.id" class="ml-2">{{ category.title }}</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <p-button (click)="replenishBalance()"
                  [disabled]="ReplenishBalanceFormGroup.invalid" label="Пополнить"
                  styleClass="p-button-raised"></p-button>
        <p-button (click)="closeModal()" label="Отмена"
                  styleClass="p-button-raised p-button-text p-button-secondary"></p-button>
      </div>
    </form>
  </ng-template>
  <ng-template #auctionStatisticModal>
    <div class="modal-header">
      <h3 class="modal-title">Статистика по аукиону</h3>
      <i (click)="closeModal()" class="pi pi-times-circle" style="font-size: 2rem"></i>
    </div>
    <div class="modal-body">
      <div class="mpd-modal-cards">
        <div *ngFor="let cardDetail of cardAuction" class="mdp-bottom-card">
          <p>{{cardDetail.title}}</p>
          <span>{{cardDetail.value}}</span>
        </div>
      </div>
      <app-advert-management-auction-statistic></app-advert-management-auction-statistic>
    </div>
  </ng-template>
  <ng-template #minusFixWordsModal>
    <form [formGroup]="MinusFixWordsFG" class="needs-validation" novalidate>
      <p-toast></p-toast>
      <div class="modal-header">
        <h3 class="modal-title">{{additionalKeyWords}} запросы <span
          style="color: grey;">{{additionalKeyWords === 'Минус' ? phrasesData.minus_phrases?.length : phrasesData.fixed_phrases?.length}}</span>
        </h3>
        <i (click)="closeModal()" class="pi pi-times-circle" style="font-size: 2rem"></i>
      </div>
      <div class="modal-body">
        <div class="position-relative" style="margin-top: 20px;">
          <p-listbox *ngIf="!showMinusWords"
                     [checkbox]="true"
                     [filter]="true"
                     [multiple]="true"
                     [options]="phrasesData.fixed_phrases"
                     formControlName="all_words">
            <ng-template let-companyWords pTemplate="item">
              <div class="p-d-flex p-ai-center list-checkbox-container">
                <span>{{companyWords}}</span>
                <i (click)="removeAdditionalWordFromTheList('plus', companyWords)"
                   class="pi pi-times remove-icon">
                </i>
              </div>
            </ng-template>
          </p-listbox>
        </div>
        <div class="position-relative" style="margin-top: 20px;">
          <p-listbox *ngIf="showMinusWords"
                     [checkbox]="true"
                     [filter]="true"
                     [multiple]="true"
                     [options]="phrasesData.minus_phrases"
                     emptyMessage="Запросы не найдены. Добавьте новый запрос!" formControlName="all_words">
            <ng-template let-companyWords pTemplate="item">
              <div class="p-d-flex p-ai-center list-checkbox-container">
                <span>{{companyWords}}</span>
                <i (click)="removeAdditionalWordFromTheList('minus', companyWords)"
                   class="pi pi-times remove-icon">
                </i>
              </div>
            </ng-template>
          </p-listbox>
        </div>
        <div class="add-new-word">
          <span class="p-float-label">
            <input formControlName="new_word" id="word" pInputText style="width: 22em;"/>
            <label htmlFor="word">Введите {{additionalKeyWords.toLowerCase()}} запрос</label>
          </span>
          <button [disabled]="MinusFixWordsFG.invalid" class="btn-add-new-word">
            <i (click)="addWordToAdditionalList()" aria-hidden="true"
               class="fa fa-plus-square"></i>
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <p-button (click)="closeModal()" label="Закрыть"
                  styleClass="p-button-outlined"></p-button>
      </div>
    </form>
  </ng-template>
</div>
