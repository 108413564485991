import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { TreeNode } from 'primeng/api';


import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';


import { ApiService } from 'src/app/shared/services/api.service';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { SelectItemGroup } from 'primeng/api';
import * as moment from 'moment';


import { LazyLoadEvent } from 'primeng/api';

import { DomSanitizer } from '@angular/platform-browser';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';

const PrimaryWhite = '#fff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#d92550';
const SecondaryBlue = '#3f6ad8';

@Component({
  selector: 'app-orders-dynamic',
  templateUrl: './dbmonth.component.html',
  styleUrls: ['./dbmonth.component.sass'],
  styles: [`
        .outofstock {
            font-weight: 700;
            color: #FF5252;
            font-size: 14px;
            text-decoration: line-through;
        }

        .lowstock {
            font-weight: 700;
            color: #FFA726;
            font-size: 14px;
        }

        .instock {
            font-weight: 700;
            color: #66BB6A;
            font-size: 14px;
        }

        :host ::ng-deep .row-accessories {
            background-color: rgba(0,0,0,.15) !important;
        }
    `
  ]
})
export class DbmonthComponent implements OnInit {



  ///////////////////////////////////////////////////////////////////////////////



  alwaysShowCalendars: boolean;


  ranges: any = {
    // 'Сегодня': [moment(), moment()],
    //'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    //'За 7 дней': [moment().subtract(6, 'days'), moment()],
    //'За 30 дней': [moment().subtract(29, 'days'), moment()],
    'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
    'Этот квартал': [moment().quarter(moment().quarter()).startOf('quarter'), moment().quarter(moment().quarter()).endOf('quarter')],
    'Этот год': [moment().subtract(0, 'year').startOf('year'), moment().subtract(0, 'year').endOf('year')],
    'Прошлый Месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Прошлый квартал': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
    'Прошлый год': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  };

  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  vis_det: any = false;

  minDate = moment().add(-91, 'days');
  maxDate = moment().add(0, 'days');

  //////////////////////////////////////////
  ///////         NEW
  cols: any;
  cars: any;
  virtualCars: any;
  arr_date_range: any;

  type_datamodel: any = 1;

  selected: any = { startDate: moment().add(-30, 'days'), endDate: moment().add(-1, 'days') };


  isNameFreeze: boolean = false;
  isPriceFreeze: boolean = false;

  column: any;
  total: any;
  total_date: any;
  selectedBarcodesNm: any;
  selectedSubject: any;

  subject: any;
  barcodesnm: any;

  frozenCols: any[];

  value3: any = 1;

  type_data: any = [
    { 'name': 'Стоимость', value: 1 },
    { 'name': 'Количество', value: 2 },
  ];
  justifyOptions: any = [
    { 'name': 'Продажи', value: 1 },
    { 'name': 'Заказы', value: 2 },
    { 'name': 'Возвраты', value: 3 },
  ];

  shop_id: any;

  constructor(
    private api: ApiService,
  ) {

  }


  ngOnInit(): void {

    this.frozenCols = [
      { field: 'abc', header: 'Vin' },
      { field: 'balance', header: 'Year' },
      { field: 'barcode', header: 'Brand' },
      { field: 'name', header: 'Color' }
    ];
    let data_shop = localStorage.getItem('mpall_shop');
    let new_data_shop = JSON.parse(data_shop);
    this.shop_id = new_data_shop.shop;
  }


  /////////////////////////////////////////////////////////////////////////////

  // получаем список предметов
  getSubject() {
    let body = { 'type_group': this.value3, 'startDate': this.selected.startDate.format("YYYY-MM-DD"), 'endDate': this.selected.endDate.format("YYYY-MM-DD"), shop_id: this.shop_id };
    return new Promise((resolve, reject) => {
      this.api.userRegisterFin('getDBMonthFilterSubject', localStorage.getItem('token'), body)
        .subscribe((data: any) => {
          this.subject = data
          //this.loading = false;
          resolve(true);
        }, (err) => {
          reject(err);
        });
    });
  }

  // получаем списко баркодов и артикулов за выбранный период
  getBarcodeNmid() {
    let body = { 'type_group': this.value3, 'startDate': this.selected.startDate.format("YYYY-MM-DD"), 'endDate': this.selected.endDate.format("YYYY-MM-DD"), shop_id: this.shop_id };
    return new Promise((resolve, reject) => {
      this.api.userRegisterFin('getDBMonthFilterBarcodeNm', localStorage.getItem('token'), body)
        .subscribe((data: any) => {
          this.barcodesnm = data
          //this.loading = false;
          resolve(true);
        }, (err) => {
          reject(err);
        });
    });
  }

  _genNewColumnDate() {
    var a = moment(this.selected.startDate.format("YYYY-MM-DD"));
    var b = moment(this.selected.endDate.format("YYYY-MM-DD")).add(1, 'days');
    this.arr_date_range = [];
    for (var m = a; m.isBefore(b); m.add('days', 1)) {
      this.arr_date_range.push({ 'field': 'd' + m.format('YYYYMMDD'), 'header': m.format('DD.MM.YY') });
    }
    this.cols = this.arr_date_range;
  }


  newValue() {
    this.getBarcodeNmid();
    this.getSubject();
    this._genNewColumnDate();
    var params = { barcode: this.selectedBarcodesNm, subject: this.selectedSubject, type_view: this.type_datamodel };
    this._loaddata(params);
  }


  /*
  loadCarsLazy(event: LazyLoadEvent) {
    var params = { barcode: null, subject: null, type_view: null };
    this._loaddata(params);
  }
  */

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////    ПРОДАЖИ/ЗАКАЗЫ/ВОЗВРАТЫ
  public changeType(obj: any) {
    var params = { barcode: this.selectedBarcodesNm, subject: this.selectedSubject, type_view: this.type_datamodel };
    this._loaddata(params);
  }
  ////    СТОИМОСТЬ/КОЛИЧЕСТВО
  selectTypeData(obj: any) {
    var params = { barcode: this.selectedBarcodesNm, subject: this.selectedSubject, type_view: obj.value };
    this._loaddata(params);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////    БАРКОДЫ

  selectedBarcodesNmClear(obj: any) {
    var params = { barcode: null, subject: this.selectedSubject, type_view: this.type_datamodel };
    this._loaddata(params);
  }

  selectedBarcodesNmChange(obj: any) {
    var params = { barcode: obj.value, subject: this.selectedSubject, type_view: this.type_datamodel };
    this._loaddata(params);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////    ПЕРДМЕТЫ
  selectedSubjectClear(obj: any) {
    var params = { barcode: this.selectedBarcodesNm, subject: null, type_view: this.type_datamodel };
    this._loaddata(params);
  }

  selectedSubjectChange(obj: any) {
    var params = { barcode: this.selectedBarcodesNm, subject: obj.value, type_view: this.type_datamodel };
    this._loaddata(params);
  }


///   ЗАГРУЗКА ДАННЫХ
  _loaddata(params = null) {

    let body = { 'type_group': this.value3, 'startDate': this.selected.startDate.format("YYYY-MM-DD"), 'endDate': this.selected.endDate.format("YYYY-MM-DD"), shop_id: this.shop_id, params };
    return new Promise((resolve, reject) => {
      this.api.userRegisterFin('getDBMonth', localStorage.getItem('token'), body)
        .subscribe(async (data: any) => {
          this.virtualCars = data.data
          this.total = data.total;
          this.total_date = data.total_date;

          resolve(true);
        }, (err) => {
          reject(err);
        });
    });
  }

}

