import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BloggersComponent } from './bloggers/bloggers.component';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { KeyQueriesComponent } from './key-queries/key-queries.component';
import { AgGridModule } from 'ag-grid-angular';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MycompairComponent } from './mycompair/mycompair.component';
import { BloggersCellActionComponent } from './bloggers/components/bloggers-cell-action.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToastModule } from 'primeng/toast';
import { KeyQueriesDisplayCellComponent } from './key-queries/key-queries-components/key-queries-display-cell.component';
import { KeyQueriesManageCellComponent } from './key-queries/key-queries-components/key-queries-manage-cell.component';
import { ModalModule } from '../../shared/common-components/modal/modal.module';
import { NgApexchartsModule } from 'ng-apexcharts';

const routes: Routes = [
  {
    path: 'bloggers',
    component: BloggersComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'key-queries',
    component: KeyQueriesComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'compare',
    component: MycompairComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    BloggersComponent,
    KeyQueriesComponent,
    MycompairComponent,
    BloggersCellActionComponent,
    KeyQueriesDisplayCellComponent,
    KeyQueriesManageCellComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HttpClientModule,
    // AG-GRID
    AgGridModule,

    //PrimeNG
    ButtonModule,
    ProgressSpinnerModule,
    InputTextModule,
    DropdownModule,
    RippleModule,
    TooltipModule,
    FormsModule,
    MultiSelectModule,
    InputTextareaModule,
    NgBootstrapFormValidationModule,
    NgbDatepickerModule,
    CalendarModule,
    InputNumberModule,
    ToastModule,
    ModalModule,
    NgApexchartsModule,
  ],
})
export class ExternalAnalysisModule {}
