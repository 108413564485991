<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
    <div class="col-sm-12 col-md-12">
        <div class="mb-3 card text-white card-body bg-warning">
            <h5 class="card-title">
                Идет Beta тестирование
            </h5>
        </div>
        <br/>
    </div>
    <div class="app-page-title"
         style="margin-bottom: 0">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
                </div>
                <div>
                    Закупки-партия
                </div>
            </div>
        </div>
    </div>
    <div class="main-card mb-3 card"
         style="height: calc(92vh - 270px)">
        <ag-grid-angular #agGrid
                         (gridReady)="onGridReady($event)"
                         [columnDefs]="dataForColumnDefs"
                         [defaultColDef]="defaultColDef"
                         [enableRangeSelection]="true"
                         [gridOptions]="gridOptions"
                         [localeText]="LOCALE_RU_FILTERS"
                         [pagination]="true"
                         [rowData]="rowData"
                         [rowDragManaged]="true"
                         [rowHeight]="40"
                         [suppressRowTransform]="true"
                         class="ag-theme-alpine"
                         rowSelection="multiple"
                         style="width: 100%; height: 100%;">
        </ag-grid-angular>
    </div>
</div>
