import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { metrikaConfig } from '../../../../shared/metrika/metrika.config';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SuppliersList } from "./supplier-selection.interface";

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-supplier-selection',
  templateUrl: './supplier-selection.component.html',
  styleUrls: ['./supplier-selection.component.scss'],
})
export class SupplierSelectionComponent implements OnInit {

  private readonly _router = inject(Router);
  private readonly _api = inject(ApiService);

  suppliersList: any[] = [];

  ngOnInit(): void {
    this.loadSuppliers();
  }

  private loadSuppliers(): void {
    this._api.userRegister('getDataShopWB', localStorage.getItem('token'), '')
        .pipe(untilDestroyed(this))
        .subscribe(
      (data: SuppliersList[]) => {
        if (!data || data.length === 0) {
          this._router.navigate(['/settings/shop/']);
        }
        if (data.length === 1) {
          this.suppliersList = data;
          this.navigateBySelectedSupplier(data[0]);
        }

        this.suppliersList = data;
      },
      (err) => {
        console.log(err);
      },
    );
  }

  navigateBySelectedSupplier(item: SuppliersList) {
    if (item) {
      metrikaConfig.params.WBSellerParams = item;
      localStorage.setItem('supplier', JSON.stringify(this.suppliersList));
      localStorage.setItem('mpall_shop', JSON.stringify({ shop: item.id, name: item.name }));
      this._router.navigate(['/dashboard/sales']);
    }
  }
}
