import { CustomTooltipComponent } from '../../guides/products/components/prdcustomTooltip';
import { CELL_CLASS, FILTER_PARAMS_TEXT } from '../../guides/products/products.mock';
import { NumberSortFunc } from '../../../shared/utils/functions/number-sort-func';
import { WBProductsLinkEmitter } from '../../../shared/utils/functions/wb-products-link-emitter';
import { IExcelSupplierTypes } from './supplier-manager.interface';
import { WBImgFormatter } from '../../../shared/utils/functions/wb-img-formatter';

export let wareHouseStatic = 'domodedovo';

export const MSKList = ['Домодедово', 'Подольск', 'Электросталь', 'Коледино'];

export const SUPPLIER_MANAGER_COLUMN_DEFS: any[] = [
  {
    headerName: 'Товар',
    children: [
      {
        headerName: 'Фото',
        cellRenderer: WBImgFormatter,
        sortable: false,
        floatingFilter: false,
        pinned: 'left',
        field: 'thumb',
        width: 100,
        filter: 'agTextColumnFilter',
        tooltipField: 'thumb',
        enableCellTextSelection: false,
        tooltipComponentParams: {
          type: 'thumb',
          photoField: 'thumb',
        },
        tooltipComponent: CustomTooltipComponent,
      },
      {
        headerName: 'Артикул',
        pinned: 'left',
        field: 'article',
        width: 200,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'SKU',
        cellRenderer: WBProductsLinkEmitter,
        pinned: 'left',
        field: 'sku',
        width: 130,
        comparator: NumberSortFunc,
        cellClass: CELL_CLASS,
      },
      {
        headerName: 'Бренд',
        field: 'brand',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Категория',
        field: 'category',
        width: 200,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
      },
      {
        headerName: 'Предмет',
        field: 'subject',
        width: 200,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
      },
      {
        headerName: 'Название',
        field: 'name',
        width: 300,
        filter: 'agTextColumnFilter',
        filterParams: FILTER_PARAMS_TEXT,
        cellClass: CELL_CLASS,
      },
      {
        headerName: 'Размер',
        field: 'size',
        width: 200,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
      },
      {
        headerName: 'Баркод',
        field: 'barcode',
        width: 200,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
      },
      {
        headerName: 'Остатки на ФФ',
        field: 'balances_ff',
        width: 150,
        filter: 'agTextColumnFilter',
        editable: true,
        columnGroupShow: 'open',
      },
      {
        headerName: 'Поставка в пути, шт',
        field: 'supply_way',
        width: 150,
        filter: 'agTextColumnFilter',
        editable: true,
        columnGroupShow: 'open',
      },
      {
        headerName: 'Коэффициент сезонности',
        field: 'seasonality_coefficient',
        width: 150,
        filter: 'agTextColumnFilter',
        editable: true,
        columnGroupShow: 'open',
      },
    ],
  },
  {
    headerName: 'Оборачиваемость дн.',
    field: 'turnover',
    width: 150,
    filter: 'agTextColumnFilter',
    columnGroupShow: 'open',
  },
  {
    headerName: 'Отгрузить шт. Итого',
    field: 'in_order_delivery_type',
    width: 150,
    filter: 'agNumberColumnFilter',
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Остатки всего',
    field: 'balance_api',
    width: 150,
    filter: 'agTextColumnFilter',
    columnGroupShow: 'open',
  },
  {
    headerName: 'Скорость в день',
    field: 'speed_for_day',
    width: 150,
    filter: 'agTextColumnFilter',
    columnGroupShow: 'open',
  },
  {
    headerName: 'В наличии до',
    field: 'exist_before_orders_estimate',
    width: 150,
    filter: 'agTextColumnFilter',
    columnGroupShow: 'open',
  },
];

export const SUPPLIER_MANAGER_WAREHOUSE_COLUMN_DEFS: any[] = [
  {
    headerName: 'Склад 1',
    headerClass: 'header-centered',
    children: [
      {
        headerName: 'Остатки',
        width: 200,
        filter: 'agTextColumnFilter',
        children: [
          {
            headerName: 'На складе ВБ по сайту',
            field: 'wh.' + wareHouseStatic + '.purchase_delivery_type',
            width: 200,
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Оборачиваемость склад ВБ',
            field: 'wh.' + wareHouseStatic + '.turnover_wh_wb',
            width: 200,
            filter: 'agTextColumnFilter',
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'Факт продаж',
        field: 'remains',
        width: 200,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        children: [
          {
            headerName: 'Заказов, шт',
            field: 'wh.' + wareHouseStatic + '.amount_of_orders_wh',
            width: 130,
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'Продаж, шт',
            field: 'wh.' + wareHouseStatic + '.amount_of_sells',
            width: 130,
            filter: 'agTextColumnFilter',
            columnGroupShow: 'open',
          },
          {
            headerName: 'Возвратов, шт.',
            field: 'wh.' + wareHouseStatic + '.amount_of_returns',
            width: 140,
            filter: 'agTextColumnFilter',
            columnGroupShow: 'open',
          },
          {
            headerName: '% выкупа',
            field: 'wh.' + wareHouseStatic + '.ransom_rate',
            width: 130,
            filter: 'agTextColumnFilter',
            columnGroupShow: 'open',
          },
          {
            headerName: 'Скорость заказов средняя, шт в день',
            field: 'wh.' + wareHouseStatic + '.average_speed_orders_per_day',
            width: 170,
            filter: 'agTextColumnFilter',
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'Прогноз',
        field: 'wh.' + wareHouseStatic + '.remains',
        width: 200,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        children: [
          {
            headerName: 'Заказов будет шт.',
            field: 'wh.' + wareHouseStatic + '.orders_to_be',
            width: 200,
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'На складе ВБ на конец периода шт.',
            field: 'wh.' + wareHouseStatic + '.at_wh_wb_by_the_period_end',
            width: 200,
            filter: 'agTextColumnFilter',
            columnGroupShow: 'open',
          },
          {
            headerName: 'Отгрузить штук итого',
            field: 'wh.' + wareHouseStatic + '.ship_total',
            width: 200,
            filter: 'agNumberColumnFilter',
          },
        ],
      },
    ],
  },
];

export const EXCEL_SUPPLIER_TYPES: IExcelSupplierTypes[] = [
  { index: 1, name: 'Поставка в пути, шт', params: 'supply_way' },
  { index: 2, name: 'Коэффициент сезонности', params: 'seasonality_coefficient' },
  { index: 3, name: 'Остатки на ФФ', params: 'balances_ff' },
];
