import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LOCALE_TEXT_FOR_FILTERS } from '../../guides/products/products.mock';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { DefaultKeyParent, DefaultKeyQueryColumn, KEY_QUERIES_COLUMN_DEFS, KeyPopUpMessages } from './key-queries.mock';
import { AgGridAngular } from 'ag-grid-angular';
import { IKeyQueryRowData, ILoadByIdResponse, IParamsKeyQueryRequest } from './key-queries.interface';
import * as moment from 'moment/moment';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import {
  CommonResponseGeneral,
  CommonResponseGeneral1,
  CommonResponseOperation1,
} from '../../../shared/interfaces/common.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MpSurfApiService } from '../../../shared/services/mpsurf-api.service';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-key-queries',
  templateUrl: './key-queries.component.html',
  styleUrls: ['./key-queries.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyQueriesComponent implements AfterViewInit {
  rowData: IKeyQueryRowData[] = [];
  readonly gridOptions: GridOptions;
  readonly colDefs = [...KEY_QUERIES_COLUMN_DEFS];
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    floatingFilter: true,
    cellStyle: { textAlign: 'center' },
  };
  readonly tooltipShowDelay = 0;
  readonly tooltipHideDelay = 2000;
  readonly keyQueryForm: FormGroup;
  isLoading = false;
  isEdit = false;

  queryId: number = null;
  showWithAdvert = 0;
  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;
  @ViewChild('keyQueryModal', { read: TemplateRef }) keyQueryModal: TemplateRef<any>;
  private readonly SHOP_ID: number;
  private gridApi!: GridApi<any>;

  constructor(
    private mpSurfService: MpSurfApiService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    this.keyQueryForm = fb.group({
      nmid: new FormControl(null, [Validators.required]),
      query_list: new FormControl(null, [Validators.required]),
    });
    this.gridOptions = {
      columnDefs: [...KEY_QUERIES_COLUMN_DEFS],
      context: { componentParent: this },
    } as GridOptions;
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop')).shop;
  }

  ngAfterViewInit() {
    if (this.agGrid) {
      this.gridApi = this.agGrid.api;
    }
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.loadData();
    this.createNewColumns();
  }

  manageAdvertQuery() {
    this.showWithAdvert = this.showWithAdvert ? 0 : 1;
    this.loadData();
  }

  openCreateModal(modalTemplate: TemplateRef<any>): void {
    this.isEdit = false;
    this.keyQueryForm.reset();
    this.modalService.open(modalTemplate);
    this.keyQueryForm.controls.query_list.setValidators([Validators.required]);
    this.keyQueryForm.controls.query_list.updateValueAndValidity();
  }

  updateKeyQueryEmitter(queryData: IKeyQueryRowData): void {
    const params = { shop_id: this.SHOP_ID };
    this.queryId = queryData.id;
    this.mpSurfService
      .loadById(params, queryData.id, 'queries')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: CommonResponseGeneral1<ILoadByIdResponse>) => {
          if (response?.data) {
            this.keyQueryForm.reset();
            this.keyQueryForm.controls.query_list.clearValidators();
            this.keyQueryForm.controls.query_list.updateValueAndValidity();

            this.keyQueryForm.controls.nmid.setValue(response.data.nmid);
            this.keyQueryForm.controls.query_list.setValue(response.data.query);

            this.isEdit = true;
            this.modalService.open(this.keyQueryModal);
          } else {
            this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
        },
      );
  }

  createUpdateKeyQuery(id: number | null): void {
    const bodyRequest = { id, ...this.keyQueryForm.getRawValue() } as IParamsKeyQueryRequest;
    this.isLoading = true;
    this.cdr.detectChanges();
    this.mpSurfService
      .createOrUpdate<IParamsKeyQueryRequest, CommonResponseOperation1>(bodyRequest, 'queries')
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        () => {
          this.modalService.dismissAll();
          this.loadData();
          this.showPopUpMessage(
            'success',
            this.isEdit ? PopUpMessages.createSuccessSummary : PopUpMessages.updateSuccessSummary,
            '',
          );
          this.isEdit = false;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
        },
      );
  }

  deleteKeyQuery(queryData: IKeyQueryRowData): void {
    if (confirm(KeyPopUpMessages.remove)) {
      const params = { id: queryData.id };
      this.mpSurfService
        .delete(params, 'queries')
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.showPopUpMessage('success', PopUpMessages.deleteSuccessSummary, PopUpMessages.deleteSuccessMessage);
            this.loadData();
          },
          (error: HttpErrorResponse) => {
            this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
          },
        );
    }
  }

  private loadData(): void {
    this.isLoading = true;
    this.mpSurfService
      .load({ show_adv: this.showWithAdvert }, 'queries')
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        (response: CommonResponseGeneral<IKeyQueryRowData[]>) => {
          this.rowData = response.data;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private createNewColumns(): void {
    const annualDate = moment().subtract(30, 'day');
    const newColumns = JSON.parse(JSON.stringify(DefaultKeyParent));
    for (let counter = 1; counter <= 31; counter++) {
      const newColumn = { ...DefaultKeyQueryColumn };
      const dateForSubtraction = annualDate.clone().add(counter - 1, 'days');
      newColumn.headerName = dateForSubtraction.format('DD.MM');
      newColumn.field = 'd' + dateForSubtraction.format('DDMM');
      newColumns.children.push(newColumn);
    }
    for (let i = newColumns.children.length - 1; i > newColumns.children.length - 7; i--) {
      newColumns.children[i].columnGroupShow = '';
    }
    this.setNewColumnsToGrid(newColumns);
  }

  private setNewColumnsToGrid(newColumns: any): void {
    this.gridOptions.columnDefs.push(newColumns);
    this.gridOptions.api.setColumnDefs(this.gridOptions.columnDefs);
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
