<ul #nav="ngbNav" class="nav-tabs tabs-animated tabs-animated-shadow" ngbNav>
  <li ngbNavItem>
    <a ngbNavLink><span>WILDBERIES</span></a>
    <ng-template ngbNavContent>
      <div class="row">
        <div class="col-md-6">
          <div class="main-card mb-3 card">
            <div class="card-body">
              <button [routerLink]="['/settings/shop']" class="mt-1 btn btn-primary">Подключится к API статистики
              </button>
            </div>
            <div class="card-body">
              <h5 class="card-title">Где взять API ключ?</h5>Мы подготовили подробную инструкцию для Вас
              <button (click)="open(content5)" class="mb-2 mr-2 btn btn-link">смотреть инструкцию</button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="main-card mb-3 card">
            <div class="card-body">
              <h5 class="card-title">Для чего? Зачем?</h5>Самый безопасный способ получения данных. Предоставляя нам Ваш
              API ключ мы получаем доступ только на получение тех данных, которые Wildberries отдает по API.
            </div>
            <div class="card-body">
              <h5 class="card-title">Это безопасно?</h5>Самый безопасный способ получения данных. Предоставляя нам Ваш
              API ключ мы получаем доступ только на получение тех данных, которые Wildberries отдает по API.
            </div>
          </div>
        </div>
      </div>

    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink><span>OZON</span></a>
    <ng-template ngbNavContent>
      <div class="row">
        <img src="/src/assets/images/techimg/job.jpg">
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>


<ng-template #content5 let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Инстуркция для получения ключей API для Wildberries</h4>
    <button (click)="d('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body"><!--
  <p>
    1. У Вас есть API ключ / Возможно у Вас есть
    Перейдите в личный кабинет поставщика Wildberries по ссылке.

      Если у Вас есть API ключ, то он будет в разделе "Доступ к API". Если нету, то необходимо его запросить в службе поддержке (см. пункт 2 ниже)
  </p>
  <p>
    Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget
    quam.
    Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet
    fermentum.
    Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac,
    vestibulum at eros.
  </p>
  <p>
    Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget
    quam. Morbi
    leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.
    Cras justo odio,
    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
  </p>
  <p>
    Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget
    quam. Morbi
    leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.
    Cras justo odio,
    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
  </p>
  <p>
    Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget
    quam. Morbi
    leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.
    Cras justo
    odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at
    eros.
  </p>
  <p>
    Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget
    quam. Morbi
    leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.
    Cras justo
    odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at
    eros.
  </p>
  <p>
    Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget
    quam. Morbi
    leo risus, porta ac consectetur ac, vestibulum at eros. Cras mattis consectetur purus sit amet fermentum.
    Cras justo
    odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at
    eros.
  </p>-->
  </div>
</ng-template>
