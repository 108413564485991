import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { finalize } from "rxjs/operators";
import { PendingService } from "../services/pending.service";
import { WITHOUT_SPINNER } from "../tokens/without-spinner.context-token";

@Injectable({
    providedIn: 'root',
})
export class HttpPendingInterceptor implements HttpInterceptor {
    private readonly _pendingService = inject(PendingService);

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        const hideSpinner = request.context.get(WITHOUT_SPINNER);
        const clonedRequest = request.clone();

        if (!hideSpinner) {
            this._pendingService.show();
        }

        return next.handle(clonedRequest).pipe(
            finalize(() => {
                if (!hideSpinner) {
                    this._pendingService.hide();
                }
            }),
        );
    }
}
