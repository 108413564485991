import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private loggedInStatus = false;

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
  }

  isAuthenticated(): boolean {
    return !!window.localStorage.getItem('token');
  }
}
