<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"/>
<p-toast/>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="h-100">
    <div class="row h-100 g-0">
      <div class="d-none d-lg-block col-lg-4">
        <app-login-slider/>
      </div>
      <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
        <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
          <div class="app-logo"></div>
          <div class="w-100 mx-auto">
            <div class="modal-content">
              <div class="modal-body">
                <h4 class="mb-0">
                  <p>Добро пожаловать</p>
                </h4>
                <h6 class="mt-3">
                  Еще не зарегистрированы?
                  <a href="https://t.me/Mpsurfreg_bot"
                     target="_blank"
                     class="pointer text-primary">
                    Создать аккаунт
                  </a>
                </h6>
                <div class="divider"></div>
                <div>
                  <form [formGroup]="LoginForm"
                        novalidate>
                    <div class="row">
                      <div class="col-md-6">
                        <fieldset class="mb-3">
                          <div role="group"
                               tabindex="-1">
                            <label class="form-label"
                                   for="exampleEmail">
                              Логин
                            </label>
                            <input class="form-control"
                                   formControlName="email"
                                   id="exampleEmail"
                                   name="email"
                                   placeholder="Введите email или номер телефона"
                                   required type="email">
                          </div>
                        </fieldset>
                      </div>
                      <div class="col-md-6">
                        <fieldset class="">
                          <div role="group" tabindex="-1">
                            <label class="form-label" for="examplePassword">
                              Пароль
                            </label>
                            <input class="form-control" formControlName="password"
                                   id="examplePassword"
                                   name="password"
                                   placeholder="Ваш пароль"
                                   type="password">
                          </div>
                        </fieldset>
                      </div>
                      <div *ngIf="LoginForm.controls['password'].hasError('invalidlogin')"
                           [innerHTML]="errorMessage"
                           class="alert alert-danger">
                      </div>
                    </div>
                    <div class="divider"></div>
                    <div class="d-flex align-items-center">
                      <p-toast/>
                      <div class="ms-auto">
                        <a class="btn-lg btn btn-link" href="/auth/reset-password">
                          Восстановить пароль
                        </a>
                        <button (click)="logIn()"
                                [disabled]="LoginForm.invalid"
                                class="btn btn-primary btn-lg" type="button">
                          Войти
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="opacity-8 mt-3">
            Copyright © mpsurf.ru 2020
          </div>
        </div>
      </div>
    </div>
  </div>

<!--  <ng-template #registrationForm>-->
<!--    <app-header-modal (closeEmitter)="closeModal()"-->
<!--                      [modalTitle]="'Регистрация'">-->
<!--    </app-header-modal>-->
<!--    <div class="modal-body">-->
<!--      <form [formGroup]="RegisterForm">-->
<!--        <div class="card__row d-flex flex-column gap-2">-->
<!--          <input formControlName="email"-->
<!--                 pInputText-->
<!--                 placeholder="Введите email"-->
<!--                 type="email"-->
<!--          />-->
<!--          <p-inputMask formControlName="phone"-->
<!--                       mask="+99999999999"-->
<!--                       placeholder="+7 (002) 555-01-02">-->
<!--          </p-inputMask>-->
<!--          <div class="d-flex align-items-center gap-2">-->
<!--            <p-checkbox binary="true"-->
<!--                        formControlName="agreed"-->
<!--                        id="consent"-->
<!--                        inputId="consent">-->
<!--            </p-checkbox>-->
<!--            <label for="consent">-->
<!--                Согласен с обработкой персональных данных и с оффертой-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
<!--    </div>-->

<!--    <app-footer-modal-->
<!--      (cancelEmitter)="closeModal()"-->
<!--      (submitEmitterJustAction)="openRegisterBot()"-->
<!--      [isDisabled]="RegisterForm.invalid">-->
<!--    </app-footer-modal>-->
<!--  </ng-template>-->
</div>



