import {BloggersCellActionComponent} from "../../external-analysis/bloggers/components/bloggers-cell-action.component";
import {DefectGoodsCellActionComponent} from "./components/defect-goods-cell-action.component";

export const DEFECT_GOODS_COLUMNS_DEFS = [
  {
    headerName: 'Дата',
    field: 'date',
    width: 150,
    filter: 'agDateColumnFilter'
  },
  {
    headerName: 'Артикул ВБ',
    field: 'nmID',
    width: 150,
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Баркод',
    field: 'barcode',
    width: 150,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Артикул поставщика',
    field: 'supplier_code',
    width: 150,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Размер ВБ',
    field: 'date',
    width: 150,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Партия №',
    field: 'batch',
    width: 150,
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Штук',
    field: 'qty',
    width: 150,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Комментарий',
    field: 'comment',
    width: 150,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Закупка, себестоимость',
    field: 'batch_price',
    width: 170,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'ШК посылки',
    field: 'shk_parcels',
    width: 150,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Действия',
    width: 150,
    field: '',
    filter: false,
    cellRenderer: DefectGoodsCellActionComponent,
  },
]
