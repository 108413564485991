<div class="slider-light">
  <ngx-slick-carousel class="slick-slider slick-dotted"
                      #slickModal="slick-carousel"
                      [config]="slideConfig2">
    <div ngxSlickItem>
      <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate">
        <div class="slide-img-bg"></div>
        <div class="slider-content text-light">
          <h3>MP Surf</h3>
          <p>
            Сервис внутренней аналитики для маркетплейсов
          </p>
        </div>
      </div>
    </div>
    <div ngxSlickItem>
      <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-premium-dark">
        <div class="slide-img-bg"></div>
        <div class="slider-content text-light">
          <h3>MP Surf</h3>
          <p>
            Товарная аналитика, Управление финансами, Уравление поставками, Партионный учет
          </p>
        </div>
      </div>
    </div>
    <div ngxSlickItem>
      <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
        <div class="slide-img-bg opacity-6"></div>
        <div class="slider-content text-light">
          <h3>MP Surf</h3>
          <p>
            Анализ эффективности бизнеса: точная чистая прибыль, управленческий учет, эффективность рекламы,
            акции, unit-экономика в реальном времени
          </p>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
