import {ITitleValue} from './shop.interface';

export const TAXES_TYPES: ITitleValue[] = [
  {
    title: 'Не установлено',
    value: 0
  },
  {
    title: 'УСН "Доходы"',
    value: 1
  },
  {
    title: 'УСН "Доходы минус расходы"',
    value: 2
  },
  {
    title: 'ОСНО "Основная система"',
    value: 3
  },
];
