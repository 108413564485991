<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
      </div>
      <div>
        Склад&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <select (change)="onChange($event.target.value)">
          <option>Все</option>
          <option *ngFor="let item of listwh; let i = index">{{item.warehouseName}}</option>
        </select>
      </div>
    </div>
    <div class="page-title-actions">
      <button (click)="exportExcel()" class="btn-shadow d-inline-flex align-items-center btn btn-success" type="button">
        <fa-icon [icon]="faArrowDown" class="mr-2"></fa-icon>
        Скачать в эксель
      </button>
    </div>
  </div>
</div>

<style>
  table tr th:first-child {
    z-index: 11;
  }

  table tr th {
    position: sticky;
    top: 0;
    z-index: 9;
    background: #fff;
  }
</style>

<div class="card-header">
  Остатки из Корзины обновлены: {{update_stock_basket_date}}<br>
  Остатки из отчета WB обновлены: {{update_stock_WB_date}}
</div>
<div class="main-card mb-3 card" style="height: 71vh">
  <div class="table-responsive">
    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th class="text-center sticky-col second-col">Фото (Артикул WB)<br>Рубрика</th>
        <th class="text-center">Бренд</th>
        <th class="text-center">Остатки из <br>корзины</th>
        <th class="text-center"><p ngbTooltip="Доступно на сайте, можно добавить в корзину.">Доступно для продаж</p>
        </th>
        <th class="text-center">В пути к клиенту</th>
        <th class="text-center">В пути от клиента</th>
        <th class="text-center">Дата последнего заказа</th>
        <th class="text-center">Коледино</th>
        <th class="text-center">Подольск</th>
        <th class="text-center">СПБ</th>
        <th class="text-center">Казань</th>
        <th class="text-center">Краснодар</th>
        <th class="text-center">Электросталь</th>
        <th class="text-center">Екатеринбург</th>
        <th class="text-center">Хабаровск</th>
        <th class="text-center">Новосибирск</th>
        <th class="text-center">Нур-Султан</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of items; let i = index">
        <td>
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left mr-3" style="width:40px">
                <div class="widget-content-left">
                  <img alt=""
                       src="https://img1.wbstatic.net/tm/new/{{item.nmId.substr(0,4)}}0000/{{item.nmId}}-1.jpg"
                       width="40"/>
                </div>
              </div>
              <div class="widget-content-left flex2">
                <div class="widget-heading"><a routerLink="/guides/products/{{item.nmId}}"
                                               target="new">{{item.nmId}}</a></div>
                <div class="widget-subheading opacity-7">{{item.subject}}</div>
                <div class="widget-subheading opacity-7">Размер: {{item.techSize}}</div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">{{item.brand}}</td>
        <td *ngIf="item.basket_qty != null" class="text-center" width="120">{{item.basket_qty}} шт</td>
        <td *ngIf="item.basket_qty == null" class="text-center" width="120">--</td>
        <td class="text-center">{{item.quantity}}</td>
        <td class="text-center">{{item.inWayToClient}}</td>
        <td class="text-center">{{item.inWayFromClient}}</td>
        <td class="text-center">{{item.max_date | date: 'dd.MM.yyyy&nbsp;HH:mm'}}</td>
        <td class="text-center">{{item.wh_koledion}}</td>
        <td class="text-center">{{item.wh_podolsk}}</td>
        <td class="text-center">{{item.wh_SPB}}</td>
        <td class="text-center">{{item.wh_KAZAN}}</td>
        <td class="text-center">{{item.wh_KRASNODAR}}</td>
        <td class="text-center">{{item.wh_ELECTROSTAL}}</td>
        <td class="text-center">{{item.wh_EKB}}</td>
        <td class="text-center">{{item.wh_HAB}}</td>
        <td class="text-center">{{item.wh_NVS}}</td>
        <td class="text-center">{{item.wh_NUR}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
