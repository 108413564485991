<div class="card">
  <p-table #dt1 [value]="advertManagementStatisticData.minus" dataKey="id"
           [rows]="10"
           [scrollable]="true"
           [showCurrentPageReport]="true"
           [rowsPerPageOptions]="[10, 25, 50]"
           (sortFunction)="customSort($event)"
           [customSort]="true"
           [paginator]="true"
           currentPageReportTemplate="Страницы для пагинации"
           [globalFilterFields]="['name', 'count', 'views', 'ctr', 'cost_click', 'sum' ]">
    <ng-template pTemplate="caption">
      <div class="flex">
        <button pButton label="Очистить" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
        <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                           placeholder="Поиск по ключевым словам"/>
                </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="min-width:15rem" pSortableColumn="name">
          <div class="flex align-items-center">
            Запрос
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
          </div>
        </th>
        <th style="min-width:15rem" pSortableColumn="count">
          <div class="flex align-items-center">
            Частотность
            <p-sortIcon field="count"></p-sortIcon>
            <p-columnFilter type="number" field="count" display="menu"></p-columnFilter>
          </div>
        </th>
        <th style="min-width:15rem" pSortableColumn="views">
          <div class="flex align-items-center">
            Просмотры
            <p-sortIcon field="views"></p-sortIcon>
            <p-columnFilter type="number" field="views" display="menu"></p-columnFilter>
          </div>
        </th>
        <th style="min-width:15rem" pSortableColumn="ctr">
          <div class="flex align-items-center">
            CTR
            <p-sortIcon field="ctr"></p-sortIcon>
            <p-columnFilter type="number" field="ctr" display="menu"></p-columnFilter>
          </div>
        </th>
        <th style="min-width:15rem" pSortableColumn="click_price">
          <div class="flex align-items-center">
            Цена клика
            <p-sortIcon field="cost_click"></p-sortIcon>
            <p-columnFilter type="number" field="cost_click" display="menu"></p-columnFilter>
          </div>
        </th>
        <th style="min-width:15rem" pSortableColumn="sum">
          <div class="flex align-items-center">
            Затраты
            <p-sortIcon field="sum"></p-sortIcon>
            <p-columnFilter type="number" field="sum" display="menu"></p-columnFilter>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-positiveData>
      <tr>
        <td>
          {{ positiveData.name }}
        </td>
        <td>
          {{ positiveData.count }}
        </td>
        <td>
          {{ positiveData.views }}
        </td>
        <td>
          {{ positiveData.ctr }}
        </td>
        <td>
          {{ positiveData.cost_click }}
        </td>
        <td>
          {{ positiveData.sum }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">Плюс запросы еще не добавлены</td>
      </tr>
    </ng-template>
  </p-table>
</div>
