<div class="app-page-title" style="z-index: 100;">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <!--<i class="icon {{icon}}"></i>-->
        <i class="vsm-icon pe-7s-note2 icon-gradient bg-mean-fruit"></i>
      </div>
      <div>
        {{brend_name}}<br />
        <!--{{selected | json}}--><!--{{control_id}}-->
        <div class="page-title-subheading" style="z-index: 100;opacity: 1.0;">
          данные за период <input type="text"
                    ngxDaterangepickerMd
                    
                    [locale]="{
                        applyLabel: 'применить', 
                        format: 'DD.MM.YYYY', 
                        'daysOfWeek': ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'], 
                        'customRangeLabel': 'Свой диапазон', 
                        'monthNames': ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']}"
                    [(ngModel)]="selected"
                    [showCustomRangeLabel]="true"
                    [alwaysShowCalendars]="true"
                    [ranges]="ranges"
                    [linkedCalendars]="true"
                    [isInvalidDate] = "isInvalidDate"
                    [showClearButton]="false"
                    [maxDate]='maxDate'
                    [minDate]='minDate'
                    (change)="newValue()"
                    placeholder="Выберите даты" />
        </div>
      </div>
    </div>
    <!--<div class="page-title-actions">
      <button type="button" class="btn-shadow mr-3 btn btn-dark">
        <fa-icon [icon]="faStar"></fa-icon>
      </button>
      <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success">
        <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
        Create New
      </button>
    </div>-->
  </div>
</div>    

<div class="row">
        <div class="col-md-6 col-lg-3">
            <div class="card-shadow-primary card-border mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                      <i class="header-icon pe-7s-wallet mr-3 text-muted opacity-6"> </i>
                      Выручка
                    </div>
                </div>
                <ul class="list-group list-group-flush">
                    <li class="p-0 list-group-item">
                        <div class="w-100">
                            <div class="widget-chart">
                                <div class="widget-chart-content">
                                    <div class="widget-numbers"><span>{{data_agr?.revenue | number: '1.0-2'}}</span></div>
                                    <!--<div class="widget-description text-focus">
                                        Разница со вчерашним днем:
                                        <span class="text-danger pl-1">
                                        <fa-icon [icon]="faAngleUp"></fa-icon>
                                        <span class="pl-1">7.35%</span>
                                    </span>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-md-6 col-lg-3">
            <div class="card-shadow-primary card-border mb-3 card">
              <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                      <i class="header-icon lnr-cart mr-3 text-muted opacity-6"> </i>
                      Продаж
                    </div>
                  </div>
                    <ul class="list-group list-group-flush">
                        <li class="p-0 list-group-item">
                            <div class="w-100">
                                <div class="widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="widget-numbers"><span>{{data_agr?.sales | number: '1.0-0'}}</span></div>
                                        <!--<div class="widget-description text-focus">
                                            Разница со вчерашним днем:
                                            <span class="text-danger pl-1">
                                            <fa-icon [icon]="faAngleUp"></fa-icon>
                                            <span class="pl-1">7.35%</span>
                                        </span>
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
            </div>
        </div>

        <div class="col-md-6 col-lg-3">
            <div class="card-shadow-primary card-border mb-3 card">
              <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                        <i class="header-icon pe-7s-back mr-3 text-muted opacity-6"> </i>
                        Средний чек
                    </div>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="p-0 list-group-item">
                            <div class="w-100">
                                <div class="widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="widget-numbers"><span>{{data_agr?.avg_check | number: '1.0-0'}}</span></div>
                                        <!--<div class="widget-description text-focus">
                                            Разница со вчерашним днем:
                                            <span class="text-danger pl-1">
                                            <fa-icon [icon]="faAngleUp"></fa-icon>
                                            <span class="pl-1">7.35%</span>
                                        </span>
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
            </div>
        </div>

        <div class="col-md-6 col-lg-3">
            <div class="card-shadow-primary card-border mb-3 card">
              <div class="card-header-tab card-header">
                    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                      <i class="header-icon pe-7s-network mr-3 text-muted opacity-6"> </i>
                      Остатки
                    </div>
                  </div>
                    <ul class="list-group list-group-flush">
                        <li class="p-0 list-group-item">
                            <div class="w-100">
                                <div class="widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="widget-numbers"><span>{{data_agr?.stock | number: '1.0-2'}}</span></div>
                                        <!--<div class="widget-description text-focus">
                                            Разница со вчерашним днем:
                                            <span class="text-danger pl-1">
                                            <fa-icon [icon]="faAngleUp"></fa-icon>
                                            <span class="pl-1">7.35%</span>
                                        </span>
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
            </div>
        </div>
    </div>
       
        <div class="row">
            <div class="col-sm-12 col-lg-6">
                <div class="main-card mb-3 card">
                    <div class="card-header">
                        Склад (продажи остатки)
                    </div>
                    <div class="card-body">
                        <canvas baseChart width="400" height="200" 
                            [datasets]="lineChartData" 
                            [labels]="lineChartLabels"
                            [options]="lineChartOptions" 
                            [colors]="lineChartColors" 
                            [legend]="lineChartLegend" 
                            [chartType]="lineChartType"
                            [plugins]="lineChartPlugins" 
                            (chartHover)="chartHovered($event)" 
                          (chartClick)="chartClicked($event)">

                        </canvas>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6">
                <div class="main-card mb-3 card">
                    <div class="card-header">
                        Продажи (выручка цена)
                    </div>
                    <div class="card-body">
                        <canvas baseChart width="400" height="200" 
                            [datasets]="SalesChartData" 
                            [labels]="SalesChartLabels"
                            [options]="lineChartOptions" 
                            [colors]="lineChartColors" 
                            [legend]="lineChartLegend" 
                            [chartType]="lineChartType"
                            [plugins]="SalesChartPlugins" 
                            >

                      </canvas>
                    </div>    
                </div>
            </div>
        </div>
    <div style="width: 100%;height: 80vh;">
        <ag-grid-angular
                            style="width: 100%; height: 100%;"
                            class="ag-theme-alpine"

                            [rowData]="rowData"
                            [columnDefs]="columnDefsSales"
                            [pagination]="true"    
                            [rowHeight]="rowHeight"
                            [rowSelection]="rowSelection"
                            [rowModelType]="rowModelType"

                            
                            
                            [localeText]="localeText"

                            [cacheOverflowSize]="cacheOverflowSize"
                            [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
                            [infiniteInitialRowCount]="infiniteInitialRowCount"
                            [maxBlocksInCache]="maxBlocksInCache"
                            [paginationPageSize]="paginationPageSize"
                            [cacheBlockSize]="cacheBlockSize"
                            (gridReady)="onGridReady($event)"
                        >
                        </ag-grid-angular>
 
    </div>
