export const MASK_TYPE = [
  '+',
  /[7]/,
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export interface IUserData {
  name: string;
  phone: string;
  phonenumber: string;
  email: string;
  subscribe: string;
}
