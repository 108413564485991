import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    AdvertIdTitle,
    AdvertRegions,
    CardDetailsPic,
    IAdvertCompanyStatistic,
    IAdvertPhrasesResponse,
    IAdvertStrategiesResponse
} from './advert-management-card.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {forkJoin, Observable} from 'rxjs';
import {ApiService} from '../../../../shared/services/api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ConfirmationService, MessageService, PrimeNGConfig} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {IResponseAdvManagementById} from '../advert-management.interface';
import {ADVERT_AUCTION, CARD_DETAILS_STAT_GRAY} from './advert-management-card.mock';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {finalize} from 'rxjs/operators';
import {PopUpStatus} from '../../../../shared/interfaces/common.type';
import {PopUpMessages} from "../../../../shared/mocks/pop-up-messages.mock";

@UntilDestroy({checkProperties: true})
@Component({
    selector: 'app-advert-management-card-aggrid-components',
    templateUrl: './advert-management-card.component.html',
    styleUrls: ['./advert-management-card.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class AdvertManagementCardComponent implements OnInit {
    advertManageFG: FormGroup;
    MinusFixWordsFG: FormGroup;
    ReplenishBalanceFormGroup: FormGroup;

    cardDetailsPicMock: CardDetailsPic[] = [...CARD_DETAILS_STAT_GRAY];
    cardAuction: CardDetailsPic[] = ADVERT_AUCTION;
    whereWriteOffMoney: AdvertIdTitle[] = [
        {id: 1, title: 'Баланс - Взаимозачет на WB'},
        {id: 0, title: 'Счет - баланс который пополняется с карты'}
    ];
    companyType: AdvertIdTitle[] = [{id: 1, title: 'Удержание ставки'}, {id: 2, title: 'Удержание позиции'}];
    companyRegion: AdvertRegions[] = [];
    isLoadingStatus = false;
    isShowCardTitleInput = false;
    shopId: string;
    additionalKeyWords: string;
    companyCardDataGeneral: any = {};

    phrasesData: IAdvertPhrasesResponse = {
        minus_phrases: [],
        fixed_phrases: [],
        minus_or_fixed: 0
    };
    @ViewChild('replenishBalanceModal', {read: TemplateRef}) replenishBalanceModal: TemplateRef<any>;
    @ViewChild('auctionStatisticModal', {read: TemplateRef}) auctionStatisticModal: TemplateRef<any>;
    @ViewChild('minusFixWordsModal', {read: TemplateRef}) minusFixWordsModal: TemplateRef<any>;
    showMinusWords = true;
    advertId: number;
    isFixedPhrases = false;

    constructor(private fb: FormBuilder,
                private modalService: NgbModal,
                private router: Router,
                private api: ApiService,
                private messageService: MessageService,
                private route: ActivatedRoute,
                private primengConfig: PrimeNGConfig,
                private cdr: ChangeDetectorRef,
                private confirmationService: ConfirmationService) {
        this.advertManageFG = fb.group({
            card_id: new FormControl(null),
            card_title: new FormControl(null),
            card_name: new FormControl(null),
            card_article: new FormControl(null),
            card_subject: new FormControl(null),
            card_status: new FormControl(false),
            card_img: new FormControl('https://basket-05.wb.ru/vol870/part87020/87020176/images/big/1.jpg'),
            card_budget: new FormControl(null),
            card_fix_plus_request: new FormControl(false),
            card_current_rate: new FormControl(null),
            card_company_type: new FormControl(2),
            card_region: new FormControl(this.companyType[0]),
            card_delta_rate: new FormControl(1),
            card_max_rate: new FormControl(50),
            card_position: new FormControl(0)
        });
        this.ReplenishBalanceFormGroup = fb.group({
            replenish_amount: new FormControl(1200, [Validators.required]),
            write_off_money_balance: new FormControl(this.whereWriteOffMoney[0])
        });
        this.MinusFixWordsFG = fb.group({
            all_words: new FormControl(null),
            new_word: new FormControl(null, [Validators.required])
        });
    }

    get minusWordsString() {
        return `Минус слова ${this.phrasesData.minus_phrases?.length || 0}`;
    }

    get fixedWordsString() {
        return `Плюс запросы слова ${this.phrasesData.fixed_phrases?.length || 0}`;
    }

    get isFixPositiveRequestsInvalid(): boolean {
        const fixedLength = this.phrasesData.fixed_phrases?.length === 0;
        if (fixedLength) {
            this.advertManageFG.controls.card_fix_plus_request.setValue(false);
        }
        return fixedLength;
    }

    ngOnInit(): void {
        this.shopId = JSON.parse(localStorage.getItem('mpall_shop') || '{}').shop;
        this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
            this.companyCardDataGeneral.card_id = +params.id ?? 0;
            this.advertId = +params.id ?? 0;
        });
        this.primengConfig.ripple = true;
        this.loadData();
    }

// LOAD Data

    loadData(): void {
        this.loadIdData();
        this.loadAdditionalData();
    }

    loadIdData() {
        this.isLoadingStatus = true;
        this.requestLoadAdvertManagementById(this.advertId)
            .pipe(untilDestroyed(this), finalize(() => this.isLoadingStatus = false))
            .subscribe(
                (response) => {
                    this.setNamesInControls(response);
                }, () => {
                    this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, 'Переименовать компанию не удалось');
                }
            );
    }

    loadAdditionalData() {
        this.isLoadingStatus = true;
        forkJoin([
            this.requestCompanyStatistic(this.advertId),
            this.requestLoadRegions(),
            this.requestLoadPhrases(this.advertId),
            this.requestLoadStrategies(this.advertId)])
            .pipe(untilDestroyed(this))
            .subscribe(
                ([
                     companyStatisticResponse,
                     regionsResponse,
                     phrasesResponse,
                     strategiesResponse]) => {
                    this.manageCompanyStrategyData(strategiesResponse);
                    this.manageCompanyStatisticData(companyStatisticResponse);
                    this.companyRegion = regionsResponse;
                    this.phrasesData = phrasesResponse;
                    this.advertManageFG.controls.card_fix_plus_request.setValue(phrasesResponse.minus_or_fixed === 1);
                    this.isLoadingStatus = false;
                }, (error) => {
                    this.isLoadingStatus = false;
                }
            );
    }

    setNamesInControls(response: IResponseAdvManagementById): void {
        this.advertManageFG.controls.card_id.setValue(this.advertId);
        this.advertManageFG.controls.card_title.setValue(response.adverts.name);
        this.advertManageFG.controls.card_article.setValue(response.adverts.article);
        this.advertManageFG.controls.card_status.setValue(+response.adverts.status === 9);
        this.advertManageFG.controls.card_name.setValue(response.adverts.article_name);
        this.advertManageFG.controls.card_img.setValue(response.adverts.article_photo);
        this.advertManageFG.controls.card_current_rate.setValue(response.price);
        this.advertManageFG.controls.card_subject.setValue(response.subjectName);
        this.ReplenishBalanceFormGroup.controls.replenish_amount.setValue(response.adverts.budget);
        this.companyCardDataGeneral = this.advertManageFG.getRawValue();
        this.cardAuction[2].value = this.companyCardDataGeneral.card_position;
        this.cardAuction[1].value = this.companyCardDataGeneral.card_current_rate;
        if (this.companyCardDataGeneral.card_max_rate) {
            this.cardAuction[0].value = this.companyCardDataGeneral.card_max_rate;
        } else {
            this.cardAuction[0].value = this.companyCardDataGeneral.card_current_rate;
        }
    }

    // LOGIC MANAGE
    copyArticle(article: string | number | boolean): void {
        this.showPopUpMessage('success', 'Скопировано', 'Артикул скопирован');
        navigator.clipboard.writeText(String(article));
    }

    renameCompany(): void {
        this.isShowCardTitleInput = false;
        this.isLoadingStatus = true;
        const cartTitleControl = this.advertManageFG.controls.card_title as FormControl;
        this.requestRenameCompany(this.advertId, cartTitleControl.value)
            .pipe(untilDestroyed(this))
            .subscribe(
                (response) => {
                    this.showPopUpMessage('success', PopUpMessages.updateSuccessSummary, 'Компания переименована');
                    this.companyCardDataGeneral.card_title = cartTitleControl.value;
                },
                (error) => {
                    this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, 'Переименовать компанию не удалось');
                    cartTitleControl.setValue(this.companyCardDataGeneral.card_title);
                }
            );
    }

    cancelRenaming(): void {
        this.isShowCardTitleInput = false;
        this.advertManageFG.controls.card_title.setValue(this.companyCardDataGeneral.card_title);
    }

    saveCompany(): void {
        this.isLoadingStatus = true;
        if (this.advertManageFG.controls.card_status.value !== this.companyCardDataGeneral.card_status) {
            this.saveCompanyStatus();
        }
        const strategyIsBet = this.advertManageFG.controls.card_company_type.value === 1;
        if (strategyIsBet) {
            this.saveStrategiesWithPosition();
        }
        forkJoin([
            this.requestSavePhrases(),
            this.requestSaveStrategiesWithPosition()
        ])
            .pipe(untilDestroyed(this), finalize(() => this.isLoadingStatus = false))
            .subscribe(
                ([requestPhrasesResponse, requestWithPosition]) => {
                    console.log(requestPhrasesResponse);
                }, () => {
                    this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
                });
    }

    saveStrategiesWithPosition() {
        this.requestSaveStrategiesWithQuote()
            .subscribe(
                (response) => {
                    console.log(response);
                },
                () => {
                    this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
                });
    }

    finishCompany(event: Event) {
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message: 'Вы уверены, что хотите завершить компанию?',
            acceptLabel: 'Завершить',
            rejectLabel: 'Нет',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.isLoadingStatus = true;
                this.requestFinishCompany(this.advertId, 'stop')
                    .pipe(untilDestroyed(this))
                    .subscribe(
                        () => {
                            this.router.navigate(['/adv/advert-management']);
                        },
                        () => {
                            this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, 'Компания не завершена!');
                        },
                    );
            },
            reject: () => {
            }
        });
    }

    replenishBalance(): void {
        this.isLoadingStatus = true;
        this.requestReplenishBudget()
            .pipe(untilDestroyed(this), finalize(() => this.isLoadingStatus = false))
            .subscribe(
                () => {
                    this.loadData();
                    this.showPopUpMessage('success', PopUpMessages.updateSuccessSummary, 'Баланс пополнен!');
                }, () => {
                    this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
                }
            );
    }

    fixPositiveRequests(): void {
    }

    getFormControlValue(formGroupName: 'advertManageFg' | 'replenish', formControlName: string): string | number | boolean {
        if (formControlName) {
            const formGroup = formGroupName === 'advertManageFg' ? this.advertManageFG : this.ReplenishBalanceFormGroup as FormGroup;
            return formGroup.controls[formControlName].value;
        }
        return '';
    }

    isShowAdditionalBlock(): boolean {
        const controlValue = this.advertManageFG.controls.card_company_type.value;
        if (controlValue) {
            return controlValue === 2;
        }
        return false;
    }

    addWordToAdditionalList() {
        const wordControl = this.MinusFixWordsFG.controls.new_word as FormControl;
        if (this.showMinusWords) {
            if (!this.phrasesData.minus_phrases) {
                this.phrasesData.minus_phrases = [];
            }
            this.phrasesData.minus_phrases = [...this.phrasesData.minus_phrases, wordControl.value];
        } else {
            if (!this.phrasesData.fixed_phrases) {
                this.phrasesData.fixed_phrases = [];
            }
            this.phrasesData.fixed_phrases = [...this.phrasesData.fixed_phrases, wordControl.value];
        }
        wordControl.reset();
        this.cdr.detectChanges();
    }

    removeAdditionalWordFromTheList(parameter: string, companyWord: string) {
        const currentArray = this.showMinusWords ? this.phrasesData.minus_phrases : this.phrasesData.fixed_phrases;
        const index = currentArray.indexOf(companyWord);
        if (index !== -1) {
            currentArray.splice(index, 1);
        }
    }

//   MODALS
    openModal(modalTag: string, additionalParam?: string): void {
        switch (modalTag) {
            case 'replenishBalanceModal':
                this.modalService.open(this.replenishBalanceModal, {backdrop: 'static'});
                break;
            case 'auctionStatisticModal':
                this.modalService.open(this.auctionStatisticModal, {backdrop: 'static', size: 'xl'});
                break;
            case 'additionalKeyWords':
                this.showMinusWords = additionalParam === 'negative';
                this.additionalKeyWords = this.showMinusWords ? 'Минус' : 'Плюс';
                this.modalService.open(this.minusFixWordsModal, {backdrop: 'static', size: 'lg'});
                break;
        }
    }

    closeModal(): void {
        this.modalService.dismissAll();
    }

    private clearNullables() {

    }

    private manageCompanyStrategyData(strategyData: IAdvertStrategiesResponse) {
        this.advertManageFG.controls.card_region.setValue(strategyData.cpm_region ?? 1);
        this.advertManageFG.controls.card_company_type.setValue(strategyData.strategy);
        this.advertManageFG.controls.card_delta_rate.setValue(strategyData.from);
        this.advertManageFG.controls.card_max_rate.setValue(strategyData.max_cpm);
        this.advertManageFG.controls.card_position.setValue(strategyData.position);
    }

    private manageCompanyStatisticData(companyStatisticResponse: IAdvertCompanyStatistic[]) {
        const statisticCard = companyStatisticResponse.filter(a => a.keyword === 'Всего по кампании')[0];
        if (statisticCard) {
            this.cardDetailsPicMock.forEach(a => {
                if (statisticCard.hasOwnProperty(a.id)) {
                    a.value = statisticCard[a.id];
                }
            });
        }
    }

    private saveCompanyStatus() {
        this.isLoadingStatus = true;
        const switchedParameter = this.advertManageFG.controls.card_status.value as FormControl;
        const status = switchedParameter ? 'start' : 'pause';
        this.requestCompanyStatus(this.advertId, status)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.showPopUpMessage('success', PopUpMessages.updateSuccessSummary, 'Статус компании изменен');
                },
                (error: HttpErrorResponse) => {
                    this.advertManageFG.controls.card_status.setValue(!switchedParameter);
                    this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, `Статус компании не изменен. - ${error.message}`);
                }
            );
    }

    private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
        this.messageService.add({
            severity: severity,
            summary: summary,
            detail: message,
        });
    }


    // REQUESTS
    private requestLoadAdvertManagementById(id): Observable<IResponseAdvManagementById> {
        // return this.api.userRegisterAdverbGetById({token: localStorage.getItem('token'), shop_id: this.shopId, id});
        return this.api.getAdvertData<IResponseAdvManagementById>({
            token: localStorage.getItem('token'),
            shop_id: this.shopId,
            id
        }, 'advert');
    }

    private requestCompanyStatus(cardId, status): Observable<any> {
        const bodyRequest = {token: localStorage.getItem('token'), shop_id: this.shopId, id: cardId};
        return this.api.getAdvertData<any>(bodyRequest, `adverts/${status}`);
    }

    private requestFinishCompany(cardId, status): Observable<any> {
        const bodyRequest = {token: localStorage.getItem('token'), shop_id: this.shopId, id: cardId};
        return this.api.getAdvertData<any>(bodyRequest, `adverts/${status}`);
    }

    private requestCompanyStatistic(cardId): Observable<IAdvertCompanyStatistic[]> {
        const bodyRequest = {token: localStorage.getItem('token'), shop_id: this.shopId, id: cardId};
        return this.api.getAdvertData<IAdvertCompanyStatistic[]>(bodyRequest, 'statistics');
    }

    private requestLoadRegions(): Observable<AdvertRegions[]> {
        const bodyRequest = {token: localStorage.getItem('token'), shop_id: this.shopId};
        return this.api.getAdvertData<AdvertRegions[]>(bodyRequest, 'regions');
    }

    private requestLoadPhrases(cardId): Observable<IAdvertPhrasesResponse> {
        const bodyRequest = {token: localStorage.getItem('token'), shop_id: this.shopId, id: cardId};
        return this.api.getAdvertData<IAdvertPhrasesResponse>(bodyRequest, 'phrases');
    }

    private requestSavePhrases(): Observable<any> {
        const bodyRequest = {
            token: localStorage.getItem('token'),
            shop_id: this.shopId,
            advertId: this.advertId,
            fixed_phrases: this.phrasesData.fixed_phrases,
            minus_phrases: this.phrasesData.minus_phrases,
            minus_or_fixed: this.advertManageFG.controls.card_fix_plus_request.value ? 1 : 0
        };
        return this.api.updateAdvertData(bodyRequest, 'phrases');
    }

    private requestLoadStrategies(cardId): Observable<IAdvertStrategiesResponse> {
        const bodyRequest = {token: localStorage.getItem('token'), shop_id: this.shopId, id: cardId};
        return this.api.getAdvertData<IAdvertStrategiesResponse>(bodyRequest, 'strategies');
    }

    private requestSaveStrategiesWithQuote(): Observable<any> {
        const bodyRequest = {
            token: localStorage.getItem('token'),
            shop_id: this.shopId,
            advertId: this.advertId,
            cpm: this.advertManageFG.controls.card_current_rate.value, // ставка
        };
        return this.api.updateAdvertData(bodyRequest, 'cpm');
    }

    private requestSaveStrategiesWithPosition(): Observable<any> {
        const strategyIsBet = this.advertManageFG.controls.card_company_type.value === 1;
        const bodyRequest = {
            token: localStorage.getItem('token'),
            shop_id: this.shopId,
            strategy: this.advertManageFG.controls.card_company_type.value, // 1 - Удержание ставки / 2- Удержание позиции
            advertId: this.advertId,

            max_cpm: !strategyIsBet ? this.advertManageFG.controls.card_max_rate.value : undefined, // Максимальная ставка
            from: !strategyIsBet ? this.advertManageFG.controls.card_delta_rate.value : undefined, // Дельта ставки
            position: !strategyIsBet ? this.advertManageFG.controls.card_position.value : undefined // Введите позицию
        };
        return this.api.updateAdvertData(bodyRequest, 'strategies');
    }

    private requestRenameCompany(advertId: number, companyName: string): Observable<any> {
        const bodyRequest = {
            token: localStorage.getItem('token'),
            shop_id: this.shopId,
            advertId,
            name: companyName
        };
        return this.api.updateAdvertData(bodyRequest, 'adverts/rename');
    }

    private requestReplenishBudget(): Observable<any> {
        const bodyRequest = {
            token: localStorage.getItem('token'),
            shop_id: +this.shopId,
            advertId: +this.advertId,
            sum: this.ReplenishBalanceFormGroup.controls.replenish_amount?.value,
            type: this.ReplenishBalanceFormGroup.controls.write_off_money_balance?.value?.id
        };
        return this.api.updateAdvertData(bodyRequest, 'budget/deposit');
    }
}
