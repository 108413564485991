export function WBImgFormatter(params: any): HTMLImageElement {
  const img = document.createElement('img');
  img.width = 40;
  img.height = 40;
  img.referrerPolicy = 'no-referrer';

  if (typeof params.value === 'undefined') {
    img.src = '/assets/images/loading.gif';
  } else {
    if (params.value == null) {
      img.src = '/assets/images/nophoto.jpg';
    } else {
      img.src = ` ${params.value} `;
      img.onerror = () => {
        img.src = '/assets/images/nophoto.jpg';
      };
    }
  }
  return img;
}
