import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IStatisticAdvertTables} from '../../menu-tabs/advert/advert-management/advert-management-card/advert-management-card.interface';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  issuesWithIncomeAndOutcomeArticles: BehaviorSubject<any[]> = new BehaviorSubject<any>(null);

  private advertManagementCashedSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private loadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private api: ApiService) {
  }

  setUpData(data: any[], parameter: string) {
    if (parameter === 'articles') {
      this.issuesWithIncomeAndOutcomeArticles.next(data);
    }
  }

  getData(parameter: string): Observable<any[]> {
    if (parameter === 'articles') {
      return this.issuesWithIncomeAndOutcomeArticles.asObservable();
    }
  }

  loadAdvertManagementData(shopId: number, cardId: number): Observable<any> {
    if (!this.loadedSubject.value && !this.advertManagementCashedSubject.value) {
      this.loadedSubject.next(true);
      this.advertManagementCashedSubject.next([]);
      const bodyRequest = {token: localStorage.getItem('token'), shop_id: shopId, id: cardId};
      this.api.getAdvertData<IStatisticAdvertTables>(bodyRequest, 'statistics/optionally')
        .subscribe((response) => {
          this.advertManagementCashedSubject.next(response);
          return this.advertManagementCashedSubject.asObservable();
        });
    }
    return this.advertManagementCashedSubject.asObservable();
  }
}
