import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MapsGeoOrderComponent } from './maps-geo-order.component';

@NgModule({
  declarations: [MapsGeoOrderComponent],
  imports: [CommonModule, AgmCoreModule, NgbModule],
  exports: [MapsGeoOrderComponent],
})
export class MapsGeoOrdersModule {}
