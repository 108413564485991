import { OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NouisliderModule } from 'ng2-nouislider';
import * as moment from 'moment';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl } from '@angular/forms';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-allbrands',
  templateUrl: './allbrands.component.html',
  styleUrls: ['./allbrands.component.sass'],
})
export class AllbrandsComponent implements OnInit {
  faCog = faCog;
  faTh = faTh;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faArrowRight = faArrowRight;
  faDotCircle = faDotCircle;
  faCheck = faCheck;
  faTrash = faTrash;
  faCalendarAlt = faCalendarAlt;

  loading: any = false;

  rowData: any = [];
  arr_par: any = [];
  params_list: any;
  columnDefsSales: any;

  rowHeight: any = 40;
  rowSelection: any = 'multiple';
  rowModelType: any = 'infinite';
  cacheOverflowSize: any = 2;
  paginationPageSize: any = 100;
  maxConcurrentDatasourceRequests: any = 3;
  infiniteInitialRowCount: any = 1;
  maxBlocksInCache: any = 10;
  cacheBlockSize: any = 100;

  loadingCellRenderer: any;
  loadingCellRendererParams: any;

  localeText: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    //'Сегодня': [moment(), moment()],
    Вчера: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'За 7 дней': [moment().subtract(6, 'days'), moment()],
    'За 30 дней': [moment().subtract(29, 'days'), moment()],
    'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
    'Прошлый Месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  minDate = moment().add(-91, 'days');
  maxDate = moment().add(-1, 'days');
  selected: any = { startDate: moment().add(-7, 'days'), endDate: moment().add(-1, 'days') };
  //displayedColumns: string[] = ['created', 'state', 'number', 'title'];
  displayedColumns: string[] = ['brands', 'logo', 'items', 'rating', 'average_check', 'sales', 'cash'];
  exampleDatabase: ExampleHttpDatabase | null;
  data: GithubIssue[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataperiod: any = 30;
  vis_det: any;
  period: any;
  private gridApi;
  private gridColumnApi;

  constructor(
    private _httpClient: HttpClient,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.localeText = {
      // Number Filter & Text Filter
      filterOoo: 'Фильтр...',
      equals: 'Равно',
      notEqual: 'не равно',
      empty: 'Выбери один',

      // Number Filter
      lessThan: 'Меньше чем',
      greaterThan: 'Больше чем',
      lessThanOrEqual: 'Меньше или равно',
      greaterThanOrEqual: 'Больше или равно',
      inRange: 'В диапазоне',
      inRangeStart: 'От',
      inRangeEnd: 'До',

      // Text Filter
      contains: 'Содержит',
      notContains: 'Не Содержит',
      startsWith: 'Начинается с',
      endsWith: 'Заканчиваеться на',

      // Date Filter
      dateFormatOoo: 'dd.mm.yyyy',

      // Filter Conditions
      andCondition: 'И',
      orCondition: 'ИЛИ',

      // Filter Buttons
      applyFilter: 'Apply',
      resetFilter: 'Reset',
      clearFilter: 'Clear',
      cancelFilter: 'Cancel',

      // Filter Titles
      textFilter: 'Text Filter',
      numberFilter: 'Number Filter',
      dateFilter: 'Date Filter',
      setFilter: 'Set Filter',
    };
  }

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  ngAfterViewInit() {
    this.exampleDatabase = new ExampleHttpDatabase(this._httpClient, this.api);

    console.log(this.exampleDatabase);

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.exampleDatabase!.getRepoIssues(
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            moment().add(-7, 'days').format('YYYY-MM-DD'),
            moment().add(-1, 'days').format('YYYY-MM-DD'),
          );
        }),
        map((data: any) => {
          console.log(data);
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total;

          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        }),
      )
      .subscribe((data) => (this.data = data));
  }

  newValue() {
    this.columnDefsSales = [
      {
        headerName: 'Бренд',
        field: 'brend',
        sortable: true,
        resizable: true,
        width: 150,
        filter: false,
        suppressSizeToFit: true,
        flex: 0,
        cellClass: ['d-flex', 'justify-content-center', 'align-items-center'],
        cellRendererParams: {
          startDate: this.selected.startDate.format('YYYY-MM-DD'),
          endDate: this.selected.endDate.format('YYYY-MM-DD'),
        },
        cellRenderer: this.wbBrend,
      },
      {
        headerName: 'Продавцов',
        field: 'qty_seller',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Товаров',
        field: 'qty_sku',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Средний чек',
        field: 'avg',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Рейтинг',
        field: 'rating',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Продаж',
        field: 'qty_sales',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Оборот',
        field: 'revenue',
        sortable: true,
        resizable: true,
        width: 150,
        wrapText: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
    ];

    console.log(this.selected);
    console.log('Date: ' + this.selected.startDate.format('YYYY-MM-DD'));

    console.log('Date: ' + this.selected.endDate.format('YYYY-MM-DD'));

    var datasource = {
      getRows: (params: any) => {
        console.log(params);
        this.getRowData(
          params.startRow,
          params.endRow,
          params.filterModel,
          params.sortModel,
          this.selected.startDate.format('YYYY-MM-DD'),
          this.selected.endDate.format('YYYY-MM-DD'),
        ).subscribe((data: any) => params.successCallback(data.data, data.totalRecord));
      },
    };
    this.gridApi.setDatasource(datasource);
  }

  /*
  newValue(){
      this.exampleDatabase = new ExampleHttpDatabase(this._httpClient, this.api);

    console.log(this.exampleDatabase);

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.exampleDatabase!.getRepoIssues(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, this.selected.startDate.format("YYYY-MM-DD"), this.selected.endDate.format("YYYY-MM-DD"));
        }),
        map((data: any) => {
            console.log(data);
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total;

          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);

  }
  */

  wbBrend(params) {
    if (typeof params.value == 'undefined') {
      let img = document.createElement('img');
      img.src = '/assets/images/loading.gif';
      img.width = 32;
      img.height = 32;
      img.referrerPolicy = 'no-referrer';
      return img;
    } else {
      return (
        '<a href="/dashboard/all/brends/' +
        params.value +
        '?d1=' +
        params.startDate +
        '&d2=' +
        params.endDate +
        '" target="_blank">' +
        params.value +
        '</a>'
      );
      //return '<a href="/wb/catlist?cat='+ params.value +'" target="_blank">'+ params.value+'</a>';
      //<a href="/dashboard/all/sellers/{{row.seller}}?d1={{selected.startDate | date: 'yyyy-MM-dd'}}&d2={{selected.endDate | date: 'yyyy-MM-dd'}}" target="_new">{{row.seller}}</a>
    }
  }

  ngOnInit() {
    //console.log(this.route.queryParams);
    this.route.queryParams.subscribe((params) => {
      if (typeof params.id !== 'undefined') {
        // your code here
        if (params.id.length > 0) {
          this.vis_det = true;
        } else {
          this.vis_det = false;
        }
      } else {
        this.vis_det = false;
        this.newValue();
      }
    });

    this.columnDefsSales = [
      {
        headerName: 'Бренд',
        field: 'brend',
        sortable: true,
        resizable: true,
        width: 150,
        filter: false,
        suppressSizeToFit: true,
        flex: 0,
        cellClass: ['d-flex', 'justify-content-center', 'align-items-center'],
        cellRendererParams: {
          startDate: this.selected.startDate.format('YYYY-MM-DD'),
          endDate: this.selected.endDate.format('YYYY-MM-DD'),
        },
        cellRenderer: this.wbBrend,
      },
      {
        headerName: 'Продавцов',
        field: 'qty_seller',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Товаров',
        field: 'qty_sku',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Средний чек',
        field: 'avg',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Рейтинг',
        field: 'rating',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Продаж',
        field: 'qty_sales',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Оборот',
        field: 'revenue',
        sortable: true,
        resizable: true,
        width: 150,
        wrapText: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
    ];
  }

  onGridReady(params: any) {
    console.log(params);

    console.log(this.selected);
    this.gridApi = params.api;

    this.gridColumnApi = params.columnApi;

    var datasource = {
      getRows: (params: any) => {
        console.log(params);
        this.getRowData(
          params.startRow,
          params.endRow,
          params.filterModel,
          params.sortModel,
          moment().add(-7, 'days').format('YYYY-MM-DD'),
          moment().add(-1, 'days').format('YYYY-MM-DD'),
        ).subscribe((data: any) => params.successCallback(data.data, data.totalRecord));
      },
    };
    params.api.setDatasource(datasource);
  }

  private getRowData(startRow: number, endRow: number, filter: any, sort: any, dateBegin: any, dateEnd: any) {
    //console.log(filter);
    this.arr_par = {
      params_list: this.params_list,
      startRow: startRow,
      endRow: endRow,
      filter: filter,
      sort: sort,
      dateBegin: dateBegin,
      dateEnd: dateEnd,
    };

    return this.api.userRegisterClick('getDataBrend', localStorage.getItem('token'), this.arr_par);
  }
}

export interface GithubApi {
  data: GithubIssue[];
  total: number;
}

export interface GithubIssue {
  //['brands', 'items', 'rating', 'average_check', 'sales', 'cash'];
  //seller: string;
  brands: number;
  items: number;
  rating: number;
  average_check: number;
  sales: number;
  cash: number;
  //created_at: string;
  //number: string;
  //state: string;
  //title: string;
}

/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDatabase {
  constructor(
    private _httpClient: HttpClient,
    private api: ApiService,
  ) {}

  getRepoIssues(sort: string, order: string, page: number, pagesize: number, startDate: any, endDate: any) {
    console.log('GOOD');
    const href = 'https://api.github.com/search/issues';
    //const requestUrl =
    //    `${href}?q=repo:angular/aggrid-components&sort=${sort}&order=${order}&page=${page + 1}`;

    //return this._httpClient.get<GithubApi>(requestUrl);

    //this.arr_par = {params_list : this.params_list, startRow: startRow, endRow :endRow, filter:filter,  sort:sort, days:days};
    return this.api.userRegisterClick('getDataBrands', localStorage.getItem('token'), {
      sort: sort,
      order: order,
      page: page,
      pagesize: pagesize,
      startDate: startDate,
      endDate: endDate,
    });
  }
}
