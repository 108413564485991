import { Component, Inject, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/shared/services/api.service';
import * as FileSaver from 'file-saver';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/common';
import { ColDef } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { MY_BUYOUTS_COLUMN_DEFS } from './mybuyouts.mock';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-mybuyouts',
  templateUrl: './mybuyouts.component.html',
  styleUrls: ['./mybuyouts.component.sass'],
  providers: [MessageService, ConfirmationService],
})
export class MybuyoutsComponent implements OnInit {
  token: any;
  shop_id: any;
  excel_lists: any;
  file: any;
  url_link: any;
  rowData: any[] = [];
  readonly faQuestionCircle = faQuestionCircle;
  readonly rowSelection = 'multiple';
  readonly rowModelType = 'infinite';
  readonly defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
    editable: true,
  };

  readonly gridOptions: GridOptions;
  readonly rowBuffer = 1000;

  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    @Inject(DOCUMENT) private document: any,
  ) {
    this.gridOptions = {
      context: { componentParent: this },
    } as GridOptions;
    this.rowBuffer = 1000;
  }

  get columnDataForGrid() {
    return MY_BUYOUTS_COLUMN_DEFS;
  }

  ngOnInit(): void {
    this.url_link = environment.apiOld;
    this.token = localStorage.getItem('token');
    this.shop_id = JSON.parse(localStorage.getItem('mpall_shop')).shop;
    this.getBuyouts();
    this.getBuyoutsForExcel();
  }

  onCellValueChanged(event) {
    this.updateBuyouts(event.data);
  }

  deleteBuyout(productData: any) {
    this.confirmationService.confirm({
      message: 'Вы уверены, что хотите удалить самовыкуп?',
      header: 'Подтверждение удаления',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Да',
      rejectLabel: 'Нет',
      accept: () => {
        const body = { shop_id: this.shop_id, delete_id: productData.id };
        this.api.userRegister('getBuyoutsDelete', localStorage.getItem('token'), body).subscribe(
          () => {
            this.getBuyouts();
            this.showPopUpMessage('success', PopUpMessages.deleteSuccessSummary, 'Самовыкуп удален');
          },
          () => {
            this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
          },
        );
      },
      reject: () => {},
    });
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.excel_lists);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'buyouts');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  addNewBuyout() {
    const body = { shop_id: this.shop_id };
    return new Promise((resolve, reject) => {
      this.api.userRegister('getBuyoutsAdd', localStorage.getItem('token'), body).subscribe(
        async (data: any) => {
          if (+data.data === 1) {
            this.getBuyouts();
          }
          resolve(true);
        },
        (err) => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
          reject(err);
        },
      );
    });
  }

  onBasicUploadError(event: any, fileUpload: any) {
    fileUpload.clear();
    this.showPopUpMessage('warning', 'Ошибка', event.error.error.text);
  }

  onBasicUploadAuto(event: any, fileUpload) {
    this.showPopUpMessage('success', 'Загружено', 'Файл загружен. Данные обновлены');
    fileUpload.clear();
    this.getBuyouts();
  }

  private getBuyouts(params = null) {
    const body = {
      shop_id: this.shop_id,
      params,
    };
    this.api.userRegister('getBuyouts', localStorage.getItem('token'), body).subscribe(
      (response: any) => {
        this.rowData = response.data;
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  private updateBuyouts(rowData) {
    const body = { shop_id: this.shop_id, data: rowData };
    this.api.userRegister('getBuyoutsUpdate', localStorage.getItem('token'), body).subscribe(
      (response: any) => {
        console.log(response);
        this.getBuyouts();
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  private getBuyoutsForExcel(params = null) {
    const body = { shop_id: this.shop_id, params };
    this.api.userRegister('getBuyoutsExcel', localStorage.getItem('token'), body).subscribe(
      (data: any) => {
        this.excel_lists = data;
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
