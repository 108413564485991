<p-progressSpinner *ngIf="isLoading"
                   [style]="{position: 'absolute', top: '40%', left: '50%'}">
</p-progressSpinner>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="fin-items-wrapper">
    <div class="col-sm-12 col-md-12">
      <div class="mb-3 card text-white card-body bg-warning">
        <h5 class="card-title">Идет Beta тестирование</h5>
      </div>
      <br/>
    </div>
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
          </div>
          <div>
            Финансовые статьи&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
        <div class="page-title-actions">
          <div class="d-inline-block" ngbDropdown placement="bottom-right">
            <button class="mb-2 btn btn-success" ngbDropdownToggle type="button">Добавить статью</button>
            <div class="dropdown-menu-right-rounded" ngbDropdownMenu>
              <button (click)="openModal(receipt)" class="dropdown-item"><span
                style="color:green">Поступления</span>
              </button>
              <button (click)="openModal(order)" class="dropdown-item"><span style="color:red">Списания</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-toast [baseZIndex]="5000" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
    <div class="row">
      <p-contextMenu #cm></p-contextMenu>
      <p-confirmDialog [baseZIndex]="10000" [style]="{width: '30vw'}"></p-confirmDialog>
    </div>
    <div class="row">
      <p-tree #treeComponent (onNodeDrop)="onNodeDropped($event)"
              [draggableNodes]="true"
              [droppableNodes]="true"
              [value]="finItemsMain"
              class="w-full md:w-30rem"
              draggableScope="self"
              droppableScope="self">
        <ng-template let-node pTemplate="default">
          <div class="node-item">
            <div class="item-block">
              <ng-container *ngIf="!node?.p_l">
                <p class="title-part">
                  {{node.label}}
                </p>
              </ng-container>
              <ng-container *ngIf="node?.p_l">
                <div class="block-part-l">
                  <i *ngIf="+node.is_block" class="fa fa-lock icon-gradient bg-love-kiss"></i>
                  <p class="title-part">
                    {{node.label}} {{+node.p_l === 1 ? '[ДДС / ОПУ]' : '[ДДС]'}}
                    <span [innerHTML]="isPnlNameExist(node)" class="pnl-tag"></span>
                  </p>
                </div>
              </ng-container>
              <div *ngIf="!node.children"
                   class="manage-part">
                <i *ngIf="+node.is_visible === 1" aria-hidden="true"
                   class="fa fa-eye-slash">
                </i>
                <i (click)="modalEditItem(node)"
                   class="lnr-pencil">
                </i>
                <i (click)="deleteItem(node)"
                   [ngClass]="{'disable-btn' : +node.is_block}"
                   class="lnr-trash">
                </i>
              </div>
            </div>
          </div>
        </ng-template>
      </p-tree>
    </div>
  </div>

  <!--  ФОРМА ДЛЯ ДОБАВЛЕНИ/ИЗМЕНЕНИЯ статьи поступления -->
  <ng-template #receipt>
    <app-header-modal
      (closeEmitter)="closeModal()"
      [modalTitle]="(isEditModal ? 'Изменение' : 'Добавление') + ' статьи поступления'">
    </app-header-modal>
    <form [formGroup]="Form"
          class="needs-validation"
          novalidate>
      <div class="modal-body">
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label">Наименование</label>
          <div class="col-sm-9">
            <input class="form-control" formControlName="name" maxLength="100" name="name"
                   placeholder="" type="text">
            <span *ngIf="this.Form.controls['name'].invalid && this.Form.controls['name'].touched" class="alert-danger">Обязательно для заполнения</span>
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label">Вид деятельности</label>
          <div style="width: 366px; padding-bottom: 16px">
            <ng-select [items]="listOfGroupsDds" [searchable]="true"
                       bindLabel="value" bindValue="id"
                       formControlName="group_dds" notFoundText="Ничего не найдено" placeholder="Категория">
            </ng-select>
          </div>
        </div>

        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="p_l">P&L (ОПУ)</label>
          <div class="col-sm-9" style="margin-top: 4px;">
            <input formControlName="p_l" id="p_l" name="checkbox" type="checkbox">
          </div>
        </div>

        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="is_visible1">Скрыть статью</label>
          <div class="col-sm-9" style="margin-top: 4px;">
            <input formControlName="is_visible" id="is_visible1" name="checkbox" type="checkbox">
          </div>
        </div>

        <div *ngIf="showSelectDropDown" class="position-relative row form-group" style="justify-content: end">
          <div style="width: 368px; padding-bottom: 16px">
            <ng-select [items]="listOfGroupsPnl" [searchable]="true"
                       bindLabel="value" bindValue="id"
                       formControlName="group_pnl" notFoundText="Ничего не найдено" placeholder="Категория">
            </ng-select>
          </div>
        </div>

        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="description_l1">Комментарий</label>
          <div class="col-sm-9"><textarea class="form-control" formControlName="description" id="description_l1"
                                          name="description"></textarea>
            <span *ngIf="this.Form.controls['description'].invalid && this.Form.controls['description'].touched"
                  class="alert-danger">Не корректно заполнено поле</span>
          </div>
        </div>

      </div>
      <app-footer-modal
        (submitEmitter)="createUpdateItem('receipt')"
        [isCreate]="!isEditModal"
        [isDisabled]="Form.invalid || Form.disabled">
      </app-footer-modal>
    </form>
  </ng-template>
  <!--  ФОРМА ДЛЯ ДОБАВЛЕНИ/ИЗМЕНЕНИЯ статьи списания -->
  <ng-template #order>
    <app-header-modal
      (closeEmitter)="closeModal()"
      [modalTitle]="(isEditModal ? 'Изменение' : 'Добавление') + ' статьи списания'">
    </app-header-modal>
    <form [formGroup]="Form"
          class="needs-validation"
          novalidate>
      <div class="modal-body">
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label">Наименование</label>
          <div class="col-sm-9">
            <input class="form-control" formControlName="name" id="name_label" maxLength="100" name="name"
                   placeholder="" type="text">
            <span *ngIf="this.Form.controls['name'].invalid && this.Form.controls['name'].touched" class="alert-danger">Обязательно для заполнения</span>
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="name_label">Вид деятельности</label>
          <div style="width: 366px; padding-bottom: 16px">
            <ng-select [items]="listOfGroupsDds" [searchable]="true"
                       bindLabel="value" bindValue="id"
                       formControlName="group_dds" notFoundText="Ничего не найдено" placeholder="Вид деятельности">
            </ng-select>
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="p_l1">P&L (ОПУ)</label>
          <div class="col-sm-9" style="margin-top: 4px;">
            <input formControlName="p_l" id="p_l1" name="checkbox" type="checkbox">
          </div>
        </div>

        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="is_visible">Скрыть статью</label>
          <div class="col-sm-9" style="margin-top: 4px;">
            <input formControlName="is_visible" id="is_visible" name="checkbox" type="checkbox">
          </div>
        </div>

        <div *ngIf="showSelectDropDown" class="position-relative row form-group" style="justify-content: end">
          <div style="width: 368px; padding-bottom: 16px">
            <ng-select [items]="listOfGroupsPnlSpisanie" [searchable]="true"
                       bindLabel="value" bindValue="id"
                       formControlName="group_pnl" notFoundText="Ничего не найдено" placeholder="Категория">
            </ng-select>
          </div>
        </div>
        <div class="position-relative row form-group">
          <label class="col-sm-3 col-form-label" for="description_l">Комментарий</label>
          <div class="col-sm-9"><textarea class="form-control" formControlName="description" id="description_l"
                                          name="description"></textarea>
            <span *ngIf="this.Form.controls['description'].invalid && this.Form.controls['description'].touched"
                  class="alert-danger">Не корректно заполнено поле</span>
          </div>
        </div>

      </div>
      <app-footer-modal
        (submitEmitter)="createUpdateItem('order')"
        [isCreate]="!isEditModal"
        [isDisabled]="Form.invalid || Form.disabled">
      </app-footer-modal>
    </form>
  </ng-template>
</div>

