import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Table} from 'primeng/table';
import {SortEvent} from 'primeng/api';
import {ApiService} from '../../../../../shared/services/api.service';
import {IStatisticAdvertTables} from '../advert-management-card.interface';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {SharedDataService} from '../../../../../shared/services/shared-data.service';

@Component({
  selector: 'app-abstract-advert-management-auction-statistic',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbstractAdvertManagementCardComponent implements OnInit {
  advertManagementStatisticData: IStatisticAdvertTables = {minus: [], plus: []};
  cardId: number;
  shopId: number;
  pending = false;

  constructor(private api: ApiService, private route: ActivatedRoute,
              private sharedData: SharedDataService) {
  }

  ngOnInit() {
  }


  loadStatisticData() {
    this.shopId = JSON.parse(localStorage.getItem('mpall_shop') || '{}').shop;
    this.route.params.subscribe(params => {
      this.cardId = +params.id ?? 0;
    });
    this.sharedData.loadAdvertManagementData(this.shopId, this.cardId).subscribe(
      (response) => {
        this.advertManagementStatisticData = response;
      }
    );
  }

  clear(table: Table) {
    table.clear();
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;
      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }
      return event.order * result;
    });
  }
}
