import { Component } from '@angular/core';

@Component({
  selector: 'app-maps-geo-orders',
  templateUrl: './maps-geo-order.component.html',
})
export class MapsGeoOrderComponent {

  heading = 'Maps';
  subheading = 'Implement in your applications Google Maps.';
  icon = 'pe-7s-map icon-gradient bg-premium-dark';

  // google maps-geo-sales-geo-orders zoom level
  zoom = 3;

  // initial center position for the map
  lat =  66.09920352413396;
  lng = 96.63847264002437;
  markers: Marker[] = [
    {
      lat: 60.05099201747296,
      lng: 30.037493567238144,
      label: 'Санкт-Петербург',
      draggable: true
    },
    {
      lat: 55.434291091442866,
      lng: 37.53843758609952,
      label: 'Подольск',
      draggable: false
    },
    {
      lat: 45.038820325806846,
      lng:  38.9708931088944,
      label: 'Краснодар',
      draggable: true
    },
    {
      lat: 56.844864951521764,
      lng:  60.62630171038544,
      label: 'Екатеринбург',
      draggable: true
    },
    {
      lat: 54.98723489847448,
      lng:  82.89172122143904,
      label: 'Новосибирск',
      draggable: true
    },
    {
      lat: 48.485949197172026,
      lng:  135.070621816366,
      label: 'Хабаровск',
      draggable: true
    },
  ];

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {

  }

  markerDragEnd(m: Marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }
}

// just an interface for type safety.
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
