import { Component, OnInit } from '@angular/core';
import * as moment from 'moment/moment';
import { TreeNode } from 'primeng/api';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import {
  GEO_ORDERS_COLUMN_DEFS_SUBJECT,
  GEO_ORDERS_COLUMN_DEFS_WH,
  GEO_ORDERS_REGION_DATA,
} from '../../../shared/mocks/geo-orders-mock';
import { THIRD_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';

@Component({
  selector: 'app-geo-orders',
  templateUrl: './geo-orders.component.html',
  styleUrls: ['./geo-orders.component.sass'],
})
export class GeoOrdersComponent implements OnInit {
  public defaultColDefSubject: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,
  };
  public rowDataSubject: any[] = [
    {
      subject_item: 'QQQQQQ',
      subject_orders_item: '21231',
      subject_orders_rubble: '222000',
      subject_orders_share: '45',
    },
    {
      subject_item: 'UUUUUUUUu',
      subject_orders_item: '123123',
      subject_orders_rubble: '3445444',
      subject_orders_share: '15',
    },
    {
      subject_item: 'KKKKKKKKKk',
      subject_orders_item: '12313123',
      subject_orders_rubble: '222000',
      subject_orders_share: '25',
    },
    {
      subject_item: 'FFFFFFFFFFf',
      subject_orders_item: '21231',
      subject_orders_rubble: '2211111',
      subject_orders_share: '10',
    },
    {
      subject_item: 'XXXXXXXXXXXXXxx',
      subject_orders_item: '22211112',
      subject_orders_rubble: '45555543',
      subject_orders_share: '13',
    },
  ];
  rowDataSubjectChartPercent: number[] = [];
  rowDataSubjectChartTitles: string[] = [];
  rowHeightSubject = 30;
  // Ware house AG-GRID
  public defaultColDefWH: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,
  };
  public rowDataWH: any[] = [
    {
      wh_shipment: 'SSSSSSS',
      wh_orders_item: '21231',
      wh_orders_rubble: '222000',
      wh_orders_share: '45',
    },
    {
      wh_shipment: 'AAAAAAA',
      wh_orders_item: '123123',
      wh_orders_rubble: '3445444',
      wh_orders_share: '15',
    },
    {
      wh_shipment: 'DDDDDDDDDD',
      wh_orders_item: '12313123',
      wh_orders_rubble: '222000',
      wh_orders_share: '25',
    },
    {
      wh_shipment: 'HHHHHHHh',
      wh_orders_item: '21231',
      wh_orders_rubble: '2211111',
      wh_orders_share: '10',
    },
    {
      wh_shipment: 'WWWWWWWWWw',
      wh_orders_item: '22211112',
      wh_orders_rubble: '45555543',
      wh_orders_share: '13',
    },
  ];
  rowDataWHChartPercent: number[] = [];
  rowDataWHChartTitles: string[] = [];
  rowHeightWH = 30;
  date_dis: any = false; // для отключения даты если идет загрузка данных
  selected: any = { startDate: moment().add(-7, 'days'), endDate: moment() };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  minDate = moment().add(-91, 'days');
  maxDate = moment().add(0, 'days');
  chartJSSizes: any = { width: '500', height: '450', type: 'pie' };
  columnForRegionTable: any;
  selectedNodes: TreeNode[];
  frozenCols: any[];
  value3: any = 3;

  constructor() {}

  get dataForRegionTable() {
    return GEO_ORDERS_REGION_DATA;
  }

  get dataForColumnSubject() {
    return GEO_ORDERS_COLUMN_DEFS_SUBJECT;
  }

  get dataForColumnWH() {
    return GEO_ORDERS_COLUMN_DEFS_WH;
  }

  get datePickerRange() {
    return THIRD_RANGE_TYPE;
  }

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  ngOnInit(): void {
    this.frozenCols = [{ field: 'name', header: 'Страна' }];
    this.rowDataSubjectChartTitles = this.rowDataSubject.map((subject) => subject.subject_item);
    this.rowDataSubjectChartPercent = this.rowDataSubject.map((subject) => +subject.subject_orders_share);
    this.rowDataWHChartPercent = this.rowDataWH.map((subject) => +subject.wh_orders_share);
    this.rowDataWHChartTitles = this.rowDataWH.map((subject) => subject.wh_shipment);
    if (window.innerWidth < 600) {
      this.chartJSSizes = { width: '430', height: '350', type: 'pie' };
    }
    if (window.innerWidth < 450) {
      this.chartJSSizes = { width: '280', height: '350', type: 'pie' };
    }
  }

  newValues() {}

  getOrdersSubject(params = null) {}

  getOrdersWH(params = null) {}

  onGridReadySubject(params: GridReadyEvent<any>) {}

  onGridReadyWH(params: GridReadyEvent<any>) {}
}
