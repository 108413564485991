import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { LOCALE_TEXT_FOR_FILTERS } from '../../../guides/products/products.mock';
import { GridOptions } from 'ag-grid';
import { RECONCILIATION_COLUMNS_DEFS } from './reconciliation-wb.mock';
import { environment } from '../../../../../environments/environment';
import { PopUpStatus } from '../../../../shared/interfaces/common.type';
import { MpSurfApiService } from '../../../../shared/services/mpsurf-api.service';
import { finalize } from 'rxjs/operators';
import { IReconciliationWbRowData } from './reconciliation-wb.interface';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-reconciliation-wb',
  templateUrl: './reconciliation-wb.component.html',
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReconciliationWbComponent {
  gridApi!: GridApi<any>;
  rowData: any[] = [];
  readonly colDefs = [...RECONCILIATION_COLUMNS_DEFS];
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly gridOptions: GridOptions;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };
  readonly tooltipShowDelay = 0;
  readonly tooltipHideDelay = 2000;
  // AG-GRID ENDS
  isLoading = false;

  readonly URL_NEW = null;
  readonly TOKEN = null;
  readonly SHOP_ID = null;
  private readonly mpService = inject(MpSurfApiService);

  constructor(
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
  ) {
    this.SHOP_ID = +JSON.parse(localStorage.getItem('mpall_shop')).shop;
    this.URL_NEW = environment.apiNew;
    this.TOKEN = localStorage.getItem('token');
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.loadData();
  }

  loadData(): void {
    this.isLoading = true;
    this.mpService
      .load({}, 'collation-wb')
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe((response: IReconciliationWbRowData[]) => {
        if (!response.length) {
          this.rowData = [];
        } else {
          this.rowData = response;
        }
      });
  }

  onSendData() {
    this.isLoading = true;
  }

  onBasicUploadError(event: any, fileUpload: any) {
    fileUpload.clear();
    this.showPopUpMessage('warning', 'Ошибка', event.error.error.text);
  }

  onBasicUploadAuto(event: any, fileUpload) {
    this.showPopUpMessage('success', 'Загружено', 'Файл загружен. Данные обновлены');
    fileUpload.clear();
    this.loadData();
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
