import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Params } from '@angular/router';

@Component({
  selector: 'app-btn-cell-renderer-buyout',
  template: `
    <button (click)="deleteBuyout()" class="btn-shadow btn btn-danger" style="margin-right: 10px;">Удалить</button>
  `,
})
export class BtnCellRendererComponentBuyout implements ICellRendererAngularComp {
  componentParent: any;
  private params: Params;

  agInit(params: Params): void {
    this.params = params;
    this.componentParent = this.params.context.componentParent;
  }

  deleteBuyout() {
    this.componentParent.deleteBuyout(this.params.data);
  }

  refresh() {
    return false;
  }
}
