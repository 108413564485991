import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../../shared/services/api.service';
import { merge, of as observableOf } from 'rxjs';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, finalize, map, startWith, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DatePickerCommon } from '../../../../../shared/common-variables/date-picker-common';
import { FIRST_RANGE_TYPE } from '../../../../../shared/common-variables/time-ranges-date-picker';
import { ORDERS_OPTIONS, SLIDES_CONFIG_5 } from './product-id.mock';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IDetailingByBarcodesList,
  IDetailTotalNmidAgv,
  IOrderOptions,
  IProductChartRequest,
  IProductOrdersStatic,
  ProductIdType,
} from './product-id.interface';
import { PopUpStatus } from '../../../../../shared/interfaces/common.type';
import { MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../../../shared/mocks/pop-up-messages.mock';
import { ProductIdChartsComponent } from './product-id-orders-chart/product-id-charts.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-products-id',
  templateUrl: './product-id.component.html',
  styleUrls: ['./product-id.component.sass'],
  providers: [MessageService],
})
export class ProductIdComponent implements OnInit, AfterViewInit {
  isLoading = false;
  DatePickerCommon: DatePickerCommon;
  DatePickerRanges = FIRST_RANGE_TYPE;
  slideConfig5 = SLIDES_CONFIG_5;
  selected: any = {
    startDate: moment().subtract(1, 'month').startOf('month'),
    endDate: moment().subtract(1, 'month').endOf('month'),
  };
  detailingByBarcodesList: IDetailingByBarcodesList[] = [];
  total_nmid_agv: IDetailTotalNmidAgv;
  nm_static: IProductOrdersStatic;
  exampleDatabase: ExampleHttpDatabase | null;
  buyoutId = undefined;
  SHOP_ID: number;

  selectedOrdersType: IOrderOptions = ORDERS_OPTIONS[0];
  ORDERS_OPTIONS: IOrderOptions[] = ORDERS_OPTIONS;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  SKU_ID: number;
  TOKEN: string;
  data: any;

  currentProductType: ProductIdType = 'Orders';

  @ViewChild(ProductIdChartsComponent) chartComponentRef: ProductIdChartsComponent;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    public toastr: ToastrService,
  ) {
    this.DatePickerCommon = new DatePickerCommon();
    this.route.params.subscribe((params) => {
      this.SKU_ID = params['id'];
    });
    this.TOKEN = localStorage.getItem('token');
    if (localStorage.getItem('mpall_shop')) {
      this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop'))?.shop;
    }
  }

  get generalRequest(): object {
    return {
      shop_id: this.SHOP_ID,
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      sku: this.SKU_ID,
    };
  }

  get resForResponseSales(): number {
    const b = moment(this.selected.startDate.format('YYYY-MM-DD'));
    const a = moment(this.selected.endDate.format('YYYY-MM-DD'));
    return +a.diff(b, 'days');
  }

  ngOnInit(): void {
    this.getItemSkuOne();
  }

  loadProductChart(): void {
    if (this.chartComponentRef) {
      const bodyRequest = {
        shop_id: this.SHOP_ID,
        startDate: this.selected.startDate.format('YYYY-MM-DD'),
        endDate: this.selected.endDate.format('YYYY-MM-DD'),
        cancel: this.selectedOrdersType.value === 3 ? 1 : undefined,
      } as IProductChartRequest;
      this.isLoading = true;
      this.chartComponentRef.loadData(bodyRequest, this.selectedOrdersType.action);
      this.chartComponentRef.getChart(bodyRequest, this.selectedOrdersType.actionChart);
      this.chartComponentRef.resForResponseSales = this.resForResponseSales;
    }
  }

  switchOrdersType(selected: any): void {
    this.chartComponentRef.currentProductType = this.selectedOrdersType.type;
    this.loadProductChart();
  }

  switchBuyouts() {
    this.buyoutId = this.buyoutId === 1 ? 0 : 1;
    this.getItemSkuOne(this.buyoutId);
    this.getItemSkuAvg(this.buyoutId);
    this.loadProductChart();
  }

  ngAfterViewInit() {
    // для раздела "по размерам"
    this.exampleDatabase = new ExampleHttpDatabase(this.api);
    this.sort?.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.exampleDatabase!.getRepoIssues(
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            moment().add(-7, 'days').format('YYYY-MM-DD'),
            moment().add(-1, 'days').format('YYYY-MM-DD'),
          );
        }),
        map((data: any) => data.data),
        catchError(() => observableOf([])),
      )
      .subscribe((data) => (this.data = data));

    this.loadProductChart();
  }

  changeDate() {
    this.getItemSkuOne(this.buyoutId);
    this.getItemSkuAvg(this.buyoutId);
    this.getDataSkuStatOper();
    this.loadProductChart();
  }

  stopLoading() {
    this.isLoading = false;
  }

  private getItemSkuAvg(view_buyout = null) {
    const body = {
      ...this.generalRequest,
      buyout: view_buyout,
    };
    this.isLoading = true;
    this.api
      .userRegister('getDataSkuAvg', this.TOKEN, body)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (response: IDetailTotalNmidAgv) => {
          this.total_nmid_agv = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, '');
        },
      );
  }

  private getItemSkuOne(view_buyout = null): void {
    this.isLoading = true;
    const body = {
      ...this.generalRequest,
      buyout: view_buyout,
    };
    this.api
      .userRegister('getDataSkuOne', this.TOKEN, body)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (response: IDetailingByBarcodesList[]) => {
          this.detailingByBarcodesList = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, '');
        },
      );
  }

  private getDataSkuStatOper(): void {
    this.isLoading = true;
    const body = this.generalRequest;
    this.api
      .userRegister('getDataSkuStatOper', this.TOKEN, body)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (response: IProductOrdersStatic) => {
          this.nm_static = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, '');
        },
      );
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.isLoading = false;
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}

/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDatabase {
  constructor(private api: ApiService) {}

  getRepoIssues(sort: string, order: string, page: number, pagesize: number, startDate: any, endDate: any) {
    return this.api.userRegisterClick('getDataSeller', localStorage.getItem('token'), {
      sort,
      order,
      page,
      pagesize,
      startDate,
      endDate,
    });
  }
}
