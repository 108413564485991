import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ApexAxisChartSeries, ApexChart, ApexTitleSubtitle, ApexXAxis, ChartComponent } from 'ng-apexcharts';
import { ICpmList } from './mycompair.interface';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-mycompair',
  templateUrl: './mycompair.component.html',
  styleUrls: ['./mycompair.component.sass'],
})
export class MycompairComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  visible_list_cmp: any;
  visible_list_edit: any;
  visible_list: any;
  name_group: any;
  list_sku: any;
  list_options: any;
  list_photo: any;
  list_main: any;
  list_user: any;
  list_price: any;
  list_stock: any;
  qty_sku: any;
  list_cmp: ICpmList[] = [];
  hideme: any;
  wait_load: any;
  visible_compair_card: any;
  name_group_edit: any;
  list_sku_edit: any;
  edit_id: any;
  list_sales_days30: any;

  constructor(
    private api: ApiService,
    public toastr: ToastrService,
  ) {
    this.hideme = {}; // init is required
    this.visible_list_cmp = true;
    this.visible_list_edit = false;
  }

  orderOriginal = (a, b): number => 0;

  ngOnInit() {
    this.visible_compair_card = false;
    this.visible_list = false;
    this.getListHeader();
  }

  view(id) {
    this.getList(id);
    this.visible_compair_card = true;
    this.visible_list_cmp = false;
  }

  open() {
    this.visible_list = true;
    this.visible_compair_card = false;
    this.visible_list_cmp = false;
  }

  closednew() {
    this.visible_list = false;
  }

  closededit() {
    this.visible_list_edit = false;
  }

  addnew() {
    if (typeof this.name_group === 'undefined' && typeof this.list_sku === 'undefined') {
      alert('Введены не корректные данные 1');
    } else {
      if (String(this.name_group) === '' || String(this.list_sku) === '') {
        alert('Введены не корректные данные 2');
      } else {
        const body = { name: this.name_group, list_sku: this.list_sku };
        this.api.userRegister('cmpCardAdd', localStorage.getItem('token'), body).subscribe(
          (data: any) => {
            if (+data.is_error === 0) {
              this.visible_list = false;
              this.name_group = '';
              this.list_sku = '';
              alert('Ожидайте обновления. Обычно это занимает около 10-15 минут при первом добавлении');
              this.getListHeader();
            } else {
              alert('Введены не корректные данные' + 'Ошибка: ' + data.msg);
            }
          },
          (error: HttpErrorResponse) => {
            alert(error);
          },
        );
      }
    }
  }

  editold(id) {
    const body = { id, name_group: this.name_group_edit, list_sku: this.list_sku_edit };
    this.api.userRegister('cmpEditId', localStorage.getItem('token'), body).subscribe(
      (data: any) => {
        if (+data === 1) {
          this.visible_list_edit = false;
          this.name_group_edit = '';
          this.list_sku_edit = '';
        } else {
          alert('Ошибка Обновления');
        }
      },
      (error: HttpErrorResponse) => {
        alert(error);
      },
    );
  }

  editCompare(id: string) {
    this.api.userRegister('cmpGetId', localStorage.getItem('token'), { id }).subscribe(
      (data: any) => {
        this.name_group_edit = data.name;
        this.list_sku_edit = data.SKU;
        this.visible_list_edit = true;
        this.edit_id = data.id;
      },
      (error: HttpErrorResponse) => {
        alert(error);
      },
    );
  }

  deleteCompare(id: string) {
    if (confirm('Вы уверены, что хотите удлаить запись ?')) {
      this.api.userRegister('cmpDelete', localStorage.getItem('token'), { id }).subscribe(
        () => {
          this.getListHeader();
        },
        (error: HttpErrorResponse) => {
          alert(error);
        },
      );
    }
  }

  private getListHeader() {
    const body = {};
    this.api.userRegister('cmpList', localStorage.getItem('token'), body).subscribe(
      (data: ICpmList[]) => {
        this.list_cmp = data;
      },
      (error: HttpErrorResponse) => {
        alert(error);
      },
    );
  }

  private getList(id) {
    const body = { id };
    this.api.userRegister('cmpCardList', localStorage.getItem('token'), body).subscribe(
      (data: any) => {
        console.log('cmpCardList', data);
        if (+data.is_wait === 1) {
          this.wait_load = true;
        } else {
          this.wait_load = false;
          this.list_options = data.data.options;
          this.list_photo = data.data.photo;
          this.qty_sku = data.data.cnt_sku;
          this.list_main = data.data.main;
          this.list_user = data.data.user;
          this.list_price = data.data.price;
          this.list_stock = data.data.stock;
          this.list_sales_days30 = data.data.sales.sales_day30;
        }
        this.chartOptions = {
          series: data.data.sales.charts.grid,
          chart: { height: 350, type: 'area' },
          title: { text: 'График продаж за 30 дней' },
          xaxis: { categories: data.data.sales.charts.cat.category },
        };
      },
      (error: HttpErrorResponse) => {
        alert(error);
      },
    );
  }
}
