import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { LOCALE_RU_FILTERS } from '../../../finances/finoperations/finoperations.mock';
import { IFinPurchaseBatchInterface } from './fin-purchase-batch.interface';
import { ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AgGridAngular } from 'ag-grid-angular';
import { FIN_PURCHASE_BATCH_COLUMN_DEFS } from './fin-purchase-batch.mock';
import { MpSurfApiService } from '../../../../shared/services/mpsurf-api.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-fin-purchase-batch',
  templateUrl: './fin-purchase-batch.component.html',
  styleUrls: ['./fin-purchase-batch.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinPurchaseBatchComponent implements OnInit {
  isLoading = false;
  rowData: IFinPurchaseBatchInterface[] = [];
  dataForColumnDefs: (ColDef | ColGroupDef)[] = FIN_PURCHASE_BATCH_COLUMN_DEFS;
  defaultColDef: ColDef = { sortable: true, resizable: true, filter: true };
  gridOptions: GridOptions = {};
  LOCALE_RU_FILTERS = LOCALE_RU_FILTERS;
  shopId: string;

  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;

  constructor(
    private cdr: ChangeDetectorRef,
    private mpSurfService: MpSurfApiService,
  ) {}

  ngOnInit() {
    this.shopId = JSON.parse(localStorage.getItem('mpall_shop')).shop;
  }

  onGridReady(params?: GridReadyEvent<any>) {
    this.loadFinPurchaseBatch();
  }

  private loadFinPurchaseBatch(): void {
    this.isLoading = true;
    this.getFinPurchaseRequest()
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe(
        (response: IFinPurchaseBatchInterface[]) => {
          this.rowData = response;
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        },
      );
  }

  private getFinPurchaseRequest(): Observable<IFinPurchaseBatchInterface[]> {
    return this.mpSurfService.load({}, 'purchases-parties');
  }
}
