import { ColDef, ColGroupDef } from 'ag-grid-community';
import { ValueGetterForDate } from '../../../../shared/utils/functions/value-getter-for-date';
import { ValueFormatterForDate } from '../../../../shared/utils/functions/value-formatter-for-date';

export const ADVERT_ANALYTIC_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Дата',
    field: 'advert_date',
    width: 150,
    filter: 'agDateColumnFilter',
    valueGetter: (params) => ValueGetterForDate(params, 'advert_date'),
    valueFormatter: (params) => ValueFormatterForDate(params),
    suppressSizeToFit: true,
    flex: 0,
  },
  {
    headerName: 'Начало',
    field: 'advert_start',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Окончание',
    field: 'advert_end',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Списано факт по финансам рекламы',
    field: 'advert_expense_fact_fin_advert',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Название рекламы',
    field: 'advert_name',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Название типа',
    field: 'advert_type_name',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'ID кампании',
    field: 'advert_company_id',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Артикул ВБ',
    field: 'advert_article_wb',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Apps',
    field: 'advert_apps',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'CR',
    field: 'advert_cr',
    width: 150,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'CPC',
    field: 'advert_cpc',
    width: 150,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'CTR',
    field: 'advert_ctr',
    width: 150,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Частота',
    field: 'advert_frequency',
    width: 150,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Рублей по РК списано',
    field: 'advert_rub_rk_expense',
    width: 150,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Просмотров',
    field: 'advert_views',
    width: 150,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Кликов',
    field: 'advert_clicks',
    width: 150,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Заказов',
    field: 'advert_orders',
    width: 150,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Уникальных просмотров',
    field: 'advert_unique_views',
    width: 150,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Категория',
    field: 'advert_category',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Категория узкая',
    field: 'advert_category_narrow',
    width: 150,
    filter: 'agTextColumnFilter',
  },
];
