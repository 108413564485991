import { ColDef, ColGroupDef } from 'ag-grid-community';
import { WBImgFormatter } from '../../../shared/utils/functions/wb-img-formatter';
import { CustomTooltipComponent } from '../../guides/products/components/prdcustomTooltip';

export const ORDERS_AGGREGATORS_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'RID',
    headerClass: 'header-centered',
    field: 'rid',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'SRID',
    headerClass: 'header-centered',
    field: 'rid',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Фото',
    headerClass: 'header-centered',
    cellRenderer: WBImgFormatter,
    field: 'photo_new',
    width: 150,
    filter: 'agTextColumnFilter',
    tooltipField: 'photo_new',
    tooltipComponentParams: {
      type: 'photo_new',
      photoField: 'photo_new',
    },
    tooltipComponent: CustomTooltipComponent,
  },
  {
    headerName: 'Название товара',
    headerClass: 'header-centered',
    field: 'good_name',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Категория товара',
    headerClass: 'header-centered',
    field: 'good_category',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Размер',
    headerClass: 'header-centered',
    field: 'size',
    width: 140,
    filter: 'agNumberColumnFilter',
  },

  {
    headerName: 'Артикул',
    headerClass: 'header-centered',
    field: 'article',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Статус заказа',
    headerClass: 'header-centered',
    field: 'good_status',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Дата заказа',
    headerClass: 'header-centered',
    field: 'order_date',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Цена заказа',
    headerClass: 'header-centered',
    field: 'order_price',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Склад',
    headerClass: 'header-centered',
    field: 'warehouse',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Регион доставки',
    headerClass: 'header-centered',
    field: 'delivery_region',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Дата прямой логистики',
    headerClass: 'header-centered',
    field: 'direct_logistic_date',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Цена прямой логистики',
    headerClass: 'header-centered',
    field: 'delivery_rub',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Дата продажи',
    headerClass: 'header-centered',
    field: 'sale_dt',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Цена продажи',
    headerClass: 'header-centered',
    field: 'sale_price',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Дата возврата',
    headerClass: 'header-centered',
    field: 'return_dt',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Дата возвратной логистики',
    headerClass: 'header-centered',
    field: 'return_delivery_dt',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Цена возвратной логистики',
    headerClass: 'header-centered',
    field: 'return_delivery_rub',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Цена возврата',
    headerClass: 'header-centered',
    field: 'return_for_pay',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Дата отмены',
    headerClass: 'header-centered',
    field: 'cancellation_date',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: '№ партии закупки',
    headerClass: 'header-centered',
    field: 'purchase_batch_num',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Самовыкуп',
    headerClass: 'header-centered',
    field: 'self_purchase',
    width: 140,
    filter: 'agNumberColumnFilter',
  },
];
export const ORDERS_AGGREGATOR_MOCK = [
  {
    rid: '1',
    srid: '1',
    photo_new: 'httpasd',
    good_name: 'Plavki',
    good_category: 'Plavki',
    size: 'Plavki',
    article: '82828323',
    good_status: 'Ready',
    order_date: '20.10.2023',
    order_price: '2000$',
    warehouse: 'Koledino',
    delivery_region: 'Moscow',
    direct_logistic_date: '24.10.2023',
    delivery_rub: '1200$',
    sale_dt: '21.10.2023',
    sale_price: '1888$',
    return_dt: '30.10.2023',
    return_delivery_dt: '02.11.2023',
    return_delivery_rub: '500$',
    return_for_pay: '500$',
    cancellation_date: '04.11.2023',
    purchase_batch_num: '9912312',
    self_purchase: '10',
  },
];
