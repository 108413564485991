export const PopUpMessages = {
  errorFailed: 'Не удалось загрузить данные!',
  errorFailedCreate: 'Не удалось создать!',
  errorFailedDelete: 'Не удалось удалить!',
  // NEW
  loadFailedSummary: 'Ошибка загрузки!',
  loadFailedMessages: 'Не удалось загрузить данные. Повторите попытку!',

  createSuccessSummary: 'Создано!',
  createSuccessMessage: 'Запись добавлена в базу',
  createFailedSummary: 'Ошибка создания',
  createFailedMessage: 'Не удалось создать. Проверьте данные и повторите попытку!',

  updateSuccessSummary: 'Изменено!',
  updateFailedSummary: 'Ошибка изменения',
  updateFailedMessage: 'Не удалось изменить. Проверьте данные и повторите попытку!',

  deleteSuccessSummary: 'Удалено!',
  deleteSuccessMessage: 'Запись удалена',
  deleteFailedSummary: 'Ошибка удаления',
  deleteFailedMessage: 'Не удалось удалить, повторите попытку',
};
