import {ColDef, ColGroupDef} from "ag-grid-community";

export const FIN_PURCHASE_BATCH_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
    {
        headerName: 'id',
        headerClass: 'header-centered',
        field: 'id',
        floatingFilter: true,
        width: 100,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'},
    },
    {
        headerName: 'purchase_id',
        headerClass: 'header-centered',
        field: 'purchase_id',
        floatingFilter: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    },
    {
        headerName: 'detail_id',
        headerClass: 'header-centered',
        field: 'detail_id',
        floatingFilter: true,
        width: 200,
        filter: 'agTextColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    },
    {
        headerName: 'user_id',
        headerClass: 'header-centered',
        field: 'user_id',
        floatingFilter: true,
        width: 130,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    },
    {
        headerName: 'user_shop_id',
        headerClass: 'header-centered',
        field: 'user_shop_id',
        floatingFilter: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    },
    {
        headerName: 'shk_id',
        headerClass: 'header-centered',
        field: 'shk_id',
        floatingFilter: true,
        width: 180,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    },
    {
        headerName: 'rr_dt',
        headerClass: 'header-centered',
        field: 'rr_dt',
        floatingFilter: true,
        width: 180,
        filter: 'agStringColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    },
    {
        headerName: 'supplier_oper_name',
        headerClass: 'header-centered',
        field: 'supplier_oper_name',
        floatingFilter: true,
        width: 200,
        filter: 'agStringColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    },
    {
        headerName: 'nm_id',
        headerClass: 'header-centered',
        field: 'nm_id',
        floatingFilter: true,
        width: 120,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    },
    {
        headerName: 'barcode',
        headerClass: 'header-centered',
        field: 'barcode',
        floatingFilter: true,
        width: 180,
        filter: 'agStringColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    },
    {
        headerName: 'party_num',
        headerClass: 'header-centered',
        field: 'party_num',
        floatingFilter: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    },
    {
        headerName: 'created_at',
        headerClass: 'header-centered',
        field: 'created_at',
        floatingFilter: true,
        width: 200,
        filter: 'agStringColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
    }
];
