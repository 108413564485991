<p-progressSpinner *ngIf="isLoading"
                   [style]="{position: 'absolute', top: '40%', left: '50%'}">
</p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="col-sm-12 col-md-12">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Идет Beta тестирование</h5>
    </div>
    <br/>
  </div>
  <div class="app-page-title mb-0">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-share icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          {{showWithAdvert ? 'Ключевые запросы органические без учета рекламы' : 'Ключевые запросы вместе с рекламными ставками'}}
        </div>
      </div>
      <div class="d-flex flex-wrap gap-2">
        <p-button (click)="manageAdvertQuery()"
                  [label]="showWithAdvert ? 'Показать без рекламы' : 'Показать с рекламой'"
                  styleClass="p-button-warning">
        </p-button>

        <p-button (click)="openCreateModal(keyQueryModal)"
                  label="Создать ключевой запрос"
                  styleClass="p-button-success">
        </p-button>
      </div>
    </div>
  </div>
  <div class="main-card mb-3 card" style="height: calc(82vh - 153px)">
    <div style="width: 100%;height: 80vh;">
      <ag-grid-angular #agGrid
                       (gridReady)="onGridReady($event)"
                       [columnDefs]="colDefs"
                       [defaultColDef]="defaultColDef"
                       [enableRangeSelection]="true"
                       [gridOptions]="gridOptions"
                       [localeText]="localeTextForFilters"
                       [pagination]="true"
                       [rowData]="rowData"
                       [rowHeight]="40"
                       [tooltipHideDelay]="tooltipHideDelay"
                       [tooltipShowDelay]="tooltipShowDelay"
                       class="ag-theme-alpine"
                       headerHeight="28"
                       style="width: 100%; height: 100%;">
      </ag-grid-angular>
    </div>
  </div>
</div>

<ng-template #keyQueryModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title">{{isEdit ? 'Изменение' : 'Создание'}} ключевого запроса</h3>
    <p (click)="d('Cross click')" class="m-0">
      <i aria-hidden="true" class="fa fa-times" style="font-size: 24px;cursor: pointer;"></i>
    </p>
  </div>
  <div class="modal-body">
    <form [formGroup]="keyQueryForm">
      <p-toast></p-toast>
      <div class="card-row">
        <h5>Введите артикул</h5>
        <input [maxlength]="50" [readOnly]="isEdit"
               [style]="{width: '27em'}"
               formControlName="nmid"
               inputmode="numeric"
               pInputText
               pattern="[0-9]*" type="text"/>

      </div>
      <div class="card-row">
        <h5>Введите список ключевых запросов (через запятую)</h5>
        <textarea [style]="{width: '27em'}"
                  cols="30"
                  formControlName="query_list"
                  maxlength="1500"
                  pInputTextarea
                  rows="5">
        </textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <p-button (click)="d('Cross click')" label="Отмена"
              styleClass="p-button-outlined p-button-secondary">
    </p-button>

    <button
      (click)="createUpdateKeyQuery(null)"
      *ngIf="!isEdit; else editSaveSubmit"
      [disabled]="keyQueryForm.invalid"
      class="p-button-raised p-button-success"
      label="Создать"
      pButton
      type="button">
    </button>
    <ng-template #editSaveSubmit>
      <button
        (click)="createUpdateKeyQuery(queryId)"
        [disabled]="keyQueryForm.invalid"
        class="p-button-raised p-button-success"
        label="Изменить"
        pButton
        type="button">
      </button>
    </ng-template>
  </div>
</ng-template>
