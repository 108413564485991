export function CustomCurrencyConverterRUB(value: string): string {
  return value
    ? value.toString() !== '-1'
      ? value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          .replace(/\./g, ',') + ' ₽'
      : '—'
    : value;
}
