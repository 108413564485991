import { ColDef, ColGroupDef } from 'ag-grid-community';
import { BtnFinaccountsCellRendererComponent } from './components/btn-finaccounts-cell-renderer.component';
import { FinPartnersSpecificCellsComponent } from '../finpartners/cell-component/finpartners-specific-cells.component';
import { CommonValueWithIDName } from '../../../../shared/interfaces/common.interface';
import { FinAccountsSpecificCellsComponent } from './components/fin-accounts-specific-cells.component';
import { ValueGetterForDate } from '../../../../shared/utils/functions/value-getter-for-date';
import { ValueFormatterForDate } from '../../../../shared/utils/functions/value-formatter-for-date';

export const FIN_ACCOUNT_TYPES = [
  { id: 1, name: 'Наличный' },
  { id: 2, name: 'Безналичный' },
  { id: 3, name: 'Карта физ лица' },
  { id: 4, name: 'Дебет' },
  { id: 5, name: 'Кредит / Займ' },
  { id: 6, name: 'Кредитная линия с лимитом' },
];

export const ACCOUNT_TYPES: CommonValueWithIDName[] = [
  // {id: 1, name: 'Наличный'},
  // {id: 2, name: 'Безналичный'},
  // {id: 3, name: 'Карта физ лица'},
  { id: 4, name: 'Дебет' },
  { id: 5, name: 'Кредит / Займ' },
  { id: 6, name: 'Кредитная линия с лимитом' },
];

export const FINACCOUNTS_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Юр. лицо',
    field: 'shop_name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Название',
    field: 'name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Тип счета',
    field: 'type',
    width: 200,
    filter: 'agTextColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Дата начального остатка',
    field: 'date_balance_init',
    width: 200,
    filter: 'agDateColumnFilter',
    valueGetter: (params) => ValueGetterForDate(params, 'date_balance_init'),
    valueFormatter: (params) => ValueFormatterForDate(params),
  },
  {
    headerName: 'Начальный остаток',
    field: 'balance_init',
    width: 200,
    filter: 'agTextColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Текущий остаток',
    field: 'current_sum',
    width: 200,
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Доступные средства',
    field: 'current_sum',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Кредитный лимит',
    field: 'credit_line',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Доступно кредитных денег по лимиту',
    field: 'available_money_by_credit_line',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },

  {
    headerName: 'Основной долг на дату',
    field: 'loan_balance',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Счет скрыт',
    field: 'is_visible',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: FinAccountsSpecificCellsComponent,
  },
  {
    headerName: 'Основной счет',
    field: 'is_main_account',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: FinAccountsSpecificCellsComponent,
  },
  {
    headerName: 'Описание',
    field: 'description',
    width: 200,
    filter: 'agStringColumnFilter',
    cellStyle: { display: 'block', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
  },
  {
    headerName: 'Действия',
    cellRenderer: BtnFinaccountsCellRendererComponent,
    autoHeaderHeight: true,
    floatingFilter: false,
    icons: {
      sortAscending: '<i class="fa fa-sort-alpha-up"/>',
      sortDescending: '<i class="fa fa-sort-alpha-down"/>',
    },
    filter: false,
    width: 180,
  },
];
