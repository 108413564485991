<p-progressSpinner *ngIf="isLoadingStatus"
                   [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoadingStatus ? 'none' : 'block'}">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="block-order-ftype">
          <div class="page-title-icon">
            <i class="pe-7s-cart icon-gradient bg-mean-fruit"></i>
          </div>
          <div class="page-title-subheading">
            <h4 style="color: #000">
              Закупка
            </h4>
            <a href="../../../../assets/images/download/purchase_template.xlsx">Скачать</a> шаблон для
            загрузки.
          </div>
          <div class="btn-actions-pane-left">
            <input (change)="filterChanged('Date')"
                   [(ngModel)]="selectedDate"
                   [alwaysShowCalendars]="true"
                   [isInvalidDate]="DatePickerCommon.isInvalidDate"
                   [linkedCalendars]="true"
                   [locale]="DatePickerCommon.localParamsRU"
                   [maxDate]='DatePickerCommon.maxDate'
                   [minDate]='DatePickerCommon.minDate'
                   [ranges]="ranges"
                   [showClearButton]="false"
                   [showCustomRangeLabel]="true"
                   ngxDaterangepickerMd
                   placeholder="Выберите даты"
                   type="text"/>
          </div>
        </div>
        <div class="block-order-ftype">
          <p-multiSelect (onChange)="filterChanged()"
                         (onClear)="selectedBarcodesNmClear()"
                         [(ngModel)]="selectedArticle"
                         [maxSelectedLabels]="1"
                         [options]="articlesList"
                         [showClear]="true"
                         class="header-toolbar-properties"
                         defaultLabel="Выберите артикул"
                         emptyMessage="Артикулов не найдено"
                         itemSize="30"
                         optionLabel="label"
                         optionValue="SKU"
                         selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
          <button class="btn btn-success add-operation-btn" style="background: #4c95d2; border: none;"
                  type="button">
            <a href="../../../../assets/images/download/purchase_template.xlsx" style="color: #FFF">
              Скачать шаблон
            </a>
          </button>
        </div>
        <div class="block-order-ftype ftype-rigth">
          <p-fileUpload #uploadedFiles
                        (onError)="onBasicUploadError($event,uploadedFiles)"
                        (onSend)="onSendData()"
                        (onUpload)="onBasicUploadAuto($event,uploadedFiles)"
                        [auto]="true"
                        [maxFileSize]="10000000"
                        [style]="{ height: '37px', fontSize: '12px'}" accept=".xls, .xlsx, .csv"
                        chooseLabel="Загрузить из excel"
                        class="mr-4 inline-block"
                        label="Import"
                        mode="basic"
                        name="file"
                        url="{{URL_NEW}}purchases/uploaded?token={{TOKEN}}&shop_id={{SHOP_ID}}"
          ></p-fileUpload>
          <button (click)="exportCollationExcel()"
                  class="p-button-success mr-2"
                  icon="pi pi-file-excel"
                  label="Сверка закупки" pButton
                  pRipple
                  pTooltip="Выгрузить в эксель"
                  tooltipPosition="bottom"
                  type="button">
          </button>
          <button (click)="openModalCreatePurchase(changeSupplier)"
                  class="button-add-purchase btn-shadow d-inline-flex align-items-center btn btn-success"
                  style="height: 37px;"
                  type="button">
            Добавить закупку
          </button>
        </div>
        <div class="block-order-ftype ftype-rigth">
          <button (click)="clearSessionStorage()"
                  [disabled]="!isSessionStorageHasFilters"
                  class="button-add-purchase btn-shadow d-inline-flex align-items-center btn btn-secondary"
                  style="height: 37px;"
                  type="button">
            Удалить сохраненные фильтры
          </button>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div style="width: 100%;height: calc(92vh - 128px)">
        <ag-grid-angular
          (gridReady)="onGridReady($event)"
          [columnDefs]="dataForColumnDefs"
          [defaultColDef]="defaultColDef"
          [enableRangeSelection]="true"
          [gridOptions]="gridOptions"
          [pagination]="true"
          [rowData]="rowData"
          [rowHeight]="40"
          [tooltipHideDelay]="tooltipHideDelay"
          [tooltipShowDelay]="tooltipShowDelay"
          class="ag-theme-alpine"
          style="width: 100%; height: 100%;">
        </ag-grid-angular>
        <button (click)="exportExcel()"
                class="mb-2 btn btn-success add-operation-btn button-export-fin"
                type="button">
          Экспорт в Excel
        </button>
        <p-toast></p-toast>
      </div>
    </div>
    <ng-template #purchaseModal let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title">
          {{!isEditModal ? 'Добавление' : 'Изменение'}} закупки
        </h4>
        <p (click)="d('Cross click')" class="m-0">
          <i aria-hidden="true" class="fa fa-times" style="font-size: 24px;cursor: pointer;"></i>
        </p>
      </div>
      <form (validSubmit)="createUpdatePurchase()"
            *ngIf="!chsuccess"
            [formGroup]="this.FormPurchase"
            class="needs-validation"
            novalidate>
        <div class="modal-body">
          <h3 class="title-modal-text">Реестр закупки</h3>
          <div class="modal-input-fields position-relative row form-group">
            <label class="col-sm-3 col-form-label" for="article">
              Артикул WB
            </label>
            <div class="col-sm-9 input-field-row">
              <p-dropdown [filterBy]="'name'"
                          [filter]="true"
                          [options]="articlesList"
                          class="propdown-dat me-2"
                          emptyMessage="Артикулов не найдено"
                          formControlName="nmidGroup"
                          id="article"
                          itemSize="30"
                          optionLabel="Barcode"
                          placeholder="Выберите артикул">
                <ng-template let-option pTemplate="item">
                  <span>{{option.Barcode}} - {{option.SKU}}</span>
                </ng-template>
              </p-dropdown>
              <button (click)="addNewArticle()"
                      class="p-button-success mr-2 button-excel button-add-supplier"
                      icon="pi pi-plus" pButton pRipple
                      pTooltip="Добавить артикул" tooltipPosition="bottom"
                      type="button">
              </button>
            </div>
          </div>

          <div class="modal-input-fields position-relative row form-group" style="margin-bottom: 10px">
            <label class="col-sm-3 col-form-label" for="purchase_date">
              Дата закупки
            </label>
            <div class="col-sm-9 input-field-row">
              <input #d1="ngbDatepicker"
                     (click)="d1.toggle()"
                     [(ngModel)]="dateOfPurchase"
                     autocomplete="off"
                     class="form-control"
                     formControlName="date_purchase"
                     id="purchase_date"
                     name="dp1"
                     ngbDatepicker placeholder="dd.mm.yyyy">
            </div>
          </div>
          <div class="modal-input-fields position-relative row form-group">
            <label class="col-sm-3 col-form-label" for="supplier">
              Поставщик
            </label>
            <div class="col-sm-9 input-field-row">
              <p-dropdown
                [(ngModel)]="selectedSupplier"
                [filter]="true"
                [options]="suppliersList"
                [placeholder]="selectedSupplier !== null
                ? selectedSupplier.shop_name
                : 'Поставщик'"
                [showClear]="true"
                class="propdown-data me-2"
                emptyMessage="Артикулов не найдено"
                filterBy="name"
                formControlName="supplier_id" id="supplier"
                itemSize="30"
                optionLabel="name">
              </p-dropdown>
              <p-toast></p-toast>
              <button (click)="openNewSupplierModal(newSupplier)"
                      class="p-button-success mr-2 button-excel button-add-supplier"
                      icon="pi pi-plus" pButton pRipple
                      pTooltip="Добавить поставщика" tooltipPosition="bottom"
                      type="button"></button>
            </div>
          </div>
          <div class="modal-input-fields position-relative row form-group" style="margin-bottom: 10px">
            <label class="col-sm-3 col-form-label" for="amount">
              Количество
            </label>
            <div class="col-sm-9 input-field-row">
              <input class="form-control" formControlName="qty"
                     id="amount" maxLength="10"
                     name="amount" type="number">
            </div>
          </div>
          <div class="modal-input-fields position-relative row form-group" style="margin-bottom: 10px">
            <label class="col-sm-3 col-form-label" for="price_batch">
              Стоимость партии закупки
            </label>
            <div class="col-sm-9 input-field-row">
              <input (change)="countAmountOfBanch($event)" class="form-control"
                     formControlName="batch_price"
                     id="price_batch" maxLength="10"
                     name="amount" type="number">
            </div>
          </div>
          <div class="modal-input-fields position-relative row form-group" style="margin-bottom: 10px">
            <label class="col-sm-3 col-form-label" for="cost_purchase">
              Стоимость единицы закупки
            </label>
            <div class="col-sm-9 input-field-row">
              <input (change)="countAmountOfCost($event)" class="form-control"
                     formControlName="batch_price_one"
                     id="cost_purchase" maxLength="10"
                     name="amount" type="number">
            </div>
          </div>
          <h3 class="title-modal-text">Фиксированные расходы на штуку</h3>
          <div class="modal-input-fields position-relative row form-group" style="margin-bottom: 10px">
            <label class="col-sm-3 col-form-label col-label-max"
                   for="unit_delivery_from_supplier_to_FF_warehouse">
              Доставка на единицу от поставщика до ФФ/склада
            </label>
            <input (change)="countAllExpenses()" class="form-control"
                   formControlName="delivery_per_unit_supplier_ff_wh"
                   id="unit_delivery_from_supplier_to_FF_warehouse"
                   maxLength="10"
                   name="amount" type="number">
          </div>
          <div class="modal-input-fields position-relative row form-group" style="margin-bottom: 10px">
            <label class="col-sm-3 col-form-label col-label-max" for="cost_of_marking_packing_per_one">
              Стоимость маркировки и упаковки на 1 шт (фф)
            </label>
            <input (change)="countAllExpenses()" class="form-control"
                   formControlName="pack_label_per_unit" id="cost_of_marking_packing_per_one" maxLength="10"
                   name="amount" type="number">
          </div>
          <div class="modal-input-fields position-relative row form-group" style="margin-bottom: 10px">
            <label class="col-sm-3 col-form-label col-label-max" for="packing_material_rub">
              Упаковочный материал доп., руб.
            </label>
            <input (change)="countAllExpenses()" class="form-control"
                   formControlName="pack_material_additionally" id="packing_material_rub" maxLength="10"
                   name="amount" type="number">
          </div>
          <div class="modal-input-fields position-relative row form-group" style="margin-bottom: 10px">
            <label class="col-sm-3 col-form-label col-label-max" for="shipment_delivery_to_WB_with_ff">
              Отгрузка и доставка на ВБ с фф
            </label>
            <input (change)="countAllExpenses()" class="form-control"
                   formControlName="delivery_wh_ff" id="shipment_delivery_to_WB_with_ff" maxLength="10"
                   name="amount" type="number">
          </div>
          <div class="modal-input-fields position-relative row form-group" style="margin-bottom: 10px">
            <label class="col-sm-3 col-form-label col-label-max" for="fixed_expenses_per_one">
              Фикс расходы на ед.
            </label>
            <input class="form-control" formControlName="fix_price_per_unit"
                   id="fixed_expenses_per_one" maxLength="10"
                   name="amount" type="number">
          </div>
          <div class="modal-input-fields position-relative row form-group" style="margin-bottom: 10px">
            <label class="col-sm-3 col-form-label" for="amount">
              Комментарий
            </label>
            <div class="col-sm-9 input-field-row">
              <textarea [pTooltip]="tooltipMessage" cols="30"
                        formControlName="nmid_personal" id="comment_field"
                        pInputTextarea
                        rows="15">
              </textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <p-toast></p-toast>
          <button [disabled]="FormPurchase.invalid || FormPurchase.disabled"
                  class="btn btn-primary"
                  type="submit">
            {{isEditModal ? 'Изменить' : 'Создать'}}
          </button>
        </div>
      </form>
    </ng-template>
    <ng-template #newSupplier let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title">Добавить контрагента</h4>
        <p (click)="d('Cross click')" class="m-0">
          <i aria-hidden="true" class="fa fa-times" style="font-size: 24px;cursor: pointer;"></i>
        </p>
      </div>
      <form (validSubmit)="createNewSupplier()"
            *ngIf="!chsuccess"
            [formGroup]="FormContrAgent"
            class="needs-validation"
            novalidate>
        <div class="modal-body">
          <div class="position-relative row form-group">
            <label class="col-sm-3 col-form-label" for="shop_lable">Магазин</label>
            <div class="col-sm-9">
              <select class="form-control" formControlName="shop" id="shop_lable" name="shop">
                <option *ngFor="let pf of shop_list" [ngValue]="pf.id">{{ pf.name }}</option>
              </select>
            </div>
            <div *ngIf="this.FormContrAgent.controls['shop'].invalid && this.FormContrAgent.controls['shop'].touched"
                 class="alert alert-danger">Не корректно
              заполнено поле
            </div>
          </div>
          <div class="position-relative row form-group">
            <label class="col-sm-3 col-form-label" for="name_label">Наименование<span
              style="color:red">*</span></label>
            <div class="col-sm-9">
              <input class="form-control" formControlName="name" id="name_label" name="name"
                     placeholder="введите наименование" type="text">
              <div *ngIf="this.FormContrAgent.controls['name'].invalid && this.FormContrAgent.controls['name'].touched"
                   class="alert alert-danger">Не
                корректно заполнено поле
              </div>
            </div>

          </div>

          <div class="position-relative row form-group">
            <label class="col-sm-3 col-form-label" for="inn_label">ИНН</label>
            <div class="col-sm-9"><input class="form-control" formControlName="inn" id="inn_label"
                                         name="inn"
                                         placeholder="ИНН" type="text">
              <div *ngIf="this.FormContrAgent.controls['inn'].invalid && this.FormContrAgent.controls['inn'].touched"
                   class="alert alert-danger">Введите
                только цифры
              </div>
            </div>
          </div>

          <div class="position-relative row form-group">
            <label class="col-sm-3 col-form-label" for="kpp_lable">КПП</label>
            <div class="col-sm-9"><input class="form-control" formControlName="kpp" id="kpp_lable"
                                         name="kpp"
                                         placeholder="КПП" type="text">
              <div *ngIf="this.FormContrAgent.controls['kpp'].invalid && this.FormContrAgent.controls['kpp'].touched"
                   class="alert alert-danger">Введите
                только цифры
              </div>
            </div>
          </div>

          <div class="position-relative row form-group">
            <label class="col-sm-3 col-form-label" for="checking_account_lable">Р/С</label>
            <div class="col-sm-9"><input class="form-control" formControlName="checking_account"
                                         id="checking_account_lable" name="checking_account"
                                         placeholder="Расчетный счет"
                                         type="text">
              <div
                *ngIf="this.FormContrAgent.controls['checking_account'].invalid && this.FormContrAgent.controls['checking_account'].touched"
                class="alert alert-danger">
                Не корректно заполнено поле
              </div>
            </div>
          </div>
          <div class="position-relative row form-group">
            <label class="col-sm-3 col-form-label" for="description_l">Описание</label>
            <div class="col-sm-9"><textarea class="form-control" formControlName="description"
                                            id="description_l"
                                            name="description"></textarea>
              <div
                *ngIf="this.FormContrAgent.controls['description'].invalid && this.FormContrAgent.controls['description'].touched"
                class="alert alert-danger">
                Не корректно заполнено поле
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <p-toast></p-toast>
          <button (click)="c('Close click')" class="btn btn-secondary" type="button">
            Закрыть
          </button>
          <button [disabled]="FormContrAgent.invalid || FormContrAgent.disabled"
                  class="btn btn-primary"
                  type="submit">
            Добавить
          </button>
        </div>
      </form>
    </ng-template>
    <ng-template #changeSupplier let-c="close" let-d="dismiss">
      <div class="modal-header header-btn">
        <p (click)="d('Cross click')" class="m-0">
          <i aria-hidden="true" class="fa fa-times" style="font-size: 24px;cursor: pointer;"></i>
        </p>
      </div>
      <div class="modal-body">
        <div class="notification-part">
          <h4 class="modal-title">
            Выберите поставщика!</h4>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="d('Cross click')"
                class="btn btn-primary"
                type="submit">
          Закрыть
        </button>
      </div>
    </ng-template>
  </div>
</div>
<p-confirmDialog [baseZIndex]="10000" [style]="{width: '30vw'}"></p-confirmDialog>
