import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { FinoperationsComponent } from './finoperations/finoperations.component';
import { FinoperationsExcelComponent } from './finoperations/components/finoperations-excel/finoperations-excel.component';
import { BtnCellRendererFinoperationComponent } from './finoperations/components/aggrid-components/btn-cell-renderer-finoperation.component';
import { FinOperationActionComponent } from './finoperations/components/aggrid-components/fin-operation-action.component';
import { FinoperationExcelRenderCellsComponent } from './finoperations/components/aggrid-components/finoperation-excel-render-cells.component';
import { FinoperationSpecificCellsComponent } from './finoperations/components/aggrid-components/finoperation-specific-cells.component';
import { FinoperationSpecificRenderCellsComponent } from './finoperations/components/aggrid-components/finoperation-specific-render-cells.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { FileUploadModule } from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import { FinaccountsComponent } from './settings/finaccounts/finaccounts.component';
import { FinAccountsSpecificCellsComponent } from './settings/finaccounts/components/fin-accounts-specific-cells.component';
import { BtnFinaccountsCellRendererComponent } from './settings/finaccounts/components/btn-finaccounts-cell-renderer.component';
import { FinitemsComponent } from './settings/finitems/finitems.component';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TreeModule } from 'primeng/tree';
import { BtnFinpartnersCellRendererComponent } from './settings/finpartners/cell-component/btn-finpartners-cell-renderer.component';
import { FinpartnersComponent } from './settings/finpartners/finpartners.component';
import { DropdownModule } from 'primeng/dropdown';
import { FinddsComponent } from './reports/findds/findds.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeTableModule } from 'primeng/treetable';
import { FinpnlComponent } from './reports/finpnl/finpnl.component';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { InputNumberModule } from 'primeng/inputnumber';
import { ModalModule } from '../../shared/common-components/modal/modal.module';
import { ReconciliationWbComponent } from './reports/reconciliation-wb/reconciliation-wb.component';

const routes: Routes = [
  {
    path: 'operations',
    component: FinoperationsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'reports/dds',
    component: FinddsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'reports/pnl',
    component: FinpnlComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'reports/reconciliation',
    component: ReconciliationWbComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'settings/items',
    component: FinitemsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'settings/partners',
    component: FinpartnersComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'settings/accounts',
    component: FinaccountsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    FinoperationsComponent,
    FinoperationsExcelComponent,
    FinaccountsComponent,
    FinitemsComponent,
    FinpartnersComponent,
    FinddsComponent,
    FinpnlComponent,

    BtnCellRendererFinoperationComponent,
    FinOperationActionComponent,
    FinoperationExcelRenderCellsComponent,
    FinoperationSpecificCellsComponent,
    FinoperationSpecificRenderCellsComponent,
    FinAccountsSpecificCellsComponent,
    BtnFinaccountsCellRendererComponent,
    BtnFinpartnersCellRendererComponent,
    ReconciliationWbComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,

    AgGridModule,

    ProgressSpinnerModule,
    ToastModule,
    FileUploadModule,
    TooltipModule,
    NgBootstrapFormValidationModule,
    NgSelectModule,
    ContextMenuModule,
    ConfirmDialogModule,
    TreeModule,
    DropdownModule,
    SelectButtonModule,
    NgxDaterangepickerMd,
    MultiSelectModule,
    TreeTableModule,
    NgbDropdownModule,
    InputNumberModule,
    ModalModule,
    NgbDatepickerModule,
  ],
  exports: [
    FinoperationsExcelComponent,
    BtnCellRendererFinoperationComponent,
    FinOperationActionComponent,
    FinoperationExcelRenderCellsComponent,
    FinoperationSpecificCellsComponent,
    FinoperationSpecificRenderCellsComponent,
    FinAccountsSpecificCellsComponent,
    BtnFinaccountsCellRendererComponent,
    BtnFinpartnersCellRendererComponent,
  ],
})
export class FinancesModule {}
