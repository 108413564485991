import { Component } from '@angular/core';
import { LOCALE_RU_FILTERS } from '../../../finances/finoperations/finoperations.mock';
import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { ADVERT_ANALYTIC_COLUMN_DEFS } from './advert-analytic.mock';
import { DragStoppedEvent, GridOptions } from 'ag-grid';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../shared/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IAdvertAnalyticResponse } from './advert-analytic.interface';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-advert-analytic',
  templateUrl: './advert-analytic.component.html',
  styleUrls: ['./advert-analytic.component.sass'],
})
export class AdvertAnalyticComponent {
  readonly dataForColumnDefs: (ColDef | ColGroupDef)[] = [];
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };
  readonly gridOptions: GridOptions = {};
  readonly localRuFilters = LOCALE_RU_FILTERS;
  isLoadingStatus = false;
  rowData: any[] = [];
  private readonly SHOP_ID: number;
  private gridApi: GridApi;

  constructor(private api: ApiService) {
    this.gridOptions = {
      context: { componentParent: this },
      onDragStopped: (event: DragStoppedEvent) => this.onColumnMoved(event),
      suppressRowClickSelection: true,
      enableCellTextSelection: true,
    } as GridOptions;
    this.dataForColumnDefs = ADVERT_ANALYTIC_COLUMN_DEFS;
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop') || '{}').shop;
  }

  onGridReady(params?: GridReadyEvent<any>) {
    this.gridApi = params.api;
    const columnState = localStorage.getItem('advertAnalyticColumnState');
    if (columnState) {
      const columnStateJSON = JSON.parse(columnState);
      params.columnApi.applyColumnState({ state: columnStateJSON, applyOrder: true });
    }
    this.loadAdvertAnalytic();
  }

  private onColumnMoved(params: DragStoppedEvent) {
    localStorage.setItem('advertAnalyticColumnState', JSON.stringify(params.columnApi.getColumnState()));
  }

  private loadAdvertAnalytic(): void {
    this.isLoadingStatus = true;
    const bodyRequest = { shop_id: this.SHOP_ID };
    this.requestLoadAll(bodyRequest).subscribe(
      (response) => {
        this.rowData = response.data;
        this.isLoadingStatus = false;
      },
      (error: HttpErrorResponse) => {
        this.isLoadingStatus = false;
        alert(error.message);
      },
    );
  }

  private requestLoadAll(params: any): Observable<IAdvertAnalyticResponse> {
    return this.api.postWithLoadOrUpdate<IAdvertAnalyticResponse>(
      'getAdvAnalitic',
      localStorage.getItem('token'),
      params,
      'adv',
    );
  }
}
