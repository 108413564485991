import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  EXCEL_SUPPLIER_TYPES,
  MSKList,
  SUPPLIER_MANAGER_COLUMN_DEFS,
  SUPPLIER_MANAGER_WAREHOUSE_COLUMN_DEFS,
} from './supplier-manager.mock';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FIRST_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { DatePickerCommon } from '../../../shared/common-variables/date-picker-common';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ICellChangedRequest,
  IExcelSupplierTypes,
  IManageType,
  ISuppliersList,
  ISuppliersRequest,
  ISuppliersWareHouses,
} from './supplier-manager.interface';
import { LOCALE_TEXT_FOR_FILTERS } from '../../guides/products/products.mock';
import moment from 'moment';
import { CustomTooltipComponent } from '../../guides/products/components/prdcustomTooltip';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { MpSurfApiService } from '../../../shared/services/mpsurf-api.service';
import { CommonResponseGeneral, CommonResponseOperation1 } from '../../../shared/interfaces/common.interface';
import { FILTER_PARAMS_FIELDS } from '../../../shared/utils/constants/filter-params-controller';
import { FilterParamsController } from '../../../shared/utils/controllers/filter-params.controller';
import { ParserDateForSingleNgbDatepicker } from '../../../shared/utils/functions/parser-date-for-single-ngb-datepicker';
import { environment } from '../../../../environments/environment';
import { isNumber } from '../../../shared/adapters/date.adapter';

@Component({
  selector: 'app-supplier-manager',
  templateUrl: './supplier-manager.component.html',
  styleUrls: ['./supplier-manager.component.sass'],
  providers: [MessageService],
})
export class SupplierManagerComponent implements OnInit, AfterViewInit, OnDestroy {
  // AG-GRID
  supplierMangerColDEFS = [...SUPPLIER_MANAGER_COLUMN_DEFS];
  rowData: ISuppliersList[] = [];
  readonly defaultColDef: ColDef = {
    sortable: true,
    headerClass: 'header-centered',
    resizable: true,
    filter: true,
    floatingFilter: true,
    cellStyle: { textAlign: 'center' },
    tooltipComponent: CustomTooltipComponent,
  };
  readonly tooltipShowDelay = 0;
  readonly tooltipHideDelay = 2000;
  gridOptions: GridOptions;
  localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  isLoading = false;
  // AG-GRID
  showSetupFilters = false;
  supplierSettingForm: FormGroup;
  DatePickerParameters: DatePickerCommon;
  ranges: any = FIRST_RANGE_TYPE;
  wareHousesList: ISuppliersWareHouses[] = [];
  modelDateIncome: any;
  unSubscribe$ = new Subject<void>();
  isMskWarehouseSelected = false;
  @ViewChild('supplierParameterModal') supplierParameterModal: any;
  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;

  EXCEL_SUPPLIER_TYPES: IExcelSupplierTypes[] = EXCEL_SUPPLIER_TYPES;
  readonly URL_NEW: string;
  readonly TOKEN: string;
  private gridApi!: GridApi<any>;
  private readonly SHOP_ID: number;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private modalService: NgbModal,
    private mpSurfService: MpSurfApiService,
    private calendar: NgbCalendar,
  ) {
    this.DatePickerParameters = new DatePickerCommon();
    this.DatePickerParameters.selectedData.startDate = moment().subtract(14, 'days');
    this.modelDateIncome = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
    this.supplierSettingForm = fb.group({
      date_income: new FormControl(this.modelDateIncome, Validators.required),
      orders_average_by_date: new FormControl(this.DatePickerParameters.selectedData, Validators.required),
      day_for_supply: new FormControl('14', Validators.required),
      ware_house_include: new FormControl(false),
      ware_houses: fb.array([]),
    });
    this.gridOptions = {
      columnDefs: [...SUPPLIER_MANAGER_COLUMN_DEFS],
      context: { componentParent: this },
      enableCellTextSelection: true,
    } as GridOptions;
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop')).shop;
    this.URL_NEW = environment.apiNew;
    this.TOKEN = localStorage.getItem('token');
  }

  get SupplierFormArray() {
    return this.supplierSettingForm.controls.ware_houses as FormArray;
  }

  get isWareHousesInclude(): boolean {
    return this.supplierSettingForm.controls.ware_house_include.value;
  }

  get selectedWareHousesList(): any[] {
    return this.supplierSettingForm.controls.ware_houses.value;
  }

  get checkUncheckSelectedTitle(): string {
    const selectedWH = this.removeUnusedWH(this.supplierSettingForm.controls.ware_houses.value);
    return this.SupplierFormArray.length === selectedWH.length ? 'Снять выделения' : 'Выделить все';
  }

  get isConfirmFilterInvalid(): boolean {
    if (this.isWareHousesInclude) {
      return this.removeUnusedWH(this.selectedWareHousesList).length === 0 || this.supplierSettingForm.invalid;
    }
    return this.supplierSettingForm.invalid;
  }

  // FILTERS
  get isSessionStorageHasFilters(): boolean {
    return !!sessionStorage.getItem(FILTER_PARAMS_FIELDS.supplierManagerFilterParams);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.agGrid) {
      this.gridApi = this.agGrid.api;
    }
  }

  ngOnDestroy() {
    this.gridOptions.columnDefs = SUPPLIER_MANAGER_WAREHOUSE_COLUMN_DEFS;
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.loadWareHousesList();
    this.gridApi = params.api;
  }

  filtersController(): void {
    const request = FilterParamsController<ISuppliersRequest>(
      FILTER_PARAMS_FIELDS.supplierManagerFilterParams,
      JSON.stringify(this.generateRequest()),
    );
    this.updateFiltersFromSessionStorage(request);
  }

  updateFiltersFromSessionStorage(requestFields: ISuppliersRequest): void {
    this.supplierSettingForm.controls['day_for_supply'].setValue(requestFields.day_for_supply);
    this.supplierSettingForm.controls['date_income'].setValue(
      ParserDateForSingleNgbDatepicker(requestFields, 'date_income'),
    );
    this.DatePickerParameters.selectedData.startDate = moment(requestFields.startDate);
    this.DatePickerParameters.selectedData.endDate = moment(requestFields.endDate);

    if (requestFields.ware_house_include) {
      this.supplierSettingForm.controls['ware_house_include'].setValue(requestFields.ware_house_include);
      this.SupplierFormArray.controls.length = 0;
      this.setActiveWareHousesToSupplierForm(requestFields.ware_houses);
    }
  }

  updateSessionStorageFromFilters(): void {
    const request = this.generateRequest();
    sessionStorage.setItem(FILTER_PARAMS_FIELDS.supplierManagerFilterParams, JSON.stringify(request));
  }

  clearSessionStorage(): void {
    sessionStorage.removeItem(FILTER_PARAMS_FIELDS.supplierManagerFilterParams);
  }

  refreshGrid() {
    if (this.gridApi) {
      this.gridApi.refreshCells();
    }
  }

  onCellValueChanged(event: any) {
    if (event && event.colDef) {
      const fieldParam = event.colDef.field;
      const status = this.manageValueChanged(fieldParam, event);
      if (status === 'reject') {
        this.showPopUpMessage('error', 'Ошибка введения данных', 'Проверьте введенные данные и повторите попытку!');
        this.applyFilters();
      } else {
        if (status === 'refresh') {
          this.refreshGrid();
        }
        if (fieldParam === 'in_order_delivery_type' || fieldParam === 'multiplicity') {
          const inOrderTypeValue = event.data.in_order_delivery_type ?? 1;
          const multiplicityTypeValue = event.data.multiplicity ?? 1;
          event.data.boxes_amount_delivery_type = Math.floor(+inOrderTypeValue / +multiplicityTypeValue);
        }
      }
    }
  }

  manageValueChanged(fieldParam: string, event: any): IManageType {
    const bodyRequest = {
      params: {},
      URL: null,
    } as ICellChangedRequest;
    let result = null as IManageType;
    const value = event.data[fieldParam];
    if (
      ['seasonality_coefficient', 'supply_way', 'balances_ff'].includes(fieldParam) &&
      (!isNumber(+value) || typeof +value !== 'number')
    ) {
      result = 'reject';
      return result;
    }
    switch (fieldParam) {
      case 'in_order_delivery_type':
      case 'multiplicity':
        const inOrderTypeValue = event.data.in_order_delivery_type ?? 1;
        const multiplicityTypeValue = event.data.multiplicity ?? 1;
        event.data.boxes_amount_delivery_type = Math.floor(+inOrderTypeValue / +multiplicityTypeValue);
        result = 'refresh';
        break;
      case 'balances_ff':
        bodyRequest.params.balances_ff = value;
        bodyRequest.params.barcode = event.data?.barcode;
        bodyRequest.URL = 'supplies/supply-param';
        this.saveChangesFromCellChanged(bodyRequest);
        break;
      case 'supply_way':
        bodyRequest.params.supply_way = value;
        bodyRequest.params.barcode = event.data?.barcode;
        bodyRequest.URL = 'supplies/supply-param';
        this.saveChangesFromCellChanged(bodyRequest);
        break;
      case 'seasonality_coefficient':
        bodyRequest.params.seasonality_coefficient = value;
        bodyRequest.params.category = event.data?.category;
        bodyRequest.params.subject = event.data?.subject;
        bodyRequest.URL = 'products/seasonality-coefficient';
        this.saveChangesFromCellChanged(bodyRequest);
        break;
    }
    return result;
  }

  saveChangesFromCellChanged(request: ICellChangedRequest): void {
    this.isLoading = true;
    this.mpSurfService
      .createOrUpdate(request.params, request.URL)
      .pipe(
        takeUntil(this.unSubscribe$),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (response: CommonResponseOperation1) => {
          if (!response.is_error) {
            this.showPopUpMessage('success', PopUpMessages.updateSuccessSummary, response.msg);
          } else {
            // this.loadProducts();
            this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
          this.applyFilters();
        },
      );
  }

  addColumn(wareHousesList: ISuppliersWareHouses[]) {
    wareHousesList.forEach((wh) => {
      const staticColumns = JSON.parse(JSON.stringify(SUPPLIER_MANAGER_WAREHOUSE_COLUMN_DEFS));
      const newColumns = staticColumns.map((columnDef) => {
        columnDef.headerName = wh.wb_wh_name;
        const updatedColumnDef = { ...columnDef };
        updatedColumnDef.children.forEach((colHeader) => {
          colHeader.children = this.setUpNewFieldForChildren(colHeader.children, wh.wb_wh_name_eng);
        });
        return updatedColumnDef;
      });
      this.gridOptions.columnDefs.push(...newColumns);
      this.gridOptions.api.setColumnDefs(this.gridOptions.columnDefs);
    });
  }

  setUpNewFieldForChildren(formatData: any[], newField: string) {
    formatData.forEach((childNode) => {
      if (childNode.field && childNode.field.startsWith('wh.')) {
        const previousData = childNode.field.split('.')[1];
        childNode.field = childNode.field.replace(previousData, newField);
      }
    });
    return formatData;
  }

  openModalWithFilters(modalContent: TemplateRef<any>) {
    this.modalService.open(modalContent, { backdrop: 'static' });
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  generateRequest(): ISuppliersRequest {
    const rawValue = this.supplierSettingForm.getRawValue();
    if (this.isWareHousesInclude) {
      rawValue.ware_houses = this.removeUnusedWH(rawValue.ware_houses);
      const wareHouseColumnList = this.wareHousesList.filter((wh) => rawValue.ware_houses.includes(wh.wb_wh_id));
      this.addColumn(wareHouseColumnList);
    } else {
      rawValue.ware_houses = [];
    }
    return {
      startDate: this.DatePickerParameters.selectedData?.startDate?.format('YYYY-MM-DD'),
      endDate: this.DatePickerParameters.selectedData?.endDate?.format('YYYY-MM-DD'),
      shop_id: this.SHOP_ID,
      ...rawValue,
    } as ISuppliersRequest;
  }

  applyFilters() {
    this.updateSessionStorageFromFilters();
    this.showSetupFilters = false;
    this.clearColumnDef();
    this.isLoading = true;
    this.modalService.dismissAll();
    const body = this.generateRequest();
    this.loadSuppliersList(body);
  }

  displayDataForInformation(controlName: string): string {
    let result = '';
    if (controlName) {
      const controlValue = this.supplierSettingForm.controls[controlName].value;
      switch (controlName) {
        case 'date_income':
          result = `${controlValue?.day?.toString().padStart(2, '0')}.${controlValue?.month
            ?.toString()
            .padStart(2, '0')}.${controlValue?.year?.toString().padStart(4, '20')}`;
          break;
        case 'orders_average_by_date':
          result = this.getDifferencesInDays(controlValue);
      }
    }
    return result;
  }

  // LOGIC
  selectMSKWH() {
    if (this.isMskWarehouseSelected) {
      this.SupplierFormArray.controls.length = 0;
      this.addWareHousesToTheForm();
      this.isMskWarehouseSelected = false;
    } else {
      this.isMskWarehouseSelected = true;
      this.SupplierFormArray.controls.length = 0;
      const availableMSKWH = this.wareHousesList.filter((x) => MSKList.includes(x.wb_wh_name));
      if (availableMSKWH.length > 0) {
        const mskIds = availableMSKWH.map((a) => a.wb_wh_id);
        this.setActiveWareHousesToSupplierForm(mskIds);
      }
    }
  }

  selectUnselectWh(checkStatus: string) {
    this.isMskWarehouseSelected = false;
    this.SupplierFormArray.controls.length = 0;
    if (checkStatus === 'Снять выделения') {
      this.addWareHousesToTheForm();
    } else {
      const wareHousesId = this.wareHousesList.map((a) => a.wb_wh_id);
      this.setterCheckedToWareHousesLise(wareHousesId);
    }
  }

  isControlValid(formControlName: string): boolean {
    return (
      this.supplierSettingForm.get(formControlName)?.invalid && this.supplierSettingForm.get(formControlName)?.touched
    );
  }

  onBasicUploadAuto(event: any, fileUpload) {
    this.showPopUpMessage('success', 'Загружено', 'Файл загружен. Данные обновлены');
    fileUpload.clear();
    this.applyFilters();
  }

  onBasicUploadError(event: any, fileUpload) {
    this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
    fileUpload.clear();
  }

  private loadSuppliersList(body: ISuppliersRequest) {
    this.isLoading = true;
    this.mpSurfService
      .loadByPost(body, 'supplies/calc')
      .pipe(
        takeUntil(this.unSubscribe$),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (response: CommonResponseGeneral<ISuppliersList[]>) => {
          this.rowData = response.data;
        },
        () => {
          this.modalService.open(this.supplierParameterModal);
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private loadWareHousesList() {
    this.isLoading = true;
    const bodyRequest = {};
    this.mpSurfService
      .load(bodyRequest, 'supplies/warehouse')
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(
        (response: ISuppliersWareHouses[]) => {
          this.wareHousesList = response;
          this.addWareHousesToTheForm();
          this.filtersController();
          this.applyFilters();
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private addWareHousesToTheForm() {
    for (const wareHouse of this.wareHousesList) {
      this.SupplierFormArray.push(new FormControl(''));
    }
  }

  private getDifferencesInDays(controlValue: any): string {
    const startDate: any = new Date(controlValue.startDate);
    const endDate: any = new Date(controlValue.endDate);
    const differenceInMilliseconds = endDate - startDate;
    return String(Math.ceil(differenceInMilliseconds / (24 * 60 * 60 * 1000)));
  }

  private clearColumnDef(): void {
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.columnDefs = this.gridOptions.columnDefs.splice(0, 6);
      this.gridOptions.api.setColumnDefs(this.gridOptions.columnDefs);
    }
  }

  private setActiveWareHousesToSupplierForm(wareHousesActiveId: number[]): void {
    const wareHousesId = this.wareHousesList.map((a) => a.wb_wh_id);
    wareHousesId.forEach((x) => {
      wareHousesActiveId.includes(x)
        ? this.SupplierFormArray.push(new FormControl([x]))
        : this.SupplierFormArray.push(new FormControl(''));
    });
  }

  private removeUnusedWH(whList: string[]): string[] {
    return whList
      .filter((x) => x)
      .filter((x) => Array.isArray(x) && x.length !== 0)
      .map((x) => x[0]);
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.isLoading = false;
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }

  private setterCheckedToWareHousesLise(listOfId: string[] | number[]): void {
    listOfId.forEach((x) => {
      this.SupplierFormArray.push(new FormControl([x]));
    });
  }
}
