import { ColDef, ColGroupDef } from 'ag-grid-community';
import { BtnFinpartnersCellRendererComponent } from './cell-component/btn-finpartners-cell-renderer.component';
import { FinPartnersSpecificCellsComponent } from './cell-component/finpartners-specific-cells.component';

export const FINPARTNERS_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    field: 'selection',
    headerName: '',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    filter: false,
    width: 50,
    checkboxSelection: true,
  },
  {
    headerName: 'Юр. лицо',
    field: 'shop_name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Наименование',
    field: 'name',
    width: 200,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Оборот',
    field: 'sum_partners',
    width: 200,
    filter: 'agTextColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Количество операций',
    field: 'count_operation',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'ИНН',
    field: 'INN',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'КПП',
    field: 'KPP',
    width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Доходная статья',
    field: 'income_item',
    width: 200,
    filter: 'agTextColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Расходная статья',
    field: 'expenses_item',
    width: 200,
    filter: 'agTextColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Расчетный счет',
    field: 'checking_account',
    width: 200,
    filter: 'agTextColumnFilter',
    cellRenderer: FinPartnersSpecificCellsComponent,
  },
  {
    headerName: 'Действия',
    icons: {
      sortAscending: '<i class="fa fa-sort-alpha-up"/>',
      sortDescending: '<i class="fa fa-sort-alpha-down"/>',
    },
    filter: false,
    cellRenderer: BtnFinpartnersCellRendererComponent,
    autoHeaderHeight: true,
    width: 140,
  },
];
