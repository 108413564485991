<div class="general-id">
  <div *ngIf="currentProductType == 'Orders'" class="card-header header-type">
    Заказы ({{items_count}} шт)&nbsp;&nbsp;&nbsp;ср. в день: {{items_count_avg}}&nbsp;&nbsp;&nbsp; отмена
    ({{order_cancel_total}} шт) {{percent_cancel}} %
  </div>
  <div *ngIf="currentProductType === 'Sales'" class="card-header header-type">
    Продаж ({{items_count}} шт)&nbsp;&nbsp;&nbsp;&nbsp;среднее количество в день: {{items_count_avg}}
  </div>
  <div class="row app-page-title">
    <div class="col-sm-10 col-md-10">
      <div class="card-body">
        <div id="chart">
          <apx-chart [chart]="chartOptions?.chart"
                     [dataLabels]="chartOptions?.dataLabels"
                     [series]="chartOptions?.series"
                     [stroke]="chartOptions?.stroke"
                     [xaxis]="chartOptions?.xaxis"
                     [yaxis]="chartOptions?.yaxis">
          </apx-chart>
        </div>
      </div>
    </div>
    <div class="col-sm-2 col-md-2">
      <div class="col-sm-12 col-md-12">
        <div class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <h6 class="widget-subheading">{{currentProductType == 'Orders' ? 'Заказы' : 'Продажи'}} ₽</h6>
              <div class="widget-chart-flex">
                <div class="widget-numbers mb-0 w-100">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">₽</small>
                      {{order_money_total | number: '1.0-0'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12">
        <div class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <h6 class="widget-subheading">{{currentProductType == 'Orders' ? 'Заказы' : 'Продажи'}} ШТ</h6>
              <div class="widget-chart-flex">
                <div class="widget-numbers mb-0 w-100">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">ШТ</small>
                      {{order_qty_total}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12">
        <div class="card-shadow-primary mb-3 widget-chart widget-chart2 text-left card">
          <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
              <h6 class="widget-subheading">{{currentProductType == 'Orders' ? 'Отмены' : 'Возвраты'}} шт.</h6>
              <div class="widget-chart-flex">
                <div class="widget-numbers mb-0 w-100">
                  <div class="widget-chart-flex">
                    <div class="fsize-4">
                      <small class="opacity-5">шт</small>
                      {{order_cancel_total | number: '1.0-0'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
