import { Component } from '@angular/core';
import { DatePickerCommon } from '../../../shared/common-variables/date-picker-common';
import { DATE_PICKER_LOCALE, SECOND_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { ApiService } from '../../../shared/services/api.service';
import { GridOptions } from 'ag-grid';
import { ADMIN_MONITORING_COLUMN_DEFS } from '../../../shared/mocks/admin-monitoring-mock';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.sass'],
})
export class MonitoringComponent {
  DatePicker: DatePickerCommon;
  loading = true;
  rowData: any;
  readonly gridOptions: any;
  readonly datePickerRange = SECOND_RANGE_TYPE;
  readonly datePickerLocale = DATE_PICKER_LOCALE;
  readonly dataForColumnDefs = ADMIN_MONITORING_COLUMN_DEFS;
  public defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,
  };
  private readonly SHOP_ID: number;

  constructor(private api: ApiService) {
    this.DatePicker = new DatePickerCommon();
    this.gridOptions = {
      context: { componentParent: this },
    } as GridOptions;
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop')).shop;
  }

  onGridReady(params?: GridReadyEvent<any>) {
    this.getMonitoringData();
  }

  newValue() {
    this.getMonitoringData({ data: this.DatePicker.selectedData });
  }

  getMonitoringData(params = null) {
    const body = {
      shop_id: this.SHOP_ID,
      params,
    };
    this.api
      .userRegisterFin('getPurchaselist', localStorage.getItem('token'), body)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.rowData = response;
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        },
      );
  }
}
