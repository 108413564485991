import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractAdvertManagementCardComponent} from '../abstract-advert-management-card.component';
import {AUCTION_TABLE_MOCK} from './auction-statistic.mock';
import {AuctionResponseByWord, AuctionResponseByWordAdverts, AuctionStatisticMock} from './advert-management-auction-statistic.interface';
import {forkJoin, Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ApiService} from '../../../../../../shared/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {SharedDataService} from '../../../../../../shared/services/shared-data.service';

@Component({
  selector: 'app-advert-management-auction-statistic',
  templateUrl: './advert-management-auction-statistic.component.html',
  styleUrls: ['./advert-management-auction-statistic.component.scss']
})
export class AdvertManagementAuctionStatisticComponent extends AbstractAdvertManagementCardComponent implements OnInit {

  auctionTableMocks: AuctionStatisticMock[] = AUCTION_TABLE_MOCK;
  titlePlaces: string[] = ['Первое место', 'Второе место', 'Третье место', 'Четвертое место',
    'Пятое место', 'Шестое место', 'Седьмое место', 'Восьмое место', 'Девятое место', 'Десятое место'];
  isLoadingStatus = false;

  constructor(api: ApiService, route: ActivatedRoute, sharedData: SharedDataService, private httpClient: HttpClient) {
    super(api, route, sharedData);
    this.loadSumForPlaces();
  }

  loadSumForPlaces() {
    this.isLoadingStatus = true;
    const cardTitles = this.auctionTableMocks.map(a => a.request);
    const requests = cardTitles.map(a => this.requestLoadSumForPlace(a));
    forkJoin(requests)
      .pipe(
        map(response => response.map(r => r.adverts.slice(0, 10))),
      )
      .subscribe(
        (response: Array<AuctionResponseByWordAdverts[]>) => {
          const prices = response.map(a => {
            return a.map(x => x.cpm);
          });
          this.auctionTableMocks.forEach((a, i) => {
            a.places = prices[i];
          });
          this.isLoadingStatus = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoadingStatus = false;
          alert(error.message);
        });
  }

  private requestLoadSumForPlace(word: string): Observable<AuctionResponseByWord> {
    const URL = `https://catalog-ads.wildberries.ru/api/v5/search?keyword=${word}`;
    return this.httpClient.get<AuctionResponseByWord>(URL);
  }
}
