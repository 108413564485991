import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../../shared/services/api.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/ja';
import 'moment/locale/fr';
import 'moment/locale/ru';
import { ISuppliersList } from '../../guides/finpurchase/finpurchase.interface';
import { FIN_OPERATION_COLUMN_DEFS, MY_FORMATS } from './finoperations.mock';
import { ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';
import { DragStoppedEvent, GridOptions } from 'ag-grid';
import { AgGridAngular } from 'ag-grid-angular';
import {
  IFinOperationExpensesFB,
  IFinOperationFinAccountsList,
  IFinOperationFinPartnersList,
  IFinOperationsShopList,
  IFormDataGeneral,
  OperationType,
} from './finoperations.interface';
import { PaymentTypesWithOperations } from './components/finoperations-excel/finoperations-excel.mock';
import { FinoperationsExcelComponent } from './components/finoperations-excel/finoperations-excel.component';
import { forkJoin, Observable } from 'rxjs';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FinoperationExcelRenderCellsComponent } from './components/aggrid-components/finoperation-excel-render-cells.component';
import { concatMap, finalize } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { CommonResponseGeneral, INmidCommon } from '../../../shared/interfaces/common.interface';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';
import { IResponseCommon } from '../../settings/shop/shop.interface';
import { environment } from '../../../../environments/environment';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-finoperations',
  templateUrl: './finoperations.component.html',
  styleUrls: ['./finoperations.component.sass'],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FinoperationsComponent extends AbstractMenuTabComponent implements OnInit, AfterViewInit {
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };
  readonly dataForColumnDefs: (ColDef | ColGroupDef)[] = FIN_OPERATION_COLUMN_DEFS;
  showAgGridExcel = false;
  rowData: IFinOperationFinAccountsList[] = [];
  rowsForRemoving: any[] = [];
  readonly FormMoving: FormGroup;
  readonly FormArrival: FormGroup;
  readonly FormExpenses: FormGroup;
  readonly FormRetention: FormGroup;
  readonly FormNewAgent: FormGroup;
  readonly FormLoanRepayment: FormGroup;
  FormSeparateOperations: FormGroup;
  finAccountsList: IFinOperationFinAccountsList[] = [];
  finAccountsListDebit: IFinOperationFinAccountsList[] = [];
  finAccountsListCredit: IFinOperationFinAccountsList[] = [];
  finAccountsListStatic: IFinOperationFinAccountsList[] = [];
  finPartnersList: IFinOperationFinPartnersList[] = [];
  listOfCommonArticles: any[] = [];
  finArticlesNm: any[] = [];
  urlLink: string;
  newSupplierName = '';
  errormsgconfirm: any;
  shop_id: string;
  modalReference: any;
  list_oper: any;
  list_item: any;
  // TODO - REFACTORING NEW CHANGES
  modelDateAccrual: {
    month: number;
    year: number;
    day: number;
  };
  modelDatePayment: {
    month: number;
    year: number;
    day: number;
  };
  suppliersList: ISuppliersList[] = [];
  isCreate = true;
  actionUID: number | undefined = undefined;
  showSelectDropDown = true;
  shopsList: IFinOperationsShopList[] = [];
  itemsIncomeList: any[] = [];
  itemsExpensesList: any[] = [];
  @ViewChild(FinoperationsExcelComponent) finoperationsExcelComponent: FinoperationsExcelComponent;
  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;
  @ViewChild('expenses', { read: TemplateRef }) modalExpenses: TemplateRef<any>;
  @ViewChild('receipt', { read: TemplateRef }) modalReceipt: TemplateRef<any>;
  @ViewChild('moving', { read: TemplateRef }) modalMoving: TemplateRef<any>;
  @ViewChild('retention', { read: TemplateRef }) modalRetention: TemplateRef<any>;
  @ViewChild('newAgent', { read: TemplateRef }) newAgent: TemplateRef<any>;
  displaySeparateSum = false;
  isFormExpensesAmountValid = false;
  displayMessageSumBigger = false;

  constructor(
    private api: ApiService,
    public toastr: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    private ym: NgxMetrikaService,
  ) {
    super();
    this.shop_id = JSON.parse(localStorage.getItem('mpall_shop')).shop;
    this.ym.hit.emit({
      url: 'my.mpsurf.ru/guides/products',
      hitOptions: { title: `Current Productd id - ${this.shop_id}`, referer: document.referrer },
    });

    // Форма прихода
    this.FormArrival = fb.group({
      amount: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.min(1)]),
      date_payment: new FormControl('', [Validators.required]),
      date_accrual: new FormControl(''),
      product_code: new FormControl(''),
      insure_arrival_payment: new FormControl(false),
      insure_arrival_income: new FormControl(false),
      description: new FormControl(''),
      project: new FormControl(''),
      item: new FormControl(''),
      partner_id: new FormControl(''),
      account_id: new FormControl('', [Validators.required]),
    });
    // Форма перемещения
    this.FormMoving = fb.group({
      amount: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      date_payment: new FormControl('', [Validators.required]),
      date_payment2: new FormControl(''),
      account_id: new FormControl('', [Validators.required]),
      account_id_2: new FormControl('', [Validators.required]),
      description: new FormControl(''),
    });
    // Форма расхода
    this.FormExpenses = fb.group({
      amount: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      date_payment: new FormControl(this.calendar.getToday(), [Validators.required]),
      date_accrual: new FormControl(this.calendar.getToday()),
      insure_arrival_payment: new FormControl(null),
      insure_arrival_income: new FormControl(null),
      product_code: new FormControl(''),
      supplier_id: new FormControl('123'),
      project: new FormControl(''),
      description: new FormControl(''),
      item: new FormControl(''),
      partner_id: new FormControl(''),
      account_id: new FormControl('', [Validators.required]),

      separate_payment: fb.array([this.createSeparateSumOperationGroup()]),
    });
    // Форма разделения операций
    this.FormSeparateOperations = fb.group({
      operations: fb.array([]),
    });
    // Форма Удержания WB
    this.FormRetention = fb.group({
      amount: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      date_payment: new FormControl(this.calendar.getToday(), [Validators.required]),
      date_accrual: new FormControl(this.calendar.getToday()),
      insure_arrival_payment: new FormControl(true),
      insure_arrival_income: new FormControl(''),
      product_code: new FormControl(''),
      supplier_id: new FormControl('123'),
      project: new FormControl(''),
      description: new FormControl(''),
      item: new FormControl(''),
      partner_id: new FormControl(''),
      account_id: new FormControl('', [Validators.required]),
    });
    // Форму Кредита/Займа
    this.FormLoanRepayment = fb.group({
      date_payment: new FormControl('', [Validators.required]),
      date_accrual: new FormControl(''),
      account_id: new FormControl('', [Validators.required]),
      credit_loan: new FormControl('', [Validators.required]),
      amount_of_payment: new FormControl('', [Validators.required]),
      debt_body: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.min(1)]),
      percentages: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.min(1)]),
      description: new FormControl(''),
    });
    this.FormNewAgent = fb.group({
      shop: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]),
      inn: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      kpp: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      checking_account: new FormControl(''),
      income_item: new FormControl(''),
      expenses_item: new FormControl(''),
      description: new FormControl(''),
    });

    this.gridOptions = {
      context: {
        componentParent: this,
      },
      onDragStopped: (event: DragStoppedEvent) => this.onColumnMoved(event),
      enableCellTextSelection: true,
      suppressRowClickSelection: true,
      frameworkComponents: {
        customFooterComponent: FinoperationExcelRenderCellsComponent,
      },
    } as GridOptions;

    this.countAmountOfPayment();

    this.separateSumOperationControls.valueChanges.subscribe((value: any[]) => {
      const sum = value.map((a) => a.sumPaymentSeparate).reduce((acc, it) => (acc += it), 0);
      this.displayMessageSumBigger = sum !== this.FormExpenses.controls['amount'].value;
    });

    this.urlLink = environment.apiOld;
  }

  get separateOperationControls(): FormArray {
    return this.FormSeparateOperations.controls.operations as FormArray;
  }

  get separateSumOperationControls(): FormArray {
    return this.FormExpenses.controls.separate_payment as FormArray;
  }

  getRowStyle = (params: CommonResponseGeneral<IFinOperationFinAccountsList>) => {
    const dateCreate = params.data?.date_create;
    if (params.data && dateCreate && moment().diff(dateCreate, 'seconds') < 30) {
      return { background: '#e1f5fe' };
    }
    return { background: 'transparent' };
  };

  ngOnInit(): void {
    this.getFinOperationManageData();
    this.getSupplierForModal();
    this.createSeparateOperationGroup();
    this.loadArticlesNM();

    this.FormExpenses.controls.amount.valueChanges.pipe(untilDestroyed(this)).subscribe((v) => {
      this.isFormExpensesAmountValid = typeof v === 'number' && v > 0;
      if (!this.isFormExpensesAmountValid) {
        this.displaySeparateSum = false;
        this.separateSumOperationControls.controls.length = 0;
        this.separateSumOperationControls.push(this.createSeparateSumOperationGroup());
      }
    });
  }

  ngAfterViewInit() {
    if (this.agGrid) {
      this.gridApi = this.agGrid.api;
    }
    this.finoperationsExcelComponent.dataIsSaved.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
      this.showAgGridExcel = !value;
    });
  }

  // AG-GRID
  onGridReady(params?: GridReadyEvent<any>) {
    this.gridApi = params.api;
    const columnState = localStorage.getItem('myColumnState');
    if (columnState) {
      const columnStateJSON = JSON.parse(columnState);
      // params.columnApi.setColumnState(columnStateJSON);
      params.columnApi.applyColumnState(columnStateJSON);
    }
  }

  onColumnMoved(params: DragStoppedEvent) {
    const columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('myColumnState', columnState);
  }

  onRowSelected(event: any) {
    const selectedUID = event.data.UID;
    const selectedStatus = event.node.selected;
    if (selectedStatus) {
      this.rowsForRemoving.push(selectedUID);
    } else {
      this.rowsForRemoving = this.rowsForRemoving.filter((row) => row !== selectedUID);
    }
  }

  removeSelected() {
    if (confirm('Вы уверены, что хотите удалить запись ?')) {
      const requests = this.rowsForRemoving.map((uid) => this._mpSurfService.delete(uid, 'fin-operation'));
      this.isLoading = true;
      forkJoin(requests)
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.isLoading = false)),
        )
        .subscribe(
          (response) => {
            this.rowsForRemoving.length = 0;
            this.getFinOperationsList();
            this.showPopUpMessage('success', 'Операции удалены', '');
          },
          (error: HttpErrorResponse) => {
            this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
          },
        );
    }
  }

  updateFinOperationEmitter(operationData: any) {
    this.showSelectDropDown = false;
    const operationTypeId = PaymentTypesWithOperations[operationData.type_operation_title];
    switch (+operationTypeId) {
      case 1:
        this.openUpdateOperationModal(this.modalReceipt, operationTypeId, operationData.UID);
        break;
      case 2:
        this.openUpdateOperationModal(this.modalExpenses, operationTypeId, operationData.UID);
        break;
      case 3:
        this.openUpdateOperationModal(this.modalMoving, operationTypeId, operationData.UID);
        break;
      case 4:
        this.openUpdateOperationModal(this.modalRetention, operationTypeId, operationData.UID);
        break;
      default:
        break;
    }
  }

  deleteFinOperationEmitter(finOperationData: any) {
    if (confirm('Вы уверены, что хотите удалить запись ?')) {
      this.isLoading = true;
      this._mpSurfService
        .delete({ uid: finOperationData.UID }, 'fin-operation')
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (data: any) => {
            if (+data.is_error === 0) {
              this.getFinOperationsList();
              this.showPopUpMessage('success', PopUpMessages.deleteSuccessSummary, PopUpMessages.deleteSuccessMessage);
            } else {
              this.showPopUpMessage('warning', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
            }
          },
          () => {
            this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
          },
        );
    }
  }

  // TODO - MODALS START
  openCreateOperationModal(modal: TemplateRef<any>, productParameter: OperationType) {
    this.isCreate = true;
    this.actionUID = undefined;
    this.modelDatePayment = this.calendar.getToday();
    this.modelDateAccrual = this.calendar.getToday();
    switch (productParameter) {
      case 'arrival':
        this.setterArrivalCreateModal();
        break;
      case 'expenses':
        this.setterExpensesCreateModal();
        break;
      case 'moving':
        this.setterMovingCreateModal();
        break;
      case 'retention':
        this.setterRetentionCreateModal();
        break;
    }
    this.modalService.open(modal, { backdrop: 'static' });
  }

  openUpdateOperationModal(modalContent: any, operationType: any, uid: any) {
    this.isCreate = false;
    this.isLoading = true;
    this._mpSurfService
      .load({ uid }, 'fin-operation')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: any) => {
          if (+response.is_error === 0 && +response.cnt === 1) {
            const operationData = response.data;
            this.actionUID = operationData.UID;
            this.setterForTime(new Date(operationData.date_payment), new Date(operationData.date_accrual));
            this.setterDataUpdateModal(+operationType, operationData);
            this.getArticlesListOfExpensesAndArrivalStatic(+operationType);
            this.modalService.open(modalContent, { backdrop: 'static' });
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  // TODO -  AG_GRID_MANAGEMENT ENDS

  createUpdateOperation(operationParameter: OperationType) {
    const formData = this.getFormData(operationParameter);
    if (operationParameter === 'expenses' && this.displaySeparateSum) {
      this.manageExpensesUpdateOperation({ ...formData.data } as IFinOperationExpensesFB);
    } else {
      this.isLoading = true;
      formData.data.uid = this.actionUID ?? undefined;
      this._mpSurfService
        .createOrUpdate(formData.data, `fin-operation/${formData.url}`)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          () => {
            this.showPopUpMessage(
              'success',
              this.isCreate ? PopUpMessages.createSuccessSummary : PopUpMessages.updateSuccessSummary,
              `Операция ${this.isCreate ? 'добавлена' : 'изменена'}`,
            );
            this.postClearModal();
            this.getFinOperationsList();
          },
          () => {
            this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
          },
        );
    }
  }

  manageExpensesUpdateOperation(fgData: IFinOperationExpensesFB) {
    if (this.isSeparateSumBigger(fgData)) {
      this.showPopUpMessage(
        'error',
        'Ошибка изменения операции',
        'Сумма разделений платежей  должна быть равна изначальной сумме!',
      );
    } else {
      const observablesArr$: Observable<any>[] = [];
      fgData.separate_payment.forEach((a) => {
        const formattedFgData = { ...fgData };
        formattedFgData.amount = a.sumPaymentSeparate;
        formattedFgData.date_payment = a.datePaymentSeparate;
        observablesArr$.push(this._mpSurfService.createOrUpdate(formattedFgData, 'fin-operation/add-order'));
      });
      this.isLoading = true;
      forkJoin(observablesArr$)
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.isLoading = false)),
        )
        .subscribe(
          () => {
            this.modalService.dismissAll();
            this.getFinOperationsList();
            this.showPopUpMessage(
              'success',
              this.isCreate ? PopUpMessages.createSuccessSummary : PopUpMessages.updateSuccessSummary,
              `Операция ${this.isCreate ? 'добавлена' : 'изменена'}`,
            );
          },
          () => {
            this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
          },
        );
    }
  }

  createUpdateRequest(action: string, params: any): Observable<any> {
    return this.api.userRegisterFin(action, this.TOKEN, {
      shop_id: this.shop_id,
      params,
      uid: this.actionUID ?? undefined,
    });
  }

  createNewSupplier() {
    const newDataShop = JSON.parse(localStorage.getItem('mpall_shop'));
    this.api
      .userRegister('addSeller', this.TOKEN, {
        shop_id: newDataShop.shop,
        id: 0,
        name: this.newSupplierName,
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: any) => {
          if (+data.data.is_error === 0) {
            this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Поставщик добавлен');
            this.modalReference.close();
            this.getSupplierForModal();
          } else {
            this.showPopUpMessage('warning', 'Не смогли добавить поставщика', '');
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  createNewAgent(): void {
    this.isLoading = true;
    this._mpSurfService
      .createOrUpdate(this.FormNewAgent.getRawValue(), 'fin-partners')
      .pipe(
        untilDestroyed(this),
        concatMap((data: IResponseCommon) => {
          if (+data.is_error === 0) {
            this.modalReference.close();
            this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Контрагент добавлен');
            this.FormNewAgent.reset();
          } else {
            this.showPopUpMessage('error', data.msg, '');
          }
          return this.getFinPartners();
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (responseFinPartners: any) => {
          this.finPartnersList = responseFinPartners;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  // TODO - ANOTHER METHODS STARTS
  getTypeAccount(type: number) {
    switch (type) {
      case 1:
        return 'Наличный';
      case 2:
        return 'Безналичный';
      case 3:
        return 'Карта физ лица';
      case 4:
        return 'Дебет';
      case 5:
        return 'Кредит / Займ';
      case 6:
        return 'Кредитная линия с лимитом';
      default:
        return '';
    }
  }

  onBasicUploadAuto(excelData: any, fileUpload) {
    this.showPopUpMessage('success', 'Файл загружен. Данные обновлены', '');
    const finOperationExcelData = excelData.originalEvent.body as Array<any>;
    this.finoperationsExcelComponent.updateRowData(finOperationExcelData);
    this.showAgGridExcel = true;
    fileUpload.clear();
    this.isLoading = false;
  }

  onBasicUploadError(event: any, fileUpload) {
    this.isLoading = false;
    this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, 'Ошибка загрузки файла');
    fileUpload.clear();
  }

  isAccountItemBusy(controlName: string) {
    if (controlName) {
      this.finAccountsList = [...this.finAccountsListStatic];
      this.finAccountsList.forEach((elem) => {
        if (elem.disabled) {
          elem.disabled = false;
        }
      });
      const controlValue = this.FormMoving.get(controlName).value;
      if (controlValue) {
        this.finAccountsList.forEach((acc) => {
          if (acc.UID === controlValue) {
            acc.disabled = true;
          }
        });
      }
    }
  }

  exportInExcel() {
    const params = {
      columnSeparator: ',',
      suppressQuotes: true,
    };
    this.gridApi.exportDataAsCsv(params);
  }

  addSeparateOperation(): void {
    this.separateOperationControls.push(this.createSeparateOperationGroup());
  }

  addSeparateSumOperation(): void {
    this.separateSumOperationControls.push(this.createSeparateSumOperationGroup());
    this.displaySeparateSum = true;
  }

  removeSeparateSumOperation(index: number): void {
    console.log(index);
    this.separateSumOperationControls.controls.splice(index - 1, 1);
    console.log(this.separateSumOperationControls.controls);
  }

  removeSeparateSum(): void {
    this.separateSumOperationControls.controls.length = 0;
    this.separateSumOperationControls.push(this.createSeparateSumOperationGroup());
    this.displaySeparateSum = false;
  }

  openNewAgentModal() {
    this.FormNewAgent.reset();
    this.modalReference = this.modalService.open(this.newAgent);
  }

  addSplitOperationOpenModal(orderMass) {
    this.FormSeparateOperations = this.fb.group({
      operations: this.fb.array([this.createSeparateOperationGroup()]),
    });
    this.modalService.dismissAll();
    this.modalReference = this.modalService.open(orderMass, { size: 'xl' });
  }

  // TODO - MODALS END
  private countAmountOfPayment(): void {
    this.FormLoanRepayment.valueChanges.pipe(untilDestroyed(this)).subscribe((controlsValue) => {
      const sum = controlsValue.debt_body + controlsValue.percentages;
      this.FormLoanRepayment.controls.amount_of_payment.patchValue(sum, { emitEvent: false });
    });
  }

  private setterArrivalCreateModal(): void {
    this.getArticlesListOfExpensesAndArrivalStatic(1);
    this.FormArrival.reset();
    this.FormArrival.get('insure_arrival_payment').setValue(true);
  }

  private setterExpensesCreateModal(): void {
    this.getArticlesListOfExpensesAndArrivalStatic(2); // подгружаем тип приход ДС
    const supplierMain = JSON.parse(localStorage.getItem('mpall_shop'));
    this.showSelectDropDown = true;
    this.FormExpenses.reset();
    this.FormExpenses.get('date_payment').setValue(this.calendar.getToday());
    this.FormExpenses.get('date_accrual').setValue(this.calendar.getToday());
    this.FormExpenses.get('insure_arrival_payment').setValue(true);
    if (supplierMain) {
      const nameSupp = this.suppliersList.find((name) => name.name === supplierMain.name);
      this.FormExpenses.get('supplier_id').setValue(nameSupp.name);
    }
  }

  private setterMovingCreateModal(): void {
    this.FormMoving.reset();
    this.finAccountsList = this.finAccountsListStatic;
  }

  private setterRetentionCreateModal(): void {
    this.getArticlesListOfExpensesAndArrivalStatic(4);
    const supplierMain = JSON.parse(localStorage.getItem('mpall_shop'));
    this.showSelectDropDown = true;
    this.FormRetention.reset();
    this.FormRetention.get('date_payment').setValue(this.calendar.getToday());
    this.FormRetention.get('date_accrual').setValue(this.calendar.getToday());
    this.FormRetention.get('insure_arrival_payment').setValue(true);
    if (supplierMain) {
      const nameSupp = this.suppliersList.find((name) => name.name === supplierMain.name);
      this.FormRetention.get('supplier_id').setValue(nameSupp.name);
    }
  }

  private setterDataUpdateModal(operationType: number, operationData: any): void {
    let formGroup;
    switch (operationType) {
      case 1:
        formGroup = this.FormArrival as FormGroup;
        break;
      case 2:
        formGroup = this.FormExpenses as FormGroup;
        break;
      case 3:
        formGroup = this.FormMoving as FormGroup;
        break;
      case 4:
        formGroup = this.FormRetention as FormGroup;
        break;
      default:
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
    }
    formGroup.patchValue(operationData);
    formGroup.controls.date_payment.setValue(this.modelDatePayment);
    formGroup.controls.date_accrual.setValue(this.modelDateAccrual);
  }

  private getFormData(productParameter: string): IFormDataGeneral {
    const fgData: any = { data: {}, url: '' };
    switch (productParameter) {
      case 'arrival':
        fgData.data = this.FormArrival.getRawValue();
        fgData.data.type_operation = 1;
        fgData.url = 'add-receipt';
        break;
      case 'expenses':
        fgData.data = this.FormExpenses.getRawValue();
        fgData.data.type_operation = 2;
        fgData.url = this.isCreate ? 'add-order' : 'update-order';
        break;
      case 'moving':
        fgData.data = this.FormMoving.getRawValue();
        fgData.data.date_payment2 = fgData.data.date_payment;
        fgData.data.type_operation = 3;
        fgData.url = 'add-moving';
        break;
      case 'retention':
        fgData.data = this.FormRetention.getRawValue();
        fgData.data.type_operation = 4;
        fgData.url = this.isCreate ? 'add-order' : 'update-order';
        break;
      case 'repayment':
        fgData.data = this.FormLoanRepayment.getRawValue();
        fgData.data.type_operation = 5;
        fgData.url = 'add-load-repayment';
    }
    return fgData;
  }

  private isSeparateSumBigger(formData: IFinOperationExpensesFB): boolean {
    if (formData?.separate_payment) {
      const sumsArr = formData.separate_payment.map((a) => a.sumPaymentSeparate);
      if (sumsArr.includes(0)) {
        return true;
      } else {
        const separateSums = sumsArr.reduce((acc, iter) => (acc += iter), 0);
        const currentExpensesSum = formData.amount;
        return !(separateSums === currentExpensesSum);
      }
    }
    return false;
  }

  private postClearModal(): void {
    this.modalService.dismissAll();
    this.FormExpenses.reset();
    this.FormRetention.reset();
    this.FormArrival.reset();
    this.FormMoving.reset();
  }

  // TODO - LOAD DATA STARTS
  private getFinOperationManageData(): void {
    this.isLoading = true;
    forkJoin([
      this.getFinAccounts(),
      this.getFinPartners(),
      this.getArticlesListOfExpensesAndArrival(),
      this.getFinOperation(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([responseFinAccounts, responseFinPartners, articles, responseOperation]) => {
          this.finoperationsExcelComponent.setupFinAccounts(responseFinAccounts);
          this.finoperationsExcelComponent.setUpFinPartners(responseFinPartners);
          this.finoperationsExcelComponent.setupFinItems(articles);
          this.setterValues(articles);
          this.finAccountsListStatic = responseFinAccounts;
          this.finAccountsList = [...this.finAccountsListStatic];
          this.finAccountsListDebit = this.finAccountsListStatic.filter((a) => +a.type === 4);
          this.finAccountsListCredit = this.finAccountsListStatic.filter((a) => [5, 6].includes(+a.type));
          this.finPartnersList = responseFinPartners;
          this.listOfCommonArticles = [...articles];
          this.manageFinOperationList(responseOperation);
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private getFinOperationsList() {
    this.isLoading = true;
    this.getFinOperation()
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (response: IFinOperationFinAccountsList[]) => {
          this.manageFinOperationList(response);
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private manageFinOperationList(response: any[]): void {
    const formattedOperationList = response.map((finOperation) => {
      finOperation.type_operation_title = PaymentTypesWithOperations[+finOperation.type_operation];
      finOperation.item_id =
        +finOperation.item_id === 0
          ? ''
          : this.listOfCommonArticles.find((item) => +item.id === +finOperation.item_id)?.name;
      if (+finOperation.group_id !== 0 && +finOperation.type_operation != 2) {
        finOperation.type_operation_title = 'Перемещение';
      } else if (+finOperation.group_id !== 0 && +finOperation.type_operation == 2 && +finOperation.account_type == 4) {
        finOperation.type_operation_title = 'Перемещение';
      }

      //finOperation.type_operation_title = 'Погашение кредита/займа';

      finOperation.account_id =
        this.finAccountsList.find((finAcc) => finOperation.account_id === finAcc.UID)?.name || '';
      finOperation.partner_id =
        this.finPartnersList.find((finPart) => finOperation.partner_id === finPart.UID)?.name || '';
      return finOperation;
    });
    this.rowData = formattedOperationList;
    this.list_oper = formattedOperationList;
    this.isLoading = false;
  }

  private setterForTime(dateOfPayment: any, dateOfAccrual: any) {
    this.modelDatePayment = {
      year: parseInt(moment(dateOfPayment).format('YYYY')),
      month: parseInt(moment(dateOfPayment).format('M')),
      day: parseInt(moment(dateOfPayment).format('D')),
    };
    this.modelDateAccrual = {
      year: parseInt(moment(dateOfAccrual).format('YYYY')),
      month: parseInt(moment(dateOfAccrual).format('M')),
      day: parseInt(moment(dateOfAccrual).format('D')),
    };
  }

  private setterValues(finItems: any): void {
    this.itemsIncomeList = finItems.filter((item) => +item.item_type === 1);
    this.itemsExpensesList = finItems.filter((item) => +item.item_type === 2);
  }

  private loadArticlesNM() {
    this._mpSurfService.load({}, 'products/important').subscribe(
      (response: INmidCommon[]) => {
        this.finArticlesNm = response.map((a) => a.nmid);
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  private getSupplierForModal() {
    const body = { shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop };
    this.api
      .userRegister('getDataShopWBList', this.TOKEN, body)
      .pipe(untilDestroyed(this))
      .subscribe(
        (response: any[]) => {
          this.finoperationsExcelComponent.setupDataShopWBList(response);
          const dinamic = { id: '0', name: 'Все' };
          this.shopsList = response;
          this.shopsList.unshift(dinamic as IFinOperationsShopList);
          this.suppliersList = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  // TODO - LOAD DATA ENDS

  // TODO SEPARATE OPERATIONS PART STARTS
  private createSeparateOperationGroup(): FormGroup {
    return new FormGroup({
      datePaymentSeparate: new FormControl(this.calendar.getToday()),
      dateAccrualSeparate: new FormControl(this.calendar.getToday()),
      supplierIdSeparate: new FormControl(null),
      articleSeparate: new FormControl(''),
      projectSeparate: new FormControl(''),
      accountIdSeparate: new FormControl(''),
      amountSeparate: new FormControl('', [
        Validators.pattern('^[+]?([0-9]{0,})*[.]?([0-9]{0,2})?$'),
        Validators.maxLength(10),
      ]),
      partnerIdSeparate: new FormControl(''),
      itemSeparate: new FormControl(''),
      descriptionSeparate: new FormControl(''),
    });
  }

  private createSeparateSumOperationGroup(): FormGroup {
    return new FormGroup({
      datePaymentSeparate: new FormControl(this.calendar.getToday()),
      sumPaymentSeparate: new FormControl(0),
    });
  }

  // TODO - API REQUESTS STARTS
  private getArticlesListOfExpensesAndArrival(): Observable<any> {
    return this._mpSurfService.load({}, 'fin-items');
  }

  private getArticlesListOfExpensesAndArrivalStatic(type: number) {
    this._mpSurfService
      .load({ type }, 'fin-items/item-list')
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: any) => {
          this.list_item = data;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private getFinOperation(): Observable<any> {
    return this._mpSurfService.load({}, 'fin-operation');
  }

  private getFinPartners(): Observable<any> {
    return this._mpSurfService.load({}, 'fin-partners');
  }

  private getFinAccounts(): Observable<any> {
    return this._mpSurfService.load({}, 'fin-account');
  }
}
