<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <!--<i class="icon {{icon}}"></i>-->
        <i class="lnr-warning icon-gradient bg-mean-fruit"></i>
      </div>
      <div>
        ДЕМО РЕЖИМ<!--{{control_id}}-->
        <!--<div class="page-title-subheading" *ngIf="control_id">
          <a [routerLink]="['/my/control/']">к списку поставок</a>
        </div>-->
      </div>
    </div>
  </div>
</div>

<div *ngIf="!vis_det">
  <div class="main-card mb-3 card">
    <div class="card-header">
      Cводка по продавцам за&nbsp;<div class="btn-actions-pane-left">
      <input (change)="newValue()"
             [(ngModel)]="selected"
             [alwaysShowCalendars]="true"
             [isInvalidDate]="isInvalidDate"
             [linkedCalendars]="true"
             [locale]="{
                        applyLabel: 'применить',
                        direction: 'ltr',
                        format: 'DD.MM.YYYY',
                        'daysOfWeek': ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
                        'customRangeLabel': 'Свой диапазон',
                        'firstDay': 1,
                        'monthNames': ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']}"
             [maxDate]='maxDate'
             [minDate]='minDate'
             [ranges]="ranges"
             [showClearButton]="false"
             [showCustomRangeLabel]="true"
             ngxDaterangepickerMd
             placeholder="Выберите даты"
             type="text"/>
    </div>
    </div>
  </div>
</div>


<div *ngIf="!loading" style="width: 100%;height: 80vh;">


  <ag-grid-angular
    (gridReady)="onGridReady($event)"
    [cacheBlockSize]="cacheBlockSize"

    [cacheOverflowSize]="cacheOverflowSize"
    [columnDefs]="columnDefsSales"
    [infiniteInitialRowCount]="infiniteInitialRowCount"
    [loadingCellRendererParams]="loadingCellRendererParams"
    [loadingCellRenderer]="loadingCellRenderer"
    [localeText]="localeText"

    [maxBlocksInCache]="maxBlocksInCache"
    [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"

    [paginationPageSize]="paginationPageSize"

    [pagination]="true"
    [rowData]="rowData"
    [rowHeight]="rowHeight"
    [rowModelType]="rowModelType"
    [rowSelection]="rowSelection"
    class="ag-theme-alpine"
    style="width: 100%; height: 100%;"
  >
  </ag-grid-angular>


</div>
