import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CustomCurrencyConverterRUB } from '../../../../../shared/utils/functions/custom-currency-converter-rub';

@Component({
  selector: 'app-btn-cell-specific-renderer',
  template: `<span style="color:{{ color }}">{{ value }}</span>`,
})
export class FinoperationSpecificRenderCellsComponent implements ICellRendererAngularComp {
  value: string;
  color = '';
  private params: any;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.color = '';
    if (params.colDef && params.data) {
      const field = params.colDef.field;
      if (field === 'amount') {
        const typeOperationTitle = params.data.type_operation_title;
        switch (typeOperationTitle) {
          case 'Поступление':
            this.color = 'green';
            break;
          case 'Списание':
            this.color = 'red';
            break;
          case 'Перемещение':
            if (+params.data.type_operation === 1) {
              this.color = 'green';
            } else {
              this.color = 'red';
            }
            break;
        }
        this.value = CustomCurrencyConverterRUB(this.value);
      }
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    this.value = params.value;
    return true;
  }
}
