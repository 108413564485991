import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengDatepickerComponent } from './primeng-datepicker/primeng-datepicker.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbRangeDatepickerComponent } from './ngb-range-datepicker/ngb-range-datepicker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [PrimengDatepickerComponent, NgbRangeDatepickerComponent],
  imports: [CommonModule, CalendarModule, FormsModule, ReactiveFormsModule, NgxDaterangepickerMd],
  exports: [PrimengDatepickerComponent, NgbRangeDatepickerComponent],
})
export class UiComponentsModule {}
