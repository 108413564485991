import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { cloneDeep } from 'lodash-es';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';

const types = ['success', 'error', 'info', 'warning'];

@Component({
  selector: 'app-biils',
  templateUrl: 'bills.component.html',
  styleUrls: ['bills.component.sass'],
  providers: [MessageService],
})
export class BillsComponent implements OnInit {
  visible_list_pay: any = true;

  list_pay: any;
  disabled = false;
  promokod: any;
  id_hash: any;
  price_new: any;
  options: GlobalConfig;
  title = '';
  params_bill: any = [];
  payment: any;
  payment_status: any;
  message = '';
  type = types[1];
  pay: any;
  private lastInserted: number[] = [];

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    private messageService: MessageService,
  ) {
    this.options = this.toastr.toastrConfig;
    this.route.queryParams.subscribe((params) => {
      if (typeof params.payment !== 'undefined') {
        this.payment = params.payment;
      }
      if (String(params.id) !== '') {
        if (typeof params.id !== 'undefined') {
          this.visible_list_pay = false;
          this.id_hash = params.id;
          this.pay = params.pay;
          if (typeof this.pay !== 'undefined') {
            return this.generateUrlPayment();
          }
        }
      } else {
        this.visible_list_pay = true;
      }
    });
  }

  ngOnInit() {
    if (typeof this.pay !== 'undefined') {
      return this.generateUrlPayment();
    }
    if (typeof this.id_hash !== 'undefined') {
      this.getOneBill(this.id_hash);
    } else {
      if (typeof this.payment !== 'undefined') {
        this.payment_status = this.payment === 'ok';
        this.getListBill();
      } else {
        this.getListBill();
      }
    }
  }

  setPromokod() {
    if (String(this.promokod) === '') {
      const opt = cloneDeep(this.options);
      const inserted = this.toastr.show('', 'Вы не ввели промокод', opt, this.options.iconClasses[this.type]);
      if (inserted) {
        this.lastInserted.push(inserted.toastId);
      }
      return inserted;
    }
    if (typeof this.promokod === 'undefined') {
      const opt = cloneDeep(this.options);
      const inserted = this.toastr.show('', 'Вы не ввели промокод', opt, this.options.iconClasses[this.type]);
      if (inserted) {
        this.lastInserted.push(inserted.toastId);
      }
      return inserted;
    }

    const body = {
      promokod: this.promokod,
      id_hash: this.id_hash,
    };

    this.api.userRegister('getPromokod', localStorage.getItem('token'), body).subscribe(
      (data: any) => {
        if (+data.is_error === 1) {
          const opt = cloneDeep(this.options);
          const inserted = this.toastr.show('', data.msg, opt, this.options.iconClasses[this.type]);
          if (inserted) {
            this.lastInserted.push(inserted.toastId);
          }
          return inserted;
        }
        if (+data.is_error === 0) {
          this.disabled = true;
          this.price_new = data.price;
          const opt = cloneDeep(this.options);
          const inserted = this.toastr.show('', data.msg, opt, this.options.iconClasses.success);
          if (inserted) {
            this.lastInserted.push(inserted.toastId);
          }
          return inserted;
        }
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  // получаем списко счетов на опалту
  getListBill() {
    this.api.userRegister('getListBill', localStorage.getItem('token'), '').subscribe(
      async (data: any) => {
        this.list_pay = [];
        for (const customer of data) {
          this.list_pay.push(customer);
        }
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  getOneBill(hash: any) {
    this.api.userRegister('getOneBill', localStorage.getItem('token'), hash).subscribe(
      (data: any) => {
        if (+data.is_error === 1) {
          this.navigateBill();
        }
        this.params_bill = data.data;
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  // удаляем счет
  deleteBill(hash: any) {
    this.api.userRegister('deleteBill', localStorage.getItem('token'), hash).subscribe(
      (data: any) => {
        if (data) {
          this.navigateBill();
          this.showPopUpMessage('error', PopUpMessages.deleteSuccessSummary, PopUpMessages.deleteSuccessMessage);
        } else {
          this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
        }
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
      },
    );
  }

  private generateUrlPayment(): void {
    this.api.userRegister('generateUrlPayment', localStorage.getItem('token'), this.id_hash).subscribe(
      (data: any) => {
        window.location.href = data;
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
      },
    );
  }

  private navigateBill(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/profile/bill/']);
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
