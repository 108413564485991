export function ValueGetterForDate(params: any, fieldName: string): Date {
  const dateString = params.data[fieldName];
  if (dateString) {
    const DATE_PARTS = dateString.split('-');
    const YEAR = parseInt(DATE_PARTS[0]);
    const MONTH = parseInt(DATE_PARTS[1]) - 1;
    const DAY = parseInt(DATE_PARTS[2]);
    return new Date(YEAR, MONTH, DAY);
  }
}
