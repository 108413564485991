import {Component} from '@angular/core';
import {ITooltipAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-tooltip-component',
  template: `<img class="img"
                  width="120"
                  height="120"
                  [src]="srcImg"
                  alt="photo">`,
  styles: [
    `
      :host {
        position: absolute;
        width: 150px;
        height: 70px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
        white-space: nowrap;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }`],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  public data!: any;
  public srcImg: string;

  agInit(params: any): void {
    this.data = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.data;
    this.srcImg = Object.keys(this.data).includes('thumb') ? this.data.thumb : this.data.photo_new;
  }
}
