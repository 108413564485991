import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {AuthGuard} from "../../shared/guards/auth.guard";
import {AdvcompanyComponent} from "./advcompany/advcompany.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {AgGridModule} from "ag-grid-angular";
import {AdvbidComponent} from "./advbid/advbid.component";
import {AdvertAnalyticComponent} from "./reports/advert-analytic/advert-analytic.component";
import {AdvertManagementComponent} from "./advert-management/advert-management.component";
import {
  AdvertManagementCardComponent
} from "./advert-management/advert-management-card/advert-management-card.component";
import {
  AdvertManagementStatisticNegativeComponent
} from "./advert-management/advert-management-card/advert-management-card-components/advert-management-statistic-negative/advert-management-statistic-negative.component";
import {
  AdvertManagementAuctionStatisticComponent
} from "./advert-management/advert-management-card/advert-management-card-components/advert-management-auction-statistic/advert-management-auction-statistic.component";
import {
  AdvertManagementStatisticPositiveComponent
} from "./advert-management/advert-management-card/advert-management-card-components/advert-management-statistic-positive/advert-management-statistic-positive.component";
import {ButtonModule} from "primeng/button";
import {TableModule} from "primeng/table";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {InputSwitchModule} from "primeng/inputswitch";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {InputTextModule} from "primeng/inputtext";
import {NgbDropdownModule, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {
  AbstractAdvertManagementCardComponent
} from "./advert-management/advert-management-card/advert-management-card-components/abstract-advert-management-card.component";
import {ToastModule} from "primeng/toast";
import {InputNumberModule} from "primeng/inputnumber";
import {DropdownModule} from "primeng/dropdown";
import {RadioButtonModule} from "primeng/radiobutton";
import {ListboxModule} from "primeng/listbox";

const routes: Routes = [
  {
    path: 'company',
    component: AdvcompanyComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'bid',
    component: AdvbidComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'reports/advert-analytic',
    component: AdvertAnalyticComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'advert-management',
    component: AdvertManagementComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'advert-management/:id',
    component: AdvertManagementCardComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    AdvcompanyComponent,
    AdvbidComponent,
    AdvertAnalyticComponent,
    AdvertManagementComponent,
    AdvertManagementCardComponent,
    AdvertManagementStatisticNegativeComponent,
    AdvertManagementStatisticPositiveComponent,
    AdvertManagementAuctionStatisticComponent,
    AbstractAdvertManagementCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,

    //PrimeNg
    ButtonModule,
    ConfirmPopupModule,
    InputSwitchModule,
    TableModule,

    // AG-GRID
    AgGridModule,

    ProgressSpinnerModule,
    InputTextModule,
    NgbDropdownModule,
    NgbModule,
    ToastModule,
    InputNumberModule,
    DropdownModule,
    RadioButtonModule,
    ListboxModule

  ]
})
export class AdvertModule {
}
