import {Component, OnInit} from '@angular/core';
import {ColDef, ColGroupDef, GridApi, GridReadyEvent} from 'ag-grid-community';
import {CustomTooltipComponent} from '../../guides/products/components/prdcustomTooltip';
import {GridOptions} from 'ag-grid';
import {LOCALE_RU_FILTERS} from '../../finances/finoperations/finoperations.mock';
import {REMAINS_COLUMN_DEFS, ROW_DATA_REMAINS_MOCK} from './remains.mock';
import {CELL_CLASS} from '../../guides/products/products.mock';

@Component({
  selector: 'app-remains',
  templateUrl: './remains.component.html',
  styleUrls: ['./remains.component.sass']
})
export class RemainsComponent implements OnInit {

  dataForColumnDefs: (ColDef | ColGroupDef)[] = REMAINS_COLUMN_DEFS;
  public defaultColDef: ColDef = {
    editable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    autoHeaderHeight: true,
    resizable: true,
    tooltipComponent: CustomTooltipComponent,
    suppressSizeToFit: true,
    cellClass: CELL_CLASS,
  };
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 2000;
  gridOptions: GridOptions = {};
  localRuFilters = LOCALE_RU_FILTERS;
  isLoadingStatus = false;
  rowData: any[] = ROW_DATA_REMAINS_MOCK;
  private gridApi: GridApi;

  constructor() {
  }

  ngOnInit(): void {
  }

  onGridReady(params?: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }
}
