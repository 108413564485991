import { Component, inject, OnInit } from '@angular/core';
import { MessageService, TreeNode } from 'primeng/api';
import * as moment from 'moment';
import { DatePickerCommon } from '../../../../shared/common-variables/date-picker-common';
import { FORTH_RANGE_TYPE } from '../../../../shared/common-variables/time-ranges-date-picker';
import { forkJoin } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopUpStatus } from '../../../../shared/interfaces/common.type';
import { PopUpMessages } from '../../../../shared/mocks/pop-up-messages.mock';
import { MpSurfApiService } from '../../../../shared/services/mpsurf-api.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-fintest',
  templateUrl: './findds.component.html',
  styleUrls: ['./findds.component.sass'],
  providers: [MessageService],
})
export class FinddsComponent implements OnInit {
  isLoading = false;
  DatePicker: DatePickerCommon;
  finDdsData: any;
  cols: any;
  list: any;
  selectedNodes: TreeNode[];
  frozenCols: any[];
  alwaysShowCalendars: boolean;
  ranges: any = FORTH_RANGE_TYPE;
  selected: any = {
    startDate: moment().subtract(0, 'year').startOf('year'),
    endDate: moment().subtract(0, 'year').endOf('year'),
  };
  justifyOptions: any[];
  value3: any = 2;
  accounts: any;
  selectedAcc: any[] = [];
  selectedPartners: any[] = [];
  partners: any;
  shopId: any;
  view_google_table_info = true;
  private readonly _mpSurfService = inject(MpSurfApiService);

  constructor(private messageService: MessageService) {
    this.DatePicker = new DatePickerCommon();
  }

  ngOnInit() {
    this.shopId = JSON.parse(localStorage.getItem('mpall_shop')).shop;
    this.justifyOptions = [
      { name: 'По неделям', value: 1 },
      { name: 'По месяцам', value: 2 },
      /*, {name: 'По кварталам', value: 3}*/
    ];
    this.frozenCols = [{ field: 'name', header: 'Статья' }];
    this.loadPartnersAndAccounts();
    this.view_google_table_info = localStorage.getItem('googleMsgInfo') == null;
  }

  closeGoogleTableMsg(param) {
    if (+param === 1) {
      localStorage.setItem('googleMsgInfo', '1');
      this.view_google_table_info = false;
    }
  }

  newValue() {
    this.changeType({ value: this.value3 });
  }

  clearFilters(paramID: number) {
    if (paramID === 1 && this.selectedAcc) {
      this.selectedAcc.length = 0;
    }
    if (paramID === 2 && this.selectedPartners) {
      this.selectedPartners.length = 0;
    }
    this.changeType({ value: this.value3 });
  }

  public changeType(obj: any) {
    let partUid;
    let accUid;
    this.selectedPartners && this.selectedPartners.length > 0 ? (partUid = this.selectedPartners) : (partUid = null);
    this.selectedAcc && this.selectedAcc.length > 0 ? (accUid = this.selectedAcc) : (accUid = null);
    const body = {
      type_group: obj.value,
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      param_acc: accUid ?? undefined,
      param_part: partUid ?? undefined,
      shop_id: this.shopId,
    };
    if ([1, 2].includes(+obj.value)) {
      this.loadFinDDS(body);
    } else if (+obj.value === 3) {
      body.endDate = moment(this.selected.endDate.format('YYYY-MM-DD'), 'YYYY-MM-DD')
        .endOf('month')
        .format('YYYY-MM-DD');
      this.loadFinDDS(body);
    }
  }

  selectParameter() {
    const body = {
      type_group: this.value3,
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      param_acc: (this.selectedAcc || this.selectedAcc?.length > 0) ?? undefined,
      param_part: (this.selectedPartners || this.selectedPartners?.length > 0) ?? undefined,
      shop_id: this.shopId,
    };
    this.loadFinDDS(body);
  }

  private loadPartnersAndAccounts() {
    forkJoin([this._mpSurfService.load({}, 'fin-account'), this._mpSurfService.load({}, 'fin-partners')])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([responsePartners, responseAccounts]) => {
          this.partners = responsePartners;
          this.accounts = responseAccounts;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private loadFinDDS(params: any): void {
    this._mpSurfService
      .load(params, 'fin-dds')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.cols = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
