import { Component } from '@angular/core';

@Component({
  selector: 'app-advcompany',
  templateUrl: './advcompany.component.html',
  styleUrls: ['./advcompany.component.sass'],
})
export class AdvcompanyComponent {
  constructor() {}
}
