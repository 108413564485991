import {AuctionStatisticMock} from './advert-management-auction-statistic.interface';

export const AUCTION_TABLE_MOCK: AuctionStatisticMock[] = [
  {
    id: 1,
    request: 'Сумка мужская спортивная',
    category: 'Bags',
    places: []
  },
  {
    id: 2,
    request: 'Сумка для спорта',
    category: 'Bags',
    places: []
  },
  {
    id: 3,
    request: 'Сумка спортивная',
    category: 'Bags',
    places: []
  }
];

