import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';

@Component({
  selector: 'app-checkbox-renderer',
  template: `<input type="checkbox"
                    style="margin: 0 auto"
                    (click)="checkedHandler($event)"
                    [checked]="checkedInput"/>`,
})

export class CheckboxProductsRendererComponent implements ICellRendererAngularComp {
  checked: any;
  checkedInput: boolean;
  componentParent: any;
  private params: any;

  agInit(params: any): void {
    if (params) {
      this.checkedInput = +params.data.status_cancel !== 0;
    }
    this.params = params;
    this.componentParent = this.params.context.componentParent;
  }

  checkedHandler(event) {
    this.checked = event.target.checked;
    this.componentParent.onRowSelected(this.params.data);
  }

  refresh() {
    return false;
  }
}
