import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Params} from '@angular/router';

@Component({
    selector: 'app-btn-cell-renderer',
    template: `
        <div>
            <button label="" (click)="editPurchase()" class="icon-button"
                    style="border: none; background: transparent; margin-right: 15px;">
                <i class="lnr-pencil"></i>
            </button>
            <button label="" (click)="deletePurchase()" class="icon-button"
                    style="border: none; background: transparent;">
                <i class="lnr-trash"></i>
            </button>
        </div>
    `
})
export class BtnCellRendererComponent implements ICellRendererAngularComp {
    componentParent: any;
    private params: Params;

    agInit(params: Params): void {
        this.params = params;
        this.componentParent = this.params.context.componentParent;
    }

    editPurchase() {
        this.componentParent.editPurchaseEmitter(this.params.data);
    }

    deletePurchase() {
        this.componentParent.deletePurchase(this.params.data);
    }

    refresh() {
        return false;
    }
}
