import { ColDef, ColGroupDef } from 'ag-grid-community';
import { BtnCellRendererComponent } from './components/btn-cell-renderer.component';
import { CustomTooltipComponent } from '../products/components/prdcustomTooltip';
import { IDataShopWBList } from './finpurchase.interface';
import { WBImgFormatter } from '../../../shared/utils/functions/wb-img-formatter';

export const FINPURCHASE_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Фото',
    headerClass: 'header-centered',
    cellRenderer: WBImgFormatter,
    pinned: 'left',
    field: 'photo_new',
    width: 150,
    filter: 'agTextColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
    tooltipField: 'photo_new',
    tooltipComponentParams: {
      type: 'photo_new',
      photoField: 'photo_new',
    },
    tooltipComponent: CustomTooltipComponent,
  },
  {
    headerName: 'Артикул WB',
    headerClass: 'header-centered',
    field: 'nmid_label',
    width: 140,
    pinned: 'left',
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Артикул поставщика',
    headerClass: 'header-centered',
    pinned: 'left',
    field: 'supplier_code',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Размер',
    headerClass: 'header-centered',
    pinned: 'left',
    field: 'size',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Баркод',
    headerClass: 'header-centered',
    field: 'nmidGroup.code',
    pinned: 'left',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },

  {
    headerName: 'Комментарий',
    headerClass: 'header-centered',
    field: 'nmid_personal',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Дата закупки',
    headerClass: 'header-centered',
    field: 'date_purchase_not_exp',
    width: 220,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Поставщик',
    headerClass: 'header-centered',
    field: 'supplier_id.shop_name',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Количество',
    headerClass: 'header-centered',
    field: 'qty',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Стоимость партии закупки',
    headerClass: 'header-centered',
    field: 'batch_price',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Стоимость единицы закупки',
    headerClass: 'header-centered',
    field: 'batch_price_one',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Доставка на единицу от поставщика до ФФ/склада',
    headerClass: 'header-centered',
    field: 'delivery_per_unit_supplier_ff_wh',
    width: 200,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Стоимость маркировки и упаковки на 1 шт',
    headerClass: 'header-centered',
    field: 'pack_label_per_unit',
    width: 200,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Упаковочный материал доп., руб.',
    headerClass: 'header-centered',
    field: 'pack_material_additionally',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Отгрузка и доставка на ВБ с фф',
    headerClass: 'header-centered',
    field: 'delivery_wh_ff',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Фикс расходы на ед.',
    headerClass: 'header-centered',
    field: 'fix_price_per_unit',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Партия',
    headerClass: 'header-centered',
    field: 'batch',
    width: 140,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Действия',
    cellRenderer: BtnCellRendererComponent,
    autoHeaderHeight: true,
  },
];

export const DATA_SHOP_WEB_ALL: IDataShopWBList = {
  id: 0,
  user_id: null,
  name: 'Все',
  wb_key_status: null,
  wb_key_new_status: null,
  wb_api_adv_key_status: null,
  wb_auth_token_client_site: null,
  wb_auth_supplier_id_status: null,
  wb_auth_wbtoken_adv_status: null,
  wb_auth_wbtoken_seller_status: null,
  telegram_id: null,
  api_key_mpall: null,
};
