import {ColDef, ColGroupDef} from 'ag-grid-community';

export const GEO_ORDERS_COLUMN_DEFS_SUBJECT: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Предмет',
    headerClass: 'header-centered',
    field: 'subject_item',
    width: 270,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'Продажи, шт.',
    field: 'subject_orders_item',
    width: 200,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'Продажи, руб.',
    field: 'subject_orders_rubble',
    filter: 'agTextColumnFilter',
    width: 200,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'Доля, %',
    field: 'subject_orders_share',
    filter: 'agTextColumnFilter',
    width: 200,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  }
];

export const GEO_ORDERS_COLUMN_DEFS_WH: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Предмет',
    headerClass: 'header-centered',
    field: 'wh_shipment',
    width: 270,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'Продажи, шт.',
    field: 'wh_sales_item',
    width: 200,
    filter: 'agNumberColumnFilter',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'Продажи, руб.',
    field: 'wh_sales_rubble',
    filter: 'agTextColumnFilter',
    width: 200,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'Доля, %',
    field: 'wh_sales_share',
    filter: 'agTextColumnFilter',
    width: 200,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  }
];
export const GEO_ORDERS_REGION_DATA: any = {
  data: [
    {
      data: {
        name: 'Беларусь',
        region_orders_item: 123123123,
        region_orders_rubble: 5325234,
        region_orders_share: 123123
      }
    },
    {
      data: {
        name: 'Россия',
        region_orders_item: 2.2222222222,
        region_orders_rubble: '12313.00',
        region_orders_share: 123123
      },
      expanded: true,
      children: [
        {
          data: {
            name: 'Центральный Федеральный Округ',
            region_orders_item: 2.2222222222,
            region_orders_rubble: '12313.00',
            region_orders_share: 222111
          },
          expanded: false,
          children: [
            {
              data: {
                name: 'Московская область',
                region_orders_item: 2.2222222222,
                region_orders_rubble: '12313.00',
                region_orders_share: 222111
              },
              expanded: false,
              children: [
                {
                  data: {
                    name: 'г. Москва',
                    region_orders_item: 2.2222222222,
                    region_orders_rubble: '12313.00',
                    region_orders_share: 222111
                  }
                },
                {
                  data: {
                    name: 'г. Подольск',
                    region_orders_item: 2.2222222222,
                    region_orders_rubble: '12313.00',
                    region_orders_share: 222111
                  }
                },
                {
                  data: {
                    name: 'г. Домодедово',
                    region_orders_item: 2.2222222222,
                    region_orders_rubble: '12313.00',
                    region_orders_share: 222111
                  }
                },
                {
                  data: {
                    name: 'г. Химки',
                    region_orders_item: 2.2222222222,
                    region_orders_rubble: '12313.00',
                    region_orders_share: 222111
                  }
                },
              ]
            },
            {
              data: {
                name: 'Ближняя область',
                region_orders_item: 2.2222222222,
                region_orders_rubble: '12313.00',
                region_orders_share: 222111
              },
            }
          ]
        },
        {
          data: {
            name: 'Приволжский Федеральный Округ',
            region_orders_item: 2.2222222222,
            region_orders_rubble: '12313.00',
            region_orders_share: 555123
          }
        }
      ]
    },
    {
      data: {
        name: 'Казахстан',
        region_orders_item: 2.2222222222,
        region_orders_rubble: '2234890.00'
      },
      expanded: true,
      children: [
        {
          data: {
            name: 'Алматы',
            region_orders_item: 2.2222222222,
            region_orders_rubble: '2234890.00',
            region_orders_share: 4456666
          }
        }
      ]
    }
  ],
  columns: [
    {
      field: 'region_orders_item',
      header: 'Заказы, шт.'
    },
    {
      field: 'region_orders_rubble',
      header: 'Заказы, руб.'
    },
    {
      field: 'region_orders_share',
      header: 'Доля, %'
    },
  ]
};

