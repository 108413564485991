import {ITariffPercents, ITariffPrices} from "./tariff.interface";

export const AVAILABLE_TARIFF_TABS = [
    'АНАЛИТИКА',
    'Управление поставками',
    'DASHBOARD АНАЛИТИКА',
    'Тепловая карта',
    'Мои заказы',
    'Мои продажи',
    'ВНЕШНЯЯ АНАЛИТИКА',
    'Сравнение карточек',
    'ФИНАНСЫ',
    'Операции',
    'Отчеты - ОПиУ (PnL)',
    'Отчеты - DDS (Cashflow)',
    'Статьи',
    'Контрагенты',
    'Счета',
    'СПРАВОЧНИКИ',
    'Мои товары',
    'НАСТРОЙКИ',
    'Мои Юрлица'
];

export const MENU_HEADERS = [
    'АНАЛИТИКА',
    'DASHBOARD АНАЛИТИКА',
    'ВНЕШНЯЯ АНАЛИТИКА',
    'ФИНАНСЫ',
    'СПРАВОЧНИКИ',
    'НАСТРОЙКИ'
];

export const TARIFF_PERCENTS: ITariffPercents[] = [
    {
        id: 1,
        selector: 'one_month_selector',
        name: '1 месяц',
        sale: '',
    },
    {
        id: 2,
        selector: 'three_month_selector',
        name: '3 месяца',
        sale: '-10%'
    },
    {
        id: 3,
        selector: 'six_month_selector',
        name: '6 месяцев',
        sale: '-20%'
    },
    {
        id: 4,
        selector: 'twelve_month_selector',
        name: '12 месяцев',
        sale: '-30%'
    }
]

export const TARIFF_PRICES: ITariffPrices = {
    one_month_selector: {
        months: 1,
        base: 4200,
        extended: 10000,
        professional: 15000,
        corporate: 25000
    },
    three_month_selector: {
        months: 3,
        base: 3360,
        extended: 8000,
        professional: 12000,
        corporate: 20000
    },
    six_month_selector: {
        months: 6,
        base: 2940,
        extended: 7000,
        professional: 10500,
        corporate: 17500
    },
    twelve_month_selector: {
        months: 12,
        base: 2520,
        extended: 6000,
        professional: 9000,
        corporate: 15000
    }
}
