import { Component } from '@angular/core';
import { ThemeOptions } from '../../../../shared/theme-options/theme-options';
import {
  faCheck,
  faCloudDownloadAlt,
  faEllipsisH,
  faFile,
  faFileExcel,
  faFilePdf,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
})
export class DrawerComponent {

  faFile = faFile;
  faCloudDownloadAlt = faCloudDownloadAlt;
  faFilePdf = faFilePdf;
  faFileExcel =faFileExcel;
  faCheck = faCheck;
  faTrash = faTrash;
  faEllipsisH = faEllipsisH;

  constructor(public globals: ThemeOptions) {
  }

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }
}
