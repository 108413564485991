import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function ParserDateForSingleNgbDatepicker<T>(Date: any, field: string) {
  return {
    year: Date[field].year,
    month: Date[field].month,
    day: Date[field].day,
  };
}

export function ParserDateRangeNgbDatepicker(momentDate: moment.Moment): NgbDateStruct {
  return {
    year: momentDate.year(),
    month: momentDate.month() + 1, // NgbDateStruct months are 1-based
    day: momentDate.date(),
  };
}
