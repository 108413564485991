<p-progressSpinner *ngIf="isLoading"
                   [style]="{position: 'absolute', top: '40%', left: '50%'}">
</p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="col-sm-12 col-md-12">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Идет Beta тестирование</h5>
    </div>
    <div class="app-page-title mb-0">
      <div class="page-title-wrapper d-flex gap-3">
        <div class="page-title-heading d-flex">
          <div class="page-title-icon">
            <i class="pe-7s-users icon-gradient bg-mean-fruit"></i>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex gap-2 mt-3">
              <h4>ABS анализ</h4>
              <app-mp-ngb-range-datepicker (selectedDateEmitter)="dateChanged($event)"
                                        [styleParams]="{ height: '37px' }"/>
            </div>
            <span class="title-last">Последняя дата загруженной реализации: 22.01.2023</span>
          </div>
        </div>
        <div class="d-flex flex-wrap gap-2">
          <p-multiSelect [(ngModel)]="selectedIndicator"
                         [options]="INDICATORS"
                         [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                         optionLabel="label"
                         optionValue="value"
                         placeholder="Приоритетность показателей">
          </p-multiSelect>
          <p-dropdown [(ngModel)]="selectedArticleBarcode"
                      [options]="ARTICLE_BARCODE_SEPARATORS"
                      [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                      optionLabel="label"
                      optionValue="value">
          </p-dropdown>
          <p-dropdown [(ngModel)]="selectedWbParams"
                      [options]="WB_PARAMS"
                      [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                      optionLabel="label"
                      optionValue="value">
          </p-dropdown>
          <p-selectButton
            (onChange)="analystStatusChanged()"
            [(ngModel)]="selectedAnaliseStatus"
            [options]="ANALISISE_STATUSES"
            optionLabel="label"
            optionValue="value">
          </p-selectButton>
        </div>
      </div>
    </div>
    <br/>
  </div>
  <div class="row">
    <div class="col-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title text-center">Оборачиваемость</h5>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th *ngFor="let key of ABS_TABLE_DATA.turnOver.tr_th">
                {{key}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let key of ABS_TABLE_DATA.turnOver.tr_td">
              <td *ngFor="let tr of key.tr">{{tr}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title text-center">Маржиналость</h5>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th *ngFor="let key of ABS_TABLE_DATA.marginality.tr_th">
                {{key}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let key of ABS_TABLE_DATA.marginality.tr_td">
              <td *ngFor="let tr of key.tr">{{tr}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title text-center">Маржиналость</h5>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th *ngFor="let key of ABS_TABLE_DATA.revenue.tr_th">
                {{key}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let key of ABS_TABLE_DATA.revenue.tr_td">
              <td *ngFor="let tr of key.tr">{{tr}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="main-card card mt-3" style="height: calc(82vh - 153px)">
    <div style="width: 100%;height: 80vh;">
      <ag-grid-angular #agGrid
                       (gridReady)="onGridReady($event)"
                       [defaultColDef]="defaultColDef"
                       [enableRangeSelection]="true"
                       [gridOptions]="gridOptions"
                       [localeText]="localeTextForFilters"
                       [pagination]="true"
                       [rowData]="rowDataGeneral"
                       [tooltipHideDelay]="0"
                       [tooltipShowDelay]="2000"
                       class="ag-theme-alpine"
                       headerHeight="28"
                       style="width: 100%; height: 100%;">
      </ag-grid-angular>
    </div>
  </div>
</div>
