<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading" style="justify-content: space-between;width: 100%;">
        <div class="block-order-ftype">
          <div class="page-title-icon">
            <i class="pe-7s-map-2 icon-gradient bg-mean-fruit"></i>
          </div>
          <div>
            Управление поставками
          </div>
          <div *ngIf="!showSetupFilters" class="information-block">
            <div class="detail-info-part">
              <p class="detail-title">Дата поставки: </p>
              <p>{{displayDataForInformation('date_income')}}</p>
            </div>
            <div class="detail-info-part">
              <p class="detail-title">Количество заказов среднее за период: </p>
              <p> {{displayDataForInformation('orders_average_by_date')}}</p>
            </div>
            <div class="detail-info-part">
              <p class="detail-title">Загружаем склад на: </p>
              <p> {{supplierSettingForm.controls.day_for_supply.value}} дней</p>
            </div>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button class="mb-2 me-2 btn btn-outline-link" ngbDropdownToggle type="button">
            Скачать шаблон
          </button>
          <div ngbDropdownMenu>
            <button *ngFor="let type of EXCEL_SUPPLIER_TYPES"
                    class="dropdown-item">
              <a *ngIf="type.index === 1"
                 href="../../../../assets/images/download/supply_way.xlsx">
                Шаблон Поставка в пути, шт
              </a>
              <a *ngIf="type.index === 2"
                 href="../../../../assets/images/download/seasonality_coefficient.xlsx">
                Шаблон Коэффициент сезонности
              </a>
              <a *ngIf="type.index === 3"
                 href="../../../../assets/images/download/balances_ff.xlsx">
                Шаблон Остатки на ФФ
              </a>
            </button>
          </div>
        </div>
        <div class="d-inline-block" ngbDropdown>
          <button class="mb-2 me-2 btn btn-outline-link" ngbDropdownToggle type="button">
            Загрузить из эксель
          </button>
          <div ngbDropdownMenu>
            <button *ngFor="let type of EXCEL_SUPPLIER_TYPES"
                    class="dropdown-item">
              <p-fileUpload #uploadedFiles
                            (onBeforeUpload)="isLoading = true"
                            (onError)="onBasicUploadError($event,uploadedFiles)"
                            (onUpload)="onBasicUploadAuto($event,uploadedFiles)"
                            [auto]="true"
                            [maxFileSize]="10000000"
                            [style]="{width: '15em'}"
                            accept=".xls, .xlsx, .csv"
                            chooseLabel="{{type?.name}}"
                            class="button-add-purchase mr-4 inline-block"
                            label="Import"
                            mode="basic"
                            name="file"
                            url="{{URL_NEW}}supplies/uploaded?token={{TOKEN}}&shop_id={{SHOP_ID}}&param={{type?.params}}"
              ></p-fileUpload>
            </button>
          </div>
        </div>
        <div *ngIf="!showSetupFilters"
             class="manage-btn-part d-flex gap-3">
          <p-toast></p-toast>
          <button (click)="openModalWithFilters(supplierParameterModal)"
                  [style]="{height: '40px', fontSize: '12px'}"
                  class="p-button-success"
                  icon="pi pi-bars"
                  label="Настроить фильтры поставки"
                  pButton
                  pRipple>
          </button>
          <button (click)="clearSessionStorage()"
                  [disabled]="!isSessionStorageHasFilters"
                  [style]="{height: '40px', fontSize: '12px'}"
                  class="p-button-secondary"
                  label="Удалить сохраненные фильтры"
                  pButton
                  pRipple>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div [ngStyle]="{'display' : showSetupFilters ? 'none' : 'block'}" class="main-card mb-3 card"
       style="height: calc(92vh - 153px)">
    <div style="width: 100%;height: 75vh;">
      <ag-grid-angular #agGrid
                       (cellValueChanged)="onCellValueChanged($event)"
                       (gridReady)="onGridReady($event)"
                       [columnDefs]="supplierMangerColDEFS"
                       [defaultColDef]="defaultColDef"
                       [enableRangeSelection]="true"
                       [gridOptions]="gridOptions"
                       [localeText]="localeTextForFilters"
                       [pagination]="true"
                       [rowData]="rowData"
                       [tooltipHideDelay]="tooltipHideDelay"
                       [tooltipShowDelay]="tooltipShowDelay"
                       class="ag-theme-alpine"
                       headerHeight="28"
                       rowHeight="35"
                       style="width: 100%; height: 100%;">
      </ag-grid-angular>
    </div>
  </div>
  <ng-template #supplierParameterModal>
    <app-header-modal
      (closeEmitter)="closeModal()"
      [modalTitle]="'Настройка фильтров поставки'">
    </app-header-modal>
    <form [formGroup]="supplierSettingForm"
          class="needs-validation"
          novalidate>
      <div class="modal-body">
        <div class="position-relative">
          <label class="col-sm-3 col-form-label" for="date_income">Дата поставки<span
            style="color:red">*</span></label>
          <div class="col-sm-9">
            <div class="position-relative" style="margin-left: 0;">
              <input #d1="ngbDatepicker"
                     (click)="d1.toggle()"
                     class="form-control"
                     formControlName="date_income"
                     id="date_income"
                     name="date_income"
                     ngbDatepicker
                     placeholder="dd.mm.yyyy"
                     style="width: 450px;">
            </div>
            <span *ngIf="isControlValid('date_income')" class="alert-danger">
          </span>
          </div>
        </div>
        <div class="position-relative" style="margin-top: 20px;">
          <label class="" for="orders_average_amount">
            Количество заказов как среднее за выбранный период<span
            style="color:red">*</span>
          </label>
          <div class="">
            <input [(ngModel)]="DatePickerParameters.selectedData"
                   [alwaysShowCalendars]="true"
                   [isInvalidDate]="DatePickerParameters.isInvalidDate"
                   [linkedCalendars]="true"
                   [locale]="DatePickerParameters.localParamsRU"
                   [maxDate]='DatePickerParameters.maxDate'
                   [minDate]='DatePickerParameters.minDate'
                   [ranges]="ranges"
                   [showClearButton]="false"
                   [showCustomRangeLabel]="true"
                   class="datepicker-modal"
                   formControlName="orders_average_by_date"
                   id="orders_average_amount"
                   ngxDaterangepickerMd
                   placeholder="Выберите даты"
                   type="text"/>
          </div>
        </div>
        <div class="position-relative" style="margin-top: 20px;">
          <label class="" for="day_for_supply">На сколько дней загружаем склад <span
            style="color:red">*</span></label>
          <div class="">
            <input class="form-control" formControlName="day_for_supply" id="day_for_supply" maxLength="10"
                   name="amount"
                   placeholder="12"
                   style="width: 450px" type="text">
            <span
              *ngIf="supplierSettingForm.controls['day_for_supply'].hasError('invalidecode') && isControlValid('day_for_supply')"
              class="alert-danger"></span>
          </div>
        </div>
        <div class="position-relative" style="margin-top: 20px;">
          <label class="" for="day_for_supply">С учетом складов</label>
          <div class="">
            <p-inputSwitch formControlName="ware_house_include"></p-inputSwitch>
          </div>
        </div>
        <div *ngIf="isWareHousesInclude">
          <div class="d-flex align-items-center gap-2" style="margin-top: 14px;margin-left: 16px;">
            <label (click)="selectMSKWH()"
                   class="p-checkbox-label checked_msk">
              {{isMskWarehouseSelected ? 'Убрать Склады МСК' : 'Склады МСК'}}
            </label>
            <label (click)="selectUnselectWh(checkUncheckSelectedTitle)"
                   class="p-checkbox-label checked_color">
              {{checkUncheckSelectedTitle}}
            </label>
          </div>
          <div class="position-relative main-ware-house"
               formArrayName="ware_houses">
            <div *ngFor="let wareHouseControl of SupplierFormArray.controls; let i = index"
                 class="ware_house_list">
              <p-checkbox [formControlName]="i"
                          [label]="wareHousesList[i]?.wb_wh_name"
                          [value]="wareHousesList[i]?.wb_wh_id"
                          id="{{i}}" inputId="ny"
                          name="group1">
              </p-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <p-toast></p-toast>
        <button (click)="applyFilters()"
                [disabled]="isConfirmFilterInvalid"
                class="btn btn-primary"
                type="submit">
          Расчитать
        </button>
      </div>
    </form>
  </ng-template>
</div>

