import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService, PrimeNGConfig, TreeDragDropService, TreeNode } from 'primeng/api';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { CommonValueWithID } from '../../../../shared/interfaces/common.interface';
import { ListOfGroupDDS } from './finitems.mock';
import { AbstractMenuTabComponent } from '../../../abstract-menu-tab.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { PopUpMessages } from '../../../../shared/mocks/pop-up-messages.mock';
import { IResponseCommon } from '../../../settings/shop/shop.interface';
import { IFinItem } from './finitems.interface';

@UntilDestroy({ checkProperties: this })
@Component({
  selector: 'app-finitems',
  templateUrl: './finitems.component.html',
  styleUrls: ['./finitems.component.sass'],
  providers: [TreeDragDropService, ConfirmationService, MessageService],
})
export class FinitemsComponent extends AbstractMenuTabComponent implements OnInit {
  isLoading = false;
  options: GlobalConfig;
  Form = new UntypedFormGroup({
    name: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    group_dds: new FormControl('', [Validators.required]),
    group_pnl: new FormControl(''),
    p_l: new FormControl(1),
    is_visible: new FormControl(0),
    description: new FormControl(null),
  });
  activeReceiptID = 0;
  activeOrderID = 0;
  showSelectDropDown = false;
  listOfGroupsPnl: CommonValueWithID[] = [];
  listOfGroupsPnlSpisanie: CommonValueWithID[] = [];
  listOfGroupsDds: CommonValueWithID[] = ListOfGroupDDS;
  finItemsMain: TreeNode[] = [];
  @ViewChild('receipt', { read: TemplateRef }) modalReceipt: TemplateRef<any>;
  @ViewChild('order', { read: TemplateRef }) modalOrder: TemplateRef<any>;
  @ViewChild('treeComponent', { static: false }) treeComponent: any;
  private lastInserted: number[] = [];

  constructor(
    public toastr: ToastrService,
    private primengConfig: PrimeNGConfig,
  ) {
    super();
    this.options = this.toastr.toastrConfig;
    this.Form.controls.p_l.valueChanges.subscribe((value) => {
      this.showSelectDropDown = value;
    });
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.loadItemGroupPNL();
    this.loadItemGroupPNLSpisanie();
    this.loadItems();
  }

  onNodeDropped(event: any) {
    const draggedNode = event.dragNode;
    const droppedNode = event.dropNode;
    if (
      draggedNode.parent &&
      droppedNode.parent &&
      draggedNode.parent.label === droppedNode.parent.label &&
      !droppedNode?.children
    ) {
      this.updateFinItemsOrderByPositionEvent();
      return;
    } else if (
      typeof droppedNode.parent !== 'undefined' ||
      (typeof draggedNode.parent === 'undefined' && draggedNode?.children.length > 0)
    ) {
      this.showPopUpMessage('error', 'Позиция не изменена', '');
      this.loadItems();
      return;
    } else {
      this.showPopUpMessage('success', 'Позиция изменена', '');
      this.updateFinItemsOrderByPositionEvent();
      return;
    }
  }

  openModal(content: any) {
    this.isEditModal = false;
    this.activeReceiptID = 0;
    this.Form.reset();
    this._modalService.open(content);
  }

  createUpdateItem(activeItem: 'receipt' | 'order') {
    this.isLoading = true;
    const plControl = this.Form.controls.p_l as FormControl;
    plControl.value ? plControl.setValue(1) : plControl.setValue(0);
    let url = 'fin-items';
    const body = this.Form.getRawValue();

    //RECEIPT - 1 | ORDER - 2
    activeItem === 'receipt' ? (body.item_type = 1) : (body.item_type = 2);
    if (this.isEditModal) {
      activeItem === 'receipt' ? (url += `/${this.activeReceiptID}`) : (url += `/${this.activeOrderID}`);
    }

    this._mpSurfService
      .createOrUpdate(body, url)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: IResponseCommon) => {
          if (+response.is_error === 0) {
            this._modalService.dismissAll();
            this.Form.reset();
            this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, PopUpMessages.createSuccessMessage);
            this.loadItems();
          }
          if (+response.is_error === 1) {
            this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
        },
      );
  }

  modalEditItem(fileData: any) {
    this.isLoading = true;
    this._mpSurfService
      .load({}, `fin-items/${fileData.key}`)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data: IFinItem) => {
          this.Form.patchValue({
            name: data.name,
            description: data.description,
            p_l: +data.p_l === 1,
            is_visible: +data.is_visible === 1 || 0,
            group_pnl: +data.group_pnl,
            group_dds: +data.group_dds,
          });

          this.isEditModal = true;
          if (+data.item_type === 1) {
            this.activeReceiptID = fileData.key;
            this._modalService.open(this.modalReceipt);
          }
          if (+data.item_type === 2) {
            this.activeOrderID = fileData.key;
            this._modalService.open(this.modalOrder);
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  deleteItem(fileData: any) {
    this._confirmationService.confirm({
      message: 'Вы уверены, что хотите удалить статью?',
      header: 'Подтверждение удаления',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Да',
      rejectLabel: 'Нет',
      accept: () => {
        this.isLoading = true;
        this._mpSurfService
          .delete({}, `fin-items/${fileData.key}`)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            (response: IResponseCommon) => {
              if (response.is_error === 0) {
                this.showPopUpMessage(
                  'success',
                  PopUpMessages.deleteSuccessSummary,
                  PopUpMessages.deleteSuccessMessage,
                );
                this.loadItems();
              }
              if (response.is_error === 1) {
                this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
              }
            },
            () => {
              this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
            },
          );
      },
      reject: () => {},
    });
  }

  isPnlNameExist(node: any): string {
    if (node && node?.pnl_name) {
      return `(${node.pnl_name})`;
    }
    return '';
  }

  private updateFinItemsOrderByPositionEvent() {
    this.isLoading = true;
    const clonedItems: TreeNode[] = this.adjustTreeForRequest();
    // this.api
    //   .userRegisterFin('updatePosisitonItem2', localStorage.getItem('token'), {
    //     shop_id: this.shop_id,
    //     params: clonedItems,
    //   })
    this._mpSurfService
      .createOrUpdate(clonedItems, 'fin-items/update-posisiton')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response) => {
          console.log(response);
          this.showPopUpMessage('success', 'Cтатья перемещена', '');
        },
        () => {
          this.showPopUpMessage(
            'error',
            'Ошибка перемещения',
            'Не удалось переместить статью. Проверьте правильность и повторите попытку',
          );
        },
      );
  }

  private loadItems() {
    this.isLoading = true;
    this._mpSurfService
      .load({}, 'fin-items')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: TreeNode[]) => {
          this.finItemsMain = response.slice();
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private loadItemGroupPNL(): void {
    this.isLoading = true;
    this._mpSurfService
      .load({}, 'fin-items/pnl-receipt')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: CommonValueWithID[]) => {
          this.listOfGroupsPnl = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private loadItemGroupPNLSpisanie(): void {
    this.isLoading = true;
    this._mpSurfService
      .load({}, 'fin-items/pnl-spisanie')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: CommonValueWithID[]) => {
          this.listOfGroupsPnlSpisanie = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private adjustTreeForRequest(): TreeNode<any>[] {
    return this.finItemsMain.map((item) => {
      const clonedItem: TreeNode = {
        key: item.key,
        label: item.label,
        data: item.data,
        icon: item.icon,
        expanded: item.expanded,
        children:
          item.children?.map((child) => ({
            key: child.key,
            label: child.label,
            data: child.data,
            icon: child.icon,
          })) || [],
        parent: item.parent,
      };
      return clonedItem;
    });
  }
}
