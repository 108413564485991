<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="block-order-ftype date-pick-block">
        <div class="page-title-icon">
          <i class="lnr-cart icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          Общие показатели
        </div>
        <div class="btn-actions-pane-left">
          <input [disabled]="disabledDatePicker" type="text" ngxDaterangepickerMd
                 [locale]="{
                        applyLabel: 'применить',
                        direction: 'ltr',
                        format: 'DD.MM.YYYY',
                        'daysOfWeek': ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
                        'customRangeLabel': 'Свой диапазон',
                        'firstDay': 1,
                        'monthNames': ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']}"
                 [(ngModel)]="selectedDate" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"
                 [ranges]="DatePickerRange"
                 [linkedCalendars]="true" [isInvalidDate]="isInvalidDate" [showClearButton]="false" [maxDate]='maxDate'
                 [minDate]='minDate' (change)="loadDataWithNewDate()" placeholder="Выберите даты"/>
        </div>
      </div>
      <div class="block-order-ftype">
        <div class="btn-actions-pane-left">
          <p-multiSelect [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                         [options]="allBrands"
                         [(ngModel)]="selectedBrand"
                         (onChange)="selectedBrandChange($event)" [maxSelectedLabels]="1"
                         optionLabel="name" itemSize="30"
                         emptyMessage="Брендов не найдено"
                         defaultLabel="Выберите бренд"
                         selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
        </div>
        <div class="btn-actions-pane-left">
          <p-multiSelect [options]="allItems"
                         [(ngModel)]="selectedItem"
                         [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                         (onChange)="selectedItemChange($event)" [maxSelectedLabels]="1"
                         optionLabel="name" itemSize="30"
                         emptyMessage="Предметов не найдено"
                         defaultLabel="Выберите предмет" selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
        </div>&nbsp;
      </div>
      <div class="block-order-ftype">
        <div class="btn-actions-pane-left">
          <p-multiSelect [options]="allArticlesWB" [(ngModel)]="selectedArticleWB"
                         [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                         (onChange)="selectedArticleWbChange($event)" [maxSelectedLabels]="1"
                         optionLabel="name" itemSize="30"
                         emptyMessage="Артикулов WB не найдено"
                         defaultLabel="Выберите aртикул WB" selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
        </div>
        <div class="btn-actions-pane-left">
          <p-multiSelect [options]="allMyArticles" [(ngModel)]="selectedMyArticle"
                         [style]="{ display: 'flex', alignItems: 'center', height: '37px' }"
                         (onChange)="selectedMyArticleChange($event)" [maxSelectedLabels]="1"
                         optionLabel="name" itemSize="30"
                         emptyMessage="Моих артикулов не найдено"
                         defaultLabel="Выберите мой артикул" selectedItemsLabel="Выбрано {0}">
          </p-multiSelect>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-header">
    <h2>
      Общие показатели
    </h2>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="card main-card mb-3">
        <div class="common-rates-wrapper">
          <div *ngFor="let rate of dataForCommonRatesTable1"
               [style.width]="rate.separated ? '150px' : '250px'"
               class="common-rates-block card-shadow-primary">
            <div class="common-rates-title">
              <h3>{{rate.title}}</h3>
            </div>
            <div class="common-rates-issue">
              <div class="common-rates-price">
                <h4 class="amount-rates">{{!rate.ratesPercent ? rate.ratesSum : rate.ratesPercent}}</h4>
                <p *ngIf="!rate.ratesPercent" class="amount-title">Сумма</p>
              </div>
              <div *ngIf="!rate.separated" class="common-rates-price">
                <h4 class="amount-rates">{{rate.ratesAmount}}</h4>
                <p class="amount-title">Кол-во</p>
              </div>
            </div>
          </div>
          <div class="common-rates-block card-shadow-primary">
            <div class="common-rates-title">
              <h3>Прибыль</h3>
            </div>
            <div class="common-rates-issue">
              <div class="common-rates-price">
                <h4 class="amount-rates" style="color: red">1 417 123</h4>
                <p class="amount-title">Пр. месяц: 1 222 333 (-30.22%)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card main-card mb-3">
  <div class="row">
    <div class="items-balance-wrapper">
      <div class="block-balance">
        <div class="title-item">
          <h2>
            Товарный остаток
          </h2>
        </div>
        <div class="rates-block">
          <div *ngFor="let sale of dataForSalesBalance"
               class="common-rates-block card-shadow-primary" style="width: 150px">
            <div class="common-rates-title">
              <h3>{{sale.title}}</h3>
            </div>
            <div class="common-rates-issue">
              <div class="common-rates-price">
                <h4 class="amount-rates">{{sale.amount}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block-balance">
        <div class="title-item">
          <h2>
            Ср. объем заказов в день
          </h2>
        </div>
        <div class="rates-block">
          <div *ngFor="let averageOrder of dataForAverageOrders"
               class="common-rates-block card-shadow-primary" style="width: 150px">
            <div class="common-rates-title">
              <h3>{{averageOrder.title}}</h3>
            </div>
            <div class="common-rates-issue">
              <div class="common-rates-price">
                <h4 class="amount-rates">{{averageOrder.amount}}</h4>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="title-item">
      <h4>Структура товарного остатка</h4>
    </div>
    <div class="card-body">
      <apx-chart
        [series]="dataForSalesBalanceChart.series"
        [chart]="{ height: '400', type: 'bar'}"
        [xaxis]="dataForSalesBalanceChart.xaxis">
      </apx-chart>
    </div>
  </div>
</div>
<div class="card main-card mb-3">
  <div class="card-header">
    <h2>
      Динамика продаж
    </h2>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="tabs-block">
        <div class="tab-sale"
             [ngClass]="{'active' : sale.btnStatus}"
             (click)="toggleSaleDynamic(sale, i)"
             *ngFor="let sale of chartSeriesData; let i = index;">
          <p>{{sale.name}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <apx-chart [series]="chartSeriesFilteredData"
                 [stroke]="{curve: 'smooth'}"
                 [xaxis]="{
                  type: 'category',
                  categories: apxChartSalesDynamicCategories
                }"
                 [chart]="{
                  height: '350',
                  type: 'line'
                }">
      </apx-chart>
    </div>
  </div>
</div>
<div class="card main-card mb-3">
  <div class="card-header">
    <h2>
      Структура продаж
    </h2>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="title-item">
        <h4 class="title-blocks-main">Количество продаж по областям</h4>
      </div>
      <div class="card-body">
        <apx-chart
          [series]="dataForSalesStructureChart.series"
          [plotOptions]="{bar: {horizontal: true}}"
          [chart]="{ height: '400', type: 'bar'}"
          [xaxis]="dataForSalesStructureChart.xaxis">
        </apx-chart>
      </div>
    </div>
    <div class="row">
      <div class="block-sales-str-wrapper">
        <div class="block-sales-str-chart">
          <div class="card-header">
            Количество продаж по брендам
          </div>
          <div class="card-body">
            <apx-chart [series]="seriesChartSaleStructureByBrand"
                       [labels]="rowSalesSubjectChartTitlesByBrand"
                       [chart]="{
                  height: '350',
                  type: 'donut'
                }">
            </apx-chart>
          </div>
        </div>
        <div class="block-sales-str-chart">
          <div class="card-header">
            Количество продаж по предметам
          </div>
          <div class="card-body">
            <apx-chart [series]="seriesChartSaleStructureBySubjects"
                       [labels]="rowSalesSubjectChartTitlesBySubjects"
                       [chart]="{
                  height: '350',
                  type: 'donut'
                }">
            </apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
