import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        tap((event: any) => {
          const body = event.body; // {data: []} || {data: null, isAuthorized: false, msg: 'not Authorized'}
          if (body && Object.keys(body).includes('isAuthorized')) {
            this.router.navigate(['/auth/login'], {queryParams: {accessDenited: 1}})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          return throwError(errorMsg);
        })
      );
  }
}
