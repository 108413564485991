import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ApiService  } from '../../../shared/services/api.service';

import * as FileSaver from 'file-saver';
import {
  GlobalConfig,
  ToastrService,
  ToastContainerDirective,
  ToastNoAnimation,
} from 'ngx-toastr';


import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mystocks',
  templateUrl: './mystocks.component.html',
  styleUrls: ['./mystocks.component.sass']
})
export class MystocksComponent implements OnInit {

    exportList: any[] = [];

    items:any;
    listwh:any;

    items_exp:any;

    update_stock_basket_date:any;
    update_stock_WB_date:any;

    faArrowDown = faArrowDown;

   constructor(
        private http: HttpClient,
        private api: ApiService,

        private router: Router,
        private route:ActivatedRoute,
        public  toastr: ToastrService,
    ) {
        this.getStockExport();
    }

  ngOnInit(): void {

      this.getWH();
      this.getItem();
  }

  //////////////////////     EXECL  //////////////////////////

    exportExcel() {

        if (this.exportList.length > 0) {
            import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.exportList);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "ExportExcel");
        });
        }
    }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }




    getWH(){
        let data_shop = localStorage.getItem('mpall_shop');
        let new_data_shop = JSON.parse(data_shop);
        let body = {shop_id:new_data_shop.shop};
        return new Promise((resolve, reject) => {
                    this.api.userRegister('getStockWBWH', localStorage.getItem('token'), body)
                        .subscribe(async (data : any) => {
                            console.log('getStockWBWH',data);

                            this.listwh = [];
                            for(let customer of data){
                                this.listwh.push(customer);
                            }

                            resolve(true);


                        }, (err) => {

                            console.log(err);

                            reject(err);
                        });
                    });
    }


    onChange(deviceValue) {
        console.log(deviceValue);
        let data_shop = localStorage.getItem('mpall_shop');
        let new_data_shop = JSON.parse(data_shop);
        let body = {shop_id:new_data_shop.shop, wh:deviceValue};
        return new Promise((resolve, reject) => {
                    this.api.userRegister('getStockWB', localStorage.getItem('token'), body)
                        .subscribe(async (data : any) => {
                            console.log('DATAAAAA',data);

                            this.items = [];
                            for(let customer of data){
                                this.items.push(customer);
                            }

                            resolve(true);


                        }, (err) => {

                            console.log(err);

                            reject(err);
                        });
                    });
    }

    // для экспорта в эксель
    getStockExport(){
        let data_shop = localStorage.getItem('mpall_shop');
        let new_data_shop = JSON.parse(data_shop);
        let body = {shop_id:new_data_shop.shop};
        return new Promise((resolve, reject) => {
                    this.api.userRegister('getStockExport', localStorage.getItem('token'), body)
                        .subscribe(async (data : any) => {
                            console.log('getStockExport',data);

                            this.items_exp = [];
                            for(let customer of data){
                                this.items_exp.push(customer);
                            }
                            this.exportList = this.items_exp;
                            resolve(true);


                        }, (err) => {

                            console.log(err);

                            reject(err);
                        });
                    });
    }

    // получаем список товаров
    getItem(){

        let data_shop = localStorage.getItem('mpall_shop');
        let new_data_shop = JSON.parse(data_shop);
        let body = {shop_id:new_data_shop.shop};
        return new Promise((resolve, reject) => {
                    this.api.userRegister('getStockWB', localStorage.getItem('token'), body)
                        .subscribe(async (data : any) => {
                            console.log('DATAAAAA',data);

                            this.update_stock_basket_date = data.update_basket;
                            this.update_stock_WB_date = data.update_wb;

                            this.items = [];
                            for(let customer of data.items){
                                this.items.push(customer);
                            }
                            //this.exportList = this.items;
                            resolve(true);


                        }, (err) => {

                            console.log(err);

                            reject(err);
                        });
                    });
    }

}
