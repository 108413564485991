import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import * as moment from 'moment';

import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';

import { faAngleDown, faAngleUp, faCalendarAlt, faCheck, faTh, faTrash } from '@fortawesome/free-solid-svg-icons';

import { ToastrService, } from 'ngx-toastr';

import { ApexAxisChartSeries, ApexChart, ApexTitleSubtitle, ApexXAxis } from "ng-apexcharts";


export type ChartOptions5 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.sass']
})
export class IndicatorsComponent implements OnInit {

    alwaysShowCalendars: boolean;
    ranges: any = {
      'Сегодня': [moment(), moment()],
      'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'За 7 дней': [moment().subtract(6, 'days'), moment()],
      'За 30 дней': [moment().subtract(29, 'days'), moment()],
      'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
      'Прошлая неделя': [moment().subtract(1, 'weeks').startOf('isoWeek'), moment().subtract(1, 'weeks').endOf('isoWeek')],
      'Прошлый Месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
    invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

    isInvalidDate = (m: moment.Moment) =>  {
      return this.invalidDates.some(d => d.isSame(m, 'day') )
    }

    minDate = moment().add(-91, 'days');
    maxDate = moment();

    selected:any = {startDate: moment().add(-7, 'days'), endDate: moment()};
    //selected:any = {startDate: moment(), endDate: moment()};



//////////////////////////////////////////////////////////////////
    //public lineChartData: ChartDataSets[] = [];
    favoriteSeason: string;
    seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
    selectedState = '';
    state = '';

    public lineChartData: ChartDataSets[] = [
        {
            data: [0],
            label: 'Продажи ₽',
            type: 'line'
        },
        {
            data: [0],
            label: 'Продажи шт',
            type: 'bar'
        },
        {
            data: [0],
            label: 'Заказы ₽',
            type: 'line'
        },
        {
            data: [0],
            label: 'Заказы шт',
            type: 'line'
        },
        {
            data: [0],
            label: 'Возвраты ₽',
            yAxisID: 'y-axis-1'
        },
        {
            data: [0],
            label: 'Возвраты шт',
            yAxisID: 'y-axis-1'
        },
        {
            data: [0],
            label: 'Логистика ₽',
            yAxisID: 'y-axis-1'
        },
        {
            data: [0],
            label: 'Логистика шт',
            yAxisID: 'y-axis-1'
        }

    ];


    lineChartLabels:any;

    public lineChartOptions: (ChartOptions & { annotation: any }) = {
        responsive: true,
        scales: {
          // We use this empty structure as a placeholder for dynamic theming.
          xAxes: [{}],
          yAxes: [
            {
              id: 'y-axis-0',
              position: 'left',
            },
            {
              id: 'y-axis-1',
              position: 'right',
              gridLines: {
                //color: 'rgba(255,0,0,0.3)',
              },
              ticks: {
                fontColor: 'red',
              }
            }
          ]
        },
        annotation: {
          annotations: [
            {
              type: 'line',
              mode: 'vertical',
              scaleID: 'x-axis-0',
              value: 'March',
              borderColor: 'orange',
              borderWidth: 2,
              label: {
                enabled: true,
                fontColor: 'orange',
                content: 'LineAnno'
              }
            },
          ],
        },
    };

    public lineChartColors: Color[] = [];
    /*
    public lineChartColors: Color[] = [
      { // grey
        backgroundColor: 'rgba(148,159,177,0.2)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      },
      { // dark grey
        backgroundColor: 'rgba(77,83,96,0.2)',
        borderColor: 'rgba(77,83,96,1)',
        pointBackgroundColor: 'rgba(77,83,96,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,83,96,1)'
      },
      { // red
        backgroundColor: 'rgba(255,0,0,0.3)',
        borderColor: 'red',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      }
    ];
    */
    public lineChartLegend = true;
    public lineChartType = 'bar';
    public lineChartPlugins = [pluginAnnotations];


    faAngleDown = faAngleDown;
    faAngleUp = faAngleUp;
    faTh = faTh;
    faCheck = faCheck;
    faTrash = faTrash;
    faCalendarAlt = faCalendarAlt;


    public chartOptions5: any;
    public chart_rev: any; // выручка
    public chart_sold: any; // проадно шутк
    public chart_stock: any; // остаток

    total_rev_cost:any;
    total_rev_period:any;

    total_sold_cost:any;
    total_sold_period:any;

    yesdata:any;

    dataperiod:any = 30;
    param_indicator:any;

    shop_arr:any;

    private gridApi;
    private gridColumnApi;


    date_begin_compair:any;
    date_end_compair:any;

  constructor(
        private http: HttpClient,
        private api: ApiService,

        private router: Router,
        private route:ActivatedRoute,
        public  toastr: ToastrService,

        private fb: FormBuilder,
    ) {

        let shop_arr  = localStorage.getItem('mpall_shop');

        this.shop_arr = JSON.parse(shop_arr);


  }



  ngOnInit() {

        let data_shop = localStorage.getItem('mpall_shop');
        let new_data_shop = JSON.parse(data_shop);

        if (new_data_shop == null){
          this.getDataAgv(300, 0);
        } else {
          this.getDataAgv(300, new_data_shop.shop);
        }



  }

    /* OLD
    newValue(param){
        console.log(param);
        let data_shop = localStorage.getItem('mpall_shop');
        let new_data_shop = JSON.parse(data_shop);
        this.getDataAgv(param, new_data_shop.shop);
    }
    */
    newValue(){

        let data_shop = localStorage.getItem('mpall_shop');
        let new_data_shop = JSON.parse(data_shop);
        if (new_data_shop == null){
          this.getDataAgv(300, 0);
        } else {
          this.getDataAgv(300, new_data_shop.shop);
        }

    }


    // получаем агрегитрвоанные данные за определенное время
    getDataAgv(period, shop_id){
        let body = {period: period, startDate:this.selected.startDate.format("YYYY-MM-DD"), endDate:this.selected.endDate.format("YYYY-MM-DD"), shop_id:shop_id};
        return new Promise((resolve, reject) => {
                    this.api.userRegister('getIndicatorDataAgv', localStorage.getItem('token'), body)
                        .subscribe( (data : any) => {
                            //console.log('INDICATOR',data);
                            this.param_indicator = data;
                            this.lineChartLabels = data.chart_label;
                            this.date_begin_compair = data.date_compair.begin;
                            this.date_end_compair   = data.date_compair.end;

                            this.lineChartData = [
                                {
                                    data: data.chart_sales_price,
                                    label: 'Продажи ₽',
                                    type: 'line'
                                },
                                {
                                    data: data.chart_sales_qty,
                                    label: 'Продажи шт',
                                    type: 'bar',
                                    yAxisID: 'y-axis-1'
                                },
                                {
                                    data: data.chart_order_price,
                                    label: 'Заказы ₽',
                                    type: 'line'
                                },
                                {
                                    data: data.chart_order_qty,
                                    label: 'Заказы шт',
                                    type: 'bar',
                                    yAxisID: 'y-axis-1'
                                },
                                {
                                    data: data.chart_return_price,
                                    label: 'Возвраты ₽',
                                    type: 'line'
                                },
                                {
                                    data: data.chart_return_qty,
                                    label: 'Возвраты шт',
                                    type: 'bar',
                                    yAxisID: 'y-axis-1'
                                },

                            ];

                            //console.log(this.lineChartData);
                            /*
                            lineChartData: ChartDataSets[] = [
                                    {
                                        data: [65, 59, 80, 81, 56, 55, 40],
                                        label: 'Продажи ₽',
                                        type: 'line'
                                    },
                                    {
                                        data: [99, 59, 80, 81, 56, 55, 40],
                                        label: 'Продажи шт',
                                        type: 'bar'
                                    },
                                    {
                                        data: [28, 48, 40, 19, 86, 27, 90],
                                        label: 'Заказы ₽',
                                        type: 'line'
                                    },
                                    {
                                        data: [28, 48, 40, 19, 86, 27, 90],
                                        label: 'Заказы шт',
                                        type: 'line'
                                    },
                                    {
                                        data: [180, 480, 770, 90, 1500, 270, 400],
                                        label: 'Возвраты ₽',
                                        yAxisID: 'y-axis-1'
                                    },
                                    {
                                        data: [180, 480, 770, 90, 1500, 270, 400],
                                        label: 'Возвраты шт',
                                        yAxisID: 'y-axis-1'
                                    },
                                    {
                                        data: [89, 740, 560, 250, 750, 456, 777],
                                        label: 'Логистика ₽',
                                        yAxisID: 'y-axis-1'
                                    },
                                    {
                                        data: [89, 740, 560, 250, 750, 456, 777],
                                        label: 'Логистика шт',
                                        yAxisID: 'y-axis-1'
                                    }

                                ];
                            */
                            resolve(true);
                        }, (err) => {

                            console.log(err);

                            //reject(err);
                        });
                    });

    }

    getData(){

            return new Promise((resolve, reject) => {
                this.api.userRegister('getIndicatorData', localStorage.getItem('token'), '')
                    .subscribe( (data : any) => {
                            console.log(data);
                            if (data.rev.data.one.data.length > 0){
                            this.yesdata = true;

                            this.total_rev_cost = data.rev.total_rev_cost;
                            this.total_rev_period = data.rev.total_rev_period;

                            this.total_sold_cost = data.sold.total_sold_cost;
                            this.total_sold_period = data.sold.total_sold_period;


                            /// Выручка
                            this.chart_rev = {
                                                series: [{name: data.rev.data.one.name, data: data.rev.data.one.data}, {name: data.rev.data.two.name, data: data.rev.data.two.data}
                                                        ],
                                                chart: {height: 350, type: "area"},
                                                xaxis: {categories: data.rev.data.cat}
                            };
                            // продано ШТ
                            this.chart_sold = {
                                                series: [{name: data.sold.data.one.name, data: data.sold.data.one.data}, {name: data.sold.data.two.name, data: data.sold.data.two.data}],
                                                chart: {height: 350, type: "area"},
                                                xaxis: {categories: data.sold.data.cat}
                            };


                            } else {
                                this.yesdata = false;
                            }

                            resolve(true);


                        }, (err) => {

                            console.log(err);

                            //reject(err);
                        });
                    });
    }

    public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
        console.log(event, active);
    }

    public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
        console.log(event, active);
    }

    changeState() {
        this.state = this.selectedState;
    }

}
