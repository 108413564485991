import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels, ApexFill,
  ApexLegend,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis
} from 'ng-apexcharts';
import {IMyOrdersHintDataArr} from '../../../shared/mocks/my-orders';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  fill: ApexFill;
};


export const myOrdersHintDataArr: IMyOrdersHintDataArr[] = [
  {
    hintStep: 1,
    hintNextStep: 2,
    hintTitle: 'Фильтры',
    hintIssue: 'Позволяет настраивать инветрвал дат за нужный период, фильтрвать по артикулам, баркодам, складам, категориям '
  },
  {
    hintStep: 2,
    hintNextStep: 3,
    hintTitle: 'График',
    hintIssue: 'Отображает инфрмацию о заказах рублях, штуках и отменах за выбраннй период времен'
  },
  {
    hintStep: 3,
    hintNextStep: 4,
    hintTitle: 'Сумарные показатели',
    hintIssue: ''
  },
  {
    hintStep: 4,
    hintNextStep: 5,
    hintTitle: 'Таблица с заказами',
    hintIssue: ''
  },
];