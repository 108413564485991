<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="fa fa-car icon-gradient bg-mean-fruit"></i>
      </div>
      <div>
        ПОСТАВКИ {{control_id}}
        <div *ngIf="control_id" class="page-title-subheading">
          <a [routerLink]="['/guides/control/']">к списку поставок</a>
        </div>
      </div><!--mysupply Расчитать поставку -->
    </div>
  </div>
</div>
<!--<div class="row">
    <img src="/assets/images/techimg/job.jpg">
</div>-->
<div *ngIf="!control_id" class="main-card mb-3 card">
  <div class="card-header">
    Поставки
  </div>
  <div class="table-responsive">
    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th class="text-center">поставщик</th>
        <th class="text-center">Номер</th>
        <th class="text-center">Склад</th>
        <th class="text-center">Статус</th>
        <th class="text-center" colspan="2">Стоимость</th>
        <th class="text-center">SKU</th>
        <th class="text-center">Количество товаров</th>
        <th class="text-center">Создана</th>
        <th class="text-center">Обновлена</th>
        <th class="text-center">Завершена</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of items; let i = index">
        <td class="text-center text-muted">{{item.seller_name}}</td>
        <td class="text-center text-muted"><a [routerLink]="['/guides/control/', item.incomeid]">№{{item.incomeid}}</a>
        </td>
        <td class="text-center text-muted">{{item.warehouseName}}</td>
        <td class="text-center text-muted">
          <div *ngIf="item.status == 'Принято'" class="badge bg-success">{{item.status}}</div>
          <div *ngIf="item.status == 'Приемка'" class="badge bg-info">{{item.status}}</div>
        </td>
        <td class="text-center text-muted" style="width:30px"><input ([ngModel])="price_income"
                                                                     (change)="onChangeEvent($event, item.incomeid)"
                                                                     type="text"
                                                                     value="{{item.price_imcome}}"></td>
        <td style="width:50px;">
          <div *ngIf="loading_price">
            <div class="loader">
              <div class="ball-clip-rotate_dop">
                <div></div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center text-muted">{{item.SKU}}</td>
        <td class="text-center text-muted">{{item.qty | number}} шт</td>
        <td class="text-center text-muted">{{item.date_create | date: 'dd.MM.yyyy'}}</td>
        <td class="text-center text-muted">{{item.lastChangeDate | date: 'dd.MM.yyyy'}}</td>
        <td class="text-center text-muted">{{item.dateClose | date: 'dd.MM.yyyy'}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="d-block text-center card-footer">
    <div class="card-body">
      <ngb-pagination (pageChange)="onPageChange(page)" [(page)]="page" [boundaryLinks]="true"
                      [collectionSize]="items_count" [maxSize]="10"
                      [pageSize]="20" [rotate]="true"></ngb-pagination>
    </div>
  </div>
  <!--<div class="d-block text-center card-footer">
    <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger">
      <i class="pe-7s-trash btn-icon-wrapper"> </i>
    </button>
    <button class="btn-wide btn btn-success">Save</button>
  </div>-->
</div>
<div *ngIf="control_id" class="main-card mb-3 card">
  <div class="card-header">
    Поставка № {{control_id}}
    <!--<div class="btn-actions-pane-right">
      <div role="group" class="btn-group-sm btn-group">
        <button class="active btn btn-focus">Last Week</button>
        <button class="btn btn-focus">All Month</button>
      </div>
    </div>-->
  </div>
  <div class="table-responsive">
    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
      <thead>
      <tr>
        <th class="text-center">Фото</th>
        <th class="text-center">Название</th>
        <th class="text-center">Количество</th>
        <th class="text-center">Цена</th>
        <th class="text-center">Закупка</th>
        <th class="text-center">Подготовка</th>
        <th class="text-center">Статус</th>
        <th class="text-center">Дата создания</th>
        <th class="text-center">Дата обновления</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item_d of items_detail; let i = index">
        <td class="text-center text-muted"><img alt=""
                                                src="https://img1.wbstatic.net/tm/new/{{item_d.nmid.substr(0,4)}}0000/{{item_d.nmid}}-1.jpg"
                                                width="40"/></td>
        <td>
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left flex2">
                <div class="widget-heading">Артикул:{{item_d.SupplierArticle}}</div>
                <div class="widget-subheading opacity-7">Баркод:{{item_d.Barcode}}</div>
                <div class="widget-subheading opacity-7">SKU:{{item_d.nmid}}</div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">{{item_d.qty}}</td>
        <td class="text-center">Цена</td>
        <td class="text-center">
          <input (change)="onChangeSKU($event, item_d.incomeid, item_d.nmid)"
                 [ngModel]="price_income_purchase">
        <td class="text-center" style="width:30px">
          <input (change)="onChangePur($event, item_d.incomeid, item_d.nmid)"
                 [ngModel]="price_income_sku"
                 type="text"
                 value="{{item_d.price_SKU_prepair}}"></td>
        <td class="text-center">{{item_d.status}}</td>
        <td class="text-center">{{item_d.date_create | date: 'dd.MM.yyyy'}}</td>
        <td class="text-center">{{item_d.lastChangeDate | date: 'dd.MM.yyyy'}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
