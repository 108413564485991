<p-progressSpinner *ngIf="isLoading"
                   [style]="{position: 'absolute', top: '40%', left: '50%'}">
</p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="col-sm-12 col-md-12">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Идет Beta тестирование</h5>
    </div>
    <div class="app-page-title mb-0">
      <div class="page-title-wrapper d-flex justify-content-between">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <i class="pe-7s-display2 icon-gradient bg-mean-fruit"></i>
          </div>
          <div>
            Заказы сводник
          </div>
        </div>
        <div class="card flex">
          <p-selectButton [(ngModel)]="aggregatorParam"
                          [options]="aggregateTypes"
                          optionLabel="label"
                          optionValue="value">
          </p-selectButton>
        </div>
      </div>
      <div class="main-card mb-3 card">
        <div style="width: 100%;height: calc(92vh - 128px)">
          <ag-grid-angular
            (gridReady)="onGridReady($event)"
            [columnDefs]="ORDERS_AGGREGATORS_COLUMN_DEFS"
            [defaultColDef]="defaultColDef"
            [enableRangeSelection]="true"
            [gridOptions]="gridOptions"
            [pagination]="true"
            [rowData]="rowData"
            [rowHeight]="40"
            [tooltipHideDelay]="0"
            [tooltipShowDelay]="2000"
            class="ag-theme-alpine"
            style="width: 100%; height: 100%;">
          </ag-grid-angular>
        </div>
      </div>
    </div>
    <br/>
  </div>
</div>

