<div (mouseout)="sidebarHoverMouseOut()"
     (mouseover)="sidebarHoverMouseIn()"
     [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme">
    <div class="app-header__logo">
        <div (click)="navigateToHome()" class="logo-src"></div>
        <div class="header__pane ms-auto">
            <button (click)="toggleSidebar();"
                    [ngClass]="{'is-active' : globals.toggleSidebar}"
                    class="hamburger close-sidebar-btn hamburger--elastic" type="button">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
            </button>
        </div>
    </div>
    <div class="app-sidebar-content">
        <div class="app-sidebar-scroll">
            <perfect-scrollbar [autoPropagation]="true" [config]="config" [scrollIndicators]="true"
                               class="scroll-container"
                               style="max-width: 600px; max-height: 95vh;">
                <div class="v-sidebar-menu vsm-default">
                    <div class="vsm-list">
                        <!--          TODO - Скрыт на время доработок-->
                        <!--                        <div class="vsm-header">Аналитика</div>-->
                        <!--                        <div (click)="closePerfectScrollBar()" class="vsm-item">-->
                        <!--                            <a class="vsm-link" routerLink="/analytic/suppliers-manager" routerLinkActive="active-item">-->
                        <!--                                <i class="vsm-icon pe-7s-map-2"></i>-->
                        <!--                                <span class="vsm-title">Управление поставками</span>-->
                        <!--                            </a>-->
                        <!--                        </div>-->
                        <div class="vsm-header">Dashboard аналитика</div>
                        <ng-container *ngIf="!hideBlocksForProd">
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/dashboard/indicators" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-graph3"></i>
                                    <span class="vsm-title">ПЛАН-ФАКТ</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/dashboard/summary" routerLinkActive="active-item">
                                    <i class="vsm-icon fa fa-bar-chart"></i>
                                    <span class="vsm-title">Динамика заказов</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/dashboard/orders-aggregator"
                                   routerLinkActive="active-item">
                                    <i class="vsm-icon fa fa-signal"></i>
                                    <span class="vsm-title">Заказы Сводник</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/dashboard/abc" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-graph3"></i>
                                    <span class="vsm-title">АВС анализ</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/dashboard/unit" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-edit"></i>
                                    <span class="vsm-title">Юнит-экономика</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/dashboard/stock" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-graph2"></i>
                                    <span class="vsm-title">Динамика остатков</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/guides/products/ID товара"
                                   routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-display2"></i>
                                    <span class="vsm-title">Сводка по товару</span>
                                </a>
                            </div>
                        </ng-container>
                        <div (click)="closePerfectScrollBar()" class="vsm-item">
                            <a class="vsm-link" routerLink="/dashboard/heatmap" routerLinkActive="active-item">
                                <i class="vsm-icon pe-7s-map"></i>
                                <span class="vsm-title">Тепловая карта</span>
                            </a>
                        </div>
                        <div (click)="closePerfectScrollBar()" class="vsm-item">
                            <a class="vsm-link" routerLink="/dashboard/orders" routerLinkActive="active-item">
                                <i class="vsm-icon lnr-cart"></i>
                                <span (click)="closePerfectScrollBar()" class="vsm-title">Мои заказы</span>
                            </a>
                        </div>
                        <div (click)="closePerfectScrollBar()" class="vsm-item">
                            <a class="vsm-link" routerLink="/dashboard/sales" routerLinkActive="active-item">
                                <i class="vsm-icon pe-7s-wallet"></i>
                                <span class="vsm-title">Мои продажи</span>
                            </a>
                        </div>
                        <ng-container *ngIf="!hideBlocksForProd">
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/dashboard/geo/orders" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-map"></i>
                                    <span class="vsm-title">Гео заказов</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/dashboard/geo/sales" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-safe"></i>
                                    <span class="vsm-title">Гео продаж</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/dashboard/rates" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-graph2"></i>
                                    <span class="vsm-title">Общие показатели</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/dashboard/remains" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-server"></i>
                                    <span class="vsm-title">Остатки</span>
                                </a>
                            </div>
                            <div class="vsm-header">Реклама</div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/adv/company" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-exapnd2"></i>
                                    <span class="vsm-title">Реклама внутренняя</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/adv/bid" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-news-paper"></i>
                                    <span class="vsm-title">Позиции по ставкам</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/adv/advert-management" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-keypad"></i>
                                    <span class="vsm-title">Управление рекламой</span>
                                </a>
                            </div>
                            <ngb-accordion #acc="ngbAccordion"
                                           [closeOthers]="true"
                                           [destroyOnHide]="false"
                                           activeIds="{{extraParameter}}">
                                <ngb-panel id="panel-campair">
                                    <ng-template ngbPanelHeader>
                                        <button class="vsm-link" ngbPanelToggle>
                                            <i class="vsm-icon pe-7s-ribbon"></i>
                                            <span class="vsm-title">Отчеты</span>
                                            <i class="vsm-arrow"></i>
                                        </button>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <div class="vsm-dropdown">
                                            <div class="vsm-list">
                                                <div (click)="closePerfectScrollBar()" class="vsm-item">
                                                    <a class="vsm-link" routerLink="/adv/reports/advert-analytic"
                                                       routerLinkActive="active-item">
                                                        <span class="vsm-title">Аналитика рекламы</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </ng-container>
                        <ngb-accordion #acc="ngbAccordion"
                                       [closeOthers]="true"
                                       [destroyOnHide]="false"
                                       activeIds="{{extraParameter}}">
                            <!--          TODO - Скрыт на время доработок-->
                            <!--                            <ngb-panel>-->
                            <!--                                <ng-template ngbPanelHeader>-->
                            <!--                                    <div class="vsm-header">Внешняя аналитика</div>-->
                            <!--                                </ng-template>-->
                            <!--                            </ngb-panel>-->
                            <!--                            <ngb-panel id="panel-campair">-->
                            <!--                                <ng-template ngbPanelHeader>-->
                            <!--                                    <button class="vsm-link" ngbPanelToggle>-->
                            <!--                                        <i class="vsm-icon pe-7s-ribbon"></i>-->
                            <!--                                        <span class="vsm-title">Сравнение конкурентов</span>-->
                            <!--                                        <i class="vsm-arrow"></i>-->
                            <!--                                    </button>-->
                            <!--                                </ng-template>-->
                            <!--                                <ng-template ngbPanelContent>-->
                            <!--                                    <div class="vsm-dropdown">-->
                            <!--                                        <div class="vsm-list">-->
                            <!--                                            <div class="vsm-item">-->
                            <!--                                                <a class="vsm-link" routerLink="/external-analysis/compare"-->
                            <!--                                                   routerLinkActive="active-item">-->
                            <!--                                                    <span class="vsm-title">Сравнение карточек</span>-->
                            <!--                                                </a>-->
                            <!--                                            </div>-->

                            <!--                                        </div>-->
                            <!--                                    </div>-->
                            <!--                                </ng-template>-->
                            <!--                            </ngb-panel>-->
                            <!--                            <ngb-panel>-->
                            <!--                                <ng-template ngbPanelHeader>-->
                            <!--                                    <div class="vsm-item" style="width: 100%">-->
                            <!--                                        <a class="vsm-link"-->
                            <!--                                           routerLink="/external-analysis/key-queries"-->
                            <!--                                           routerLinkActive="active-item">-->
                            <!--                                            <i class="vsm-icon pe-7s-share"></i>-->
                            <!--                                            <span class="vsm-title"-->
                            <!--                                                  style="text-transform: initial; font-weight: initial">Ключевые запросы</span>-->
                            <!--                                        </a>-->
                            <!--                                    </div>-->
                            <!--                                </ng-template>-->
                            <!--                            </ngb-panel>-->
                            <!--                            <ngb-panel>-->
                            <!--                                <ng-template ngbPanelHeader>-->
                            <!--                                    <div class="vsm-item" style="width: 100%">-->
                            <!--                                        <a class="vsm-link"-->
                            <!--                                           routerLink="/external-analysis/bloggers"-->
                            <!--                                           routerLinkActive="active-item">-->
                            <!--                                            <i class="vsm-icon pe-7s-users"></i>-->
                            <!--                                            <span class="vsm-title"-->
                            <!--                                                  style="text-transform: initial; font-weight: initial">Блогеры</span>-->
                            <!--                                        </a>-->
                            <!--                                    </div>-->
                            <!--                                </ng-template>-->
                            <!--                            </ngb-panel>-->
                            <ngb-panel>
                                <ng-template ngbPanelHeader>
                                    <div class="vsm-header">Финансы</div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelHeader>
                                    <div class="vsm-item" style="width: 100%">
                                        <a class="vsm-link" routerLink="/fin/operations" routerLinkActive="active-item">
                                            <i class="vsm-icon pe-7s-id"></i>
                                            <span class="vsm-title"
                                                  style="text-transform: initial; font-weight: initial">Операции</span>
                                        </a>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel [ngbCollapse]="true" id="fin_reports">
                                <ng-template ngbPanelHeader>
                                    <button class="vsm-link" ngbPanelToggle>
                                        <i class="vsm-icon pe-7s-graph2"></i>
                                        <span class="vsm-title">Отчёты</span>
                                        <i class="vsm-arrow"></i>
                                    </button>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="vsm-dropdown">
                                        <div class="vsm-list">
                                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                                <a class="vsm-link" routerLink="/fin/reports/pnl"
                                                   routerLinkActive="active-item">
                                                    <span class="vsm-title">ОПиУ (PnL)</span></a>
                                            </div>
                                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                                <a [routerLinkActiveOptions]="{exact: true}" class="vsm-link"
                                                   routerLink="/fin/reports/dds" routerLinkActive="active-item">
                                                    <span class="vsm-title">ДДС (CashFlow)</span></a>
                                            </div>
                                            <div (click)="closePerfectScrollBar()" *ngIf="!hideBlocksForProd"
                                                 class="vsm-item">
                                                <a [routerLinkActiveOptions]="{exact: true}" class="vsm-link"
                                                   routerLink="/fin/reports/reconciliation"
                                                   routerLinkActive="active-item">
                                                    <span class="vsm-title">Сверка с ВБ</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="fin_settings">
                                <ng-template ngbPanelHeader>
                                    <button class="vsm-link" ngbPanelToggle>
                                        <i class="vsm-icon pe-7s-graph2"></i>
                                        <span class="vsm-title">Настройки</span>
                                        <i class="vsm-arrow"></i>
                                    </button>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="vsm-dropdown">
                                        <div class="vsm-list">
                                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                                <a class="vsm-link" routerLink="/fin/settings/items"
                                                   routerLinkActive="active-item">
                                                    <span class="vsm-title">Статьи</span></a>
                                            </div>
                                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                                <a class="vsm-link" routerLink="/fin/settings/partners"
                                                   routerLinkActive="active-item">
                                                    <span class="vsm-title">Контрагенты</span></a>
                                            </div>
                                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                                <a class="vsm-link" routerLink="/fin/settings/accounts"
                                                   routerLinkActive="active-item">
                                                    <span class="vsm-title">Счета</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                        <div class="vsm-header">Справочники</div>
                        <div (click)="closePerfectScrollBar()" class="vsm-item">
                            <a class="vsm-link" routerLink="/guides/products" routerLinkActive="active-item">
                                <i class="vsm-icon pe-7s-diamond"></i>
                                <span class="vsm-title">Мои товары</span>
                            </a>
                        </div>
                        <ng-container *ngIf="!hideBlocksForProd">
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/guides/purchase" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-cart"></i>
                                    <span class="vsm-title">Закупка</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/guides/stock" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-box2"></i>
                                    <span class="vsm-title">Склады</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/guides/control" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-timer"></i>
                                    <span class="vsm-title">Поставки</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" *ngIf="hideBlocksForProd" class="vsm-item">
                                <a class="vsm-link" routerLink="/guides/tariffwb" routerLinkActive="active-item">
                                    <i class=""></i>
                                    <span class="vsm-title">Тарифы WB</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/guides/buyouts" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-cart"></i>
                                    <span class="vsm-title">Самовыкупы</span>
                                </a>
                            </div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/guides/defect-goods" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-junk"></i>
                                    <span class="vsm-title">Брак, утиль</span>
                                </a>
                            </div>
                        </ng-container>
                        <div class="vsm-header">Настройки</div>
                        <div (click)="closePerfectScrollBar()" class="vsm-item mb-3">
                            <a class="vsm-link" routerLink="/settings/shop" routerLinkActive="active-item">
                                <i class="vsm-icon pe-7s-users"></i>
                                <span class="vsm-title">Мои Юрлица</span>
                            </a>
                        </div>
                        <ng-container *ngIf="isAdmin">
                            <div class="vsm-header">Админ</div>
                            <div (click)="closePerfectScrollBar()" class="vsm-item">
                                <a class="vsm-link" routerLink="/admin/monitoring" routerLinkActive="active-item">
                                    <i class="vsm-icon pe-7s-albums"></i>
                                    <span class="vsm-title">Мониторинг</span>
                                </a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</div>
