import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MpSurfApiService } from '../../../shared/services/mpsurf-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCALE_TEXT_FOR_FILTERS } from '../products/products.mock';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KEY_QUERIES_COLUMN_DEFS } from '../../external-analysis/key-queries/key-queries.mock';
import { GridOptions } from 'ag-grid';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { environment } from '../../../../environments/environment';
import { IDefectGoodsAdditionalParams, IDefectGoodsList } from './defect-goods.interface';
import { DEFECT_GOODS_COLUMNS_DEFS } from './defect-goods.mock';
import { finalize } from 'rxjs/operators';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OperationType } from '../../finances/finoperations/finoperations.interface';
import { IBarcodeNmid } from '../../../shared/interfaces/common.interface';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-defect-goods',
  templateUrl: './defect-goods.component.html',
  styleUrls: ['./defect-goods.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefectGoodsComponent implements OnInit {
  rowData: IDefectGoodsList[] = [];
  colDefs = [...DEFECT_GOODS_COLUMNS_DEFS];
  localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  gridOptions: GridOptions;
  defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };
  rowHeight = 40;
  isLoading = false;
  isEdit = false;

  barcodesList: IBarcodeNmid[] = [];
  batchesList: string[] = [];
  currentDefectGoodId = null;

  readonly DefectGoodsForm: UntypedFormGroup;
  readonly URL_NEW: string;
  readonly TOKEN: string;
  readonly SHOP_ID: number;
  @ViewChild('defectGoodModal', { read: TemplateRef }) defectGoodModal: TemplateRef<any>;

  constructor(
    private mpSurfService: MpSurfApiService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private modalService: NgbModal,
  ) {
    this.gridOptions = {
      columnDefs: [...KEY_QUERIES_COLUMN_DEFS],
      context: { componentParent: this },
    } as GridOptions;
    this.DefectGoodsForm = new UntypedFormGroup({
      date: new FormControl(null, [Validators.required]),
      barcode: new FormControl(null, [Validators.required]),
      batch: new FormControl(null, [Validators.required]),
      qty: new FormControl(null, [Validators.required]),
      shk_parcels: new FormControl(null),
      comment: new FormControl(null),

      supplier_code: new FormControl(null), // Артикул поставщик
    });
    this.URL_NEW = environment.apiNew;
    this.TOKEN = localStorage.getItem('token');
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop')).shop;
  }

  ngOnInit() {}

  onGridReady(params: GridReadyEvent<any>) {
    this.loadData();
  }

  loadBatchesList() {
    this.isLoading = true;
    const barcode = this.DefectGoodsForm.controls['barcode'].getRawValue();
    this.mpSurfService
      .load({ barcode }, 'purchases/batch-number')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((batch: { batch: string[] }) => {
        this.batchesList = batch.batch;
      });
  }

  loadData(): void {
    this.isLoading = true;
    this.mpSurfService
      .load({}, 'defect')
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        (response: IDefectGoodsList[]) => {
          this.rowData = response;
          this.loadAdditionalData();
        },
        () => this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages),
      );
  }

  loadAdditionalData() {
    this.isLoading = true;
    this.mpSurfService
      .load({}, 'products/important')
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        (response: IDefectGoodsAdditionalParams[]) => {
          this.barcodesList = response.map((a) => {
            return {
              label: `${a.Barcode} - ${a.SKU}`,
              value: a.Barcode,
            };
          });
        },
        () => {
          () => this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  openModal(): void {
    this.isEdit = false;
    this.DefectGoodsForm.reset();
    this.modalService.open(this.defectGoodModal);
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  editModalEmitter(currentRowDate: IDefectGoodsList) {
    this.isEdit = true;
    this.currentDefectGoodId = currentRowDate.id;
    this.modalService.open(this.defectGoodModal, { backdrop: 'static' });
    this.DefectGoodsForm.patchValue(currentRowDate);
  }

  createUpdateDefectGood(type: OperationType): void {
    this.isLoading = true;
    const URL = type === 'create' ? 'defect' : `defect/${this.currentDefectGoodId}`;
    this.mpSurfService
      .createOrUpdate(this.DefectGoodsForm.getRawValue(), URL)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        () => {
          this.modalService.dismissAll();
          const successSummary = this.isEdit ? PopUpMessages.updateSuccessSummary : PopUpMessages.createSuccessSummary;
          this.showPopUpMessage('success', successSummary, '');
          this.loadData();
        },
        () => {
          const failedSummary = this.isEdit ? PopUpMessages.updateFailedSummary : PopUpMessages.createFailedSummary;
          const failedMessage = this.isEdit ? PopUpMessages.updateFailedMessage : PopUpMessages.createFailedMessage;
          this.showPopUpMessage('error', failedSummary, failedMessage);
        },
      );
  }

  deleteDefectGood(goodId: number) {
    if (confirm('Вы действительно хотите удалить запись?')) {
      this.mpSurfService
        .delete({}, `defect/${goodId}`)
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.isLoading = false;
            this.cdr.markForCheck();
          }),
        )
        .subscribe(
          () => {
            this.modalService.dismissAll();
            this.showPopUpMessage('success', PopUpMessages.deleteSuccessSummary, PopUpMessages.deleteFailedMessage);
            this.loadData();
          },
          () => {
            this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
          },
        );
    }
  }

  onBasicUploadAuto(event: any, fileUpload: any) {
    this.showPopUpMessage('success', 'Загружено', 'Файл загружен. Данные обновлены');
    fileUpload.clear(); // this will clear your file
    this.loadData();
  }

  onBasicUploadError(event: any, fileUpload) {
    this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
    fileUpload.clear(); // this will clear your file
  }

  onSendData() {
    this.isLoading = true;
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.isLoading = false;
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
