import { Component } from '@angular/core';

@Component({
  selector: 'app-advbid',
  templateUrl: './advbid.component.html',
  styleUrls: ['./advbid.component.sass'],
})
export class AdvbidComponent {
  constructor() {}
}
