export const ListOfGroupDDS = [
  {id: 0, value: ''},
  {id: 1, value: 'Операционная'},
  {id: 2, value: 'Финансовая'},
  {id: 3, value: 'Инвестиционная'}
];


export const mockData = [
  {
    key: '1',
    label: 'Операционная деятельность',
    data: 'Operation',
    expanded: true,
    icon: 'pi pi-fw pi-inbox',
    children: [
      {
        key: '0-123',
        label: 'Поступления',
        data: 'Arrival_operation',
        icon: 'pi pi-fw pi-cog',
        children: [
          {key: '0-123', label: 'Work', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'SSSS', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'DDDD', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
        ]
      },
      {
        key: '0-123',
        label: 'Списания',
        data: 'Orders_operation',
        icon: 'pi pi-fw pi-cog',
        children: [
          {key: '0-123', label: 'ASD', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: '@WQE', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'LLL', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
        ]
      },
    ]
  },
  {
    key: '2',
    label: 'Финансовая деятельность',
    data: 'Finance',
    expanded: true,
    icon: 'pi pi-fw pi-inbox',
    children: [
      {
        key: '0-123',
        label: 'Поступления',
        data: 'Arrival_finance',
        icon: 'pi pi-fw pi-cog',
        children: [
          {key: '0-123', label: 'PPPPW', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'IYIY', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'QWRUQE', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
        ]
      },
      {
        key: '0-123',
        label: 'Списания',
        data: 'Orders_finance',
        icon: 'pi pi-fw pi-cog',
        children: [
          {key: '0-123', label: 'NNMNMN', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'NBBBB', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'HHJHJ', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
        ]
      },
    ]
  },
  {
    key: '3',
    label: 'Инвестиционная деятельность',
    data: 'Investment',
    expanded: true,
    icon: 'pi pi-fw pi-inbox',
    children: [
      {
        key: '0-123',
        label: 'Поступления',
        data: 'Arrival_investment',
        icon: 'pi pi-fw pi-cog',
        children: [
          {key: '0-123', label: 'OIYOH', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'JKB', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'LHJKJH', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
        ]
      },
      {
        key: '0-123',
        label: 'Списания',
        data: 'Orders_investment',
        icon: 'pi pi-fw pi-cog',
        children: [
          {key: '0-123', label: 'OOBJK', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'PIHOIH', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
          {key: '0-123', label: 'JK', data: 'Work Folder', icon: 'pi pi-fw pi-cog'},
        ]
      },
    ]
  },
];
