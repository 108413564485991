<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <!--<i class="icon {{icon}}"></i>-->
        <i class="fa fa-bar-chart icon-gradient bg-mean-fruit"></i>
      </div>
      <div>
        Показатели c &nbsp;&nbsp;<!--{{control_id}}-->
        <!--<div class="page-title-subheading" *ngIf="control_id">
          <a [routerLink]="['/my/control/']">к списку поставок</a>
        </div>-->
      </div>
      <div class="btn-actions-pane-left">
        <input (change)="newValue()"
               [(ngModel)]="selected"

               [alwaysShowCalendars]="true"

               [isInvalidDate]="isInvalidDate"
               [linkedCalendars]="true"
               [locale]="{
                        applyLabel: 'применить',
                        direction: 'ltr',
                        format: 'DD.MM.YYYY',
                        'daysOfWeek': ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
                        'customRangeLabel': 'Свой диапазон',
                        'firstDay': 1,
                        'monthNames': ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']}"
               [maxDate]='maxDate'
               [minDate]='minDate'
               [ranges]="ranges"
               [showClearButton]="false"
               [showCustomRangeLabel]="true"
               ngxDaterangepickerMd
               placeholder="Выберите даты"
               type="text"/>&nbsp;&nbsp;&nbsp;&nbsp;сравниваем
        с {{date_begin_compair | date: 'dd.MM.yyyy'}} по {{date_end_compair | date: 'dd.MM.yyyy'}}
      </div>
    </div>
    <!--<div class="page-title-actions">
      <button type="button" class="btn-shadow mr-3 btn btn-dark">
        <fa-icon [icon]="faStar"></fa-icon>
      </button>
      <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success">
        <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
        Create New
      </button>
    </div>-->

  </div>
</div>

<!--<div class="row">
    <img src="/assets/images/techimg/job.jpg">

</div>-->

<!--<div class="main-card mb-3 card">
    <div class="card-header">
        Показатели
        <div class="btn-actions-pane-right">
            <div role="group" class="btn-group-sm btn-group">
                <div class="btn-group-lg btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="dataperiod">
                    <label ngbButtonLabel class="btn-outline-focus">
                        <input ngbButton type="radio" [value]="1"  (change)="newValue('1')"> Сегодня
                    </label>
                    <label ngbButtonLabel class="btn-outline-focus">
                        <input ngbButton type="radio" [value]="-1"  (change)="newValue('-1')"> Вчера
                    </label>
                    <label ngbButtonLabel class="btn-outline-focus">
                        <input ngbButton type="radio" [value]="77"  (change)="newValue('77')"> Неделя
                    </label>
                    <label ngbButtonLabel class="btn-outline-focus">
                        <input ngbButton type="radio" [value]="7"  (change)="newValue('7')"> 7 дней
                    </label>
                    <label ngbButtonLabel class="btn-outline-focus">
                        <input ngbButton type="radio" [value]="14"  (change)="newValue('14')"> 14 дней
                    </label>
                    <label ngbButtonLabel class="btn-outline-focus">
                        <input ngbButton type="radio" [value]="300"  (change)="newValue('300')"> Месяц
                    </label>
                    <label ngbButtonLabel class="btn-outline-focus">
                        <input ngbButton type="radio" [value]="30"  (change)="newValue('30')"> 30 дней
                    </label>
                    <label ngbButtonLabel class="btn-outline-focus">
                        <input ngbButton type="radio" [value]="90"  (change)="newValue('90')"> 90 дней
                    </label>


                </div>

            </div>
        </div>
    </div>
</div>-->
<!--<div class="main-card mb-3 card">
    <div class="card-header">
        Выберите интервал дат&nbsp;&nbsp;&nbsp;-->
<!--<div class="btn-actions-pane-right">
    <div role="group" class="btn-group-sm btn-group">
        <div class="btn-group-lg btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="dataperiod">
            <label ngbButtonLabel class="btn-outline-focus">
                <input ngbButton type="radio" [value]="1"  (change)="newValue('1')"> Сегодня
            </label>
            <label ngbButtonLabel class="btn-outline-focus">
                <input ngbButton type="radio" [value]="7"  (change)="newValue('7')"> 7 дней
            </label>
            <label ngbButtonLabel class="btn-outline-focus">
                <input ngbButton type="radio" [value]="14"  (change)="newValue('14')"> 14 дней
            </label>
            <label ngbButtonLabel class="btn-outline-focus">
                <input ngbButton type="radio" [value]="30"  (change)="newValue('30')"> 30 дней
            </label>
        </div>
    </div>
</div>-->
<!--<div class="btn-actions-pane-left">
        <input type="text"
            ngxDaterangepickerMd

            [locale]="{
                applyLabel: 'применить',
                direction: 'ltr',
                format: 'DD.MM.YYYY',
                'daysOfWeek': ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
                'customRangeLabel': 'Свой диапазон',
                'firstDay': 1,
                'monthNames': ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']}"

            [(ngModel)]="selected"
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            [isInvalidDate] = "isInvalidDate"
            [showClearButton]="false"
            [maxDate]='maxDate'
            [minDate]='minDate'
            (change)="newValue()"
            placeholder="Выберите даты" />&nbsp;&nbsp;&nbsp;&nbsp;сравниваем с {{date_begin_compair | date: 'dd.MM.yyyy'}} по {{date_end_compair | date: 'dd.MM.yyyy'}}

</div><br />

</div>
</div>-->


<div class="row">


  <!--<div class="col-md-6 col-lg-4">

      <div class="card-shadow-primary card-border mb-3 card">
          <div class="card-header-tab card-header">
              <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i class="header-icon pe-7s-car mr-3 text-muted opacity-6"> </i>
                Доставка
              </div>
          </div>
          <ul class="list-group list-group-flush">
              <li class="p-0 list-group-item">
                  <div class="w-100">
                      <div class="col-md-12 col-xl-12">
                          <div class="widget-content">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-right ml-0 mr-3">
                                <div class="widget-numbers text-success">{{param_indicator?.away?.from}}&nbsp;шт</div>
                              </div>
                              <div class="widget-content-left">
                                <div class="widget-heading">В пути к клиенту</div>
                                <div class="widget-subheading">Общее количество товара, которые едут к клиенту сейчас</div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </li>
              <li class="p-0 list-group-item">
                  <div class="w-100">
                      <div class="col-md-12 col-xl-12">
                          <div class="widget-content">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-right ml-0 mr-3">
                                <div class="widget-numbers text-danger">{{param_indicator?.away?.to}}&nbsp;шт</div>
                              </div>
                              <div class="widget-content-left">
                                <div class="widget-heading">В пути от клиента</div>
                                <div class="widget-subheading">Общее количество товара, которые едет обратно на склады</div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </li>
          </ul>
      </div>


  </div>-->

  <div class="col-md-6 col-lg-2">
    <div class="card-shadow-primary card-border mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon pe-7s-wallet mr-3 text-muted opacity-6"> </i>
          Продажи
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="p-0 list-group-item">
          <div class="w-100">
            <div class="widget-chart">
              <div class="widget-chart-content">
                <!--<div class="widget-subheading">Сумма</div>-->
                <div class="widget-numbers-sm mt-0 text-capitalize">{{param_indicator?.sales?.sum | number : '1.0-0'}}
                  <small>₽</small></div>
                <div class="widget-description opacity-8 text-focus text-right">
                  <div *ngIf="param_indicator?.sales_com?.sum > param_indicator?.sales?.sum"
                       class="d-inline text-danger pr-1">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                    <span
                      class="pl-1">{{param_indicator?.sales.sum - param_indicator?.sales_com?.sum | number : '1.0-0'}}</span>
                  </div>
                  <div *ngIf="param_indicator?.sales_com?.sum < param_indicator?.sales?.sum"
                       class="d-inline text-success pr-1">
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                    <span
                      class="pl-1">+{{param_indicator?.sales.sum - param_indicator?.sales_com?.sum | number : '1.0-0'}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="divider mt-0 mb-0 mr-2"></div>
            <div class="widget-chart">
              <div class="widget-chart-content">
                <!--<div class="widget-subheading">Количество </div>-->
                <div class="widget-numbers-sm mt-0 text-dark">{{param_indicator?.sales?.qty}} <small>шт</small></div>
                <div class="widget-description opacity-8 text-focus text-right">
                  <div *ngIf="param_indicator?.sales_com?.qty > param_indicator?.sales?.qty"
                       class="d-inline text-danger pr-1">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                    <span class="pl-1">{{param_indicator?.sales.qty - param_indicator?.sales_com?.qty}}</span>
                  </div>
                  <div *ngIf="param_indicator?.sales_com?.qty < param_indicator?.sales?.qty"
                       class="d-inline text-success pr-1">
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                    <span class="pl-1">+{{param_indicator?.sales.qty - param_indicator?.sales_com?.qty}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-md-6 col-lg-2">
    <div class="card-shadow-primary card-border mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon lnr-cart mr-3 text-muted opacity-6"> </i>
          Заказы
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="p-0 list-group-item">
          <div class="w-100">
            <div class="widget-chart">
              <div class="widget-chart-content">

                <!--<div class="widget-subheading">Сумма</div>-->
                <div class="widget-numbers-sm mt-0 text-capitalize">{{param_indicator?.orders?.sum | number : '1.0-0'}}
                  <small>₽</small></div>
                <div class="widget-description opacity-8 text-focus text-right">
                  <div *ngIf="param_indicator?.orders_com?.sum > param_indicator?.orders?.sum"
                       class="d-inline text-danger pr-1">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                    <span
                      class="pl-1">{{param_indicator?.orders.sum - param_indicator?.orders_com?.sum | number : '1.0-0'}}</span>
                  </div>
                  <div *ngIf="param_indicator?.orders_com?.sum < param_indicator?.orders?.sum"
                       class="d-inline text-success pr-1">
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                    <span
                      class="pl-1">+{{param_indicator?.orders.sum - param_indicator?.orders_com?.sum | number : '1.0-0'}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="divider mt-0 mb-0 mr-2"></div>
            <div class="widget-chart">
              <div class="widget-chart-content">
                <!--<div class="widget-subheading">Количество </div>-->
                <div class="widget-numbers-sm mt-0 text-dark">{{param_indicator?.orders?.qty}} <small>шт</small></div>
                <div class="widget-description opacity-8 text-focus text-right">
                  <div *ngIf="param_indicator?.orders_com?.qty > param_indicator?.orders?.qty"
                       class="d-inline text-danger pr-1">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                    <span class="pl-1">{{param_indicator?.orders.qty - param_indicator?.orders_com?.qty}}</span>
                  </div>
                  <div *ngIf="param_indicator?.orders_com?.qty < param_indicator?.orders?.qty"
                       class="d-inline text-success pr-1">
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                    <span class="pl-1">+{{param_indicator?.orders.qty - param_indicator?.orders_com?.qty}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-md-6 col-lg-2">
    <div class="card-shadow-primary card-border mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon pe-7s-back mr-3 text-muted opacity-6"> </i>
          Возвраты
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="p-0 list-group-item">
          <div class="w-100">
            <div class="widget-chart">

              <div class="widget-chart-content">

                <!--<div class="widget-subheading">Сумма</div>-->
                <div class="widget-numbers-sm mt-0 text-capitalize">{{param_indicator?.returns?.sum | number : '1.0-0'}}
                  <small>₽</small></div>
                <div class="widget-description opacity-8 text-focus text-right">
                  <div *ngIf="param_indicator?.returns_com?.sum < param_indicator?.returns?.sum"
                       class="d-inline text-danger pr-1">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                    <span
                      class="pl-1">+{{param_indicator?.returns.sum - param_indicator?.returns_com?.sum | number : '1.0-0'}}</span>
                  </div>
                  <div *ngIf="param_indicator?.returns_com?.sum > param_indicator?.returns?.sum"
                       class="d-inline text-success pr-1">
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                    <span
                      class="pl-1"> {{param_indicator?.returns.sum - param_indicator?.returns_com?.sum | number : '1.0-0'}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="divider mt-0 mb-0 mr-2"></div>
            <div class="widget-chart">
              <div class="widget-chart-content">
                <!--<div class="widget-subheading">Количество </div>-->
                <div class="widget-numbers-sm mt-0 text-dark">{{param_indicator?.returns?.qty}} <small>шт</small></div>
                <div class="widget-description opacity-8 text-focus text-right">
                  <div *ngIf="param_indicator?.returns_com?.qty < param_indicator?.returns?.qty"
                       class="d-inline text-danger pr-1">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                    <span class="pl-1">+{{param_indicator?.returns.qty - param_indicator?.returns_com?.qty}}</span>
                  </div>
                  <div *ngIf="param_indicator?.returns_com?.qty > param_indicator?.returns?.qty"
                       class="d-inline text-success pr-1">
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                    <span class="pl-1">{{param_indicator?.returns.qty - param_indicator?.returns_com?.qty}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-md-6 col-lg-2">
    <div class="card-shadow-primary card-border mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon pe-7s-network mr-3 text-muted opacity-6"> </i>
          Логистика
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="p-0 list-group-item">
          <div class="w-100">
            <div class="widget-chart">
              <div class="widget-numbers-sm mt-0 text-capitalize">{{param_indicator?.logistics?.sum | number : '1.0-0'}}
                <small>₽</small></div>
              <div class="widget-description opacity-8 text-focus text-right">
                <div *ngIf="param_indicator?.logistics_com?.sum < param_indicator?.logistics?.sum"
                     class="d-inline text-danger pr-1">
                  <fa-icon [icon]="faAngleDown"></fa-icon>
                  <span
                    class="pl-1">+{{param_indicator?.logistics.sum - param_indicator?.logistics_com?.sum | number : '1.0-0'}}</span>
                </div>
                <div *ngIf="param_indicator?.logistics_com?.sum > param_indicator?.logistics?.sum"
                     class="d-inline text-success pr-1">
                  <fa-icon [icon]="faAngleUp"></fa-icon>
                  <span
                    class="pl-1"> {{param_indicator?.logistics.sum - param_indicator?.logistics_com?.sum | number : '1.0-0'}}</span>
                </div>
              </div>
            </div>
            <div class="divider mt-0 mb-0 mr-2"></div>
            <div class="widget-chart">
              <div class="widget-chart-content">
                <div class="widget-numbers-sm mt-0 text-dark">{{param_indicator?.logistics?.qty}} <small>шт</small>
                </div>
                <div class="widget-description opacity-8 text-focus text-right">
                  <div *ngIf="param_indicator?.logistics_com?.qty < param_indicator?.logistics?.qty"
                       class="d-inline text-danger pr-1">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                    <span class="pl-1">+{{param_indicator?.logistics.qty - param_indicator?.returns_com?.qty}}</span>
                  </div>
                  <div *ngIf="param_indicator?.logistics_com?.qty > param_indicator?.logistics?.qty"
                       class="d-inline text-success pr-1">
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                    <span class="pl-1">{{param_indicator?.logistics.qty - param_indicator?.returns_com?.qty}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

</div>


<div class="main-card mb-3 card">
  <canvas (chartClick)="chartClicked($event)" (chartHover)="chartHovered($event)" [chartType]="lineChartType"
          [colors]="lineChartColors"
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [legend]="lineChartLegend"
          [options]="lineChartOptions"
          [plugins]="lineChartPlugins"
          baseChart
          height="100"
          width="400">
  </canvas>
</div>




