import * as moment from 'moment';

export const FIRST_RANGE_TYPE: any = {
  Сегодня: [moment(), moment()],
  Вчера: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'За 7 дней': [moment().subtract(6, 'days'), moment()],
  'За 30 дней': [moment().subtract(29, 'days'), moment()],
  'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
  'Прошлая неделя': [moment().subtract(1, 'weeks').startOf('isoWeek'), moment().subtract(1, 'weeks').endOf('isoWeek')],
  'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
};

export const SECOND_RANGE_TYPE: any = {
  Сегодня: [moment(), moment()],
  Вчера: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'За 7 дней': [moment().subtract(6, 'days'), moment()],
  'За 30 дней': [moment().subtract(29, 'days'), moment()],
  'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
  'Этот квартал': [
    moment().quarter(moment().quarter()).startOf('quarter'),
    moment().quarter(moment().quarter()).endOf('quarter'),
  ],
  'Этот год': [moment().subtract(0, 'year').startOf('year'), moment().subtract(0, 'year').endOf('year')],
  'Прошлый Месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'Прошлый квартал': [
    moment().subtract(1, 'quarter').startOf('quarter'),
    moment().subtract(1, 'quarter').endOf('quarter'),
  ],
  'Прошлый год': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
};

export const THIRD_RANGE_TYPE: any = {
  'За 7 дней': [moment().subtract(6, 'days'), moment()],
  'За 14 дней': [moment().subtract(13, 'days'), moment()],
  'За 30 дней': [moment().subtract(29, 'days'), moment()],
  'Этот квартал': [
    moment().quarter(moment().quarter()).startOf('quarter'),
    moment().quarter(moment().quarter()).endOf('quarter'),
  ],
  'Прошлый Месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'Прошлый квартал': [
    moment().subtract(1, 'quarter').startOf('quarter'),
    moment().subtract(1, 'quarter').endOf('quarter'),
  ],
};

export const FORTH_RANGE_TYPE: any = {
  'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
  'Этот квартал': [
    moment().quarter(moment().quarter()).startOf('quarter'),
    moment().quarter(moment().quarter()).endOf('quarter'),
  ],
  'Этот год': [moment().subtract(0, 'year').startOf('year'), moment().subtract(0, 'year').endOf('year')],
  'Прошлый Месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'Прошлый квартал': [
    moment().subtract(1, 'quarter').startOf('quarter'),
    moment().subtract(1, 'quarter').endOf('quarter'),
  ],
  'Прошлый год': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  //'2 года': [moment().subtract(+1, 'year').startOf('year'), moment().subtract(-1, 'year').endOf('year')], // для тестирования ПНЛ и ДДС
};

export const DATE_PICKER_LOCALE: any = {
  applyLabel: 'применить',
  direction: 'ltr',
  format: 'DD.MM.YYYY',
  daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  customRangeLabel: 'Свой диапазон',
  firstDay: 1,
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
};
