import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderModalComponent} from "./header-modal/header-modal.component";
import {FooterModalComponent} from "./footer-modal/footer-modal.component";


@NgModule({
  declarations: [
    HeaderModalComponent,
    FooterModalComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderModalComponent,
    FooterModalComponent
  ]
})
export class ModalModule {
}
