import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../../../shared/services/common.service';
import {HttpErrorResponse} from '@angular/common/http';
import { INotificationResponse, INotificationResponseData } from "./notification-dots.interface";

@Component({
  selector: 'app-notification-dots',
  templateUrl: './notification-dots.component.html',
  styleUrls: ['./notification-dots.component.sass']
})
export class NotificationDotsComponent implements OnInit {
  notificationMessages: INotificationResponseData[] = [];
  displayNewNotifications = false;
  newNotificationId: number;
  oldNotificationId: number;
  clickedCount = 0;

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.setUpNotificationService();
  }

  private setUpNotificationService() {
    this.notificationMessages = [];
    this.commonService.getNotifications('getNotification', localStorage.getItem('token'))
      .subscribe(
        (response: INotificationResponse) => {
          if (response.is_error === 0) {
            this.handleNotificationMessages(response);
          }
        }, (error: HttpErrorResponse) => {
          console.error(error);
        }
      );
  }

  notificationClicked() {
    if (this.clickedCount > 1) {
      this.oldNotificationId = this.newNotificationId;
      localStorage.setItem('notification-id', String(this.newNotificationId));
    } else {
      this.displayNewNotifications = false;
      localStorage.setItem('notification-id', String(this.newNotificationId));
    }
    this.clickedCount++;
  }

  private handleNotificationMessages(notification: INotificationResponse): void {
    this.notificationMessages = notification.data;
    this.oldNotificationId = +localStorage.getItem('notification-id');
    if (this.notificationMessages[this.notificationMessages.length - 1]) {
      this.newNotificationId = this.notificationMessages[this.notificationMessages.length - 1].id as number;
      this.displayNewNotifications = this.newNotificationId > this.oldNotificationId;
    }
  }
}
