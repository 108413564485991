import { ThousandSeparator } from '../../../shared/utils/functions/number-space-formatter';
import { ChartOptions } from './myorders.mock';

export function GenerateOrdersChartData(data: any): Partial<ChartOptions> {
    return {
        series: [
            {name: 'Заказы ₽', type: 'line', data: data.order_money},
            {name: 'Заказы шт.', type: 'column', data: data.order_qty},
        ],
        chart: {
            type: 'area',
            height: 300,
            stacked: false,
            animations: {
                enabled: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            //@ts-ignore
            horizontalAlign: 'left',
            offsetX: 40,
        },
        xaxis: {
            type: 'category',
            categories: data.category,
        },
        yaxis: [
            {
                floating: false,
                forceNiceScale: true,
                tickAmount: 6,
                labels: {
                    formatter: function (value: number): string {
                        return ThousandSeparator(value);
                    },
                },
            },
            {
              forceNiceScale: true,
              floating: false,
              opposite: true
            },
        ],
    };
}