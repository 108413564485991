import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ISelectedTypesP } from '../../../shared/interfaces/common.interface';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { ORDERS_AGGREGATOR_MOCK, ORDERS_AGGREGATORS_COLUMN_DEFS } from './orders-aggregator.mock';

@Component({
  selector: 'app-orders-aggregator',
  templateUrl: './orders-aggregator.component.html',
  styleUrls: ['./orders-aggregator.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersAggregatorComponent {
  readonly gridOptions: GridOptions;
  readonly ORDERS_AGGREGATORS_COLUMN_DEFS = ORDERS_AGGREGATORS_COLUMN_DEFS;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };
  rowData = [];

  aggregateTypes: ISelectedTypesP<number, string>[] = [
    { label: 'По апи реализации', value: 1 },
    { label: 'Включая оперативные данные', value: 2 },
  ];

  aggregatorParam = 1;

  isLoading = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
  ) {
    this.gridOptions = {
      context: { componentParent: this },
      suppressRowClickSelection: true,
    } as GridOptions;
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.loadData();
  }

  loadData() {
    this.rowData = ORDERS_AGGREGATOR_MOCK;
  }
}
