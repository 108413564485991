import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import * as moment from 'moment';
import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { ChartComponent } from 'ng-apexcharts';
import { DATE_PICKER_LOCALE, FIRST_RANGE_TYPE } from '../../../shared/common-variables/time-ranges-date-picker';
import { DatePickerCommon } from '../../../shared/common-variables/date-picker-common';
import { ChartOptions } from './mysales.mock';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import {
  IChartSales,
  IMySaleItem,
  IMySalesAdditionalParameters,
  IMySalesGeneral,
  IMySalesViewCancel,
} from './mysales.interface';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-mysales',
  templateUrl: './mysales.component.html',
  styleUrls: ['./mysales.component.sass'],
  providers: [MessageService],
})
export class MysalesComponent implements OnInit {
  ranges = FIRST_RANGE_TYPE;
  DATE_PICKER_LOCALE = DATE_PICKER_LOCALE;
  DatePicker: DatePickerCommon;
  @ViewChild('chart') chart: ChartComponent;
  chartOptions: Partial<ChartOptions>;
  visibleBtn = 1;
  alwaysShowCalendars: boolean;
  faStar = faStar;
  faPlus = faPlus;
  dataDisabled = false;
  dataPeriod = 1;
  page = 1;
  itemsCountAvg: number;
  items: IMySaleItem[] = [];
  itemsCount: number;
  salesMoneyTotal: any;
  salesQtyTotal: any;
  salesReturnMoneyTotal: any;
  isLoading = true;
  nmid: any;
  wareHousesList: IMySalesAdditionalParameters[] = [];
  categoryList: IMySalesAdditionalParameters[] = [];
  subjectList: IMySalesAdditionalParameters[] = [];
  selectedWH: any;
  selectedCategory: any;
  selectedSubject: any;
  selectedNm: any;
  shopId: any;

  view_google_table_info = true;

  constructor(
    private api: ApiService,
    private messageService: MessageService,
  ) {
    this.DatePicker = new DatePickerCommon();
    this.DatePicker.selectedData.startDate = moment().subtract(14, 'days');
    this.view_google_table_info = localStorage.getItem('googleMsgInfo') == null;
  }

  private get generalRequest(): {} {
    return {
      days: this.dataPeriod,
      startDate: this.DatePicker.selectedData.startDate.format('YYYY-MM-DD'),
      endDate: this.DatePicker.selectedData.endDate.format('YYYY-MM-DD'),
      shop_id: this.shopId,
    };
  }

  private get resForResponseSales(): number {
    const b = moment(this.DatePicker.selectedData.startDate.format('YYYY-MM-DD'));
    const a = moment(this.DatePicker.selectedData.endDate.format('YYYY-MM-DD'));
    return +a.diff(b, 'days');
  }

  ngOnInit(): void {
    const dataShop = localStorage.getItem('mpall_shop');
    if (dataShop) {
      this.shopId = JSON.parse(dataShop).shop;
    }
  }

  closeGoogleTableMsg(param) {
    if (+param === 1) {
      localStorage.setItem('googleMsgInfo', '1');
      this.view_google_table_info = false;
    }
  }

  onClearParameters(parameter: string) {
    switch (parameter) {
      case 'article':
        this.selectedNm = undefined;
        break;
      case 'warehouse':
        this.selectedWH = undefined;
        break;
      case 'category':
        this.selectedCategory = undefined;
        break;
      case 'subject':
        this.selectedSubject = undefined;
        break;
      default:
        this.selectedParameters();
    }
    this.selectedParameters();
  }

  selectedParameters(page?: number) {
    this.isLoading = true;
    const hidden = +this.visibleBtn === 1 ? 0 : 1;
    const body = {
      barcode: this.selectedNm ?? undefined,
      cancel: hidden,
      wh: this.selectedWH ?? undefined,
      category: this.selectedCategory ?? undefined,
      subject: this.selectedSubject ?? undefined,
      currentPage: page ?? undefined,
    };
    this.loadData(body);
  }

  view_only_cancel(hidden) {
    this.isLoading = true;
    +hidden === 1 ? (this.visibleBtn = 0) : (this.visibleBtn = 1);
    this.page = 1;
    const body = {
      startDate: this.DatePicker.selectedData.startDate.format('YYYY-MM-DD'),
      endDate: this.DatePicker.selectedData.endDate.format('YYYY-MM-DD'),
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop,
      cancel: hidden,
    };
    this.api
      .userRegister('getSales', localStorage.getItem('token'), body)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (data: IMySalesViewCancel) => {
          this.salesAutoSetter(data);
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  newValue() {
    this.getBarcodeNmid();
    this.loadAllParameters();
    this.dataDisabled = true;
    this.isLoading = true;
    this.page = 1;
    this.visibleBtn = 1;
    this.getChart();
    const body = {
      startDate: this.DatePicker.selectedData.startDate.format('YYYY-MM-DD'),
      endDate: this.DatePicker.selectedData.endDate.format('YYYY-MM-DD'),
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop,
      params: {
        barcode: this.selectedNm,
        wh: this.selectedWH,
      },
    };
    this.api
      .userRegister('getSales', localStorage.getItem('token'), body)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (data: IMySalesViewCancel) => {
          this.salesAutoSetter(data);
          this.dataDisabled = false;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  loadAllParameters() {
    this.isLoading = true;
    forkJoin([
      this.getParametersList('getSalesWh'),
      this.getParametersList('getSalesCategory'),
      this.getParametersList('getSalesSubject'),
    ])
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(([responseWH, responseCategory, responseSubject]) => {
        this.wareHousesList = responseWH.data;
        this.categoryList = responseCategory.data;
        this.subjectList = responseSubject.data;
      });
  }

  // TODO LOAD ALL
  private loadData(params = null) {
    this.isLoading = true;
    const body = {
      days: this.dataPeriod,
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop,
      startDate: this.DatePicker.selectedData.startDate.format('YYYY-MM-DD'),
      endDate: this.DatePicker.selectedData.endDate.format('YYYY-MM-DD'),
      params,
    };
    this.api
      .userRegister('getSales', localStorage.getItem('token'), body)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (data: any) => {
          this.getChart();
          this.items = data.data;
          this.itemsCount = data.count;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private getChart() {
    const body = {
      startDate: this.DatePicker.selectedData.startDate.format('YYYY-MM-DD'),
      endDate: this.DatePicker.selectedData.endDate.format('YYYY-MM-DD'),
      shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop,
      params: {
        barcode: this.selectedNm ? this.selectedNm : undefined,
        wh: this.selectedWH ? this.selectedWH : undefined,
        category: this.selectedCategory ?? undefined,
        subject: this.selectedSubject ?? undefined,
      },
    };
    this.api.userRegister('getChartSales', localStorage.getItem('token'), body).subscribe(
      (data: IChartSales) => {
        this.salesMoneyTotal = data.sales_money_total;
        this.salesQtyTotal = data.sales_qty_total;
        this.salesReturnMoneyTotal = data.sales_return_money_total * -1;
        this.chartOptions = {
          series: [
            { name: 'Продажи шт.', type: 'column', data: data.sales_qty },
            { name: 'Возвраты шт.', type: 'column', data: data.sales_return_qty },
            { name: 'Продажи ₽', type: 'line', data: data.sales_money },
          ],
          chart: { type: 'area', height: 330, stacked: false },
          dataLabels: { enabled: false },
          colors: ['#1A74E8', '#B33824', '#B22884'],
          stroke: { curve: 'smooth' },
          legend: { horizontalAlign: 'left', offsetX: 40 },
          xaxis: { type: 'category', categories: data.category },
          yaxis: [
            { seriesName: 'Column A', floating: false, forceNiceScale: true, tickAmount: 6 },
            { floating: false, forceNiceScale: true, tickAmount: 6, show: false },
            { forceNiceScale: true, floating: false, opposite: true },
          ],
        };
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  private getParametersList(parameterActionName: string): Observable<any> {
    const body = this.generalRequest;
    return this.api.userRegister(parameterActionName, localStorage.getItem('token'), body);
  }

  private getBarcodeNmid() {
    const body = this.generalRequest;
    this.api.userRegister('getSalesNm', localStorage.getItem('token'), body).subscribe(
      (response: IMySalesGeneral<IMySalesAdditionalParameters[]>) => {
        this.nmid = response.data;
        if (response.data) {
          this.itemsCount = response.data.length;
        }
        this.resForResponseSales === 0
          ? +response.data.length === 0
            ? (this.itemsCountAvg = 0)
            : (this.itemsCountAvg = Math.round(response.data.length))
          : (this.itemsCountAvg = Math.round(response.data.length / this.resForResponseSales));
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  private salesAutoSetter(data: IMySalesViewCancel) {
    this.items = data.data;
    this.itemsCount = +data.count;
    if (+this.resForResponseSales === 0) {
      +data.count === 0 ? (this.itemsCountAvg = 0) : (this.itemsCountAvg = Math.round(+data.count));
    } else {
      this.itemsCountAvg = Math.round(+data.count / this.resForResponseSales);
    }
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
