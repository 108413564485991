import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {concat, interval, Observable, timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {
  INotificationResponse
} from "../../general-components/header/header-elements/notification-dots/notification-dots.interface";

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(private http: HttpClient) { }

  public getNotifications(action, token): Observable<INotificationResponse> {
    const body = { action, token};
    return concat(
      this.http.post<INotificationResponse>(environment.apiOld + 'data', body),
      timer(600000).pipe(
        switchMap(() => this.http.post<INotificationResponse>(environment.apiOld + 'data', body))
      )
    );
  }
}
