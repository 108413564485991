import { OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { NouisliderModule } from 'ng2-nouislider';
import * as moment from 'moment';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl } from '@angular/forms';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';

import * as pluginAnnotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-allsellers',
  templateUrl: './allsellers.component.html',
  styleUrls: ['./allsellers.component.sass'],
})
export class AllsellersComponent implements OnInit {
  faCog = faCog;
  faTh = faTh;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faArrowRight = faArrowRight;
  faDotCircle = faDotCircle;
  faCheck = faCheck;
  faTrash = faTrash;
  faCalendarAlt = faCalendarAlt;

  loading: any = false;

  rowData: any = [];
  arr_par: any = [];
  params_list: any;
  columnDefsSales: any;

  rowHeight: any = 40;
  rowSelection: any = 'multiple';
  rowModelType: any = 'infinite';
  cacheOverflowSize: any = 2;
  paginationPageSize: any = 100;
  maxConcurrentDatasourceRequests: any = 3;
  infiniteInitialRowCount: any = 1;
  maxBlocksInCache: any = 10;
  cacheBlockSize: any = 100;

  loadingCellRenderer: any;
  loadingCellRendererParams: any;

  localeText: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    // 'Сегодня': [moment(), moment()],
    Вчера: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'За 7 дней': [moment().subtract(6, 'days'), moment()],
    'За 30 дней': [moment().subtract(29, 'days'), moment()],
    'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
    'Прошлый Месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  vis_det: any = false;
  minDate = moment().add(-91, 'days');
  maxDate = moment().add(-1, 'days');
  selected: any = { startDate: moment().add(-7, 'days'), endDate: moment().add(-1, 'days') };
  // displayedColumns: string[] = ['created', 'state', 'number', 'title'];
  displayedColumns: string[] = ['seller', 'brend', 'qty_sku', 'rating', 'avg', 'qty_sales', 'revenue'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataperiod: any = 30;
  public someRange: number[] = [3, 7];
  period: any;
  private gridApi;
  private gridColumnApi;

  constructor(
    private _httpClient: HttpClient,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.localeText = {
      // Number Filter & Text Filter
      filterOoo: 'Фильтр...',
      equals: 'Равно',
      notEqual: 'не равно',
      empty: 'Выбери один',

      /* for number filter */
      lessThan: 'Меньше чем',
      greaterThan: 'Больше чем',
      lessThanOrEqual: 'Меньше или равно',
      greaterThanOrEqual: 'Больше или равно',
      inRange: 'В промежутке',
      inRangeStart: 'от',
      inRangeEnd: 'до',

      // Text Filter
      contains: 'Содержит',
      notContains: 'Не Содержит',
      startsWith: 'Начинается с',
      endsWith: 'Заканчиваеться на',

      // Date Filter
      dateFormatOoo: 'dd.mm.yyyy',

      // Filter Conditions
      andCondition: 'И',
      orCondition: 'ИЛИ',

      // Filter Buttons
      resetFilter: 'Очистить',
      applyFilter: 'Применить',
      clearFilter: 'Clear',
      cancelFilter: 'Cancel',

      // Filter Titles
      textFilter: 'Text Filter',
      numberFilter: 'Number Filter',
      dateFilter: 'Date Filter',
      setFilter: 'Set Filter',
    };

    //console.log(this.route.queryParams);
    this.route.queryParams.subscribe((params) => {
      if (typeof params.id !== 'undefined') {
        // your code here
        if (params.id.length > 0) {
          this.vis_det = true;
        } else {
          this.vis_det = false;
        }
      } else {
        this.vis_det = false;
        this.newValue();
      }
    });
  }

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  public chartHovered({ event, active }: { event: MouseEvent; active: {}[] }): void {
    console.log(event, active);
  }

  public chartClicked({ event, active }: { event: MouseEvent; active: {}[] }): void {
    console.log(event, active);
  }

  ngAfterViewInit() {}

  numberSpacesFormatter(param) {
    return param.value
      ? param.value.toString() !== '-1'
        ? param.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        : '—'
      : param.value;
  }

  newValue() {
    this.columnDefsSales = [
      {
        headerName: 'Продавец',
        field: 'seller',
        sortable: true,
        resizable: true,
        width: 150,
        filter: false,
        suppressSizeToFit: true,
        flex: 0,
        cellClass: ['d-flex', 'justify-content-center', 'align-items-center'],
        cellRendererParams: {
          startDate: this.selected.startDate.format('YYYY-MM-DD'),
          endDate: this.selected.endDate.format('YYYY-MM-DD'),
        },
        cellRenderer: this.wbSeller,
      },
      {
        headerName: 'Брендов',
        field: 'qty_brend',
        floatingFilter: true,
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          suppressAndOrCondition: true,
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Товаров',
        field: 'qty_sku',
        floatingFilter: true,
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          suppressAndOrCondition: true,
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Средний чек',
        field: 'avg',
        floatingFilter: true,
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          suppressAndOrCondition: true,
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Рейтинг',
        field: 'rating',
        floatingFilter: true,
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          suppressAndOrCondition: true,
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Продаж',
        field: 'qty_sales',
        floatingFilter: true,
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          suppressAndOrCondition: true,
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Оборот',
        field: 'revenue',
        floatingFilter: true,
        sortable: true,
        resizable: true,
        width: 150,
        wrapText: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          suppressAndOrCondition: true,
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
    ];

    /*
        columnDefs: [
        { field: "id", hide: true },
        { field: "name",            headerName: 'Подкатегория',     checkboxSelection: false, filterParams: filterParams_text, width: 300, suppressSizeToFit: true, cellRenderer: function(params) { if (params.value === undefined) { return tableSpinner } else return (params.data) ? ((params.data['childs']) ? '<a href="?cat_id=' + params.data['id'] + '">' + params.value + ' (' + params.data['childs'] + ')</a><a class="ml-2 text-secondary" href="/stat/category/ym/' + params.data['id'] + '/"><i class="fa fa-chart-area"></i></a>' : '<a href="/stat/category/ym/' + params.data['id'] + '/">' + params.value + '</a><a class="ml-2 text-secondary" href="/stat/category/ym/' + params.data['id'] + '/"><i class="fa fa-chart-area"></i></a>') : ''; } },
        { field: "products_count",  headerName: 'Товаров, шт',      valueFormatter: numberSpacesFormatter, type: 'rightAligned', filter: 'agNumberColumnFilter', filterParams: filterParams_num },
        { field: "sellers_count",   headerName: 'Продавцов, шт',    minWidth: 140, valueFormatter: numberSpacesFormatter, type: 'rightAligned', filter: 'agNumberColumnFilter', filterParams: filterParams_num },
        { field: "brands_count",    headerName: 'Брендов, шт',      valueFormatter: numberSpacesFormatter, type: 'rightAligned', filter: 'agNumberColumnFilter', filterParams: filterParams_num },
        { field: "check",           headerName: 'Средний чек, ₽',   minWidth: 150, valueFormatter: numberSpacesFormatter, type: 'rightAligned', filter: 'agNumberColumnFilter', filterParams: filterParams_num },
        { field: "sold",            headerName: 'Продажи, шт',      valueFormatter: numberSpacesFormatter, type: 'rightAligned', filter: 'agNumberColumnFilter', filterParams: filterParams_num },
        { field: "turnover",        headerName: 'Выручка, ₽',       minWidth: 160, valueFormatter: numberSpacesFormatter, type: 'rightAligned', filter: 'agNumberColumnFilter', filterParams: filterParams_num, sort: 'desc' },
        { field: "trend_30",        headerName: 'Тренд выручки',    headerTooltip: 'Выручка за последние 30 дней по отношению к предыдущим 30 дням', minWidth: 160, cellRenderer: trendRenderer, type: 'rightAligned', filter: 'agNumberColumnFilter', filterParams: filterParams_num },
    ],
    */
    console.log(this.selected);
    console.log('Date: ' + this.selected.startDate.format('YYYY-MM-DD'));

    console.log('Date: ' + this.selected.endDate.format('YYYY-MM-DD'));

    var datasource = {
      getRows: (params: any) => {
        console.log(params);
        this.getRowData(
          params.startRow,
          params.endRow,
          params.filterModel,
          params.sortModel,
          this.selected.startDate.format('YYYY-MM-DD'),
          this.selected.endDate.format('YYYY-MM-DD'),
        ).subscribe((data: any) => params.successCallback(data.data, data.totalRecord));
      },
    };
    this.gridApi.setDatasource(datasource);
  }

  onChangeFilter(value: any) {
    console.log('Value changed to', value);
  }

  wbSeller(params) {
    if (typeof params.value == 'undefined') {
      let img = document.createElement('img');
      img.src = '/assets/images/loading.gif';
      img.width = 32;
      img.height = 32;
      img.referrerPolicy = 'no-referrer';
      return img;
    } else {
      return (
        '<a href="/dashboard/all/sellers/' +
        params.value +
        '?d1=' +
        params.startDate +
        '&d2=' +
        params.endDate +
        '" target="_blank">' +
        params.value +
        '</a>'
      );
      //return '<a href="/wb/catlist?cat='+ params.value +'" target="_blank">'+ params.value+'</a>';
      //<a href="/all/sellers/{{row.seller}}?d1={{selected.startDate | date: 'yyyy-MM-dd'}}&d2={{selected.endDate | date: 'yyyy-MM-dd'}}" target="_new">{{row.seller}}</a>
    }
  }

  ngOnInit(): void {
    //console.log(this.route.queryParams);
    this.route.queryParams.subscribe((params) => {
      if (typeof params.id !== 'undefined') {
        // your code here
        if (params.id.length > 0) {
          this.vis_det = true;
        } else {
          this.vis_det = false;
        }
      } else {
        this.vis_det = false;
        this.newValue();
      }
    });

    this.columnDefsSales = [
      {
        headerName: 'Продавец',
        field: 'seller',
        sortable: true,
        resizable: true,
        width: 150,
        filter: false,
        suppressSizeToFit: true,
        flex: 0,
        cellClass: ['d-flex', 'justify-content-center', 'align-items-center'],
        cellRendererParams: {
          startDate: this.selected.startDate.format('YYYY-MM-DD'),
          endDate: this.selected.endDate.format('YYYY-MM-DD'),
        },
        cellRenderer: this.wbSeller,
      },
      {
        headerName: 'Брендов',
        field: 'qty_brend',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Товаров',
        field: 'qty_sku',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Средний чек',
        field: 'avg',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Рейтинг',
        field: 'rating',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Продаж',
        field: 'qty_sales',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
      {
        headerName: 'Оборот',
        field: 'revenue',
        sortable: true,
        resizable: true,
        width: 150,
        wrapText: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'inRange',
            'greaterThan',
            'greaterThanOrEqual',
            'lessThan',
            'lessThanOrEqual',
          ],
          defaultOption: 'equals',
        },
      },
    ];
  }

  onGridReady(params: any) {
    console.log(params);

    console.log(this.selected);
    this.gridApi = params.api;

    this.gridColumnApi = params.columnApi;

    var datasource = {
      getRows: (params: any) => {
        console.log(params);
        this.getRowData(
          params.startRow,
          params.endRow,
          params.filterModel,
          params.sortModel,
          moment().add(-7, 'days').format('YYYY-MM-DD'),
          moment().add(-1, 'days').format('YYYY-MM-DD'),
        ).subscribe((data: any) => params.successCallback(data.data, data.totalRecord));
      },
    };
    params.api.setDatasource(datasource);
  }

  private getRowData(startRow: number, endRow: number, filter: any, sort: any, dateBegin: any, dateEnd: any) {
    //console.log(filter);
    this.arr_par = {
      params_list: this.params_list,
      startRow: startRow,
      endRow: endRow,
      filter: filter,
      sort: sort,
      dateBegin: dateBegin,
      dateEnd: dateEnd,
    };

    return this.api.userRegisterClick('getDataSeller', localStorage.getItem('token'), this.arr_par);
  }
}
