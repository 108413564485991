<div class="app-header-left">
    <ul [ngStyle]="{'opacity': value ? '0' : '1'}" class="header-megamenu nav">
        <li class="nav-item">
            <button [autoClose]="true" [ngbPopover]="headerMegaPopover" class="btn btn-link dropdown-toggle"
                    placement="bottom-left" popoverClass="rm-max-width" type="button">
        <span>
          <i class="nav-link-icon pe-7s-graph2"></i>
          Аналитика
        </span>
            </button>
        </li>
        <li class="nav-item">
            <button [autoClose]="'true'" [ngbPopover]="headerMegaPopover3" class="btn btn-link dropdown-toggle"
                    placement="bottom-left" popoverClass="rm-max-width" type="button">
            <span>
              <i class="nav-link-icon fa fa-money"></i>
              Финансы
            </span>
            </button>
        </li>
        <li class="nav-item">
            <button [autoClose]="'true'" [ngbPopover]="headerMegaPopover2" class="btn btn-link dropdown-toggle"
                    placement="bottom-left" popoverClass="rm-min-width" type="button">
        <span>
          <i class="nav-link-icon pe-7s-home"></i>
          Настройки
        </span>
            </button>
        </li>
        <li *ngIf="showBlocksForProd" class="nav-item">
            <button [autoClose]="'true'" [ngbPopover]="headerMegaPopover4" class="btn btn-link dropdown-toggle"
                    placement="bottom-left" popoverClass="rm-max-width" type="button">
            <span>
              <i class="nav-link-icon fa fa-money"></i>
              Закупки
            </span>
            </button>
        </li>
        <li class="nav-item">
            <button class="dropdown-item" type="button">
                <a [routerLink]="['/profile/tariff']" style="font-size: 1rem">
                    <i class="nav-link-icon pe-7s-settings"></i>
                    Тарифы
                </a>
            </button>
        </li>
        <ng-container *ngIf="isAdmin">
            <li class="nav-item">
                <button [autoClose]="'true'" [ngbPopover]="headerMegaPopover5" class="btn btn-link dropdown-toggle"
                        placement="bottom-left" popoverClass="rm-min-width" type="button">
        <span>
          <i class="nav-link-icon pe-7s-home"></i>
          Админ
        </span>
                </button>
            </li>
        </ng-container>
    </ul>
</div>
<ng-template #headerMegaPopover>
    <div class="dropdown-mega-menu">
        <div class="grid-menu grid-menu-1col">
            <div class="g-0 row">
                <div class="col-sm-4 col-xl-4">
                    <ul class="nav flex-column">
                        <li class="nav-item-header nav-item"><b>DASHBOARD АНАЛИТИКА</b></li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/dashboard/heatmap">
                                <i class="nav-link-icon  pe-7s-map"></i>
                                <span>Тепловая карта</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/dashboard/orders">
                                <i class="nav-link-icon  lnr-cart"></i>
                                <span>Мои заказы</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/dashboard/sales">
                                <i class="nav-link-icon pe-7s-wallet"></i>
                                <span>Мои продажи</span>
                            </a>
                        </li>
                        <ng-container *ngIf="showBlocksForProd">
                            <li *ngIf="showBlocksForProd" class="nav-item">
                                <a class="nav-link" routerLink="/dashboard/indicators">
                                    <i class="nav-link-icon pe-7s-graph3"></i>
                                    <span>ПЛАН-ФАКТ</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/dashboard/summary">
                                    <i class="nav-link-icon fa fa-bar-chart"></i>
                                    <span>Динамика заказов</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/dashboard/abc">
                                    <i class="nav-link-icon pe-7s-graph3"></i>
                                    <span>АВС анализ</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/dashboard/unit">
                                    <i class="nav-link-icon"></i>
                                    <span>Юнит-экономика</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/dashboard/stock">
                                    <i class="nav-link-icon"></i>
                                    <span>Динамика остатков</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/guides/products/list">
                                    <i class="nav-link-icon"></i>
                                    <span>Сводка по товару</span>
                                </a>
                            </li>
                            <li *ngIf="showBlocksForProd" class="nav-item">
                                <a class="nav-link" routerLink="/dashboard/geo/orders">
                                    <i class="nav-link-icon pe-7s-map"></i>
                                    <span>Гео заказов</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/dashboard/geo/orders">
                                    <i class="nav-link-icon pe-7s-safe"></i>
                                    <span>Гео продаж</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/dashboard/rates">
                                    <i class="nav-link-icon pe-7s-graph2"></i>
                                    <span>Общие показатели</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/dashboard/remains">
                                    <i class="nav-link-icon pe-7s-server"></i>
                                    <span>Остатки</span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <!--          TODO - Скрыт на время доработок-->
                <!--        <div class="col-sm-4 col-xl-4">-->
                <!--          <ul class="nav flex-column">-->
                <!--            <li class="nav-item-header nav-item"><b>АНАЛИТИКА ПОСТАВОК</b></li>-->
                <!--            <li class="nav-item">-->
                <!--              <a class="nav-link" routerLink="/analytic/suppliers-manager">-->
                <!--                <i class="nav-link-icon pe-7s-map-2"></i>-->
                <!--                <span>Управление поставками</span>-->
                <!--              </a>-->
                <!--            </li>-->
                <!--          </ul>-->
                <!--        </div>-->

                <!--          TODO - Скрыт на время доработок-->
                <!--                <div *ngIf="showBlocksForProd" class="col-sm-4 col-xl-4">-->
                <!--                    <ul class="nav flex-column">-->
                <!--                        <li class="nav-item-header nav-item"><b>ВНЕШНЯЯ АНАЛИТИКА</b></li>-->
                <!--                        <li class="nav-item">-->
                <!--                            <a class="nav-link" routerLink="/external-analysis/compare">-->
                <!--                                <i class="nav-link-icon  pe-7s-graph1"></i>-->
                <!--                                <span>Сравнение карточек</span>-->
                <!--                            </a>-->
                <!--                        </li>-->
                <!--                        <li class="nav-item">-->
                <!--                            <a class="nav-link" routerLink="/external-analysis/key-queries">-->
                <!--                                <i class="nav-link-icon  pe-7s-share"></i>-->
                <!--                                <span>Ключевые запросы</span>-->
                <!--                            </a>-->
                <!--                        </li>-->
                <!--                        <li class="nav-item">-->
                <!--                            <a class="nav-link" routerLink="/external-analysis/bloggers">-->
                <!--                                <i class="nav-link-icon  pe-7s-users"></i>-->
                <!--                                <span>Блогеры</span>-->
                <!--                            </a>-->
                <!--                        </li>-->
                <!--                    </ul>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</ng-template>
<ng-template #headerMegaPopover2>
    <div class="dropdown-mega-menu">
        <div class="grid-menu grid-menu-col">
            <div class="g-0 row">
                <div class="col-sm-4 col-xl-4">
                    <ul class="nav flex-column">
                        <!--li class="nav-item-header nav-item"><b>Wildberries</b></li>-->
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/guides/products">
                                <i class="nav-link-icon pe-7s-diamond"></i>
                                <span>Мои товары</span>
                            </a>
                        </li>
                        <ng-container *ngIf="showBlocksForProd">
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/guides/defect-goods">
                                    <i class="nav-link-icon"></i>
                                    <span>Брак, утиль</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/guides/purchase">
                                    <i class="nav-link-icon"></i>
                                    <span>Закупка</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/guides/stock">
                                    <i class="nav-link-icon pe-7s-box2"></i>
                                    <span>Склады</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/guides/control">
                                    <i class="nav-link-icon pe-7s-timer"></i>
                                    <span>Поставки</span>
                                </a>
                            </li>
                            <!--              <li class="nav-item">-->
                            <!--                <a class="nav-link" routerLink="/guides/tariffwb">-->
                            <!--                  <i class="nav-link-icon"></i>-->
                            <!--                  <span>Тарифы WB</span>-->
                            <!--                </a>-->
                            <!--              </li>-->
                            <li class="nav-item">
                                <a class="nav-link" routerLink="/guides/buyouts">
                                    <i class="nav-link-icon"></i>
                                    <span>Самовыкупы</span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #headerMegaPopover3>
    <div class="dropdown-mega-menu">
        <div class="grid-menu grid-menu-3col">
            <div class="no-gutters row">
                <div class="col-sm-4 col-xl-4">
                    <ul class="nav flex-column">
                        <!--<li class="nav-item-header nav-item"><b>Финансы</b></li>-->
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/fin/operations">
                                <!--<i class="nav-link-icon pe-7s-graph3"></i>-->
                                <span>Добавить операцию</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-4 col-xl-4">
                    <ul class="nav flex-column">
                        <li class="nav-item-header nav-item"><b>Отчеты</b></li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/fin/reports/pnl">
                                <span>ОПиУ (PnL)</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/fin/reports/dds">
                                <span>ДДС (CashFlow)</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-4 col-xl-4">
                    <ul class="nav flex-column">
                        <li class="nav-item-header nav-item"><b>Настройки</b></li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/fin/settings/items">
                                <!--<i class="nav-link-icon fa fa-money"></i>-->
                                <span>Настройка статей</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/fin/settings/accounts">
                                <!--<i class="nav-link-icon pe-7s-graph3"></i>-->
                                <span>Контрагенты</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/fin/settings/partners">
                                <!--<i class="nav-link-icon fa fa-money"></i>-->
                                <span>Настройка счетов</span>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #headerMegaPopover4>
    <div class="dropdown-mega-menu">
        <div class="grid-menu grid-menu-3col">
            <div class="no-gutters row">
                <div class="col-sm-3 col-xl-2">
                    <ul class="nav flex-column">
                        <li class="nav-item-header nav-item"><b>Финансы</b></li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/guides/purchase">
                                <span>Закупки</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #headerMegaPopover5>
    <div class="dropdown-mega-menu">
        <div class="grid-menu grid-menu-col">
            <div class="g-0 row">
                <div class="col-sm-4 col-xl-4">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/admin/monitoring">
                                <span>Мониторинг</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #headerMegaPopover6>
    <div class="dropdown-mega-menu">
        <div class="grid-menu grid-menu-col">
            <ul class="nav flex-column">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/adv/reports/advert-analytic">
                        <span>Аналитка рекламы</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/adv/advert-management">
                        <span>Управление рекламой</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</ng-template>
