<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"/>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
    <div class="col-sm-12 col-md-12">
        <div class="mb-3 card text-white card-body bg-warning">
            <h5 class="card-title">Идет Beta тестирование</h5>
        </div>
        <br/>
    </div>
    <div class="app-page-title" style="margin-bottom: 0">
        <div class="page-title-wrapper">
            <div class="page-title-heading">
                <div class="page-title-icon">
                    <i class="pe-7s-box2 icon-gradient bg-mean-fruit"></i>
                </div>
                <div>
                    Операции
                </div>
            </div>
            <div class="page-title-actions">
                <div class="header-wrap-btns" ngbDropdown placement="bottom-right">
                    <p-toast></p-toast>
                    <button class="mb-2 btn btn-success add-operation-btn" style="background: #4c95d2; border: none;"
                            type="button">
                        <a href="../../../../assets/images/download/finoperation_template.xlsx" style="color: #FFF">
                            Скачать шаблон
                        </a>
                    </button>
                    <p-fileUpload #uploadedFiles
                                  (onBeforeUpload)="isLoading = true"
                                  (onError)="onBasicUploadError($event,uploadedFiles)"
                                  (onUpload)="onBasicUploadAuto($event,uploadedFiles)"
                                  [auto]="true"
                                  [maxFileSize]="10000000" [style]="{ height: '35px'}"
                                  accept=".xls, .xlsx, .csv"
                                  chooseLabel="Загрузить из excel"
                                  class="button-add-purchase mr-4 inline-block"
                                  label="Import"
                                  mode="basic"
                                  name="file"
                                  url="{{urlLink}}data?action=upload&type=finoperation&token={{TOKEN}}&shop_id={{shop_id}}"/>
                    <button class="mb-2 btn btn-success add-operation-btn" ngbDropdownToggle type="button">Добавить
                        операцию
                    </button>
                    <div class="dropdown-menu-right-rounded" ngbDropdownMenu>
                        <button (click)="openCreateOperationModal(receipt, 'arrival')"
                                class="dropdown-item">
              <span
                      style="color:green">Поступления</span>
                        </button>
                        <button (click)="openCreateOperationModal(expenses, 'expenses')"
                                class="dropdown-item">
              <span
                      style="color:red">Списания</span>
                        </button>
                        <button (click)="openCreateOperationModal(moving, 'moving')" class="dropdown-item">
                            Перемещение
                        </button>
                        <button (click)="openCreateOperationModal(retention, 'retention')"
                                class="dropdown-item"
                                style="color:#7013ce">
                            Удержание WB
                        </button>
                        <button (click)="openCreateOperationModal(loanRepayment, 'repayment')"
                                class="dropdown-item"
                                style="color:#b90000">
                            Погашение кредита\займа
                        </button>
                    </div>
                    <button [hidden]="true"
                            data-target="#purchase"
                            data-toggle="modal"
                            id="openModalButton">
                        Open Modal
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!showAgGridExcel">
        <div *ngIf="!showAgGridExcel && rowData.length === 0" class="text-center mbg-3">
      <span>
          Операций еще нет. Добавьте операции с помощью кнопки добавить операцию в правом верхнем углу
      </span>
        </div>
        <div *ngIf="rowsForRemoving.length > 0" class="button-wrapp">
            <p>Выбрано: {{ rowsForRemoving.length }}</p>
            <p-toast></p-toast>
            <button (click)="removeSelected()" class="icon-button" label="">
                <i class="lnr-trash"></i>
                Удалить
            </button>
        </div>
        <div *ngIf="!showAgGridExcel && rowData.length > 0"
             class="main-card mb-3 card"
             style="height: calc(92vh - 270px)">
            <ag-grid-angular #agGrid
                             (gridReady)="onGridReady($event)"
                             (rowSelected)="onRowSelected($event)"
                             [columnDefs]="dataForColumnDefs"
                             [defaultColDef]="defaultColDef"
                             [enableRangeSelection]="true"
                             [getRowStyle]="getRowStyle"
                             [gridOptions]="gridOptions"
                             [localeText]="LOCALE_RU_FILTERS"
                             [pagination]="true"
                             [rowData]="rowData"
                             [rowDragManaged]="true"
                             [rowHeight]="40"
                             [suppressRowTransform]="true"
                             class="ag-theme-alpine"
                             rowSelection="multiple"
                             style="width: 100%; height: 100%;"/>
            <button (click)="exportInExcel()" class="mb-2 btn btn-success add-operation-btn button-export-fin"
                    type="button">Экспорт в csv
            </button>
        </div>
    </div>
    <app-finoperations-excel
            [ngStyle]="{'display': showAgGridExcel ? 'block' : 'none'}">
    </app-finoperations-excel>

    <!--  ФОРМА ДЛЯ ДОБАВЛЕНИ/ИЗМЕНЕНИЯ ПОСТУПЛЕНИЯ -->
    <ng-template #receipt>
        <app-header-modal
                (closeEmitter)="closeModal()"
                [modalTitle]="(isCreate ? 'Добавление' : 'Изменение') + ' операции поступления'">
        </app-header-modal>
        <form [formGroup]="FormArrival"
              class="needs-validation"
              novalidate>
            <div class="modal-body">
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="date_payment_l">Дата поступления<span
                            style="color:red">*</span></label>
                    <div class="col-sm-9">
                        <div class="position-relative row form-group" style="margin-left: 0;">
                            <input #d1="ngbDatepicker" (click)="d1.toggle()"
                                   [(ngModel)]="modelDatePayment" class="form-control" formControlName="date_payment"
                                   name="dp1" ngbDatepicker placeholder="dd.mm.yyyy" style="width: 150px;">
                            <!--            TODO Hidden by Natasha-->
                            <!--            <div class="" style="width: 200px; display: flex; gap: 11px">-->
                            <!--              <input type="checkbox" name="checkbox" formControlName="insure_arrival_payment"-->
                            <!--                     id="insure_arrival_payment">-->
                            <!--              <label for="insure_arrival_payment" style="width: 100%" class="col-sm-3 col-form-label">Подтвердить-->
                            <!--                оплату</label>-->
                            <!--            </div>-->
                        </div>
                        <span
                                *ngIf="this.FormArrival.controls['date_payment'].invalid && this.FormArrival.controls['date_payment'].touched"
                                class="alert-danger">Не верно заполненон поле</span>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="date_accrual_l">Дата начисления</label>
                    <div class="col-sm-9">
                        <div class="position-relative row form-group" style="margin-left: 0;">
                            <input #d2="ngbDatepicker" (click)="d2.toggle()"
                                   [(ngModel)]="modelDateAccrual" class="form-control" formControlName="date_accrual"
                                   name="dp2" ngbDatepicker placeholder="dd.mm.yyyy" style="width: 150px;">
                            <!--            TODO Hidden by Natasha-->
                            <!--            <div class="" style="width: 200px; display: flex; gap: 11px">-->
                            <!--              <input type="checkbox" name="checkbox" formControlName="insure_arrival_income" id="insure_arrival_income">-->
                            <!--              <label for="insure_arrival_income" style="width: 100%" class="col-sm-3 col-form-label">-->
                            <!--                Подтвердить-->
                            <!--                начисление</label>-->
                            <!--            </div>-->
                        </div>
                        <span
                                *ngIf="this.FormArrival.controls['date_accrual'].invalid && this.FormArrival.controls['date_accrual'].touched"
                                class="alert-danger">Не корректно заполнено поле</span>
                    </div>
                </div>
                <div class="position-relative row form-group" style="display: flex">
                    <label class="col-sm-3 col-form-label" for="account_lable">Счет<span
                            style="color:red">*</span></label>
                    <div style="width: 366px;">
                        <ng-select [dropdownPosition]="'bottom'"
                                   [items]="finAccountsList"
                                   [searchable]="true"
                                   [virtualScroll]="true"
                                   bindLabel="name"
                                   bindValue="UID"
                                   formControlName="account_id" notFoundText="Ничего не найдено"
                                   placeholder="Cчета">
                        </ng-select>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="amount_label">Сумма, руб.<span
                            style="color:red">*</span></label>
                    <div class="col-sm-9">
                        <p-inputNumber
                                [maxFractionDigits]="3"
                                [maxlength]="15"
                                [minFractionDigits]="1"
                                [style]="{ width: '343px', height: '37px', fontSize: '12px'}"
                                formControlName="amount"
                                mode="decimal" placeholder="123.45">
                        </p-inputNumber>
                        <span *ngIf="this.FormArrival.controls['amount'].invalid && this.FormArrival.controls['amount'].touched"
                              class="alert-danger">Введите только цифры</span>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label">Артикул</label>
                    <div style="width: 366px;">
                        <ng-select [items]="finArticlesNm" [searchable]="true"
                                   bindLabel="name" bindValue="name"
                                   formControlName="product_code" notFoundText="Ничего не найдено"
                                   placeholder="Артикул">
                        </ng-select>
                    </div>
                    <!--        TODO Hidden by Natasha-->
                    <!--        <div style="width: 323px;" *ngIf="!showSelectDropDown">-->
                    <!--          <input name="amount" id="project" placeholder="" type="text"-->
                    <!--                 formControlName="product_code" class="form-control">-->
                    <!--        </div>-->
                    <!--        <button type="button" pButton pRipple icon="pi pi-plus"-->
                    <!--                style="width: 30px; height: 30px; margin-top: 2px;"-->
                    <!--                class="p-button-success mr-2 button-excel button-add-supplier"-->
                    <!--                [pTooltip]="'Добавить артикул'"-->
                    <!--                tooltipPosition="bottom" (click)="switchProductCode()">-->
                    <!--        </button>-->
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label">Контрагент</label>
                    <div style="width: 323px;">
                        <ng-select [items]="finPartnersList" [searchable]="true" bindLabel="name"
                                   bindValue="UID"
                                   formControlName="partner_id" notFoundText="Ничего не найдено"
                                   placeholder="Категория">
                        </ng-select>
                    </div>
                    <button (click)="openNewAgentModal()" [pTooltip]="'Добавить контрагента'"
                            class="p-button-success mr-2 button-excel button-add-supplier" icon="pi pi-plus"
                            pButton
                            pRipple
                            style="width: 30px; height: 30px; margin-top: 2px;"
                            tooltipPosition="bottom" type="button">
                    </button>
                </div>
                <div class="position-relative row form-group" style="display: flex">
                    <label class="col-sm-3 col-form-label">
                        Статья</label>
                    <div style="width: 366px;">
                        <ng-select [items]="list_item" [searchable]="true" bindLabel="name" bindValue="id"
                                   formControlName="item" notFoundText="Ничего не найдено" placeholder="Статья">
                        </ng-select>
                    </div>
                </div>
                <!--      TODO Hidden by Natasha-->
                <!--      <div class="position-relative row form-group">-->
                <!--        <label for="project" class="col-sm-3 col-form-label">Проект</label>-->
                <!--        <div class="col-sm-9">-->
                <!--          <input name="amount" id="project" placeholder="" type="text"-->
                <!--                 formControlName="project" class="form-control">-->
                <!--          <span class="alert-danger"-->
                <!--                *ngIf="this.FormArrival.controls['project'].hasError('invalidecode') && this.FormArrival.controls['project'].invalid && this.FormArrival.controls['project'].touched">-->
                <!--            {{errormsgconfirm}}-->
                <!--          </span>-->
                <!--        </div>-->
                <!--      </div>-->
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="description_l">Комментарий</label>
                    <div class="col-sm-9">
          <textarea class="form-control" formControlName="description"
                    id="description_l"
                    name="description">
          </textarea>
                        <span
                                *ngIf="this.FormArrival.controls['description'].invalid && this.FormArrival.controls['description'].touched"
                                class="alert-danger">Не корректно заполнено поле</span>
                    </div>
                </div>
            </div>
            <app-footer-modal
                    (submitEmitter)="createUpdateOperation('arrival')"
                    [isCreate]="isCreate"
                    [isDisabled]="FormArrival.invalid">
            </app-footer-modal>
        </form>
    </ng-template>

    <!--  ФОРМА ДЛЯ ДОБАВЛЕНИ/ИЗМЕНЕНИЯ СПИСАНИЯ -->
    <ng-template #expenses>
        <app-header-modal
                (closeEmitter)="closeModal()"
                [modalTitle]="(isCreate ? 'Добавление' : 'Изменение') + ' операции списания'">
        </app-header-modal>
        <form [formGroup]="FormExpenses"
              class="needs-validation"
              novalidate>
            <div class="modal-body">
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="date_payment_l">Дата оплаты<span
                            style="color:red">*</span></label>
                    <div class="col-sm-9">
                        <div class="position-relative row form-group ms-0">
                            <input #d1="ngbDatepicker"
                                   (click)="d1.toggle()"
                                   autocomplete="off"
                                   class="form-control"
                                   formControlName="date_payment"
                                   name="dp1"
                                   ngbDatepicker
                                   placeholder="DD.MM.YYYY"
                                   style="width: 150px;">
                        </div>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="date_accrual">Дата начисления</label>
                    <div class="col-sm-9">
                        <div class="position-relative row form-group ms-0">
                            <input #d2="ngbDatepicker"
                                   (click)="d2.toggle()"
                                   autocomplete="off"
                                   class="form-control"
                                   formControlName="date_accrual"
                                   id="date_accrual"
                                   name="dp2"
                                   ngbDatepicker
                                   placeholder="DD.MM.YYYY"
                                   style="width: 150px;">
                        </div>
                    </div>
                </div>
                <div class="position-relative row form-group" style="display: flex">
                    <label class="col-sm-3 col-form-label" for="account_lable">Счет<span
                            style="color:red">*</span></label>
                    <div style="width: 366px;">
                        <ng-select [dropdownPosition]="'bottom'"
                                   [items]="finAccountsList"
                                   [searchable]="true"
                                   [virtualScroll]="true"
                                   bindLabel="name"
                                   bindValue="UID"
                                   formControlName="account_id"
                                   notFoundText="Ничего не найдено"
                                   placeholder="Cчет">
                        </ng-select>
                    </div>
                </div>
                <div class="position-relative row form-group d-flex">
                    <label class="col-sm-3 col-form-label"
                           for="amount_label">
                        Сумма, руб.<span
                            style="color:red">*</span></label>
                    <div class="col-sm-9" style="width: 325px;">
                        <p-inputNumber
                                [maxlength]="15"
                                [style]="{ width: '300px', height: '37px', fontSize: '12px'}"
                                formControlName="amount">
                        </p-inputNumber>
                        <span (click)="addSeparateSumOperation()"
                              *ngIf="isFormExpensesAmountValid && !displaySeparateSum"
                              class="separate__sum_title">Разделить сумму</span>
                        <span (click)="removeSeparateSum()"
                              *ngIf="isFormExpensesAmountValid && displaySeparateSum"
                              class="separate__sum_title">Удалить разделение</span>
                    </div>
                    <button (click)="addSeparateSumOperation()"
                            [disabled]="!displaySeparateSum"
                            [pTooltip]="'Разделить платеж'"
                            class="p-button-success mr-2 button-excel button-add-supplier"
                            icon="pi pi-plus"
                            pButton pRipple
                            style="width: 30px; height: 30px; margin-top: 2px;"
                            tooltipPosition="bottom" type="button">
                    </button>
                </div>
                <div *ngIf="isFormExpensesAmountValid && displaySeparateSum"
                     class="separate__block"
                     formArrayName="separate_payment">
                    <div *ngFor="let operation of separateSumOperationControls.controls; let i = index;"
                         class="wrapp-operation">
                        <div [formGroupName]="i" class="wrap__expenses__block d-flex justify-content-end gap-1">
                            <input #d1="ngbDatepicker"
                                   (click)="d1.toggle()"
                                   autocomplete="off"
                                   class="form-control"
                                   formControlName="datePaymentSeparate"
                                   name="dp1"
                                   ngbDatepicker
                                   placeholder="DD.MM.YYYY"
                                   style="width: 167px;">
                            <p-inputNumber
                                    [maxlength]="15"
                                    [style]="{ height: '37px', fontSize: '12px'}"
                                    formControlName="sumPaymentSeparate">
                            </p-inputNumber>
                            <button (click)="removeSeparateSumOperation(i)"
                                    [style.visibility]="i < 2 ? 'hidden' : 'visible'"
                                    class="p-button-danger mr-2 button-excel button-add-supplier"
                                    icon="pi pi-minus-circle"
                                    pButton
                                    style="width: 30px; height: 30px; margin-top: 2px;"
                                    type="button">
                            </button>
                        </div>
                    </div>
                    <p *ngIf="displayMessageSumBigger" class="text-end m-0" style="color: red">
                        Сумма платежей должна быть равна изначальной сумме!
                    </p>
                </div>

                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label">Артикул</label>
                    <ng-container *ngIf="showSelectDropDown">
                        <div style="width: 366px;">
                            <ng-select [items]="finArticlesNm" [placeholder]="'Артикул'"
                                       [searchable]="true"
                                       bindLabel="name"
                                       bindValue="name"
                                       formControlName="product_code"
                                       notFoundText="Ничего не найдено">
                            </ng-select>
                        </div>
                    </ng-container>
                </div>
                <div class="position-relative row form-group d-flex">
                    <label class="col-sm-3 col-form-label">
                        Контрагент</label>
                    <div style="width: 323px;">
                        <ng-select [items]="finPartnersList"
                                   [searchable]="true"
                                   bindLabel="name"
                                   bindValue="UID"
                                   formControlName="partner_id"
                                   notFoundText="Ничего не найдено"
                                   placeholder="Категория">
                        </ng-select>
                    </div>
                    <button (click)="openNewAgentModal()"
                            [pTooltip]="'Добавить контрагента'"
                            class="p-button-success mr-2 button-excel button-add-supplier"
                            icon="pi pi-plus"
                            pButton
                            pRipple
                            style="width: 30px; height: 30px; margin-top: 2px;"
                            tooltipPosition="bottom" type="button">
                    </button>
                </div>
                <div class="position-relative row form-group" style="display: flex">
                    <label class="col-sm-3 col-form-label">
                        Статья</label>
                    <div style="width: 366px;">
                        <ng-select [items]="list_item" [searchable]="true" bindLabel="name" bindValue="id"
                                   formControlName="item" notFoundText="Ничего не найдено" placeholder="Категория">
                        </ng-select>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="description_l-1">Комментарий</label>
                    <div class="col-sm-9">
            <textarea class="form-control"
                      formControlName="description"
                      name="description">
            </textarea>
                    </div>
                </div>
            </div>
            <app-footer-modal
                    (submitEmitter)="createUpdateOperation('expenses')"
                    [isCreate]="isCreate"
                    [isDisabled]="FormExpenses.invalid">
            </app-footer-modal>
        </form>
    </ng-template>

    <!--  ФОРМА ДЛЯ ДОБАВЛЕНИ/ИЗМЕНЕНИЯ УДЕРЖАНИЯ -->
    <ng-template #retention>
        <app-header-modal
                (closeEmitter)="closeModal()"
                [modalTitle]="(isCreate ? 'Добавление' : 'Изменение') + ' операции удержание WB'">
        </app-header-modal>
        <form [formGroup]="FormRetention"
              class="needs-validation"
              novalidate>
            <p-toast></p-toast>
            <div class="modal-body">
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="date_payment_l">Дата оплаты<span
                            style="color:red">*</span></label>
                    <div class="col-sm-9">
                        <div class="position-relative row form-group" style="margin-left: 0;">
                            <input #d1="ngbDatepicker" (click)="d1.toggle()"
                                   autocomplete="off" class="form-control" formControlName="date_payment"
                                   id="date_payment_l" name="dp1" ngbDatepicker placeholder="DD.MM.YYYY"
                                   style="width: 150px;">
                            <!--              TODO Approved by Natasha-->
                            <!--              <div class="" style="width: 200px; display: flex; gap: 11px">-->
                            <!--                <input type="checkbox" name="checkbox" formControlName="insure_arrival_payment"-->
                            <!--                       id="insure_arrival_payment">-->

                            <!--                <label for="insure_arrival_payment" style="width: 100%" class="col-sm-3 col-form-label">Подтвердить-->
                            <!--                  оплату</label>-->
                            <!--              </div>-->
                        </div>
                        <span
                                *ngIf="this.FormRetention.controls['date_payment'].invalid && this.FormRetention.controls['date_payment'].touched"
                                class="alert-danger">Не верно заполненон поле</span>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="date_accrual_l">Дата начисления</label>
                    <div class="col-sm-9">
                        <div class="position-relative row form-group" style="margin-left: 0;">
                            <input #d2="ngbDatepicker" (click)="d2.toggle()"
                                   autocomplete="off" class="form-control"
                                   formControlName="date_accrual" id="date_accrual_l" name="dp2"
                                   ngbDatepicker placeholder="DD.MM.YYYY" style="width: 150px;">
                            <!--              TODO Approved by Natasha-->
                            <!--              <div class="" style="width: 200px; display: flex; gap: 11px">-->
                            <!--                <input type="checkbox" name="checkbox" formControlName="insure_arrival_income"-->
                            <!--                       id="insure_arrival_income">-->
                            <!--                <label for="insure_arrival_income" style="width: 100%" class="col-sm-3 col-form-label">Подтвердить-->
                            <!--                  начисление</label>-->
                            <!--              </div>-->
                        </div>
                        <span
                                *ngIf="this.FormRetention.controls['date_accrual'].invalid && this.FormRetention.controls['date_accrual'].touched"
                                class="alert-danger">Не корректно заполнено поле</span>
                    </div>
                </div>
                <div class="position-relative row form-group" style="display: flex">
                    <label class="col-sm-3 col-form-label" for="account_lable">Счет<span
                            style="color:red">*</span></label>
                    <div style="width: 366px;">
                        <ng-select [dropdownPosition]="'bottom'"
                                   [items]="finAccountsList"
                                   [searchable]="true"
                                   [virtualScroll]="true"
                                   bindLabel="name"
                                   bindValue="UID"
                                   formControlName="account_id"
                                   id="account_lable" notFoundText="Ничего не найдено"
                                   placeholder="Cчета">
                        </ng-select>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="amount_label">Сумма, руб.<span
                            style="color:red">*</span></label>
                    <div class="col-sm-9">
                        <p-inputNumber
                                [maxFractionDigits]="3"
                                [maxlength]="15"
                                [minFractionDigits]="1"
                                [style]="{ width: '343px', height: '37px', fontSize: '12px'}"
                                formControlName="amount"
                                mode="decimal" placeholder="123.45">
                        </p-inputNumber>
                        <span
                                *ngIf="this.FormRetention.controls['amount'].hasError('invalidecode') && this.FormRetention.controls['amount'].invalid && this.FormExpenses.controls['amount'].touched"
                                class="alert-danger">
            {{ errormsgconfirm }}
          </span>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label">Артикул</label>
                    <div style="width: 366px;">
                        <ng-select [items]="finArticlesNm" [placeholder]="'Артикул'"
                                   [searchable]="true"
                                   bindLabel="name"
                                   bindValue="name" formControlName="product_code" notFoundText="Ничего не найдено">
                        </ng-select>
                    </div>
                    <!--            <button type="button" pButton pRipple icon="pi pi-plus"-->
                    <!--                    style="width: 30px; height: 30px; margin-top: 2px;"-->
                    <!--                    class="p-button-success mr-2 button-excel button-add-supplier"-->
                    <!--                    [pTooltip]="'Добавить артикул'"-->
                    <!--                    tooltipPosition="bottom" (click)="showSelectDropDown = !showSelectDropDown">-->
                    <!--            </button>-->
                    <!--          <ng-container *ngIf="!showSelectDropDown">-->
                    <!--            <div style="width: 323px;">-->
                    <!--              <input name="amount" id="project" placeholder="" type="text"-->
                    <!--                     formControlName="product_code" class="form-control">-->
                    <!--            </div>-->
                    <!--            <button type="button" pButton pRipple icon="pi pi-arrow-circle-left"-->
                    <!--                    style="width: 30px; height: 30px; margin-top: 2px;"-->
                    <!--                    class="p-button-success mr-2 button-excel button-add-supplier"-->
                    <!--                    [pTooltip]="'Добавить артикул'"-->
                    <!--                    tooltipPosition="bottom" (click)="showSelectDropDown = !showSelectDropDown">-->
                    <!--            </button>-->
                    <!--          </ng-container>-->
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label">
                        Контрагент</label>
                    <div style="width: 323px;">
                        <ng-select [items]="finPartnersList" [searchable]="true" bindLabel="name"
                                   bindValue="UID"
                                   formControlName="partner_id" notFoundText="Ничего не найдено"
                                   placeholder="Категория">
                        </ng-select>
                    </div>
                    <button (click)="openNewAgentModal()" [pTooltip]="'Добавить контрагента'"
                            class="p-button-success mr-2 button-excel button-add-supplier" icon="pi pi-plus"
                            pButton
                            pRipple
                            style="width: 30px; height: 30px; margin-top: 2px;"
                            tooltipPosition="bottom" type="button">
                    </button>
                </div>
                <div class="position-relative row form-group" style="display: flex">
                    <label class="col-sm-3 col-form-label">
                        Статья</label>
                    <div style="width: 366px;">
                        <ng-select [items]="list_item" [searchable]="true" bindLabel="name" bindValue="id"
                                   formControlName="item" notFoundText="Ничего не найдено" placeholder="Категория">
                        </ng-select>
                    </div>
                </div>
                <!--        TODO Hidden by Natasha-->
                <!--        <div class="position-relative row form-group">-->
                <!--          <label for="project" class="col-sm-3 col-form-label">Проект</label>-->
                <!--          <div class="col-sm-9">-->
                <!--            <input name="amount" id="project" placeholder="" type="text"-->
                <!--                   formControlName="project" class="form-control">-->
                <!--            <span class="alert-danger"-->
                <!--                  *ngIf="this.FormRetention.controls['project'].hasError('invalidecode') && this.FormRetention.controls['project'].invalid && this.FormRetention.controls['project'].touched">-->
                <!--            {{errormsgconfirm}}-->
                <!--          </span>-->
                <!--          </div>-->
                <!--        </div>-->
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="description_l-1">Комментарий</label>
                    <div class="col-sm-9">
          <textarea class="form-control" formControlName="description" id="description_l-1"
                    name="description">
        </textarea>
                    </div>
                </div>
            </div>
            <app-footer-modal
                    (submitEmitter)="createUpdateOperation('retention')"
                    [isCreate]="isCreate"
                    [isDisabled]="FormRetention.invalid">
            </app-footer-modal>
        </form>
    </ng-template>

    <!--  ФОРМА ДЛЯ ДОБАВЛЕНИ/ИЗМЕНЕНИЯ ПЕРЕМЕЩЕНИЯ -->
    <ng-template #moving>
        <app-header-modal
                (closeEmitter)="closeModal()"
                [modalTitle]="(isCreate ? 'Добавление' : 'Изменение') + ' операции перемещения'">
        </app-header-modal>
        <form [formGroup]="FormMoving"
              class="needs-validation"
              novalidate>
            <div class="modal-body">
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="date_payment_l">Дата оплаты<span
                            style="color:red">*</span></label>
                    <div class="col-sm-5">
                        <input #d1="ngbDatepicker" (click)="d1.toggle()" [(ngModel)]="modelDatePayment"
                               class="form-control"
                               formControlName="date_payment" name="dp1" ngbDatepicker placeholder="dd.mm.yyyy">
                        <!--<div class="alert alert-danger" *ngIf="this.FormArrival.controls['date_payment'].invalid && this.FormArrival.controls['date_payment'].touched">Не корректно заполнено поле</div>-->
                        <span
                                *ngIf="this.FormMoving.controls['date_payment'].invalid && this.FormMoving.controls['date_payment'].touched"
                                class="alert-danger">Не верно заполненон поле</span>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="amount_label">Сумма<span
                            style="color:red">*</span></label>
                    <div class="col-sm-9">
                        <p-inputNumber
                                [maxFractionDigits]="3"
                                [maxlength]="15"
                                [minFractionDigits]="1"
                                [style]="{ width: '343px', height: '37px', fontSize: '12px'}"
                                formControlName="amount"
                                id="amount_label"
                                mode="decimal" placeholder="123.45">
                        </p-inputNumber>
                        <span
                                *ngIf="this.FormMoving.controls['amount'].hasError('invalidecode') && this.FormMoving.controls['amount'].invalid && this.FormMoving.controls['amount'].touched"
                                class="alert-danger">{{ errormsgconfirm }}</span>
                    </div>
                </div>
                <div class="position-relative row form-group" style="display: flex">
                    <label class="col-sm-3 col-form-label">Счет откуда<span style="color:red">*</span></label>
                    <div style="width: 366px;">
                        <ng-select (click)="isAccountItemBusy('account_id_2')"
                                   [dropdownPosition]="'bottom'"
                                   [items]="finAccountsList"
                                   [searchable]="true"
                                   [virtualScroll]="true"
                                   bindLabel="name"
                                   bindValue="UID"
                                   formControlName="account_id"
                                   notFoundText="Ничего не найдено"
                                   placeholder="Cчета">
                        </ng-select>
                    </div>
                </div>
                <div class="position-relative row form-group" style="display: flex">
                    <label class="col-sm-3 col-form-label">Счет куда<span style="color:red">*</span></label>
                    <div style="width: 366px;">
                        <ng-select (click)="isAccountItemBusy('account_id')"
                                   [dropdownPosition]="'bottom'"
                                   [items]="finAccountsList"
                                   [searchable]="true"
                                   [virtualScroll]="true"
                                   bindLabel="name"
                                   bindValue="UID"
                                   formControlName="account_id_2" notFoundText="Ничего не найдено"
                                   placeholder="Cчета">
                        </ng-select>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="description1">Комментарий</label>
                    <div class="col-sm-9">
          <textarea class="form-control" formControlName="description"
                    id="description1"
                    name="description">
          </textarea>
                    </div>
                </div>
            </div>
            <p-toast></p-toast>
            <app-footer-modal
                    (submitEmitter)="createUpdateOperation('moving')"
                    [isCreate]="isCreate"
                    [isDisabled]="FormMoving.invalid">
            </app-footer-modal>
        </form>
    </ng-template>

    <!--  ФОРМА ДЛЯ СОЗДАНИЯ КРЕДИТ\ЗАЙМА -->
    <ng-template #loanRepayment>
        <app-header-modal
                (closeEmitter)="closeModal()"
                [modalTitle]="'Добавление операции погашения кредита займа'">
        </app-header-modal>
        <form [formGroup]="FormLoanRepayment"
              class="needs-validation"
              novalidate>
            <div class="modal-body">
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label"
                           for="date_payment_l">Дата оплаты<span
                            style="color:red">*</span></label>
                    <div class="col-sm-9">
                        <div class="position-relative row form-group" style="margin-left: 0;">
                            <input #d1="ngbDatepicker"
                                   (click)="d1.toggle()"
                                   autocomplete="off"
                                   class="form-control"
                                   formControlName="date_payment"
                                   name="dp1"
                                   ngbDatepicker
                                   placeholder="DD.MM.YYYY"
                                   style="width: 150px;">
                        </div>
                        <span
                                *ngIf="this.FormRetention.controls['date_payment'].invalid && this.FormRetention.controls['date_payment'].touched"
                                class="alert-danger">Не верно заполненон поле</span>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label"
                           for="date_accrual_l">
                        Дата начисления
                        <span style="color:red">*</span>
                    </label>
                    <div class="col-sm-9">
                        <div class="position-relative row form-group"
                             style="margin-left: 0;">
                            <input #d2="ngbDatepicker"
                                   (click)="d2.toggle()"
                                   autocomplete="off"
                                   class="form-control"
                                   formControlName="date_accrual"
                                   name="dp2"
                                   ngbDatepicker
                                   placeholder="DD.MM.YYYY"
                                   style="width: 150px;">
                        </div>
                        <span
                                *ngIf="this.FormRetention.controls['date_accrual'].invalid && this.FormRetention.controls['date_accrual'].touched"
                                class="alert-danger">Не корректно заполнено поле</span>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label"
                           for="account_lable">
                        Счет
                        <span style="color:red">*</span>
                    </label>
                    <div style="width: 366px;">
                        <ng-select [dropdownPosition]="'bottom'"
                                   [items]="finAccountsListDebit"
                                   [searchable]="true"
                                   [virtualScroll]="true"
                                   bindLabel="name"
                                   bindValue="UID"
                                   formControlName="account_id"
                                   notFoundText="Ничего не найдено"
                                   placeholder="Cчета">
                        </ng-select>
                    </div>
                </div>
                <div class="position-relative row form-group"
                     style="display: flex">
                    <label class="col-sm-3 col-form-label"
                           for="credit_loan">
                        Кредит\займ<span style="color:red">*</span>
                    </label>
                    <div style="width: 366px;">
                        <ng-select [dropdownPosition]="'bottom'"
                                   [items]="finAccountsListCredit"
                                   [searchable]="true"
                                   [virtualScroll]="true"
                                   bindLabel="name"
                                   bindValue="UID"
                                   formControlName="credit_loan"
                                   id="credit_loan" notFoundText="Ничего не найдено"
                                   placeholder="Кредиты\Займы">
                        </ng-select>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="amount_label">
                        Тело долга, сумма руб.
                        <span style="color:red">*</span>
                    </label>
                    <div class="col-sm-9">
                        <p-inputNumber
                                [maxFractionDigits]="3"
                                [maxlength]="15"
                                [minFractionDigits]="1"
                                [style]="{ width: '343px', height: '37px', fontSize: '12px'}"
                                formControlName="debt_body"
                                mode="decimal"
                                placeholder="123.45">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="amount_label">
                        Проценты, сумма руб.
                        <span style="color:red">*</span>
                    </label>
                    <div class="col-sm-9">
                        <p-inputNumber
                                [maxFractionDigits]="3"
                                [maxlength]="15"
                                [minFractionDigits]="1"
                                [style]="{ width: '343px', height: '37px', fontSize: '12px'}"
                                formControlName="percentages"
                                mode="decimal"
                                placeholder="123.45">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="amount_label">
                        Сумма платежа, руб.<span style="color:red">*</span>
                    </label>
                    <div class="col-sm-9">
                        <input [disabled]="true"
                               [style]="{ width: '343px', height: '37px', fontSize: '12px'}"
                               class="p-inputtext p-component p-element p-inputnumber-input p-filled"
                               formControlName="amount_of_payment"
                               type="number">
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label"
                           for="description_l-1">
                        Комментарий
                    </label>
                    <div class="col-sm-9">
          <textarea class="form-control"
                    formControlName="description"
                    name="description">
        </textarea>
                    </div>
                </div>
            </div>
            <p-toast></p-toast>
            <app-footer-modal
                    (submitEmitter)="createUpdateOperation('repayment')"
                    [isCreate]="isCreate"
                    [isDisabled]="FormLoanRepayment.invalid">
            </app-footer-modal>
        </form>
    </ng-template>

    <!--  ФОРМА ДЛЯ ДОБАВЛЕНИ НОВОГО АГЕНТА -->
    <ng-template #newAgent>
        <app-header-modal
                (closeEmitter)="closeModal()"
                [modalTitle]="'Добавить контрагента'">
        </app-header-modal>
        <form [formGroup]="FormNewAgent"
              class="needs-validation"
              novalidate>
            <div class="modal-body">
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="shop_lable">Магазин</label>
                    <div class="col-sm-9">
                        <select class="form-control"
                                formControlName="shop"
                                id="shop_lable"
                                name="shop">
                            <option *ngFor="let shop of shopsList"
                                    [ngValue]="shop.id">
                                {{ shop.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="name_label">Наименование<span
                            style="color:red">*</span></label>
                    <div class="col-sm-9">
                        <input class="form-control"
                               formControlName="name"
                               id="name_label"
                               name="name"
                               placeholder="Введите наименование"
                               type="text">
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="inn_label">ИНН</label>
                    <div class="col-sm-9">
                        <input class="form-control"
                               formControlName="inn"
                               id="inn_label"
                               name="inn"
                               placeholder="ИНН"
                               type="text">
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="kpp_lable">КПП</label>
                    <div class="col-sm-9">
                        <input class="form-control"
                               formControlName="kpp"
                               id="kpp_lable"
                               name="kpp"
                               placeholder="КПП"
                               type="text">
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="checking_account_lable">Р/С</label>
                    <div class="col-sm-9">
                        <input class="form-control"
                               formControlName="checking_account"
                               id="checking_account_lable"
                               name="checking_account"
                               placeholder="Расчетный счет"
                               type="text">
                    </div>
                </div>

                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="checking_account_lable">Доходная статья</label>
                    <div class="col-sm-9" style="margin-top: 4px;">
                        <p-dropdown [filter]="true" [options]="itemsIncomeList"
                                    [showClear]="true"
                                    [style]="{ display: 'flex', alignItems: 'center', height: '34px'}"
                                    formControlName="income_item"
                                    optionLabel="name" optionValue="id"
                                    placeholder="Нераспределенный доход"></p-dropdown>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="checking_account_lable">Расходная статья</label>
                    <div class="col-sm-9" style="margin-top: 4px;">
                        <p-dropdown [filter]="true" [options]="itemsExpensesList"
                                    [showClear]="true"
                                    [style]="{ display: 'flex', alignItems: 'center', height: '34px'}"
                                    formControlName="expenses_item"
                                    optionLabel="name" optionValue="id"
                                    placeholder="Нераспределенный расход"></p-dropdown>
                    </div>
                </div>
                <div class="position-relative row form-group">
                    <label class="col-sm-3 col-form-label" for="description">Описание</label>
                    <div class="col-sm-9">
            <textarea class="form-control"
                      formControlName="description"
                      id="description"
                      name="description">

            </textarea>
                    </div>
                </div>
            </div>
            <p-toast></p-toast>
            <app-footer-modal
                    (submitEmitter)="createNewAgent($event)"
                    [isCreate]="isCreate"
                    [isDisabled]="FormNewAgent.invalid">
            </app-footer-modal>
        </form>
    </ng-template>
</div>
