import { ColDef, ColGroupDef } from 'ag-grid-community';
import { CustomTooltipComponent } from './components/prdcustomTooltip';
import { CheckboxProductsRendererComponent } from './components/checkbox-products-renderer.component';
import { FinoperationSpecificCellsComponent } from '../../finances/finoperations/components/aggrid-components/finoperation-specific-cells.component';
import { NumberSpacesFormatter } from '../../../shared/utils/functions/number-space-formatter';
import { WBImgFormatter } from '../../../shared/utils/functions/wb-img-formatter';
import { WBProductsLinkEmitter } from '../../../shared/utils/functions/wb-products-link-emitter';
import { NumberSortFunc } from '../../../shared/utils/functions/number-sort-func';

export const LOCALE_TEXT_FOR_FILTERS: any = {
  // Number Filter & Text Filter
  filterOoo: 'Фильтр...',
  equals: 'Равно',
  notEqual: 'не равно',
  empty: 'Пусто',
  /* for number filter */
  lessThan: 'Меньше чем',
  greaterThan: 'Больше чем',
  lessThanOrEqual: 'Меньше или равно',
  greaterThanOrEqual: 'Больше или равно',
  inRange: 'В промежутке',
  inRangeStart: 'от',
  inRangeEnd: 'до',
  // Text Filter
  contains: 'Содержит',
  notContains: 'Не Содержит',
  startsWith: 'Начинается с',
  endsWith: 'Заканчиваеться на',
  // Date Filter
  dateFormatOoo: 'dd.mm.yyyy',
  // Filter Conditions
  andCondition: 'И',
  orCondition: 'ИЛИ',
  // Filter Buttons
  resetFilter: 'Очистить',
  applyFilter: 'Применить',
  clearFilter: 'Clear',
  cancelFilter: 'Cancel',
  // Filter Titles
  textFilter: 'Text Filter',
  numberFilter: 'Number Filter',
  dateFilter: 'Date Filter',
  setFilter: 'Set Filter',
};
export const FILTER_PARAMS_TEXT = {
  buttons: ['reset', 'apply'],
  closeOnApply: true,
  suppressAndOrCondition: true,
  filterOptions: ['contains', 'notContains'],
  defaultOption: 'contains',
};
export const FILTER_PARAMS_NUM = {
  buttons: ['reset', 'apply'],
  closeOnApply: true,
  suppressAndOrCondition: true,
  filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
};
export const FILTER_PARAMS_FULL_TEXT = {
  buttons: ['reset', 'apply'],
  closeOnApply: true,
  suppressAndOrCondition: true,
  filterOptions: ['contains', 'notContains', 'equals'],
};
export const CUSTOM_STYLES = `
  .ball-clip-rotate_dop {
    background-color: #3f6ad8;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border: 2px solid #3f6ad8;
    border-bottom-color: transparent;
    height: 15px;
    width: 15px;
    background: transparent !important;
    display: inline-block;
    -webkit-animation: rotate 0.75s 0s linear infinite;
    animation: rotate 0.75s 0s linear infinite;
  }
  .price_list {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #f0f3f5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  :host ::ng-deep .p-cell-editing {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;
export let CELL_CLASS = ['d-flex', 'justify-content-center', 'align-items-center'];
export let CELL_CLASS_LEFT = ['d-flex', 'justify-content-left', 'align-items-left'];

export const GENERAL_STATUSES = [];

export let PRODUCTS_COLUMN_DEFS_SALES_ONINIT: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Выведен из оборота',
    field: 'status_cancel',
    resizable: true,
    width: 30,
    cellClass: CELL_CLASS_LEFT,
    cellRenderer: CheckboxProductsRendererComponent,
  },
  {
    headerName: 'Фото',
    field: 'photo_new',
    cellRenderer: WBImgFormatter,
    sortable: true,
    resizable: true,
    width: 100,
    filter: false,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS,
    tooltipField: 'photo_new',
    tooltipComponentParams: {
      type: 'success',
      photoField: 'photo_new', // Field containing the photo information
    },
    tooltipComponent: CustomTooltipComponent,
  },
  {
    headerName: 'Категория',
    field: 'category',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Категория ПНЛ',
    field: 'category_pnl',
    floatingFilter: true,
    sortable: true,
    editable: true,
    resizable: true,
    width: 150,
    filter: true,
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    cellClass: CELL_CLASS,
    flex: 0,
  },
  {
    headerName: 'Статус',
    field: 'status_name',
    floatingFilter: true,
    editable: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: GENERAL_STATUSES,
    },
  },
  {
    headerName: 'Предмет',
    field: 'subject',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Артикул WB',
    field: 'nmid',
    cellRenderer: WBProductsLinkEmitter,
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Артикул поставщика',
    field: 'SKU',
    floatingFilter: true,
    valueGetter: (params) => {
      return params.data.SKU;
    },
    sortable: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    filterParams: FILTER_PARAMS_TEXT,
  },
  {
    headerName: 'Баркод',
    field: 'Barcode',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    filterParams: FILTER_PARAMS_TEXT,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Наименование ВБ',
    field: 'SKU_name',
    floatingFilter: true,
    sortable: false,
    resizable: true,
    width: 150,
    filter: false,
    suppressSizeToFit: true,
    flex: 0,
  },
  {
    headerName: 'Мое название',
    field: 'my_name',
    floatingFilter: true,
    sortable: false,
    resizable: true,
    width: 150,
    filter: false,
    suppressSizeToFit: true,
    editable: true,
    flex: 0,
  },
  {
    headerName: 'Себестоимость',
    field: 'cost_price',
    floatingFilter: true,
    width: 180,
    sortable: true,
    filter: true,
    editable: true,
    cellStyle: { background: '#f0f3f5' },
    valueFormatter: NumberSpacesFormatter,
    valueSetter: (params) => {
      if (!numberIsNan(params)) {
        params.data[params.colDef.field] = params.newValue;
        return true;
      } else {
        return false;
      }
    },
    comparator: NumberSortFunc,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellRenderer: FinoperationSpecificCellsComponent,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Размер',
    field: 'techSize',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 100,
    filter: 'agTextColumnFilter',
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: ['d-flex', 'justify-content-center', 'align-items-center'],
  },
  {
    headerName: 'Бренд',
    field: 'brand',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    wrapText: true,
    filter: 'agTextColumnFilter',
    filterParams: FILTER_PARAMS_TEXT,
  },
  {
    headerName: 'Поставщик',
    field: 'shop_name',
    floatingFilter: true,
    sortable: true,
    resizable: true,
    width: 150,
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS_LEFT,
  },
  {
    headerName: 'Мои остатки',
    field: 'mystockwh',
    floatingFilter: true,
    sortable: true,
    editable: true,
    resizable: true,
    width: 150,
    valueSetter: (params) => {
      if (!numberIsNan(params)) {
        params.data[params.colDef.field] = params.newValue;
        return true;
      } else {
        return false;
      }
    },
    cellStyle: { background: '#f0f3f5' },
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS_LEFT,
  },
  {
    headerName: 'Цвета',
    field: 'colors',
    floatingFilter: true,
    sortable: true,
    editable: true,
    resizable: true,
    width: 150,
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS_LEFT,
  },
  {
    headerName: 'Дата последней продажи',
    field: 'last_sale',
    floatingFilter: true,
    sortable: true,
    editable: true,
    resizable: true,
    width: 150,
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS_LEFT,
  },
  {
    headerName: 'Доступно на FBO',
    field: 'quantity',
    floatingFilter: true,
    sortable: true,
    editable: true,
    resizable: true,
    width: 150,
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS,
  },
  {
    headerName: 'Срок производства пошива',
    field: 'production_time',
    floatingFilter: true,
    sortable: true,
    editable: true,
    resizable: true,
    width: 150,
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS,
    cellStyle: { background: '#f0f3f5' },
  },
  {
    headerName: 'Срок доставки до ФФ',
    field: 'delivery_time_ff',
    floatingFilter: true,
    sortable: true,
    editable: true,
    resizable: true,
    width: 150,
    filter: true,
    filterParams: FILTER_PARAMS_TEXT,
    suppressSizeToFit: true,
    flex: 0,
    cellClass: CELL_CLASS,
    cellStyle: { background: '#f0f3f5' },
  },
];

function editorHandler(value: any) {
  console.log(value);
}

function numberIsNan(params: any): boolean {
  return isNaN(params.newValue);
}
