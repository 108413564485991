<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div *ngIf="view_google_table_info" class="col-sm-5 col-md-5">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Special Title Treatment</h5>With supporting text below
      as a natural lead-in to additional content.
      <div class="col-sm-4 col-md-4">
        <button (click)="closeGoogleTableMsg(1)" class="btn btn-primary mt-2 mr-2">Все понятно</button>
      </div>
    </div>
    <br/>
  </div>
  <div class="app-page-title">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="icon pe-7s-users icon-gradient bg-happy-itmeo"></i>
        </div>
        <div>
          Мои Юрлица
        </div>
      </div>
      <div class="page-title-actions">
        <button (click)="openLarge(getPhone, 0)" class="btn-shadow d-inline-flex align-items-center btn btn-success"
                type="button">
          <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
          Добавить юр. лицо
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="shop?.length == 0" class="text-center mbg-3">
    <button (click)="openLarge(getPhone, 0)"
            class="btn-wide btn-pill fsize-1 btn-shadow d-inline-flex align-items-center btn-lg btn btn-success"
            type="button">
    <span class="mr-2 opacity-7">
      <i class="icon pe-7s-plus"></i>
    </span>
      Добавить поставщика
    </button>
  </div>
  <div *ngIf="shop?.length != 0" class="row">
    <div *ngFor="let item of shop" class="col-md-12 col-lg-6 col-xl-4">
      <div class="card-shadow-primary profile-responsive card-border mb-3 card">
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-dark">
            <div class="menu-header-content btn-pane-right">
              <div>
                <h5 *ngIf="item.name; else itemSpace" class="menu-header-title">{{item.name}}</h5>
                <h6 *ngIf="item.name_company" class="menu-header-subtitle">{{item.name_company}}</h6>
                <h6 *ngIf="item.name_company; else itemSpace" class="menu-header-subtitle"><br></h6>
                <h6 class="menu-header-subtitle">ИНН: {{item.inn || '-----------------'}}</h6>
                <ng-template #itemSpace>
                  <h5 class="menu-header-title"><br></h5>
                </ng-template>
              </div>
              <div class="menu-header-btn-pane">
                <p-toast></p-toast>
                <button (click)="disabledSeller(item.id)" [disabled]="item.readOnly == 1"
                        class="me-2 btn btn-warning btn-sm"
                        matTooltip="Поставщик будет удален из системы и все его ключи досутпа" matTooltipShowDelay="0">
                  Отключить поставщика
                </button>
              </div>
            </div>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Название подключения</div>
                  <div *ngIf="item.name; else enterName" class="widget-subheading">{{item.name}}</div>
                  <ng-template #enterName>
                    <div class="widget-subheading">
                      <div class="badge rounded-pill bg-danger">введите название</div>
                    </div>
                  </ng-template>
                </div>
                <div class="widget-content-right me-3">
                  <button (click)="updateToken('name',item.id)"
                          [disabled]="item.readOnly == 1"
                          class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm"
                          matTooltip="Для ручного добавления"
                          matTooltipShowDelay="0">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>
                <div class="widget-content-right">
                  <div *ngIf="item.name; else itemNameData" class="fsize-4">
                  <span class="text-success pe-2">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                  </span>
                  </div>
                  <ng-template #itemNameData>
                    <div class="fsize-2"
                         matTooltip="Не удалось получить данные. Необходимо обновить подключение"
                         matTooltipShowDelay="0">
                        <span class="text-danger pe-2">
                          <fa-icon [icon]="faWindowClose"></fa-icon>
                        </span>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Налог
                    (Ставка: {{item.tax_percent ? item.tax_percent + '%' : 'Не установлено'}}
                    , {{getTypeOfTax(+item.tax_type)}})
                  </div>
                </div>
                <div class="widget-content-right" style="margin-right: 43px">
                  <button (click)="updateToken('taxes',item.id)"
                          [disabled]="item.readOnly == 1"
                          class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm"
                          matTooltip="Для ручного добавления"
                          matTooltipShowDelay="0">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Подключение через API ключи</div>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ключ API Новый</div>
                  <div *ngIf="item.wb_key_new_status == null" class="widget-subheading"><br></div>
                  <div *ngIf="item.wb_key_new_status == 0" class="widget-subheading">
                    <div class="badge rounded-pill bg-warning">выполняется проверка</div>
                  </div>
                  <div *ngIf="item.wb_key_new_status == 99" class="widget-subheading">
                    <div class="badge rounded-pill bg-danger">токен не действителен</div>
                  </div>
                </div>
                <div class="widget-content-right">
                  <button (click)="updateToken('KeyApiNewer',item.id)"
                          [disabled]="item.readOnly == 1"
                          class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.wb_key_new_status == 1" class="fsize-4">
                  <span class="text-success pe-2">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_key_new_status == null" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_key_new_status == 99" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_key_new_status == 0" class="fsize-4"
                       matTooltip="Данные загружаются. Ожидайте обновления" matTooltipShowDelay="0">
                    <div class="loader">
                      <div class="ball-clip-rotate">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ключ API Стандартный</div>
                  <div *ngIf="item.wb_key_new_status == null" class="widget-subheading"><br></div>
                  <div *ngIf="item.wb_key_new_status == 0" class="widget-subheading">
                    <div class="badge rounded-pill bg-warning">выполняется проверка</div>
                  </div>
                  <div *ngIf="item.wb_key_new_status == 99" class="widget-subheading">
                    <div class="badge rounded-pill bg-danger">токен не действителен</div>
                  </div>
                </div>
                <div class="widget-content-right">
                  <button (click)="updateToken('KeyApiNew',item.id)"
                          [disabled]="item.readOnly == 1"
                          class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.wb_key_new_status == 1" class="fsize-4">
                  <span class="text-success pe-2">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_key_new_status == null" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_key_new_status == 99" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_key_new_status == 0" class="fsize-4"
                       matTooltip="Данные загружаются. Ожидайте обновления" matTooltipShowDelay="0">
                    <div class="loader">
                      <div class="ball-clip-rotate">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ключ API Статистика</div>
                  <div *ngIf="item.wb_key_status == null" class="widget-subheading"><br></div>
                  <div *ngIf="item.wb_key_status == 0" class="widget-subheading">
                    <div class="badge rounded-pill bg-warning">выполняется проверка</div>
                  </div>
                  <div *ngIf="item.wb_key_status == 99" class="widget-subheading">
                    <div class="badge rounded-pill bg-danger">токен не действителен</div>
                  </div>
                </div>
                <div class="widget-content-right">
                  <button (click)="updateToken('KeyApiStat',item.id)"
                          [disabled]="item.readOnly == 1"
                          class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.wb_key_status == 1" class="fsize-4">
                  <span class="text-success pe-2">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_key_status == null" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_key_status == 99" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_key_status == 0" class="fsize-4"
                       matTooltip="Данные загружаются. Ожидайте обновления" matTooltipShowDelay="0">
                    <div class="loader">
                      <div class="ball-clip-rotate">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ключ API Реклама</div>
                  <div *ngIf="item.wb_api_adv_key_status == null" class="widget-subheading"><br></div>
                  <div *ngIf="item.wb_api_adv_key_status == 0" class="widget-subheading">
                    <div class="badge rounded-pill bg-warning">выполняется проверка</div>
                  </div>
                  <div *ngIf="item.wb_api_adv_key_status == 99" class="widget-subheading">
                    <div class="badge rounded-pill bg-danger">Ключ не действителен</div>
                  </div>
                </div>
                <div class="widget-content-right">
                  <button (click)="updateToken('KeyApiAdv',item.id)"
                          [disabled]="item.readOnly == 1"
                          class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.wb_api_adv_key_status == 1" class="fsize-4">
                  <span class="text-success pe-2">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_api_adv_key_status == null" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_api_adv_key_status == 99" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_api_adv_key_status == 0" class="fsize-4"
                       matTooltip="Данные загружаются. Ожидайте обновления" matTooltipShowDelay="0">
                    <div class="loader">
                      <div class="ball-clip-rotate">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Подключение через Wild token
                    <button (click)="view_full_access_wb()"
                            *ngIf="!hide_full_access_wb"
                            [disabled]="item.readOnly == 1" class="mb-2 me-2 btn btn-dashed btn-outline-primary btn-sm">
                      Показать
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li *ngIf="hide_full_access_wb" class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wild token</div>
                  <div *ngIf="item.wb_auth_token_client_site != null" class="widget-subheading"><br></div>
                  <div *ngIf="item.wb_auth_token_client_site == null" class="widget-subheading">
                    <div class="badge rounded-pill bg-danger">токен не активен</div>
                  </div>
                </div>
                <div class="widget-content-right">
                  <button (click)="updateToken('wild',item.id)"
                          [disabled]="item.readOnly == 1"
                          class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm"
                          matTooltip="Для ручного добавления"
                          matTooltipShowDelay="0">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.wb_auth_token_client_site != null" class="fsize-4">
                  <span class="text-success pe-2">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_auth_token_client_site == null" class="fsize-2"
                       matTooltip="Не удалось получить данные. Необходимо обновить подключение" matTooltipShowDelay="0">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li *ngIf="hide_full_access_wb" class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Supplier-id</div>
                  <div *ngIf="item.wb_auth_supplier_id_status == null" class="widget-subheading"><br></div>
                  <div *ngIf="item.wb_auth_supplier_id_status == 0" class="widget-subheading">
                    <div class="badge rounded-pill bg-warning">выполняется проверка</div>
                  </div>
                  <div *ngIf="item.wb_auth_supplier_id_status == 99" class="widget-subheading">
                    <div class="badge rounded-pill bg-danger">Supplier-id не корректный</div>
                  </div>
                </div>
                <div class="widget-content-right">
                  <button (click)="updateToken('supplier',item.id)"
                          [disabled]="item.readOnly == 1"
                          class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.wb_auth_supplier_id_status == 1" class="fsize-4">
                  <span class="text-success pe-2">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_auth_supplier_id_status == null" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_auth_supplier_id_status == 99" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_auth_supplier_id_status == 0" class="fsize-4"
                       matTooltip="Данные загружаются. Ожидайте обновления" matTooltipShowDelay="0">
                    <div class="loader">
                      <div class="ball-clip-rotate">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li *ngIf="hide_full_access_wb" class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">

                <div class="widget-content-left flex2">
                  <div class="widget-heading">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WBToken для рекламных
                    кампаний
                  </div>
                  <div *ngIf="item.wb_auth_wbtoken_adv_status == null" class="widget-subheading"><br></div>
                  <div *ngIf="item.wb_auth_wbtoken_adv_status == 0" class="widget-subheading">
                    <div class="badge rounded-pill bg-warning">выполняется проверка</div>
                  </div>
                  <div *ngIf="item.wb_auth_wbtoken_adv_status == 99" class="widget-subheading">
                    <div class="badge rounded-pill bg-danger">токен не действителен</div>
                  </div>
                </div>
                <div class="widget-content-right">
                  <button (click)="updateToken('WBTokenAdv',item.id)"
                          [disabled]="item.readOnly == 1"
                          class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.wb_auth_wbtoken_adv_status == 1" class="fsize-4">
                  <span class="text-success pe-2">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_auth_wbtoken_adv_status == null" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_auth_wbtoken_adv_status == 99" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.wb_auth_wbtoken_adv_status == 0" class="fsize-4"
                       matTooltip="Данные загружаются. Ожидайте обновления" matTooltipShowDelay="0">
                    <div class="loader">
                      <div class="ball-clip-rotate">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Telegram ID</div>
                  <div *ngIf="item.telegram_id != null" class="widget-subheading">{{item.telegram_id}}</div>
                  <div *ngIf="item.telegram_id == null" class="widget-subheading"><br></div>
                </div>
                <div class="widget-content-right">
                  <button (click)="updateToken('tgid',item.id)"
                          [disabled]="item.readOnly == 1"
                          class="me-1 btn-icon btn-icon-only btn-shadow btn-dashed btn btn-outline-primary btn-sm">
                    <i class="pe-7s-tools btn-icon-wrapper"> </i>
                  </button>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="widget-content-right">
                  <div *ngIf="item.telegram_id != null" class="fsize-4">
                  <span class="text-success pe-2">
                    <fa-icon [icon]="faAngleDown"></fa-icon>
                  </span>
                  </div>
                  <div *ngIf="item.telegram_id == null" class="fsize-2">
                  <span class="text-danger pe-2">
                    <fa-icon [icon]="faWindowClose"></fa-icon>
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left flex2">
                  <div class="widget-heading">Ключ для google таблиц</div>
                  <div class="widget-subheading">{{item.api_key_mpall}}</div>
                </div>
                <div class="widget-content-right">
                  <div class="fsize-4">
                    <button (click)="updateTokenMpall(item.id)"
                            [disabled]="item.readOnly == 1" class="me-1 btn-icon btn-icon-only btn btn-warning btn-sm">
                      <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <ng-template #addUser let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Добавление пользователя</h4>
      <button (click)="d('Cross click')" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title"></h5>

        <form (ngSubmit)="submitForm()" [formGroup]="form" novalidate>
          <p-inputMask formControlName="phone_number" id="user_phone" mask="+9(999) 999-99-99"
                       name="phone_number"></p-inputMask>
          <div *ngFor="let data of shop_list; let i=index">

            <label>
              <input (change)="onCheckboxChange($event)" [value]="data.id" type="checkbox"/>
              {{data.name}}
            </label>
          </div>
          <input [disabled]="form.invalid || form.disabled" class="mt-1 btn btn-primary" type="submit"
                 value="Добавить пользователя">
        </form>
      </div>
    </div>

  </ng-template>
  <ng-template #getPhone>
    <app-header-modal
      (closeEmitter)="closeModal()"
      [modalTitle]="modal_title_get_phone">
    </app-header-modal>
    <div class="modal-body">
      <div class="col-md-12">
        <h5>Введите название подключения</h5>
        <div class="main-card mb-12 d-flex mb-3">
          <input [(ngModel)]="name_connect"
                 [style]="{width: '20vw'}"
                 class="me-2"
                 pInputText
                 width="100%">
          <button (click)="createConnection(SHOPID)"
                  [disabled]="name_connect?.length === 0"
                  class="p-button-raised p-button-rounded"
                  pButton
                  type="button">
            Добавить
          </button>
        </div>
        <div *ngIf="exists_phone_color == 'warning'" class="alert alert-warning">{{exists_phone}}</div>
        <div *ngIf="exists_phone_color == 'success'" class="alert alert-success">{{exists_phone}}</div>
      </div>
    </div>
  </ng-template>
  <ng-template #updateTokenTmpl>
    <app-header-modal
      (closeEmitter)="closeModal()"
      [modalTitle]="modal_title_update_token">
    </app-header-modal>

    <div class="modal-body">
      <p>Для изменения "{{modal_title_update_token}}" необходимо внести новое значение в поле ниже</p>

      <div *ngIf="modal_title_update_token == 'Название подключения'">
        <input [(ngModel)]="name_connect" pInputText width="100%">&nbsp;&nbsp;&nbsp;<button
        (click)="updateTokenNew(modal_title_update_token, modal_shop_id)" class="p-button-raised p-button-rounded"
        pButton
        type="button">
        Сохранить
      </button>
        <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>
      </div>

      <div class="main-card mb-12">
        <div *ngIf="modal_title_update_token == 'Wild token'" class="d-flex flex-column">
          <div>
            <p>
              Номер телефона необходимо указать от аккаунта со статусом "Владелец"
            </p>
            <p-inputMask [(ngModel)]="val6" [style]="{width: '100%', marginBottom: '10px'}" class="mb-2"
                         mask="+7(999) 999-99-99"
                         placeholder="+7(999) 999-99-99"></p-inputMask>
            <div *ngIf="error_phone_rr" class="alert alert-danger">Не корректно заполнено поле</div>
            <textarea [(ngModel)]="wild" [cols]="30" [rows]="5" [style]="{width: '100%'}" autoResize="autoResize"
                      pInputTextarea></textarea>
            <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>

          </div>
          <button (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
                  class="mt-2 p-button-raised p-button-rounded align-self-end" pButton
                  type="button">Сохранить
          </button>
        </div>
        <div *ngIf="modal_title_update_token == 'Ключ API Новый'" class="d-flex flex-column">
          <textarea [(ngModel)]="KeyApiNewer" [cols]="30" [rows]="5" autoResize="autoResize" pInputTextarea></textarea>
          <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>
          <button (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
                  class="mt-2 p-button-raised p-button-rounded align-self-end" pButton
                  type="button">Сохранить
          </button>
        </div>
        <div *ngIf="modal_title_update_token == 'Налог'" class="d-flex flex-column">
          <div class="position-relative row form-group mb-3">
            <label class="col-sm-3 col-form-label" for="account_id">Тип налога<span style="color:red">*</span></label>
            <div class="col-sm-9">
              <ng-select [(ngModel)]="tax_type" [dropdownPosition]="'bottom'"
                         [items]="typesOfTaxes"
                         [searchable]="true"
                         [virtualScroll]="true"
                         bindLabel="title"
                         bindValue="value"
                         id="account_id" notFoundText="Ничего не найдено"
                         placeholder="Тип налога">
              </ng-select>
            </div>
          </div>
          <div class="position-relative row form-group mb-3">
            <label class="col-sm-3 col-form-label" for="account_id">Процент, %<span style="color:red">*</span></label>
            <div class="col-sm-9">
              <p-inputNumber [(ngModel)]="tax_percent" [max]="100" [min]="0" [style]="{width: '18vw'}"></p-inputNumber>
            </div>
          </div>
          <button (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
                  class="p-button-raised p-button-rounded align-self-end"
                  pButton
                  type="button">Сохранить
          </button>
          <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>
        </div>
        <div *ngIf="modal_title_update_token == 'Supplier-id'" class="d-flex gap-2 flex-column">
          <input [(ngModel)]="supplier" pInputText width="100%">
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
          <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>
        </div>
        <div *ngIf="modal_title_update_token == 'WBToken для рекламных кампаний'" class="d-flex gap-2 flex-column">
          <textarea [(ngModel)]="WBTokenAdv" [cols]="30" [rows]="5" autoResize="autoResize" pInputTextarea></textarea>
          <button (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
                  class="p-button-raised p-button-rounded align-self-end" pButton
                  type="button">Сохранить
          </button>

          <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>
        </div>
        <div *ngIf="modal_title_update_token == 'WBToken для внутренней аналитики'" class="">
          <textarea [(ngModel)]="WBTokenPortal" [cols]="30" [rows]="5" autoResize="autoResize"
                    pInputTextarea></textarea>
          <button (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
                  class="p-button-raised p-button-rounded align-self-end" pButton
                  type="button">Сохранить
          </button>

          <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>
        </div>
        <div *ngIf="modal_title_update_token == 'Ключ API Статистика'" class="d-flex gap-2 flex-column">
          <textarea [(ngModel)]="KeyApiStat" [cols]="30" [rows]="5" autoResize="autoResize" pInputTextarea></textarea>
          <button (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
                  class="p-button-raised p-button-rounded align-self-end" pButton
                  type="button">Сохранить
          </button>
          <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>
        </div>
        <div *ngIf="modal_title_update_token == 'Ключ API Стандартный'" class="d-flex gap-2 flex-column">
          <textarea [(ngModel)]="KeyApiNew" [cols]="30" [rows]="5" autoResize="autoResize" pInputTextarea></textarea>
          <button (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
                  class="p-button-raised p-button-rounded align-self-end" pButton
                  type="button">Сохранить
          </button>

          <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>
        </div>
        <div *ngIf="modal_title_update_token == 'Ключ API Реклама'" class="d-flex gap-2 flex-column">
          <textarea [(ngModel)]="KeyApiAdv" [cols]="30" [rows]="5" autoResize="autoResize" pInputTextarea></textarea>
          <button (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
                  class="p-button-raised p-button-rounded align-self-end" pButton
                  type="button">Сохранить
          </button>
          <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>
        </div>
        <div *ngIf="modal_title_update_token == 'Telegram ID'" class="d-flex gap-2 flex-column">
          <input [(ngModel)]="tgid" pInputText width="100%">
          <button
            (click)="updateTokenNew(modal_title_update_token, modal_shop_id)"
            class="p-button-raised p-button-rounded align-self-end"
            pButton
            type="button">
            Сохранить
          </button>
          <div *ngIf="error_phone" class="alert alert-danger">{{error_phone}}</div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
