import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-settings',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.sass'],
  providers: [CommonModule],
})
export class SettingsComponent implements OnInit {
  apikey: any;
  closeResult: string;
  active: any = 1;

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    if (localStorage.getItem('wb_apikey') == null) {
      this.getApikey();
    } else {
      this.apikey = '**********KEY OK********************';
    }

    this.getApikey();
  }

 private getApikey(): void {
    this.api.userRegister('getApikey', localStorage.getItem('token'), '')
        .pipe(untilDestroyed(this))
        .subscribe((data: any) => {
        if (data == true) {
          localStorage.setItem('wb_apikey', data);
          this.apikey = '**********KEY OK********************';
        }
      },
      (err) => {},
    );
  }


  open(content) {
    this.modalService.open(content, { size: 'lg' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
