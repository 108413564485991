<p-progressSpinner *ngIf="isLoadingStatus" [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<div [ngStyle]="{'display': isLoadingStatus ? 'none' : 'block'}">
  <div class="col-sm-12 col-md-12">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Идет Beta тестирование</h5>
    </div>
    <br/>
  </div>
  <div class="app-page-title" style="margin-bottom: 0">
    <div class="page-title-wrapper">
      <div class="page-title-heading">
        <div class="page-title-icon">
          <i class="pe-7s-display1 icon-gradient bg-mean-fruit"></i>
        </div>
        <div>
          Аналитика рекламы
        </div>
      </div>
    </div>
  </div>
  <div class="main-card mb-3 card" style="height: calc(92vh - 270px)">
    <ag-grid-angular #agGrid
                     style="width: 100%; height: 100%;"
                     class="ag-theme-alpine"
                     [columnDefs]="dataForColumnDefs"
                     [defaultColDef]="defaultColDef"
                     rowSelection="multiple"
                     [rowHeight]="40"
                     [localeText]="localRuFilters"
                     [rowData]="rowData"
                     (gridReady)="onGridReady($event)"
                     [gridOptions]="gridOptions"
                     [rowDragManaged]="true"
                     [pagination]="true"
                     [suppressRowTransform]="true"
                     [enableRangeSelection]="true">
    </ag-grid-angular>
  </div>
</div>
