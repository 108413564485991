<p-progressSpinner *ngIf="isLoading" [style]="{position: 'absolute', top: '40%', left: '50%'}"></p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="mb-3 card">
    <div class="card-header-tab card-header">
      <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
        <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
        Действующий тариф
      </div>
    </div>
    <div class="no-gutters row">
      <div class="col-sm-6 col-md-4 col-xl-4">
        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
          <div class="widget-chart-content">
            <div class="widget-subheading">Наименование</div>
            <div class="widget-numbers">{{tariff?.name}}</div>
          </div>
        </div>
        <div class="divider m-0 d-md-none d-sm-block"></div>
      </div>
      <div class="col-sm-6 col-md-4 col-xl-4">
        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
          <div class="widget-chart-content">
            <div class="widget-subheading">Действует до</div>
            <div class="widget-numbers"><span>{{tariff?.expdate}}</span></div>
            <div class="widget-description opacity-8 text-focus">включительно</div>
          </div>
        </div>
        <div class="divider m-0 d-md-none d-sm-block"></div>
      </div>
      <div class="col-sm-12 col-md-4 col-xl-4">
        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
          <div [ngSwitch]="tariff?.color" class="widget-chart-content">
            <div class="widget-subheading">Осталось</div>
            <div *ngSwitchCase="'green'" class="widget-numbers text-success"><span>{{tariff?.days}}</span>
            </div>
            <div *ngSwitchCase="'red'" class="widget-numbers text-danger"><span>{{tariff?.days}}</span>
            </div>
            <div *ngSwitchCase="'yellow'" class="widget-numbers text-warning"><span>{{tariff?.days}}</span>
            </div>
            <div class="widget-description opacity-8 text-focus">дней</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 card">
    <div class="d-flex justify-content-center align-items-center p-4 gap-4">
      <p-toast></p-toast>
      <div *ngFor="let category of tariffPercents"
           class="d-flex gap-3 fs-2 field-checkbox">
        <p-radioButton
          (ngModelChange)="tariffPercentChanged(category.selector)"
          [(ngModel)]="selectedTariff"
          [inputId]="category.id"
          [value]="category"
          name="category"></p-radioButton>
        <label [for]="category.id"
               class="ml-2" style="cursor: pointer;">
          {{ category.name }}
        </label>

        <div *ngIf="category.id !== 1" class="d-flex align-items-center">
          <span class="percent__content">{{category.sale}}</span>
        </div>
      </div>
    </div>
    <p-toast></p-toast>
    <table class="table">
      <thead>
      <tr>
        <th class="th-scope" scope="col">
        </th>
        <th class="th-scope" scope="col">
          <div class="centered th_header pt-2 pb-2">
            <h3>Базовый</h3>
            <h4 style="font-size:40px;"><b>{{currentPrices.base * currentPrices.months | number:'1.0-2'}}</b></h4>
            <span class="prices__for_month">месяц {{getMonthsPrice('base')}}₽</span>
            <button (click)="createBill(2)"
                    class="btn_buy btn btn-success"
                    type="button">
              Купить
            </button>
          </div>
        </th>
        <th class="th-scope" scope="col">
          <div class="centered th_header pt-2 pb-2">
            <h3>Расширенный</h3>
            <h4 style="font-size:40px;"><b>{{currentPrices.extended * currentPrices.months  | number:'1.0-2'}}</b></h4>
            <span class="prices__for_month">месяц {{getMonthsPrice('extended')  | number:'1.0-2'}}</span>
            <button (click)="createBill(6)"
                    class="btn_buy btn btn-success"
                    type="button">
              Купить
            </button>
          </div>
        </th>
        <th class="th-scope" scope="col">
          <div class="centered th_header pt-2 pb-2">
            <h3>Профессиональный</h3>
            <h4 style="font-size:40px;"><b>{{currentPrices.professional * currentPrices.months | number:'1.0-2'}}</b>
            </h4>
            <span class="prices__for_month">
                          месяц {{getMonthsPrice('professional') | number:'1.0-2'}}
                        </span>
            <button (click)="createBill(10)"
                    class="btn_buy btn btn-success"
                    type="button">
              Купить
            </button>
          </div>
        </th>
        <th class="th-scope" scope="col">
          <div class="centered th_header pt-2 pb-2">
            <h3>Корпоративный</h3>
            <h4 style="font-size:40px;"><b>{{currentPrices.corporate * currentPrices.months | number:'1.0-2'}}</b></h4>
            <span class="prices__for_month">
                          месяц {{getMonthsPrice('corporate') | number:'1.0-2'}}
                        </span>
            <button (click)="createBill(14)"
                    class="btn_buy btn btn-success"
                    type="button">
              Купить
            </button>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let t of availableTariffTabs;">
        <th scope="row">
          <span [ngStyle]="{'font-weight' : headerTabs.includes(t) ? '500' : '100'}">
            {{t}}
          </span>
        </th>
        <td *ngFor="let x of [1,1,1,1]" class="text-center td_cell">
          <!--<i aria-hidden="true" class="fa fa-check-circle"></i>-->
          <svg class="tariff-result-icon icon-size-24 lucide-icon svg-icon--primary" data-v-ddce9764="" fill="none"
               height="32" stroke="#38AC5A"
               stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="32"
               xmlns="http://www.w3.org/2000/svg">
            <polyline key="10jjfj" points="20 6 9 17 4 12"></polyline>
          </svg>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


