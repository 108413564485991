import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Params} from '@angular/router';
import {BloggersRowData} from "../bloggers.interface";

@Component({
  selector: 'app-btn-cell-renderer',
  template: `
    <button (click)="editPurchase()"
            class="icon-button"
            style="margin-right: 25px;">
      <i class="lnr-pencil"></i>
    </button>
    <button (click)="deletePurchase()"
            class="icon-button">
      <i class="lnr-trash"></i>
    </button>
  `,
  styleUrls: ['../bloggers.component.scss'],
})
export class BloggersCellActionComponent implements ICellRendererAngularComp {
  componentParent: any;
  private params: Params;

  agInit(params: Params): void {
    this.params = params;
    this.componentParent = this.params.context.componentParent;
  }

  editPurchase() {
    this.componentParent.editModalEmitter(this.params.data);
  }

  deletePurchase() {
    const bloggerData = this.params.data as BloggersRowData;
    this.componentParent.deleteBloggerAdvertCompany(bloggerData.blogger_id);
  }

  refresh() {
    return false;
  }
}
