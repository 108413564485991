import { ISelectedTypesP } from '../../../shared/interfaces/common.interface';
import { CustomTooltipComponent } from '../../guides/products/components/prdcustomTooltip';
import { WBImgFormatter } from '../../../shared/utils/functions/wb-img-formatter';

export const ANALISISE_STATUSES: ISelectedTypesP<number, string>[] = [
  { value: 1, label: 'АВС анализ кратко' },
  { value: 2, label: 'АВС анализ подробно' },
];

export const WB_PARAMS: ISelectedTypesP<number, string>[] = [
  { value: 1, label: 'Оборачиваемость по WB' },
  { value: 2, label: 'WB + мои склады' },
];

export const INDICATORS: ISelectedTypesP<number, string>[] = [
  { value: 1, label: 'Маржа' },
  { value: 2, label: 'Выручка' },
  { value: 3, label: 'Оборачиваемость' },
];
export const ARTICLE_BARCODE_SEPARATORS: ISelectedTypesP<number, string>[] = [
  { value: 1, label: 'Артикул WB' },
  { value: 2, label: 'Баркоду WB' },
];

export const ABS_TABLE_DATA = {
  turnOver: {
    tr_th: ['', 'Артикулов', 'Товар в с/с закупки', 'Средняя оборачиваемость за период'],
    tr_td: [
      {
        tr: ['A', 17, 123, 123123],
      },
      {
        tr: ['B', 17, 123, 123123],
      },
      {
        tr: ['C', 17, 123, 123123],
      },
    ],
  },
  marginality: {
    tr_th: ['', 'Артикулов', 'Товар в с/с закупки', 'Общая маржинальность за период'],
    tr_td: [
      {
        tr: ['A', 17, 123, 123123],
      },
      {
        tr: ['B', 17, 123, 123123],
      },
      {
        tr: ['C', 17, 123, 123123],
      },
    ],
  },
  revenue: {
    tr_th: ['', 'Артикулов', 'Товар в с/с закупки', 'Общая выручка за период'],
    tr_td: [
      {
        tr: ['A', 17, 123, 123123],
      },
      {
        tr: ['B', 17, 123, 123123],
      },
      {
        tr: ['C', 17, 123, 123123],
      },
    ],
  },
};

export const ABS_ANALYST_BRIEF_COLUMN_DEFS: any[] = [
  {
    headerName: 'Категория товара',
    headerClass: 'header-centered',
    field: 'good_category',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },

  {
    headerName: 'Фото',
    headerClass: 'header-centered',
    cellRenderer: WBImgFormatter,
    pinned: 'left',
    field: 'thumb',
    width: 100,
    filter: 'agTextColumnFilter',
    tooltipField: 'thumb',
    enableCellTextSelection: false,
    tooltipComponentParams: {
      type: 'thumb',
      photoField: 'thumb',
    },
    tooltipComponent: CustomTooltipComponent,
  },
  {
    headerName: 'ABC',
    headerClass: 'header-centered',
    field: 'abs',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },

  {
    headerName: 'Наименование артикула',
    headerClass: 'header-centered',
    field: 'article_name',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Артикул ВБ ',
    headerClass: 'header-centered',
    field: 'article_wb',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Баркод ВБ',
    headerClass: 'header-centered',
    field: 'barcode_wb',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Денег в товаре c/с+фикс.',
    headerClass: 'header-centered',
    field: 'money_merchandise_with_fix',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Остатки TOTAL с фф',
    headerClass: 'header-centered',
    field: 'total_leftovers_ff',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Среднее заказов в день',
    headerClass: 'header-centered',
    field: 'average_orders_day',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Параметр АВС 1',
    headerClass: 'header-centered',
    field: 'param_1',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Параметр АВС 2',
    headerClass: 'header-centered',
    field: 'param_2',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Параметр АВС 3',
    headerClass: 'header-centered',
    field: 'param_3',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
];

export const ABS_ANALYST_FULL_COLUMN_DEFS: any[] = [
  {
    headerName: 'Категория товара',
    headerClass: 'header-centered',
    field: 'good_category',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Фото',
    headerClass: 'header-centered',
    cellRenderer: WBImgFormatter,
    pinned: 'left',
    field: 'thumb',
    width: 100,
    filter: 'agTextColumnFilter',
    tooltipField: 'thumb',
    enableCellTextSelection: false,
    tooltipComponentParams: {
      type: 'thumb',
      photoField: 'thumb',
    },
    tooltipComponent: CustomTooltipComponent,
  },
  {
    headerName: 'ABC',
    headerClass: 'header-centered',
    field: 'abs',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Наименование артикула',
    headerClass: 'header-centered',
    field: 'article_name',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Артикул ВБ ',
    headerClass: 'header-centered',
    field: 'article_wb',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Баркод ВБ',
    headerClass: 'header-centered',
    field: 'barcode_wb',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Размер ВБ',
    headerClass: 'header-centered',
    field: 'size_wb',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },

  {
    headerName: 'Артикул поставщика',
    headerClass: 'header-centered',
    field: 'supplier_article',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Бренд',
    headerClass: 'header-centered',
    field: 'brand',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Маржинальность, %',
    headerClass: 'header-centered',
    field: 'marginality',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Рентабельность товара по с/с, %',
    headerClass: 'header-centered',
    field: 'profitability_goods_percent',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Денег в товаре с/с+фикс.',
    headerClass: 'header-centered',
    field: 'money_in_good',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Оборачиваемость, дней',
    headerClass: 'header-centered',
    field: 'turnover_days',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Остатки TOTAL с фф',
    headerClass: 'header-centered',
    field: 'total_leftover',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Остатки FBO',
    headerClass: 'header-centered',
    field: 'leftovers_fbo',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Остатки FBS',
    headerClass: 'header-centered',
    field: 'leftovers_fbs',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Среднее заказов в день',
    headerClass: 'header-centered',
    field: 'average_orders_per_day',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Продаж с вычетом СПП, руб.',
    headerClass: 'header-centered',
    field: 'sales_net_spp',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: '% от выручки',
    headerClass: 'header-centered',
    field: 'percent_of_revenue',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Заказы (всего)',
    headerClass: 'header-centered',
    field: 'orders_total',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Продано',
    headerClass: 'header-centered',
    field: 'sold',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: '% выкупа ',
    headerClass: 'header-centered',
    field: 'percent_redemption',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Маржа, руб',
    headerClass: 'header-centered',
    field: 'margin_rubles',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: '% от маржи',
    headerClass: 'header-centered',
    field: 'margin_percent',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Маржа на шт, руб.',
    headerClass: 'header-centered',
    field: 'margin_per_items',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Расходы общие',
    headerClass: 'header-centered',
    field: 'general_expenses',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Себес-ть (закупка), руб.',
    headerClass: 'header-centered',
    field: 'self_cost_purchase',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Расходы фикс.+ налоги по проданным',
    headerClass: 'header-centered',
    field: 'expenses_per_taxes',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Логистика, руб',
    headerClass: 'header-centered',
    field: 'logistics_rub',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Логистика на шт, руб.',
    headerClass: 'header-centered',
    field: 'logistics_per_item_rub',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Комиссия ВБ (минус СПП, руб)',
    headerClass: 'header-centered',
    field: 'wb_commission_minus_spp_rub',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: '% комиссии ВБ за вычетом СПП',
    headerClass: 'header-centered',
    field: 'commission_percent_wb_without_spp',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Комиссия ВБ, руб',
    headerClass: 'header-centered',
    field: 'commission_wb_rub',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: '% комиссии ВБ',
    headerClass: 'header-centered',
    field: 'percent_commission_wb',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'СПП, руб',
    headerClass: 'header-centered',
    field: 'spp_rub',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: '% СПП ВБ',
    headerClass: 'header-centered',
    field: 'percent_spp_wb',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Реклама и маркетинг суммарно',
    headerClass: 'header-centered',
    field: 'advert_marketing_sum',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Реклама внутренняя',
    headerClass: 'header-centered',
    field: 'internal_advert',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Таргет и директ',
    headerClass: 'header-centered',
    field: 'target_direct',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Блогеры',
    headerClass: 'header-centered',
    field: 'bloggers',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Cамовыкупы',
    headerClass: 'header-centered',
    field: 'self_purchase',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Маркетинг',
    headerClass: 'header-centered',
    field: 'marketing',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'На брак',
    headerClass: 'header-centered',
    field: 'defects',
    width: 120,
    sortable: true,
    filter: 'agTextColumnFilter',
  },
];

export const ABS_ANALYST_BRIEF_MOCK = [
  {
    good_category: '1',
    thumb: 'httto',
    abc: 1,
    article_name: 'ASDASD',
    article_wb: 'ASDASD',
    barcode_wb: 'ASDASD',
    money_merchandise_with_fix: '1000',
    total_leftovers_ff: 'ASDasd',
    average_orders_day: 100,
    param_1: 99,
    param_2: 99,
    param_3: 99,
  },
];

export const ABS_ANALYST_FULL_MOCK = [
  {
    good_category: '1',
    thumb: 'httto',
    abc: 1,
    article_name: 'ASDASD',
    article_wb: 'ASDASD',
    barcode_wb: 'ASDASD',
    size_wb: 'ASDASD',
    supplier_article: 123123,
    brand: 'XXX',
    marginality: '123123',
    profitability_goods_percent: '123123',
    money_in_good: '123123',
    turnover_days: '4243',
    total_leftover: '123',
    leftovers_fbo: '003',
    leftovers_fbs: '883',
    average_orders_per_day: 123,
    sales_net_spp: 444,
    percent_of_revenue: 999,
    orders_total: 388,
    sold: 9992,
    percent_redemption: 22,
    margin_rubles: 44,
    margin_percent: 55,
    margin_per_items: 99,
    general_expenses: 123,
    self_cost_purchase: 888,
    expenses_per_taxes: 888,
    logistics_rub: 992,
    logistics_per_item_rub: 299,
    wb_commission_minus_spp_rub: 123,
    commission_percent_wb_without_spp: 444,
    commission_wb_rub: 999,
    percent_commission_wb: 999,
    spp_rub: 999,
    percent_spp_wb: 123,
    advert_marketing_sum: 123,
    internal_advert: 123,
    target_direct: 333,
    bloggers: 333,
    self_purchase: 333,
    marketing: 333,
    defects: 333,
  },
];
