import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../shared/services/api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.sass'],
  styles: [`
    .ball-clip-rotate_dop {
      background-color: #3f6ad8;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      margin: 2px;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      border: 2px solid #3f6ad8;
      border-bottom-color: transparent;
      height: 10px;
      width: 10px;
      background: transparent !important;
      display: inline-block;
      -webkit-animation: rotate 0.75s 0s linear infinite;
      animation: rotate 0.75s 0s linear infinite;
    }
  `]


})
export class ControlComponent implements OnInit {

  items_count: any;
  items: any;
  items_detail: any;
  price_income: any;
  range: any;

  loading_price: any = false;
  control_id: any = false;

  price_income_purchase: any;
  price_income_sku: any;

  page = 1;

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    private fb: FormBuilder,
  ) {

    this.route.params.subscribe(params => {
      this.control_id = params['id'];
      console.log(typeof (this.control_id));
      console.log(params['id']);
    });
  }

  ngOnInit() {
    if (typeof (this.control_id) !== 'undefined') {
      this.getIncomesDetail();
    } else {
      this.getIncomes();
    }
  }

  onChangeEvent(event: any, id: any) {
    this.loading_price = true;
    let body = {income_id: id, cost: event.target.value};
    this.api.userRegister('updateIncomes', localStorage.getItem('token'), body)
      .subscribe(async (data: any) => {
        console.log('DATAAAAA', data);
        setTimeout(() => {
          this.loading_price = false;
        }, 1000);
      }, (err) => {
        console.log(err);
      });
  }

  onChangePur(event: any, id: any, nmid: any) {
    //this.loading_price = true;
    let body = {cost: event.target.value, income_id: id, pur: id, nmid: nmid};
    this.api.userRegister('updateIncomesPrice', localStorage.getItem('token'), body)
      .subscribe(async (data: any) => {
        setTimeout(() => {
        }, 1000);
      }, (err) => {
        console.log(err);
      });
  }

  onChangeSKU(event: any, id: any, nmid: any) {
    //this.loading_price = true;
    let body = {cost: event.target.value, income_id: id, sku: id, nmid: nmid};
    this.api.userRegister('updateIncomesPrice', localStorage.getItem('token'), body)
      .subscribe(async (data: any) => {
        setTimeout(() => {
          //this.loading_price = false;
        }, 1000);
      }, (err) => {
        console.log(err);
      });
  }


  getIncomes() {
    //this.loading = true;
    let data_shop = localStorage.getItem('mpall_shop');
    let new_data_shop = JSON.parse(data_shop);
    let body = {shop_id: new_data_shop.shop};
    return new Promise((resolve, reject) => {
      this.api.userRegister('getIncomes', localStorage.getItem('token'), body)
        .subscribe(async (data: any) => {
          this.items = data.data;
          this.items_count = data.count;
          //this.loading = false;
          resolve(true);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  onPageChange(pageNum: number) {
    let data_shop = localStorage.getItem('mpall_shop');
    let new_data_shop = JSON.parse(data_shop);
    let body = {currentPage: pageNum, shop_id: new_data_shop.shop};
    return new Promise((resolve, reject) => {
      this.api.userRegister('getIncomes', localStorage.getItem('token'), body)
        .subscribe(async (data: any) => {
          console.log('data', data);
          this.items = data.data;
          this.items_count = data.count;
          //this.loading = false;
          resolve(true);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getIncomesDetail() {
    let data_shop = localStorage.getItem('mpall_shop');
    let new_data_shop = JSON.parse(data_shop);
    let body = {constrol_id: this.control_id, shop_id: new_data_shop.shop};
    return new Promise((resolve, reject) => {
      this.api.userRegister('getIncomesDetail', localStorage.getItem('token'), body)
        .subscribe(async (data: any) => {
          this.items_detail = data;
          //this.items_count = data.count;
          //this.loading = false;
          resolve(true);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }
}
