import { ColDef, ColGroupDef } from 'ag-grid-community';
import { CustomTooltipComponent } from '../../guides/products/components/prdcustomTooltip';
import { WBImgFormatter } from '../../../shared/utils/functions/wb-img-formatter';

export let ADVERT_MANAGEMENT_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    headerName: '',
    field: 'advertId',
    filter: 'agNumberColumnFilter',
    width: 150,
  },
  {
    headerName: '',
    field: 'photo_new',
    cellRenderer: WBImgFormatter,
    width: 100,
    filter: false,
    tooltipField: 'photo_new',
    tooltipComponentParams: {
      type: 'success',
      photoField: 'photo_new', // Field containing the photo information
    },
    tooltipComponent: CustomTooltipComponent,
  },
  {
    headerName: 'Артикул',
    field: 'article',
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    headerName: 'Название компании',
    field: 'name',
    filter: 'agTextColumnFilter',
    width: 350,
  },
  {
    headerName: 'Стратегия',
    field: 'strategy',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Бюджет',
    field: 'budget',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'CRC',
    field: 'crc',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'CTR',
    field: 'ctr',
    width: 150,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Статус',
    field: 'status_title',
    filter: 'agTextColumnFilter',
    width: 350,
  },
];
