import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Params} from '@angular/router';

@Component({
  template: `
    <div class="key__query__display"
         [style.color]="cellColor">
      <span>{{value}}</span>
    </div>
  `,
  styleUrls: ['../key-queries.component.scss'],
})
export class KeyQueriesDisplayCellComponent implements ICellRendererAngularComp {

  value: number | string;

  get cellColor(): string {
    if (this.value !== '-') {
      return this.value < 0 ? 'red' : (this.value > 0 ? ' green' : '');
    }
    return '';
  }

  agInit(params: Params): void {
    if (params.value === 0) {
      this.value = '-'
    } else {
      this.value = (params.value) ? Number(params.value) : '-';
    }
  }

  refresh() {
    return false;
  }
}
