import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefectGoodsComponent } from './defect-goods/defect-goods.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { DefectGoodsCellActionComponent } from './defect-goods/components/defect-goods-cell-action.component';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { RippleModule } from 'primeng/ripple';
import { ModalModule } from '../../shared/common-components/modal/modal.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { ProductsComponent } from './products/products.component';
import { ProductIdComponent } from './products/components/products-id/product-id.component';
import { ControlComponent } from './control/control.component';
import { MystocksComponent } from './mystocks/mystocks.component';
import { MybuyoutsComponent } from './mybuyouts/mybuyouts.component';
import { FinPurchaseComponent } from './finpurchase/finpurchase.component';
import { FinPurchaseBatchComponent } from './finpurchase/fin-purchase-batch/fin-purchase-batch.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProductIdChartsComponent } from './products/components/products-id/product-id-orders-chart/product-id-charts.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbDatepickerModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TariffwbComponent } from './tariffwb/tariffwb.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BtnCellRendererComponentBuyout } from './mybuyouts/components/btn-cell-renderer.component-buyout';

const routes: Routes = [
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'products/:id',
    component: ProductIdComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'defect-goods',
    component: DefectGoodsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'control',
    component: ControlComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'control/:id',
    component: ControlComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'stock',
    component: MystocksComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'buyouts',
    component: MybuyoutsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'purchase',
    component: FinPurchaseComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'purchase/batch',
    component: FinPurchaseBatchComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'tariffwb',
    component: TariffwbComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    DefectGoodsComponent,
    ProductsComponent,
    ProductIdComponent,
    ProductIdChartsComponent,
    ControlComponent,
    MystocksComponent,
    MybuyoutsComponent,
    FinPurchaseComponent,
    FinPurchaseBatchComponent,
    DefectGoodsCellActionComponent,
    BtnCellRendererComponentBuyout,
    TariffwbComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HttpClientModule,
    AgGridModule,
    ProgressSpinnerModule,
    ToastModule,
    ButtonModule,
    FileUploadModule,
    CalendarModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    MultiSelectModule,
    RippleModule,
    ModalModule,
    NgxDaterangepickerMd,
    FormsModule,
    FontAwesomeModule,
    TooltipModule,
    NgApexchartsModule,
    SlickCarouselModule,
    NgbPaginationModule,
    SelectButtonModule,
    NgBootstrapFormValidationModule,
    NgbDatepickerModule,
    TooltipModule,
    TooltipModule,
    ConfirmDialogModule,
  ],
  exports: [DefectGoodsComponent, ProductIdChartsComponent],
})
export class GuidesModule {}
