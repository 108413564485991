import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Params } from '@angular/router';

@Component({
  selector: 'app-btn-cell-renderer',
  template: `
    <button *ngIf="editIsHidden" (click)="editPurchase()" class="icon-button" style="margin-right: 25px;">
      <i class="lnr-pencil"></i>
    </button>
    <button (click)="deletePurchase()" class="icon-button">
      <i class="lnr-trash"></i>
    </button>
  `,
  styleUrls: ['../../finoperations.component.sass'],
})
export class BtnCellRendererFinoperationComponent implements ICellRendererAngularComp {
  editIsHidden = false;

  private componentParent: any;

  private params: Params;

  agInit(params: Params): void {
    this.params = params;
    this.editIsHidden = !['Перемещение', 'Погашение кредита/займа'].includes(this.params.data.type_operation_title);
    if (this.params.data.group_id > 0 && this.params.data.type_operation === 2) {
      this.editIsHidden = false;
    }
    this.componentParent = this.params.context.componentParent;
  }

  editPurchase() {
    this.componentParent.updateFinOperationEmitter(this.params.data);
  }

  deletePurchase() {
    this.componentParent.deleteFinOperationEmitter(this.params.data);
  }

  refresh() {
    return false;
  }
}
