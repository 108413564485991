import * as moment from 'moment/moment';

export class DatePickerCommon {
  disableData = false; // для отключения даты если идет загрузка данных
  selectedData = { startDate: moment(), endDate: moment() };
  selectedDataWithRange = { startDate: moment(), endDate: moment() };
  minDate: any = moment().add(-91, 'days');
  maxDate: any = moment();
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  localParamsRU: any = {
    applyLabel: 'применить',
    direction: 'ltr',
    format: 'DD.MM.YYYY',
    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    customRangeLabel: 'Свой диапазон',
    firstDay: 1,
    monthNames: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
  };

  isInvalidDate = (m: moment.Moment) => this.invalidDates.some((d) => d.isSame(m, 'day'));
}
