import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { SupplierBtnCellRendererComponent } from './supplier-manager/components/supplier-btn-cell-renderer.component';
import { SupplierManagerComponent } from './supplier-manager/supplier-manager.component';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../../shared/adapters/date.adapter';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { ModalModule } from '../../shared/common-components/modal/modal.module';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { RippleModule } from 'primeng/ripple';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { UiComponentsModule } from '../../shared/ui/components/ui-components.module';

const routes: Routes = [
  {
    path: 'suppliers-manager',
    component: SupplierManagerComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [SupplierManagerComponent, SupplierBtnCellRendererComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    AgGridModule,
    InputSwitchModule,
    CheckboxModule,
    ProgressSpinnerModule,
    NgbModule,
    PerfectScrollbarModule,
    NgbModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ToastModule,
    ModalModule,
    DropdownModule,
    FileUploadModule,
    RippleModule,
    NgxDaterangepickerMd,
    UiComponentsModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: { suppressScrollX: true },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'ru-RU',
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU',
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class AnalyticModule {}
