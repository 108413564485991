import { Component, inject, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MpSurfApiService } from '../shared/services/mpsurf-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCALE_TEXT_FOR_FILTERS } from './guides/products/products.mock';
import { PopUpStatus } from '../shared/interfaces/common.type';
import { LOCALE_RU_FILTERS } from './finances/finoperations/finoperations.mock';
import { GridApi } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { Router } from '@angular/router';

@Component({
  selector: 'app-abstract-menu-tab',
  template: '',
  providers: [MessageService, ConfirmationService],
})
export abstract class AbstractMenuTabComponent implements OnInit {
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly LOCALE_RU_FILTERS = LOCALE_RU_FILTERS;
  isLoading = false;
  isEditModal = false;

  protected readonly _router = inject(Router);

  protected readonly _mpSurfService = inject(MpSurfApiService);
  protected readonly _messageService = inject(MessageService);
  protected readonly _confirmationService = inject(ConfirmationService);
  protected readonly _modalService = inject(NgbModal);
  protected readonly SHOP_ID;

  //AG GRID
  protected gridApi: GridApi;
  protected gridOptions: GridOptions = {};
  protected readonly TOKEN: string;

  protected constructor() {
    this.SHOP_ID = JSON.parse(localStorage.getItem('mpall_shop'))?.shop;
    this.TOKEN = localStorage.getItem('token');
  }

  ngOnInit() {}

  closeModal() {
    this._modalService.dismissAll();
  }

  protected showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.isLoading = false;
    this._messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}