import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Params} from '@angular/router';

@Component({
  selector: 'app-cell-renderer-with-scroll',
  template: `<div class="scrollable-cell-content" [innerHTML]="params.value"></div>`,
  styles: [
      `
        .scrollable-cell-content {
          max-height: 50px; /* Set the desired height for the cell content */
          overflow-x: auto; /* Enable horizontal scrolling */
          white-space: nowrap; /* Prevent text from wrapping within the div */
        }
      `
  ]
})
export class SupplierBtnCellRendererComponent implements ICellRendererAngularComp {
  params: Params;
  componentParent: any;
  agInit(params: Params): void {
    this.params = params;
    this.componentParent = this.params.context.componentParent;
  }
  refresh() {
    return false;
  }
}
