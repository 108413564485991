<p-progressSpinner *ngIf="isLoading"
                   [style]="{position: 'absolute', top: '40%', left: '50%'}">
</p-progressSpinner>
<p-toast></p-toast>
<div [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="col-sm-12 col-md-12">
    <div class="mb-3 card text-white card-body bg-warning">
      <h5 class="card-title">Идет Beta тестирование</h5>
    </div>
    <div class="app-page-title mb-0">
      <div class="page-title-wrapper d-flex justify-content-between">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <i class="pe-7s-news-paper icon-gradient bg-mean-fruit"></i>
          </div>
          <div>
            Сверка WB
          </div>
        </div>
        <div class="d-flex flex-wrap gap-2">
          <p-fileUpload #uploadedFiles
                        (onError)="onBasicUploadError($event,uploadedFiles)"
                        (onSend)="onSendData()"
                        (onUpload)="onBasicUploadAuto($event,uploadedFiles)"
                        [auto]="true"
                        [maxFileSize]="10000000"
                        [style]="{ height: '37px', fontSize: '12px'}" accept=".xls, .xlsx, .csv"
                        chooseLabel="Загрузить еженедельные отчеты"
                        class="mr-4 inline-block"
                        label="Import"
                        mode="basic"
                        name="file"
                        url="{{URL_NEW}}collation-wb/uploaded?token={{TOKEN}}&shop_id={{SHOP_ID}}"
          ></p-fileUpload>
        </div>
      </div>
    </div>
    <br/>
  </div>
  <div class="main-card mb-3 card" style="height: calc(82vh - 153px)">
    <div style="width: 100%;height: 80vh;">
      <ag-grid-angular #agGrid
                       (gridReady)="onGridReady($event)"
                       [columnDefs]="colDefs"
                       [defaultColDef]="defaultColDef"
                       [enableRangeSelection]="true"
                       [gridOptions]="gridOptions"
                       [localeText]="localeTextForFilters"
                       [pagination]="true"
                       [rowData]="rowData"
                       [tooltipHideDelay]="tooltipHideDelay"
                       [tooltipShowDelay]="tooltipShowDelay"
                       class="ag-theme-alpine"
                       headerHeight="28"
                       style="width: 100%; height: 100%;">
      </ag-grid-angular>
    </div>
  </div>

  <!--  <ng-template #bloggersAdvertCompanyModal let-c="close" let-d="dismiss">-->
  <!--    <p-toast></p-toast>-->
  <!--    <div class="modal-header">-->
  <!--      <h3 class="modal-title">{{isEdit ? 'Изменение' : 'Создание'}} рекламной компании блогера</h3>-->
  <!--      <p (click)="d('Cross click')" class="m-0">-->
  <!--        <i aria-hidden="true" class="fa fa-times" style="font-size: 24px;cursor: pointer;"></i>-->
  <!--      </p>-->
  <!--    </div>-->
  <!--    <div class="modal-body">-->
  <!--      <form [formGroup]="bloggersForm">-->
  <!--        <div class="card__row">-->
  <!--          <h5>Артикул ВБ</h5>-->
  <!--          <input [style]="{width: '434px'}"-->
  <!--                 formControlName="nmid"-->
  <!--                 pInputText-->
  <!--                 type="text"/>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Артикул поставщика</h5>-->
  <!--          <input [style]="{width: '434px'}"-->
  <!--                 formControlName="nmid_supplier"-->
  <!--                 pInputText-->
  <!--                 type="text"/>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Имя блогера</h5>-->
  <!--          <div>-->
  <!--            <p-dropdown [options]="bloggersList"-->
  <!--                        [style]="{width: '381px'}"-->
  <!--                        class="me-1"-->
  <!--                        formControlName="blogger_id"-->
  <!--                        optionLabel="blogger_name"-->
  <!--                        optionValue="blogger_id"-->
  <!--            ></p-dropdown>-->
  <!--            <button (click)="openModal(false, bloggerModal, false)"-->
  <!--                    [pTooltip]="'Добавить контрагента'"-->
  <!--                    class="p-button-success mr-2 button-excel button-add-supplier" icon="pi pi-plus"-->
  <!--                    pButton-->
  <!--                    pRipple-->
  <!--                    tooltipPosition="bottom"-->
  <!--                    type="button">-->
  <!--            </button>-->
  <!--          </div>-->

  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Вид рекламы</h5>-->
  <!--          <div>-->
  <!--            <p-multiSelect [options]="BloggersAdverts"-->
  <!--                           [style]="{width: '381px'}"-->
  <!--                           class="me-1"-->
  <!--                           formControlName="adv_type">-->
  <!--            </p-multiSelect>-->
  <!--            <button (click)="openModal(false, advertModal, false)"-->
  <!--                    [pTooltip]="'Добавить тип рекламы'"-->
  <!--                    class="p-button-success mr-2 button-excel button-add-supplier" icon="pi pi-plus"-->
  <!--                    pButton-->
  <!--                    pRipple-->
  <!--                    tooltipPosition="bottom"-->
  <!--                    type="button">-->
  <!--            </button>-->
  <!--          </div>-->

  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Дата и время старта рекламы</h5>-->
  <!--          <p-calendar-->
  <!--            [style]="{width: '434px'}"-->
  <!--            dateFormat="dd.mm.yy"-->
  <!--            formControlName="adv_start">-->
  <!--          </p-calendar>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Дата и время окончания рекламы</h5>-->
  <!--          <p-calendar-->
  <!--            [style]="{width: '434px'}"-->
  <!--            dateFormat="dd.mm.yy"-->
  <!--            formControlName="adv_finish">-->
  <!--          </p-calendar>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Дата отзыва</h5>-->
  <!--          <p-calendar-->
  <!--            [style]="{width: '434px'}"-->
  <!--            dateFormat="dd.mm.yy"-->
  <!--            formControlName="feedback_date">-->
  <!--          </p-calendar>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Цена товара</h5>-->
  <!--          <p-inputNumber-->
  <!--            [style]="{width: '434px'}"-->
  <!--            [useGrouping]="false"-->
  <!--            formControlName="price_release"-->
  <!--            inputId="withoutgrouping"-->
  <!--            mode="decimal">-->
  <!--          </p-inputNumber>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Цена рекламы </h5>-->
  <!--          <p-inputNumber-->
  <!--            [style]="{width: '434px'}"-->
  <!--            [useGrouping]="false"-->
  <!--            formControlName="price_adv_product"-->
  <!--            inputId="withoutgrouping"-->
  <!--            mode="decimal">-->
  <!--          </p-inputNumber>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Подписчиков всего</h5>-->
  <!--          <p-inputNumber-->
  <!--            [style]="{width: '434px'}"-->
  <!--            [useGrouping]="false"-->
  <!--            formControlName="all_subs"-->
  <!--            inputId="withoutgrouping"-->
  <!--            mode="decimal">-->
  <!--          </p-inputNumber>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Подписчиков живых душ</h5>-->
  <!--          <p-inputNumber-->
  <!--            [style]="{width: '434px'}"-->
  <!--            [useGrouping]="false"-->
  <!--            formControlName="people_subs"-->
  <!--            inputId="withoutgrouping"-->
  <!--            mode="decimal">-->
  <!--          </p-inputNumber>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Охваты факт</h5>-->
  <!--          <p-inputNumber-->
  <!--            [style]="{width: '434px'}"-->
  <!--            [useGrouping]="false"-->
  <!--            formControlName="coverage_fact"-->
  <!--            inputId="withoutgrouping"-->
  <!--            mode="decimal">-->
  <!--          </p-inputNumber>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Клики факт</h5>-->
  <!--          <p-inputNumber-->
  <!--            [style]="{width: '434px'}"-->
  <!--            [useGrouping]="false"-->
  <!--            formControlName="click_fact"-->
  <!--            inputId="withoutgrouping"-->
  <!--            mode="decimal">-->
  <!--          </p-inputNumber>-->
  <!--        </div>-->
  <!--        <div class="card__row">-->
  <!--          <h5>Заказы за время рекламы</h5>-->
  <!--          <p-inputNumber-->
  <!--            [style]="{width: '434px'}"-->
  <!--            [useGrouping]="false"-->
  <!--            formControlName="order_adv_time"-->
  <!--            inputId="withoutgrouping"-->
  <!--            mode="decimal">-->
  <!--          </p-inputNumber>-->
  <!--        </div>-->

  <!--        <div class="card__row">-->
  <!--          <h5>Комментарий</h5>-->
  <!--          <div>-->
  <!--            <textarea [style]="{width: '434px'}"-->
  <!--                      cols="30"-->
  <!--                      formControlName="feedback_wb"-->
  <!--                      pInputTextarea rows="5">-->
  <!--            </textarea>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </form>-->
  <!--    </div>-->
  <!--    <div class="modal-footer">-->
  <!--      <p-button (click)="d('Cross click')" label="Отмена"-->
  <!--                styleClass="p-button-outlined p-button-secondary">-->
  <!--      </p-button>-->
  <!--      <button (click)="createUpdateBloggerAdvertCompany(null)"-->
  <!--              *ngIf="!isEdit; else editSaveSubmit"-->
  <!--              [disabled]="bloggersForm.invalid"-->
  <!--              class="p-button-raised p-button-success"-->
  <!--              label="Создать"-->
  <!--              pButton-->
  <!--              type="button">-->
  <!--      </button>-->
  <!--      <ng-template #editSaveSubmit>-->
  <!--        <button-->
  <!--          (click)="createUpdateBloggerAdvertCompany(bloggerModalId)"-->
  <!--          [disabled]="bloggersForm.invalid"-->
  <!--          class="p-button-raised p-button-success"-->
  <!--          label="Изменить"-->
  <!--          pButton-->
  <!--          type="button">-->
  <!--        </button>-->
  <!--      </ng-template>-->
  <!--    </div>-->
  <!--  </ng-template>-->
</div>
