<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon pe-7s-graph1"></i>
      </div>
      <div>
        Здесь показывается сравнение по артикулам
      </div>
    </div>
    <div class="page-title-actions">
      <button (click)="open()" class="btn-shadow d-inline-flex align-items-center btn btn-success" type="button">
        Добавить новое сравнение
      </button>
    </div>
  </div>
</div>
<div *ngIf="visible_list_edit" class="main-card mb-3 card">
  <div class="card-body">
    <h5 class="card-title">Редактирование списка</h5>
    <form>
      <div class="form-row">
        <div class="col-md-12">
          <div class="position-relative form-group">
            <label for="name_group1">Название группы</label>
            <input [(ngModel)]="name_group_edit" class="form-control" id="name_group1" name="name_group_edit" placeholder=""
                   type="text">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12">
          <div class="position-relative form-group">
            <label for="list_sku_edit">Список sku WB(можно ссылкой)</label>
            <textarea [(ngModel)]="list_sku_edit" autosize class="form-control  form-control-lg"
                      id="list_sku_edit" name="list_sku_edit" placeholder=""></textarea>
          </div>
        </div>
      </div>
      <div class="card-body">
        <button (click)="editold(edit_id)" class="mb-2 mr-2 btn btn-success">Изменить</button>
        <button (click)="closededit()" class="mb-2 mr-2 btn btn-info">Закрыть</button>
      </div>

    </form>
  </div>
</div>
<div *ngIf="visible_list" class="main-card mb-3 card">
  <div class="card-body">
    <h5 class="card-title">Добавление нового списка</h5>
    <form>
      <div class="form-row">
        <div class="col-md-12">
          <div class="position-relative form-group">
            <label for="name_group1">Название группы</label>
            <input [(ngModel)]="name_group" class="form-control" id="name_group1" name="name_group" placeholder=""
                   type="text">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12">
          <div class="position-relative form-group">
            <label for="list_sku">Список sku WB(можно ссылкой)</label>
            <textarea [(ngModel)]="list_sku" autosize class="form-control  form-control-lg" id="list_sku"
                      name="list_sku"
                      placeholder=""></textarea>
          </div>
        </div>
      </div>
      <div class="card-body">
        <button (click)="addnew()" class="mb-2 mr-2 btn btn-success">Добавить</button>
        <button (click)="closednew()" class="mb-2 mr-2 btn btn-info">Закрыть</button>
      </div>
    </form>
  </div>
</div>


<div class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
      Список сравнений
      <button (click)="visible_list_cmp=!visible_list_cmp" class="btn-shadow btn btn-success">Скрыть/показать</button>
    </div>
  </div>
  <div *ngIf="visible_list_cmp" class="table-responsive">
    <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
      <tbody>
      <tr>
        <th class="text-center">#</th>
        <th class="text-center">Дата создания</th>
        <th class="text-center">Дата обновления</th>
        <th class="text-center">Название</th>
        <th class="text-center">Кооличество SKU</th>
        <th class="text-center" colspan="3"></th>
      </tr>
      <tr *ngFor="let data of list_cmp; let i = index">
        <td class="text-center text-muted" style="width: 80px;">{{i + 1}}</td>
        <td class="text-center" style="width: 80px;">
          {{data.date_create}}
        </td>
        <td class="text-center">{{data.date_update}}</td>
        <td class="text-center">{{data.group_name}}</td>
        <td class="text-center">{{data.SKU}}</td>
        <td class="text-center">
          <div class="btn-group-sm btn-group" role="group">
            <button (click)="view(data.id)" class="btn-shadow btn btn-success">Показать</button>
          </div>
        </td>
        <td class="text-center">
          <div class="btn-group-sm btn-group" role="group">
            <button (click)="editCompare(data.id)" class="btn-shadow btn btn-warning">Изменить</button>
          </div>
        </td>
        <td class="text-center">
          <div class="btn-group-sm btn-group" role="group">
            <button (click)="deleteCompare(data.id)" class="btn-shadow btn btn-danger">Удалить</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<style>
  thead {
    position: sticky;
    top: 100px;
    background-color: #edecec;

  }
</style>
<div *ngIf="wait_load" class="main-card mb-3 card"><h2>Ожидайте загрузки данных. Обычно это занимает от 15 до 30
  минут</h2></div>
<div *ngIf="!wait_load">
  <div *ngIf="visible_compair_card" class="main-card mb-3 card">
    <div>
      <table class="align-middle mb-0 table table-borderless table-striped table-hover">
        <thead>
        <tr>
          <th class="text-center">#</th>
          <th *ngFor="let item_op of list_photo?.sku | keyvalue: orderOriginal" class="text-center">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-outer mr-3">
                  <div *ngFor="let ph of item_op.value | keyvalue" class="widget-content-left">
                    <img alt="" src="https://img1.wbstatic.net/tm/new/{{ph.value}}0000/{{ph.key}}-1.jpg" width="80"/>
                  </div>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr>
          <th class="text-left text-muted">&nbsp;&nbsp;&nbsp;{{list_photo?.name}}</th>
          <th *ngFor="let item_op of list_photo?.sku | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item_op.value | keyvalue">{{ph.key}}</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td [attr.colspan]="qty_sku"><b>Продажи</b></td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;За 30 дней</td>
          <td *ngFor="let item of list_sales_days30?.day30 | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;За 14 дней</td>
          <td *ngFor="let item of list_sales_days30?.day14 | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;За 7 дней</td>
          <td *ngFor="let item of list_sales_days30?.day7 | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>

        <tr>
          <td [attr.colspan]="qty_sku">

            <div class="card-body">
              <apx-chart
                [chart]="chartOptions?.chart"
                [series]="chartOptions?.series"
                [title]="chartOptions?.title"
                [xaxis]="chartOptions?.xaxis"
              ></apx-chart>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Рейтинг</td>
          <td *ngFor="let item of list_user?.rating | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Отзывы</td>
          <td *ngFor="let item of list_user?.feedbacks | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>

        <tr>
          <td [attr.colspan]="qty_sku"><b>Цены</b></td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Базовая цена</td>
          <td *ngFor="let item of list_price?.priceU | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Скидка(селлер+СПП)</td>
          <td *ngFor="let item of list_price?.sale | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}%</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Цена продажи</td>
          <td *ngFor="let item of list_price?.salePriceU | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Цена селлера</td>
          <td *ngFor="let item of list_price?.ext_basicPriceU | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Скидка селлера</td>
          <td *ngFor="let item of list_price?.ext_basicSale | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}%</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Цена продажи с СПП</td>
          <td *ngFor="let item of list_price?.ext_clientPriceU | keyvalue: orderOriginal"
              class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td [attr.colspan]="qty_sku"><b>Остатки</b></td>
        </tr>
        <tr>
          <td [attr.colspan]="qty_sku" class="text-left text-muted">&nbsp;&nbsp;&nbsp;<b>Склад [размер]</b></td>
        </tr>
        <tr *ngFor="let item of list_stock | keyvalue: orderOriginal">
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;&nbsp;{{item.key}} </td>
          <td *ngFor="let ph of item.value | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let nn of ph.value | keyvalue">{{nn.value}}</div>
          </td>
        </tr>
        <tr>
          <td [attr.colspan]="qty_sku"><b>Основное</b></td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Название</td>
          <td *ngFor="let item of list_main?.imt_name | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Категория</td>
          <td *ngFor="let item of list_main?.subj_root_name | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Предмет</td>
          <td *ngFor="let item of list_main?.subj_name | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Описание</td>
          <td *ngFor="let item of list_main?.description | keyvalue: orderOriginal" class="text-center text-muted"
              style="vertical-align: top;">
            <div *ngFor="let ph of item.value | keyvalue">
              Длина: {{ph.value.length}}<br>
              <button (click)="hideme[item.key] = !hideme[item.key]" class="btn-shadow btn btn-success">
                Показать/Скрыть
              </button>
              <div [hidden]="!hideme[item.key]">{{ph.value}}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Цвет(а)</td>
          <td *ngFor="let item of list_main?.nm_colors_names | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Состав</td>
          <td *ngFor="let item of list_main?.compositions | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Комплектация</td>
          <td *ngFor="let item of list_main?.contents | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Бренд</td>
          <td *ngFor="let item of list_main?.brand_name | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Кол-во фото</td>
          <td *ngFor="let item of list_main?.media_photo | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;Видео</td>
          <td *ngFor="let item of list_main?.media_video | keyvalue: orderOriginal" class="text-center text-muted">
            <div *ngFor="let ph of item.value | keyvalue">{{ph.value}}</div>
          </td>
        </tr>
        <tr>
          <td [attr.colspan]="qty_sku"><b>Характеристики</b></td>
        </tr>
        <tr *ngFor="let item of list_options;let i = index ">
          <td class="text-left text-muted">&nbsp;&nbsp;&nbsp;{{item?.name}}</td>
          <td *ngFor="let item_op of item?.options | keyvalue: orderOriginal"
              class="text-center text-muted">{{item_op?.value}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
