import {Component, OnInit} from '@angular/core';
import {AbstractAdvertManagementCardComponent} from '../abstract-advert-management-card.component';
import {ApiService} from '../../../../../../shared/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {SharedDataService} from '../../../../../../shared/services/shared-data.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-advert-management-statistic-positive',
  templateUrl: './advert-management-statistic-positive.component.html',
  styleUrls: ['./advert-management-statistic-positive.component.scss']
})
export class AdvertManagementStatisticPositiveComponent extends AbstractAdvertManagementCardComponent implements OnInit {
  constructor(api: ApiService, route: ActivatedRoute, sharedData: SharedDataService, private httpClient: HttpClient) {
    super(api, route, sharedData);
  }
  ngOnInit() {
    this.loadStatisticData();
  }
}

