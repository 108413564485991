import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { DatePickerCommon } from '../../../../shared/common-variables/date-picker-common';
import { FORTH_RANGE_TYPE } from '../../../../shared/common-variables/time-ranges-date-picker';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { PopUpMessages } from '../../../../shared/mocks/pop-up-messages.mock';
import { AbstractMenuTabComponent } from '../../../abstract-menu-tab.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-finpnl',
  templateUrl: './finpnl.component.html',
  styleUrls: ['./finpnl.component.sass'],
  providers: [MessageService, ConfirmationService],
})
export class FinpnlComponent extends AbstractMenuTabComponent implements OnInit {
  DateCommon: DatePickerCommon;
  value3: any = 3;
  selectedNodes: TreeNode[];
  cols: any;
  frozenCols: any[];
  justifyOptions: any[];
  ranges: any = FORTH_RANGE_TYPE;
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  selected: any = {
    startDate: moment().subtract(0, 'year').startOf('year'),
    endDate: moment().subtract(0, 'year').endOf('year'),
  };
  selectedAcc: any;
  selectedPartners: any;
  token: any;
  shop_id: any;
  barcodesList: any[] = [];
  selectedNm: any;
  selectedCategory: string;
  selectedSubject: string;
  categoriesList: string[] = [];
  subjectsList: string[] = [];
  isLoading = false;

  constructor() {
    super();
    this.DateCommon = new DatePickerCommon();
  }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.shop_id = JSON.parse(localStorage.getItem('mpall_shop')).shop;
    this.justifyOptions = [
      { name: 'По неделям', value: 2 },
      { name: 'По месяцам', value: 3 },
      /*, {name: 'По кварталам', value: 4}*/
    ];
    this.frozenCols = [{ field: 'name', header: 'Статья' }];
    this.loadAllParameters();
  }

  newValue() {
    this.loadPNLList();
  }

  onClearParameters(parameter: string) {
    switch (parameter) {
      case 'article':
        this.selectedNm = undefined;
        break;
      case 'category':
        this.selectedCategory = undefined;
        break;
      case 'subject':
        this.selectedSubject = undefined;
        break;
      default:
        this.selectedParameters();
    }
    this.selectedParameters();
  }

  selectedParameters() {
    let body = {
      barcode: this.selectedNm ?? undefined,
      category: this.selectedCategory ?? undefined,
      subject: this.selectedSubject ?? undefined,
    };
    if (!body.barcode && !body.category && !body.subject) {
      body = null;
    }
    this.loadPNLList(body);
  }

  changeType(obj: any) {
    const body = {
      barcode: this.selectedNm ?? undefined,
      category: this.selectedCategory ?? undefined,
      subject: this.selectedSubject ?? undefined,
    };
    if (+obj.value === 2) {
      this.loadPNLList(body);
    }
    if (+obj.value === 3) {
      this.loadPNLList(body);
    }
    if (+obj.value === 4) {
      this.loadPNLList(body);
    }
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.cols?.data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Fin-PNL');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  selectAcc(obj: any) {
    const partUID = typeof this.selectedPartners?.UID !== 'undefined' ? this.selectedPartners : null;
    let body = {
      type_group: this.value3,
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      param_acc: obj.value,
      param_part: partUID,
    };
    this._mpSurfService
      .load({ body }, 'fin-pnl')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.cols = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  selectPart(obj: any) {
    const accUID = typeof this.selectedAcc?.UID !== 'undefined' ? this.selectedPartners : null;
    const body = {
      type_group: this.value3,
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      param_acc: accUID,
      param_part: obj.value,
    };

    this._mpSurfService
      .load(body, 'fin-pnl')
      .pipe(untilDestroyed(this))
      .subscribe(
        (response) => {
          this.cols = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private loadPNLList(body = null) {
    const params = {
      shop_id: this.shop_id,
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      type_group: this.value3,
      params: body ?? undefined,
    };
    // this.api
    //   .userRegisterFin('getPnlList', localStorage.getItem('token'), body)
    this._mpSurfService
      .load(params, 'fin-pnl')
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: any) => {
          this.cols = data;
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        },
      );
  }

  private loadAllParameters() {
    this.isLoading = true;
    forkJoin([
      this.getParametersList('fin-dict/fin-nm'),
      this.getParametersList('fin-dict/fin-category'),
      this.getParametersList('fin-dict/fin-subject'),
    ])
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(([responseBarcode, responseCategory, responseSubject]) => {
        this.barcodesList = responseBarcode.data;
        this.categoriesList = responseCategory.data;
        this.subjectsList = responseSubject.data;
      });
  }

  private getParametersList(URL: string): Observable<any> {
    const params = {
      days: 1,
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      shop_id: this.shop_id,
    };
    // return this.api.userRegisterFin(parameterActionName, localStorage.getItem('token'), body);
    return this._mpSurfService.load(params, URL);
  }
}
