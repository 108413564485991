<button (click)="addFinOperation()"
        *ngIf="displayBtn"
        [pTooltip]="(operationStatus && operationStatus === 'agent')
        ? 'Добавить контрагента'
        : 'Добавить счет'" class="p-button-success mr-2 button-excel button-add-supplier" icon="pi pi-plus"
        pButton
        pRipple
        style="width: 30px; height: 30px; margin-top: 5px; position: absolute; right: 50%"
        tooltipPosition="bottom"
        type="button">
</button>


