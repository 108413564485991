import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Params} from '@angular/router';

@Component({
  selector: 'app-btn-fin-accounts-cell-renderer',
  template: `
    <button *ngIf="visibleValue"
            class="icon-button visible-icon"
            style="border: none;
              background: transparent;
              margin-right: 15px;">
      <i class="fa fa-eye-slash"></i>
    </button>
    <button label="" (click)="editAccount()" class="icon-button"
            style="border: none; background: transparent; margin-right: 15px;">
      <i class="lnr-pencil"></i>
    </button>
    <button label="" (click)="deleteAccount()" class="icon-button" style="border: none; background: transparent;">
      <i class="lnr-trash"></i>
    </button>
  `,
  styleUrls: ['../finaccounts.component.sass']
})
export class BtnFinaccountsCellRendererComponent implements ICellRendererAngularComp {
  componentParent: any;
  visibleValue = false;
  private params: Params;

  agInit(params: Params): void {
    this.params = params;
    this.visibleValue = +params.data.is_visible === 0;
    this.componentParent = this.params.context.componentParent;
  }

  editAccount() {
    this.componentParent.openUpdateAccountModal(this.params.data);
  }

  deleteAccount() {
    this.componentParent.deleteAccount(this.params.data);
  }

  refresh() {
    return false;
  }
}
