import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { ICurrentTariff, ITariffPercents, ITariffPricesItem, TariffPlane } from './tariff.interface';
import { CommonType2 } from '../../../shared/interfaces/common.interface';
import { AVAILABLE_TARIFF_TABS, MENU_HEADERS, TARIFF_PERCENTS, TARIFF_PRICES } from './tariff.mock';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.components.scss'],
  providers: [MessageService],
})
export class TariffComponent implements OnInit {
  isLoading = false;

  availableTariffTabs: string[] = AVAILABLE_TARIFF_TABS;

  headerTabs: string[] = MENU_HEADERS;

  tariff: ICurrentTariff;

  readonly tariffPercents: ITariffPercents[] = TARIFF_PERCENTS;

  selectedTariff: ITariffPercents = null;

  currentPrices: ITariffPricesItem = null;

  constructor(
    private api: ApiService,
    private messageService: MessageService,
    private router: Router,
  ) {
    this.selectedTariff = TARIFF_PERCENTS[1];
    this.tariffPercentChanged('three_month_selector');
  }

  ngOnInit(): void {
    this.getTariff();
  }

  createBill(id: number): void {
    this.api
      .userRegister('createBill', localStorage.getItem('token'), id)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (response: CommonType2) => {
          response.is_error === 0
            ? this.router.navigate(['/profile/bill'], { queryParams: { id: response.id } })
            : this.showPopUpMessage('error', 'Невозможно перейти к оплате. Повторите попытку', '');
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
        },
      );
  }

  tariffPercentChanged(selector: string) {
    this.currentPrices = TARIFF_PRICES[selector];
  }

  getMonthsPrice(plan: TariffPlane): number {
    return this.currentPrices ? this.currentPrices[plan] : 0;
  }

  private getTariff(): void {
    this.isLoading = true;
    this.api
      .userRegister('getTariff', localStorage.getItem('token'), '')
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(
        (response: ICurrentTariff) => {
          this.tariff = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
