import {BloggersCellActionComponent} from "./components/bloggers-cell-action.component";

export const BLOGGERS_COLUMNS_DEFS = [
    {
        headerName: 'Идентификатор записи таблицы',
        field: 'id',
        width: 150,
        filter: 'agNumberColumnFilter'
    },
    {
        headerName: 'Идентификатор юр. лица',
        field: 'user_shop_id',
        width: 150,
        filter: 'agNumberColumnFilter'
    },
    {
        headerName: 'Идентификатор блогера таблица',
        field: 'blogger_id',
        width: 150,
        filter: 'agNumberColumnFilter'
    },

    {
        headerName: 'Имя блогера',
        field: 'blogger_name',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Артикул ВБ',
        field: 'nmid',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Артикул поставщика',
        field: 'nmid_supplier',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Дата и время старта рекламы',
        field: 'adv_start',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Дата и время окончания рекламы',
        field: 'adv_finish',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Цена товара на момент выхода рекламы',
        field: 'price_release',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Отзыв на ВБ',
        field: 'feedback_wb',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Дата отзыва',
        field: 'feedback_date',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Тип рекламы',
        field: 'adv_type',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Оплата товара',
        field: 'pay_product',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Оплата рекламы',
        field: 'pay_adv',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Цена рекламы с учетом товара',
        field: 'price_adv_product',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Подписчиков всего',
        field: 'all_subs',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Подписчиков живых душ',
        field: 'people_subs',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Охваты план',
        field: 'coverage_plan',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Охваты факт',
        field: 'coverage_fact',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'СРМ план',
        field: 'cpm_plan',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'СРМ факт',
        field: 'cpm_fact',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Клики план',
        field: 'click_plan',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Клики факт',
        field: 'click_fact',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'CTR',
        field: 'ctr',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Заказы план',
        field: 'order_plan',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Заказы факт',
        field: 'order_fact',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Заказы за время рекламы',
        field: 'order_adv_time',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'CR',
        field: 'cr',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Выручка с рекламы, руб. (в заказах)',
        field: 'profit_adv',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'ROMI (окупаемость средств)',
        field: 'romi',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'CPL (стоимость лида или заказа)',
        field: 'cpl',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Дата создания записи',
        field: 'created_at',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Дата обновления записи',
        field: 'updated_at',
        width: 150,
        filter: 'agTextColumnFilter'
    },
    {
        headerName: 'Действия',
        width: 150,
        icons: {
            sortAscending: '<i class="fa fa-sort-alpha-up"/>',
            sortDescending: '<i class="fa fa-sort-alpha-down"/>',
        },
        field: '',
        filter: false,
        cellRenderer: BloggersCellActionComponent,
    },
];

export const BloggersAdverts = ['Сторис', 'Пост', 'Рилс']
