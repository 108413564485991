import {ColDef, ColGroupDef} from 'ag-grid-community';

export const ADMIN_MONITORING_COLUMN_DEFS: (ColDef | ColGroupDef)[] = [
  {
    headerName: '',
    children: [
      {
        headerName: 'Логин',
        headerClass: 'header-centered',
        field: 'login',
        width: 140,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Название магазина селлера',
    headerClass: 'header-centered',
    field: 'name-seller-market',
    width: 200,
    floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'Google ключ',
    headerClass: 'header-centered',
    field: 'google-key',
    width: 200,
    floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'User ID',
    headerClass: 'header-centered',
    field: 'user-id',
    width: 200,
    floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'User Shop',
    headerClass: 'header-centered',
    field: 'user-shop',
    width: 200,
    floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'Удалена ли учетка селлера',
    headerClass: 'header-centered',
    field: 'saler-account-is-deleted',
    width: 200,
    floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'Доступ к Google таблице',
    headerClass: 'header-centered',
    field: 'google-sheets-access',
    width: 200,
    floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: {textAlign: 'center'}
  },
  {
    headerName: 'Ключи',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'Статистика',
        headerClass: 'header-centered',
        field: 'key-stat',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Cтандартный',
        headerClass: 'header-centered',
        field: 'key-standart',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Реклама',
        headerClass: 'header-centered',
        field: 'key-advert',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Wild token',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'Tелефон',
        headerClass: 'header-centered',
        field: 'token-phone',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Tокен статус',
        headerClass: 'header-centered',
        field: 'token-status',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'ID Поставщика в системе WB',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'Supplier Id',
        headerClass: 'header-centered',
        field: 'supplier-id',
        width: 230,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'WBToken статус',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'Портал поставщика',
        headerClass: 'header-centered',
        field: 'wbtoken-status-supplier-portal',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Реклама',
        headerClass: 'header-centered',
        field: 'wbtoken-status-advert',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'X User Id',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'X User Id',
        headerClass: 'header-centered',
        field: 'x-user-id',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Refresh Token',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'Refresh Token',
        headerClass: 'header-centered',
        field: 'token-refresh',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Заказы FBO',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'MIN',
        headerClass: 'header-centered',
        field: 'orders-fbo-min',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'MAX',
        headerClass: 'header-centered',
        field: 'orders-fbo-max',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Днем загружено',
        headerClass: 'header-centered',
        field: 'orders-days-loaded',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Продажи',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'MIN',
        headerClass: 'header-centered',
        field: 'sales-fbo-min',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'MAX',
        headerClass: 'header-centered',
        field: 'sales-fbo-max',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Днем загружено',
        headerClass: 'header-centered',
        field: 'sales-days-loaded',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Реализация',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'MIN',
        headerClass: 'header-centered',
        field: 'realisation-fbo-min',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'MAX',
        headerClass: 'header-centered',
        field: 'realisation-fbo-max',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Количество строк',
        headerClass: 'header-centered',
        field: 'realisation-rows-amount',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Дней загружено',
        headerClass: 'header-centered',
        field: 'realisation-days-loaded',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Блок',
        headerClass: 'header-centered',
        field: 'realisation-block',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Дата последней попытки',
        headerClass: 'header-centered',
        field: 'realisation-last-attempt-date',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
    ],
  },
  {
    headerName: 'Заказы FBS',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'MIN',
        headerClass: 'header-centered',
        field: 'orders-fbs-min',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'MAX',
        headerClass: 'header-centered',
        field: 'orders-fbs-max',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Поставки ',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'MIN',
        headerClass: 'header-centered',
        field: 'delivery-min',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'MAX',
        headerClass: 'header-centered',
        field: 'delivery-max',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'АПИ Карточки ',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'MIN',
        headerClass: 'header-centered',
        field: 'api-cart-min',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'MAX',
        headerClass: 'header-centered',
        field: 'api-cart-max',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Реклама АПИ ',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'MIN',
        headerClass: 'header-centered',
        field: 'api-advert-min',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'MAX',
        headerClass: 'header-centered',
        field: 'api-advert-max',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Реклама Токен',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'Кол-во компаний',
        headerClass: 'header-centered',
        field: 'advert-token-companies-amount',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Статистика компаний',
        headerClass: 'header-centered',
        field: 'advert-token-companies-statistics',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Фин стат обновленный',
        headerClass: 'header-centered',
        field: 'advert-token-fin-stat-new',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'Фин стат обновленный WB',
        headerClass: 'header-centered',
        field: 'advert-token-fin-stat-new-wb',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Сток ФБО',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'Сервер обновил',
        headerClass: 'header-centered',
        field: 'stock-service-refresh',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'WB обновил',
        headerClass: 'header-centered',
        field: 'stock-wb-refresh',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'АПИ Цены',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'MIN',
        headerClass: 'header-centered',
        field: 'api-price-min',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      },
      {
        headerName: 'MAX',
        headerClass: 'header-centered',
        field: 'api-price-max',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Тарифы WB',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'Дата последней загрузки',
        headerClass: 'header-centered',
        field: 'tariff-wb-last-uploads-date',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  },
  {
    headerName: 'Сток WH',
    wrapHeaderText: true,
    autoHeaderHeight: true,
    children: [
      {
        headerName: 'Дата последнего обновления',
        headerClass: 'header-centered',
        field: 'stock-wh-last-uploads-date',
        width: 200,
        floatingFilter: true, sortable: true, resizable: true, filter: true, suppressSizeToFit: true, flex: 0,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: {textAlign: 'center'}
      }
    ],
  }
];
