const colorHeaderGeneralData = 'red';
const colorGeneralData = 'rgb(196 206 29)';
const colorDelta = 'rgb(53 153 11)';
export const RECONCILIATION_COLUMNS_DEFS = [
  {
    headerName: 'Основные данные',
    headerClass: 'centered header_general_data_reconciliation',
    children: [
      {
        headerName: '№ отчета',
        headerClass: 'header_general_data_reconciliation',
        field: 'report_id',
        width: 150,
        pinned: 'left',
        filter: 'agTextColumnFilter',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Дата начала',
        headerClass: 'header_general_data_reconciliation',
        field: 'start_date',
        width: 150,
        pinned: 'left',
        filter: 'agDateColumnFilter',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Дата конца',
        headerClass: 'header_general_data_reconciliation',
        field: 'end_date',
        width: 150,
        filter: 'agDateColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Дата формирования',
        headerClass: 'header_general_data_reconciliation',
        field: 'form_date',
        width: 150,
        filter: 'agDateColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Продажа, руб',
        headerClass: 'header_general_data_reconciliation',
        field: 'sales_rub',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Согласованная скидка, %',
        headerClass: 'header_general_data_reconciliation',
        field: 'agreed_discount_percent',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'К перечислению за товар, руб',
        headerClass: 'header_general_data_reconciliation',
        field: 'pay_for_good_rub',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Стоимость логистики, руб',
        headerClass: 'header_general_data_reconciliation',
        field: 'logistic_cost_rub',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Штраф. Повышенная логистика согласно коэффициенту по обмерам',
        headerClass: 'header_general_data_reconciliation',
        field: 'penalty_increased_logistic',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Другие виды штрафов',
        headerClass: 'header_general_data_reconciliation',
        field: 'another_penalties',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Общая сумма штрафов',
        headerClass: 'header_general_data_reconciliation',
        field: 'total_penalties_sum',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Доплаты',
        headerClass: 'header_general_data_reconciliation',
        field: 'allowances',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Стоимость хранения, руб',
        headerClass: 'header_general_data_reconciliation',
        field: 'storage_cost_rub',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Стоимость платной приемки, руб',
        headerClass: 'header_general_data_reconciliation',
        field: 'paid_acceptance_cost_rub',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Прочие удержания, руб',
        headerClass: 'header_general_data_reconciliation',
        field: 'other_withholding_rub',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Итого к оплате без учета долга предыдущих',
        headerClass: 'header_general_data_reconciliation',
        field: 'total_payable_excluding_debt_of_previous',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Итого к оплате, руб',
        headerClass: 'header_general_data_reconciliation',
        field: 'total_payable',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Валюта',
        headerClass: 'header_general_data_reconciliation',
        field: 'currency',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'open',
        cellStyle: { background: colorGeneralData },
      },
      {
        headerName: 'Статус отчета',
        headerClass: 'header_general_data_reconciliation',
        field: 'report_status',
        width: 150,
        filter: 'agTextColumnFilter',
        columnGroupShow: 'closed',
        cellStyle: { background: colorGeneralData },
      },
    ],
  },
  {
    headerName: 'Товарооборот',
    headerClass: 'header_additional_reconciliation',
    children: [
      {
        headerName: 'ЛК ВБ',
        headerClass: 'header_additional_reconciliation',
        field: 'turnover_lk_wb',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PNL Недели модель',
        headerClass: 'header_additional_reconciliation',
        field: 'turnover_week_model_pnl',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Дельта',
        headerClass: 'header_additional_reconciliation',
        field: 'turnover_delta',
        width: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { background: colorDelta },
      },
      {
        headerName: '% точности',
        headerClass: 'header_additional_reconciliation',
        field: 'turnover_accuracy_percent',
        width: 150,
        filter: 'agTextColumnFilter',
      },
    ],
  },
  {
    headerName: 'Комиссия',
    headerClass: 'header_additional_reconciliation',
    children: [
      {
        headerName: 'ЛК ВБ',
        headerClass: 'header_additional_reconciliation',
        field: 'commission_lk_wb',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PNL Недели модель',
        headerClass: 'header_additional_reconciliation',
        field: 'commission_week_model_pnl',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Дельта',
        headerClass: 'header_additional_reconciliation',
        field: 'commission_delta',
        width: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { background: colorDelta },
      },
      {
        headerName: '% точности',
        headerClass: 'header_additional_reconciliation',
        field: 'commission_accuracy_percent',
        width: 150,
        filter: 'agTextColumnFilter',
      },
    ],
  },
  {
    headerName: 'Логистика',
    headerClass: 'header_additional_reconciliation',
    children: [
      {
        headerName: 'ЛК ВБ',
        headerClass: 'header_additional_reconciliation',
        field: 'logistic_lk_wb',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PNL Недели модель',
        headerClass: 'header_additional_reconciliation',
        field: 'logistic_week_model_pnl',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Дельта',
        headerClass: 'header_additional_reconciliation',
        field: 'logistic_delta',
        width: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { background: colorDelta },
      },
      {
        headerName: '% точности',
        headerClass: 'header_additional_reconciliation',
        field: 'logistic_accuracy_percent',
        width: 150,
        filter: 'agTextColumnFilter',
      },
    ],
  },
  {
    headerName: 'Удержания',
    headerClass: 'header_additional_reconciliation',
    children: [
      {
        headerName: 'ЛК ВБ',
        headerClass: 'header_additional_reconciliation',
        field: 'withholding_lk_wb',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PNL Недели модель',
        headerClass: 'header_additional_reconciliation',
        field: 'withholding_week_model_pnl',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Удержания Реклама',
        headerClass: 'header_additional_reconciliation',
        field: 'withholding_advert',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PNL Недели Реклама факт.',
        headerClass: 'header_additional_reconciliation',
        field: 'withholding_pnk_week_advert_fact',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Дельта',
        headerClass: 'header_additional_reconciliation',
        field: 'withholding_delta',
        width: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { background: colorDelta },
      },
      {
        headerName: '% точности',
        headerClass: 'header_additional_reconciliation',
        field: 'withholding_accuracy_percent',
        width: 150,
        filter: 'agTextColumnFilter',
      },
    ],
  },
  {
    headerName: 'К перечислению',
    headerClass: 'header_additional_reconciliation',
    children: [
      {
        headerName: 'ЛК ВБ',
        headerClass: 'header_additional_reconciliation',
        field: 'be_transferred_lk_wb',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PNL Недели модель',
        headerClass: 'header_additional_reconciliation',
        field: 'be_transferred_week_model_pnl',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Дельта',
        headerClass: 'header_additional_reconciliation',
        field: 'be_transferred_delta',
        width: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { background: colorDelta },
      },
      {
        headerName: '% точности',
        headerClass: 'header_additional_reconciliation',
        field: 'be_transferred_accuracy_percent',
        width: 150,
        filter: 'agTextColumnFilter',
      },
    ],
  },
  {
    headerName: 'Платная приемка',
    headerClass: 'header_additional_reconciliation',
    children: [
      {
        headerName: 'ЛК ВБ',
        headerClass: 'header_additional_reconciliation',
        field: 'paid_acceptance_lk_wb',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'PNL Недели модель',
        headerClass: 'header_additional_reconciliation',
        field: 'paid_acceptance_week_model_pnl',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Дельта',
        headerClass: 'header_additional_reconciliation',
        field: 'paid_acceptance_delta',
        width: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { background: colorDelta },
      },
      {
        headerName: '% точности',
        headerClass: 'header_additional_reconciliation',
        field: 'paid_acceptance_accuracy_percent',
        width: 150,
        filter: 'agTextColumnFilter',
      },
    ],
  },
];

export const RECONCILIATION_MOCK = [
  {
    report_id: '123123',
    start_date: '123123123',
    end_date: '123123',
    form_date: 'ф',
    sales_rub: 'ы',
    agreed_discount_percent: 'S',
    pay_for_good_rub: 's',
    logistic_cost_rub: 's',
    penalty_increased_logistic: 's',
    another_penalties: 's',
    total_penalties_sum: 'S',
    allowances: 's',
    storage_cost_rub: 'S',
    paid_acceptance_cost_rub: 'S',
    other_withholding_rub: 'a',
    total_payable: 'a',
    currency: 'a',
    report_status: 's',
    turnover_lk_wb: 's',
    turnover_week_model_pnl: 's',
    turnover_delta: 's',
    turnover_accuracy_percent: 's',
    commission_lk_wb: 's',
    commission_week_model_pnl: 's',
    commission_delta: 's',
    commission_accuracy_percent: 's',
    logistic_lk_wb: 's',
    logistic_week_model_pnl: 's',
    logistic_delta: 's',
    logistic_accuracy_percent: 's',
    withholding_lk_wb: 's',
    withholding_week_model_pnl: 's',
    withholding_advert: 's',
    withholding_pnk_week_advert_fact: 's',
    withholding_delta: 's',
    withholding_accuracy_percent: 's',
    be_transferred_lk_wb: 's',
    be_transferred_week_model_pnl: 's',
    be_transferred_delta: 's',
    be_transferred_accuracy_percent: 's',
    paid_acceptance_lk_wb: 's',
    paid_acceptance_week_model_pnl: 's',
    paid_acceptance_delta: 's',
    paid_acceptance_accuracy_percent: 's',
  },
];

// cellStyle: {background: '#f0f3f5'},
