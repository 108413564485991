export function NumberSpacesFormatter(param: any): any {
  return param.value
    ? param.value.toString() !== '-1'
      ? param.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      : '—'
    : param.value;
}

export function ThousandSeparator(value: number | string | undefined | null): string {
  if (!value) {
    return '0';
  }
  const parts = value.toString().split(',');
  let intPart = parts[0];
  const floatPart = parts[1];
  const regExp = /(\d+)(\d{3})/;
  while (regExp.test(intPart)) {
    intPart = intPart.replace(regExp, '$1' + ' ' + '$2');
  }
  return '' + intPart + (floatPart ? `.${floatPart}` : '');
}
