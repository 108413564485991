<div class="app-page-title" style="z-index: 100;">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="vsm-icon pe-7s-users icon-gradient bg-mean-fruit"></i>
      </div>
      <div>
        {{seller_name}} <!--{{control_id}}-->
        <div class="page-title-subheading" style="z-index: 100;opacity: 1.0;">
          данные за период
          <input (change)="newValue()"
                 [(ngModel)]="selected"
                 [alwaysShowCalendars]="true"
                 [isInvalidDate]="isInvalidDate"
                 [linkedCalendars]="true"
                 [locale]="{
                        applyLabel: 'применить',
                        direction: 'ltr',
                        format: 'DD.MM.YYYY',
                        'daysOfWeek': ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
                        'customRangeLabel': 'Свой диапазон',
                        'firstDay': 1,
                        'monthNames': ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']}"
                 [maxDate]='maxDate'
                 [minDate]='minDate'
                 [ranges]="ranges"
                 [showClearButton]="false"
                 [showCustomRangeLabel]="true"
                 ngxDaterangepickerMd
                 placeholder="Выберите даты"
                 type="text"/>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6 col-lg-3">
    <div class="card-shadow-primary card-border mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon pe-7s-wallet mr-3 text-muted opacity-6"> </i>
          Выручка
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="p-0 list-group-item">
          <div class="w-100">
            <div class="widget-chart">
              <div class="widget-chart-content">
                <div class="widget-numbers"><span>{{data_agr?.revenue | number: '1.0-2'}}</span></div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-md-6 col-lg-3">
    <div class="card-shadow-primary card-border mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon lnr-cart mr-3 text-muted opacity-6"> </i>
          Продаж
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="p-0 list-group-item">
          <div class="w-100">
            <div class="widget-chart">
              <div class="widget-chart-content">
                <div class="widget-numbers"><span>{{data_agr?.sales | number: '1.0-0'}}</span></div>
                <!--<div class="widget-description text-focus">
                    Разница со вчерашним днем:
                    <span class="text-danger pl-1">
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                    <span class="pl-1">7.35%</span>
                </span>
                </div>-->
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-md-6 col-lg-3">
    <div class="card-shadow-primary card-border mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon pe-7s-back mr-3 text-muted opacity-6"> </i>
          Средний чек
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="p-0 list-group-item">
          <div class="w-100">
            <div class="widget-chart">
              <div class="widget-chart-content">
                <div class="widget-numbers"><span>{{data_agr?.avg_check | number: '1.0-0'}}</span></div>
                <!--<div class="widget-description text-focus">
                    Разница со вчерашним днем:
                    <span class="text-danger pl-1">
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                    <span class="pl-1">7.35%</span>
                </span>
                </div>-->
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-md-6 col-lg-3">
    <div class="card-shadow-primary card-border mb-3 card">
      <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          <i class="header-icon pe-7s-network mr-3 text-muted opacity-6"> </i>
          Остатки
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="p-0 list-group-item">
          <div class="w-100">
            <div class="widget-chart">
              <div class="widget-chart-content">
                <div class="widget-numbers"><span>{{data_agr?.stock | number: '1.0-2'}}</span></div>
                <!--<div class="widget-description text-focus">
                    Разница со вчерашним днем:
                    <span class="text-danger pl-1">
                    <fa-icon [icon]="faAngleUp"></fa-icon>
                    <span class="pl-1">7.35%</span>
                </span>
                </div>-->
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-header">
        Склад (продажи остатки)
      </div>
      <div class="card-body">
        <canvas (chartClick)="chartClicked($event)" (chartHover)="chartHovered($event)" [chartType]="lineChartType"
                [colors]="lineChartColors"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [legend]="lineChartLegend"
                [options]="lineChartOptions"
                [plugins]="lineChartPlugins"
                baseChart
                height="200"
                width="400">

        </canvas>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-lg-6">
    <div class="main-card mb-3 card">
      <div class="card-header">
        Продажи (выручка цена)
      </div>
      <div class="card-body">
        <canvas [chartType]="lineChartType" [colors]="lineChartColors" [datasets]="SalesChartData"
                [labels]="SalesChartLabels"
                [legend]="lineChartLegend"
                [options]="lineChartOptions"
                [plugins]="SalesChartPlugins"
                baseChart
                height="200"
                width="400"
        >

        </canvas>
      </div>
    </div>
  </div>
</div>
<div style="width: 100%;height: 80vh;">
  <ag-grid-angular
    (gridReady)="onGridReady($event)"
    [cacheBlockSize]="cacheBlockSize"

    [cacheOverflowSize]="cacheOverflowSize"
    [columnDefs]="columnDefsSales"
    [infiniteInitialRowCount]="infiniteInitialRowCount"
    [localeText]="localeText"
    [maxBlocksInCache]="maxBlocksInCache"
    [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"


    [paginationPageSize]="paginationPageSize"

    [pagination]="true"
    [rowData]="rowData"
    [rowHeight]="rowHeight"
    [rowModelType]="rowModelType"
    [rowSelection]="rowSelection"
    class="ag-theme-alpine"
    style="width: 100%; height: 100%;"
  >
  </ag-grid-angular>

</div>
