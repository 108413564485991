import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/services/api.service';
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faPlus,
  faStar,
  faTh,
  faTrash,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import { finalize } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TAXES_TYPES } from './shop.mock';
import { IDataShopWBList, IDataShopWbReloaded, IResponseCommon, IShopStatic, ITitleValue } from './shop.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { AbstractMenuTabComponent } from '../../abstract-menu-tab.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class ShopComponent extends AbstractMenuTabComponent implements OnInit {
  readonly faAngleDown = faAngleDown;
  readonly faAngleUp = faAngleUp;
  readonly faPlus = faPlus;
  readonly faStar = faStar;
  readonly faTh = faTh;
  readonly faTrash = faTrash;
  readonly faCheck = faCheck;
  readonly faWindowClose = faWindowClose;
  readonly formGroup = new FormGroup({
    shop_name: new FormControl('', Validators.required),
    shop_key: new FormControl('', Validators.required),
  }); //CHECK
  form = new FormGroup({
    checkArray: new FormArray([], [Validators.required]),
    phone_number: new FormControl('', [Validators.required]),
  });
  readonly typesOfTaxes: ITitleValue[] = TAXES_TYPES;
  shop: IDataShopWBList[] = [];
  shop_list: IDataShopWbReloaded[] = [];
  wild: any;
  supplier: any;
  WBTokenAdv: any;
  WBTokenPortal: any;
  KeyApiStat: any;
  KeyApiNew: any;
  KeyApiNewer: any;
  KeyApiAdv: any;
  tgid: any;
  val6: string;
  hide_full_access_wb = false;
  error_phone: string | boolean;
  exists_phone: string;
  modal_title_get_phone: string;
  modal_title_update_token: string;
  modal_shop_id: any;
  exists_phone_color: string;
  name_connect = '';
  error_phone_rr: any;
  tax_type: any;
  tax_percent: number;
  view_google_table_info = true;

  SHOPID: any; //CHECK

  @ViewChild('updateTokenTmpl') modalContentUpdateToken: TemplateRef<any>;

  constructor(private api: ApiService) {
    super();
    this.view_google_table_info = localStorage.getItem('googleMsgInfo') == null;
  }

  ngOnInit() {
    this.getData();
    this.getDataShop();
  }

  closeGoogleTableMsg(param) {
    if (+param === 1) {
      localStorage.setItem('googleMsgInfo', '1');
      this.view_google_table_info = false;
    }
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (+item.value === +e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submitForm() {
    console.log(this.form.value);
  }

  view_full_access_wb() {
    this.hide_full_access_wb = true;
  }

  openLarge(content, id) {
    this.SHOPID = id;
    this.modal_title_get_phone = 'Добавление поставщика';
    this._modalService.open(content);
  }

  disabledSeller(id) {
    if (confirm('Вы уверены, что хотите отключить поставщика от системы?')) {
      const body = { shop_id: this.SHOP_ID, id };
      this.api.userRegister('disabledSeller', localStorage.getItem('token'), body).subscribe(
        (data: IShopStatic<IResponseCommon>) => {
          if (+data.data.is_error === 0) {
            this.getData();
            this.showPopUpMessage('success', PopUpMessages.updateFailedSummary, 'Поставщик отключен');
            this.reloadWBlist();
          } else {
            this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
        },
      );
    }
  }

  createConnection(id) {
    if (this.name_connect?.length <= 150 && this.name_connect?.length > 1) {
      const body = { shop_id: this.SHOP_ID, id, name: this.name_connect };
      this.api.userRegister('addSeller', localStorage.getItem('token'), body).subscribe(
        (data: IShopStatic<IResponseCommon>) => {
          if (+data.data.is_error === 0) {
            this.getData();
            this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Селлер создан!');
            this._modalService.dismissAll();
            this.reloadWBlist();
          } else {
            this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
        },
      );
    } else {
      this.error_phone = true;
      this.exists_phone = 'Ошибка заполнения';
      this.exists_phone_color = 'warning';
    }
  }

  updateToken(editType, id) {
    this.modal_title_update_token = '';
    this.modal_shop_id = id;
    this.error_phone = '';
    switch (editType) {
      case 'name':
        this.modal_title_update_token = 'Название подключения';
        break;
      case 'taxes':
        const currentShopAccount = this.shop.filter((shop) => shop.id === id)[0];
        if (currentShopAccount) {
          this.tax_percent = +currentShopAccount.tax_percent;
          this.tax_type = +currentShopAccount.tax_type;
        }
        this.modal_title_update_token = 'Налог';
        break;
      case 'wild':
        this.modal_title_update_token = 'Wild token';
        break;
      case 'supplier':
        this.modal_title_update_token = 'Supplier-id';
        break;
      case 'WBTokenAdv':
        this.modal_title_update_token = 'WBToken для рекламных кампаний';
        break;
      case 'WBTokenPortal':
        this.modal_title_update_token = 'WBToken для внутренней аналитики';
        break;
      case 'KeyApiStat':
        this.modal_title_update_token = 'Ключ API Статистика';
        break;
      case 'KeyApiNew':
        this.modal_title_update_token = 'Ключ API Стандартный';
        break;
      case 'KeyApiNewer':
        this.modal_title_update_token = 'Ключ API Новый';
        this.KeyApiNewer = null;
        break;
      case 'KeyApiAdv':
        this.modal_title_update_token = 'Ключ API Реклама';
        break;
      case 'tgid':
        this.modal_title_update_token = 'Telegram ID';
        break;
    }
    this._modalService.open(this.modalContentUpdateToken);
  }

  updateTokenNew(editType: string, id: number) {
    let value;
    let phone;
    switch (editType) {
      case 'Название подключения':
        value = this.name_connect;
        break;
      case 'Wild token':
        value = this.wild;
        phone = this.val6;
        break;
      case 'Налог':
        value = { tax_percent: this.tax_percent, tax_type: this.tax_type };
        break;
      case 'Supplier-id':
        value = this.supplier;
        break;
      case 'WBToken для рекламных кампаний':
        value = this.WBTokenAdv;
        break;
      case 'WBToken для внутренней аналитики':
        value = this.WBTokenPortal;
        break;
      case 'Ключ API Статистика':
        value = this.KeyApiStat;
        break;
      case 'Ключ API Стандартный':
        value = this.KeyApiNew;
        break;
      case 'Ключ API Новый':
        value = this.KeyApiNewer;
        break;
      case 'Ключ API Реклама':
        value = this.KeyApiAdv;
        break;
      case 'Telegram ID':
        value = this.tgid;
        break;
    }
    const body = { id, type_edit: editType, value, phone };
    this.api.userRegister('updateDataShop', localStorage.getItem('token'), body).subscribe(
      (data: IShopStatic<IResponseCommon>) => {
        if (+data.data.is_error === 0) {
          this._modalService.dismissAll();
          this.showPopUpMessage('success', PopUpMessages.updateFailedSummary, data.data.msg);
          this.name_connect = '';
          this.wild = '';
          this.supplier = '';
          this.WBTokenAdv = '';
          this.tax_type = '';
          this.tax_percent = 0;
          this.WBTokenPortal = '';
          this.KeyApiStat = '';
          this.KeyApiNew = '';
          this.tgid = '';
          this.getData();
        } else {
          this.showPopUpMessage('warning', PopUpMessages.updateFailedSummary, data.data.msg);
          this.error_phone = data.data.msg;
          this.error_phone_rr = data.data.msg_phone;
        }
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
      },
    );
  }

  updateTokenMpall(id) {
    if (
      confirm(
        'Вы уверены, что хотите изменить Ключ для гугл таблиц? ' +
          'После нажаният на кнопку "ДА" не зубудьте его поменять в настройках гугл таблицы',
      )
    ) {
      const body = { id };
      this.api.userRegister('updateDataShopKeyMpall', localStorage.getItem('token'), body).subscribe(
        (data: IShopStatic<IResponseCommon>) => {
          if (data.data.is_error === 0) {
            this.showPopUpMessage('success', PopUpMessages.updateSuccessSummary, '');
            this.getData();
          } else {
            this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
          }
        },
        (error: HttpErrorResponse) => {
          this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
        },
      );
    }
  }

  getTypeOfTax(type: number): string {
    return !type ? 'Не установлено' : this.typesOfTaxes.filter((x) => x.value === +type)[0].title;
  }

  getDataShop() {
    this.api.userRegister('getDataShopWB', localStorage.getItem('token'), '').subscribe(
      (data: IDataShopWbReloaded[]) => {
        this.shop_list = data;
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  getData() {
    this.isLoading = true;
    this.api
      .userRegister('getDataShopWBList', localStorage.getItem('token'), '')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data: IDataShopWBList[]) => {
          if (!data) {
            this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
          }
          this.shop = data;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private reloadWBlist() {
    this.api
      .userRegister('getDataShopWB', localStorage.getItem('token'), '')
      .subscribe((response: IDataShopWbReloaded[]) => {
        localStorage.setItem('supplier', JSON.stringify(response));
        window.location.reload();
      });
  }
}
