import { ColDef, ColGroupDef } from 'ag-grid-community';

export const DB_UNIT_COL_DEF: (ColDef | ColGroupDef)[] = [
  {
    children: [
      {
        headerName: 'Баркод',
        headerClass: 'header-centered',
        field: 'bar_code',
        width: 140,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Артикул',
        field: 'article',
        width: 140,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Название товара',
        field: 'item_name',
        filter: 'agTextColumnFilter',
        width: 130,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
    ],
  },
  {
    headerName: 'ОСНОВНЫЕ ПОКАЗАТЕЛИ',
    headerClass: 'header-centered',
    children: [
      {
        headerName: 'Цена без СПП',
        field: 'price_without_spp',
        width: 130,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Общая себестоимость',
        field: 'total_cost',
        width: 130,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Маржа',
        field: 'profit_margin',
        width: 120,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Маржа, %',
        field: 'profit_margin_percent',
        width: 120,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
    ],
  },
  {
    headerName: 'ЗАТРАТЫ',
    children: [
      {
        headerName: 'Себестоимость товара с фф',
        field: 'total_cost_ff',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Логистика, комиссия, приемка ВБ',
        field: 'logistic_commission_priemkaVB',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'НАЛОГ УСН',
        field: 'tax_usn',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
    ],
  },
  {
    headerName: 'Себестоимость товара с фф',
    children: [
      {
        headerName: 'Текущая партия в продаже',
        field: 'current_batch_in_sale',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Стоимость закупки за шт.',
        field: 'cost_of_purchase_for_one_item',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Доставка на единицу от поставщика до ФФ/склада',
        field: 'deliver_for_one_product_from_customer_to_ff',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Стоимость маркировки и упаковки на 1 шт (фф)',
        field: 'labeling_packaging_costs_for_one_item',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Упаковочный материал доп., руб.',
        field: 'covering_material_dop',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Отгрузка и доставка на ВБ с фф',
        field: 'shipment_deliver_fb_with_ff',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Итого себестоимость руб',
        field: 'prime_cost',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
    ],
  },
  {
    headerName: 'Логистика, комиссия, приемка ВБ',
    children: [
      {
        headerName: 'Комиссия FBO ВБ',
        field: 'commission_fbo_vb',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Литраж',
        field: 'capacity',
        width: 140,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Логистика ВБ, тариф за шт. ',
        field: 'logistic_vb_with_one_rate',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: '% выкупа за 45 дней факт',
        field: 'percent_redemption_for_45_days',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Покатушки с учетом % выкупа',
        field: 'transport_with_redemption_percent',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Хранение ВБ за сутки, руб.',
        field: 'storage_vb_for_full_day',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: 'Хранение на штуку',
        field: 'storage_for_one_item',
        width: 160,
        filter: 'agNumberColumnFilter',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { textAlign: 'center' },
      },
    ],
  },
];

export const TAXES_PERCENTS_LIST: string[] = [
  '1%',
  '2%',
  '3%',
  '4%',
  '5%',
  '6%',
  '7%',
  '8%',
  '9%',
  '10%',
  '11%',
  '12%',
  '13%',
  '14%',
  '15%',
  '16%',
  '17%',
  '18%',
  '19%',
  '20%',
];
