import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Params} from '@angular/router';

@Component({
  selector: 'app-btn-cell-renderer',
  template: `
    <div style="text-align: center">
      <button label="" (click)="edit()"
              class="icon-button" style="margin-right: 25px;">
        <i class="lnr-pencil"></i>
      </button>
      <button label="" (click)="delete()" class="icon-button">
        <i class="lnr-trash"></i>
      </button>
    </div>
  `,
  styleUrls: ['../key-queries.component.scss'],
})
export class KeyQueriesManageCellComponent implements ICellRendererAngularComp {
  componentParent: any;
  isMovingOperation = false;
  private params: Params;

  agInit(params: Params): void {
    this.params = params;
    this.componentParent = this.params.context.componentParent;
  }

  edit() {
    this.componentParent.updateKeyQueryEmitter(this.params.data);
  }

  delete() {
    this.componentParent.deleteKeyQuery(this.params.data);
  }

  refresh() {
    return false;
  }
}
