import { Nullable } from '../../../shared/interfaces/common.type';

export type ProductCellChanges =
  | 'cost_price'
  | 'mystockwh'
  | 'production_time'
  | 'delivery_time_ff'
  | 'my_name'
  | 'status_name'
  | 'category_pnl';

export const CellChangesTypes = [
  'cost_price',
  'mystockwh',
  'production_time',
  'delivery_time_ff',
  'my_name',
  'status_name',
  'category_pnl',
];

export interface IProductsResponse {
  totalRecords: number;
  data: IProductsRowData[];
}

export interface IProductsRowData {
  id: number;
  user_id: number;
  user_shop_id: number;
  shop_name: string;
  rating: null | number;
  SKU: string;
  SKU_name: string;
  my_name: string;
  nmid: string;
  date_sales: Nullable<string>;
  price: null | number;
  discount: Nullable<string>;
  price_discount: null | number;
  final_price: Nullable<string>;
  cost_price: Nullable<string>;
  Barcode: string;
  techSize: string;
  subject: string;
  category: string;
  DaysOnSite: Nullable<string>;
  brand: string;
  photo: string;
  mystockwh: number;
  colors: string;
  delivery_time_ff: number;
  production_time: number;
  quantity: string;
  last_sale: string;
  status_cancel: number;
  photo_new: string;
  category_pnl: string;
  status_name: string;
}

export interface IBodyRequestCell {
  bodyRequest: {
    Barcode?: string;
    nmid?: string;
    id?: number;
    cost_price?: number;
    mystockwh?: number;
    my_name?: string;
    category_pnl?: string;
    product_id?: number;
    delivery_time_ff?: number;
    production_time?: number;
    status_id?: number;
  };
  URL: string;
}

export interface IStatus {
  id?: number;
  name: string;
  index: number;
}
