import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { LOCALE_TEXT_FOR_FILTERS } from '../../guides/products/products.mock';
import { GridOptions } from 'ag-grid';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MpSurfApiService } from '../../../shared/services/mpsurf-api.service';
import { BloggersRowData, IBloggersList } from './bloggers.interface';
import { BLOGGERS_COLUMNS_DEFS, BloggersAdverts } from './bloggers.mock';
import { finalize } from 'rxjs/operators';
import { PopUpStatus } from '../../../shared/interfaces/common.type';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpMessages } from '../../../shared/mocks/pop-up-messages.mock';
import { MessageService } from 'primeng/api';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-bloggers',
  templateUrl: './bloggers.component.html',
  styleUrls: ['./bloggers.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BloggersComponent implements OnInit, AfterViewInit {
  gridApi!: GridApi<any>;
  rowData: BloggersRowData[] = [];
  readonly colDefs = [...BLOGGERS_COLUMNS_DEFS];
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly gridOptions: GridOptions;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: 'center' },
  };
  // AG-GRID ENDS
  isLoading = false;
  isEdit = false;
  bloggerModalId: number;

  bloggersList: IBloggersList[] = [];

  readonly bloggersForm = new UntypedFormGroup({
    nmid: new UntypedFormControl(null, [Validators.required]),
    nmid_supplier: new UntypedFormControl(null, [Validators.required]),
    blogger_id: new UntypedFormControl(null, [Validators.required]),
    adv_type: new UntypedFormControl(null, [Validators.required]),
    adv_start: new UntypedFormControl(null, [Validators.required]),
    adv_finish: new UntypedFormControl(null),
    feedback_date: new UntypedFormControl(null, [Validators.required]),
    price_release: new UntypedFormControl(null, [Validators.required]),
    price_adv_product: new UntypedFormControl(null, [Validators.required]),
    all_subs: new UntypedFormControl(null, [Validators.required]),
    people_subs: new UntypedFormControl(null, [Validators.required]),
    coverage_fact: new UntypedFormControl(null, [Validators.required]),
    click_fact: new UntypedFormControl(null, [Validators.required]),
    order_adv_time: new UntypedFormControl(null, [Validators.required]),
    feedback_wb: new UntypedFormControl(null),
  });

  BloggersAdverts = [...BloggersAdverts];

  newBloggerNameModel = '';

  newBloggerAdvertModel = '';

  modalReference: any;

  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;
  @ViewChild('bloggersAdvertCompanyModal', { read: TemplateRef }) bloggersAdvertCompanyModal: TemplateRef<any>;
  @ViewChild('bloggerModal', { read: TemplateRef }) bloggerModal: TemplateRef<any>;

  constructor(
    private mpSurfService: MpSurfApiService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
  ) {
    this.gridOptions = { context: { componentParent: this } } as GridOptions;
  }

  ngOnInit(): void {
    this.loadBloggersList();
  }

  ngAfterViewInit() {
    if (this.agGrid) {
      this.gridApi = this.agGrid.api;
    }
    this.cdr.markForCheck();
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.loadBloggersAdvertCompany();
  }

  openModal(editStatus: boolean, modalCdr: any, isClearForms: boolean) {
    if (isClearForms) {
      this.bloggersForm.reset();
    }
    this.newBloggerNameModel = '';
    this.newBloggerAdvertModel = '';
    this.isEdit = editStatus;
    this.modalReference = this.modalService.open(modalCdr);
  }

  editModalEmitter(currentRowDate: BloggersRowData) {
    this.isEdit = true;
    this.bloggerModalId = currentRowDate.id;
    this.modalService.open(this.bloggersAdvertCompanyModal, { backdrop: 'static' });
    this.bloggersForm.patchValue(currentRowDate);

    this.bloggersForm.controls['adv_start'].setValue(new Date(currentRowDate.adv_start));
    this.bloggersForm.controls['feedback_date'].setValue(new Date(currentRowDate.feedback_date));

    if (currentRowDate.adv_finish) {
      this.bloggersForm.controls['adv_finish'].setValue(new Date(currentRowDate.adv_start));
    }
  }

  createUpdateBloggerAdvertCompany(bloggerId: number | null) {
    const bodyRequest = this.bloggersForm.getRawValue() as BloggersRowData;
    bodyRequest.blogger_name =
      this.bloggersList.find((a) => a.blogger_id === bodyRequest.blogger_id)?.blogger_name || '';
    const URL = bloggerId ? `bloggers-adv/${bloggerId}` : 'bloggers-adv';
    this.isLoading = true;
    this.mpSurfService
      .createOrUpdate(bodyRequest, URL)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        () => {
          this.modalService.dismissAll();
          this.loadBloggersAdvertCompany();
          const message = `Рекламная компания блогера ${this.isEdit ? 'изменена' : 'создано'}`;
          this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, message);
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  createNewBlogger(): void {
    this.isLoading = true;
    this.mpSurfService
      .createOrUpdate({ blogger_name: this.newBloggerNameModel }, 'bloggers')
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        () => {
          this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Блогер создан!');
          this.loadBloggersList();
          this.modalReference.close();
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );

    console.log(this.newBloggerNameModel);
  }

  createNewAdvert(operationType: string): void {
    this.BloggersAdverts.push(this.newBloggerAdvertModel);
    this.newBloggerAdvertModel = '';
    this.modalReference.close();
    this.cdr.markForCheck();
    this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Новый вид рекламы создан!');
  }

  deleteBloggerAdvertCompany(bloggerId: number) {
    if (confirm('Вы действительно хотите удалить Рекламную компанию блогера')) {
      this.isLoading = true;
      this.mpSurfService
        .delete({}, `bloggers-adv/${bloggerId}`)
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.isLoading = false;
            this.cdr.markForCheck();
          }),
        )
        .subscribe(
          () => {
            this.showPopUpMessage('success', PopUpMessages.deleteSuccessSummary, 'Рекламная компания блогера удалена!');
            this.loadBloggersAdvertCompany();
          },
          () => {
            this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
          },
        );
    }
  }

  closeModal(status: string): void {
    if (status === 'MAIN') {
      this.modalService.dismissAll();
    } else {
      this.modalReference.close();
    }
  }

  private loadBloggersAdvertCompany(): void {
    this.isLoading = true;
    this.mpSurfService
      .load({}, 'bloggers-adv')
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        (response: BloggersRowData[]) => {
          this.rowData = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private loadBloggersList(): void {
    this.mpSurfService
      .load({}, 'bloggers')
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        (response: IBloggersList[]) => {
          this.bloggersList = response;
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
        },
      );
  }

  private showPopUpMessage(severity: PopUpStatus, summary: string, message: string): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: message,
    });
  }
}
