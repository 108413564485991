import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment/moment';
import { IFinAccountCreateUpdateDeleteResponse, IFinAccountsList } from './finaccounts.interface';
import { ACCOUNT_TYPES, FINACCOUNTS_COLUMN_DEFS } from './finaccounts.mock';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { GridOptions } from 'ag-grid';
import { LOCALE_TEXT_FOR_FILTERS } from '../../../guides/products/products.mock';
import { UntilDestroy } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { CommonValueWithIDName } from '../../../../shared/interfaces/common.interface';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PopUpMessages } from '../../../../shared/mocks/pop-up-messages.mock';
import { AbstractMenuTabComponent } from '../../../abstract-menu-tab.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-finaccounts',
  templateUrl: './finaccounts.component.html',
  styleUrls: ['./finaccounts.component.sass'],
  providers: [MessageService, ConfirmationService],
})
export class FinaccountsComponent extends AbstractMenuTabComponent implements OnInit {
  // AG-GRID
  readonly gridOptions: GridOptions;
  readonly finAccountsColumnDF = FINACCOUNTS_COLUMN_DEFS;
  readonly localeTextForFilters = LOCALE_TEXT_FOR_FILTERS;
  readonly defaultColDef: ColDef = {
    headerClass: 'header-centered',
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    floatingFilter: true,
    cellStyle: { textAlign: 'center' },
  };
  readonly accountForm = new UntypedFormGroup({
    name: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
    type: new FormControl(null, [Validators.required]),
    account_bik: new FormControl(null),
    account_bank: new FormControl(null),
    account_settlement: new FormControl(null),
    account_corrective: new FormControl(null),
    current_sum: new FormControl(null),
    credit_limit: new FormControl(null),
    is_visible: new FormControl(false),
    is_main_account: new FormControl(false),
    date_balance_init: new FormControl(null, [Validators.required]),
    balance_init: new FormControl(null, [
      Validators.required,
      Validators.pattern('^[+]?([0-9]{0,})*[.]?([0-9]{0,2})?$'),
    ]),
    description: new FormControl(null),
  });
  rowData: IFinAccountsList[];
  isLoading = false;
  chsuccess = false;
  activeUID: any;
  accountType: CommonValueWithIDName[] = ACCOUNT_TYPES;
  modalReference: any;
  isShowBankCredentials = false;
  isShowCredentialsTitle = false;
  isEditModal = false;
  @ViewChild('createUpdateAccountModalNew', { read: TemplateRef }) createUpdateAccountModalNew: TemplateRef<any>;

  constructor(private calendar: NgbCalendar) {
    super();
    this.gridOptions = { context: { componentParent: this } } as GridOptions;
  }

  get creditLimitSelected(): boolean {
    return !!(this.accountForm.controls.type.value && this.accountForm.controls.type.value === 6);
  }

  get creditLoanSelected(): boolean {
    return !!(this.accountForm.controls.type.value && this.accountForm.controls.type.value === 5);
  }

  ngOnInit(): void {}

  onGridReady(params: GridReadyEvent<any>) {
    this.loadData();
  }

  openCreateAccountModal(accountModal: any) {
    this.isEditModal = false;
    this.reloadForm();
    this.isShowCredentialsTitle = false;
    this.isShowBankCredentials = false;
    this.modalReference = this._modalService.open(accountModal);
  }

  openUpdateAccountModal(rowData: IFinAccountsList) {
    this.isShowBankCredentials = false;
    this.isShowCredentialsTitle = false;
    this.isEditModal = true;
    // const body = { shop_id: JSON.parse(localStorage.getItem('mpall_shop')).shop, uid: rowData.UID };
    // this.api.userRegisterFin('getFinAccountOne', localStorage.getItem('token'), body);
    this._mpSurfService.load({ uid: rowData.UID }, 'fin-account').subscribe(
      (data: IFinAccountsList) => {
        if ([2, 3].includes(+data.type)) {
          this.isShowCredentialsTitle = true;
        }
        this.activeUID = data.UID;
        const dateOfPayment = new Date(data.date_balance_init);
        const modelDatePayment = {
          year: Number(moment(dateOfPayment).format('YYYY')),
          month: Number(moment(dateOfPayment).format('M')),
          day: Number(moment(dateOfPayment).format('D')),
        };
        this.accountForm.patchValue({
          name: data.name,
          type: Number(data.type),
          account_bik: data.account_bik ?? '',
          account_bank: data.account_bank ?? '',
          account_settlement: data.account_settlement ?? '',
          account_corrective: data.account_corrective ?? '',
          credit_limit: data.credit_limit ?? '',
          current_sum: data.current_sum ?? '',
          is_visible: +data.is_visible === 0 ?? '',
          is_main_account: +data.is_main_account === 0 ?? '',
          balance_init: data.balance_init,
          description: data.description,
          date_balance_init: modelDatePayment,
        });
        this._modalService.open(this.createUpdateAccountModalNew, { backdrop: 'static' });
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
      },
    );
  }

  createNewAccount() {
    this._mpSurfService.createOrUpdate(this.accountForm.getRawValue(), 'fin-account').subscribe(
      (response: IFinAccountCreateUpdateDeleteResponse) => {
        if (+response.is_error === 0) {
          this.showPopUpMessage('success', PopUpMessages.createSuccessSummary, 'Счет создан');
          this.reloadForm();
          this.loadData();
        } else {
          this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
        }
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.createFailedSummary, PopUpMessages.createFailedMessage);
      },
    );
  }

  updateAccount(UID: string) {
    this.isLoading = true;
    const body = this.accountForm.getRawValue();
    body.uid = UID;
    this._mpSurfService
      .createOrUpdate(body, 'fin-account')
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response: IFinAccountCreateUpdateDeleteResponse) => {
          if (+response.is_error === 0) {
            this.reloadForm();
            this.loadData();
            this.showPopUpMessage('success', PopUpMessages.updateSuccessSummary, 'Счет обновлен!');
          } else {
            this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
          }
        },
        () => {
          this.showPopUpMessage('error', PopUpMessages.updateFailedSummary, PopUpMessages.updateFailedMessage);
        },
      );
  }

  deleteAccount(accountData: IFinAccountsList): void {
    this._confirmationService.confirm({
      message: 'Вы уверены, что хотите удалить аккаунт?',
      header: 'Подтверждение удаления',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Да',
      rejectLabel: 'Нет',
      accept: () => {
        this.isLoading = true;
        this._mpSurfService
          .delete({ uid: accountData.UID }, 'fin-account')
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            (response: IFinAccountCreateUpdateDeleteResponse) => {
              if (+response.is_error === 0) {
                this.showPopUpMessage(
                  'success',
                  PopUpMessages.deleteSuccessSummary,
                  PopUpMessages.deleteSuccessMessage,
                );
                this.loadData();
              } else {
                this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
              }
            },
            () => {
              this.showPopUpMessage('error', PopUpMessages.deleteFailedSummary, PopUpMessages.deleteFailedMessage);
            },
          );
      },
      reject: () => {},
    });
  }

  autoSetupCredentials(parameter: string): void {
    this.isShowBankCredentials = false;
    const value = parameter.split(' ')[1] ?? '';
    if (value) {
      this.isShowCredentialsTitle = [2, 3].includes(+value);
    }
  }

  private loadData() {
    this._mpSurfService.load({}, 'fin-account').subscribe(
      (response: IFinAccountsList[]) => {
        this.rowData = response;
      },
      () => {
        this.showPopUpMessage('error', PopUpMessages.loadFailedSummary, PopUpMessages.loadFailedMessages);
      },
    );
  }

  private reloadForm(): void {
    this._modalService.dismissAll();
    this.accountForm.reset();
    this.accountForm.get('date_balance_init').setValue(this.calendar.getToday());
  }
}
