<div class="app-page-title app-page-title-simple">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="pe-7s-cart icon-gradient bg-mean-fruit"></i>
      </div>
      <div class="get-price-title">
        <div>
          Самовыкупы
        </div>
        <div class="download_part">
           <span [pTooltip]="
           'Самовыкупы можно загружать через эксель, добавлять по 1 шт и скачивать.' +
            'При загрузки уже существующего ID чека,данные заменяються. ' +
            'шаблон для загрузки.'" class="pe-2" tooltipPosition="right">
               <fa-icon [icon]="faQuestionCircle"></fa-icon>
           </span>
          <a class="link-part-buyouts" href="../../../../assets/images/download/buyouts_template.xlsx">Скачать</a>
        </div>
      </div>
    </div>
    <div class="right-side-header">
      <button (click)="exportExcel()"
              class="p-button-success mr-2"
              icon="pi pi-file-excel"
              pButton pRipple
              pTooltip="Выгрузить в эксель"
              tooltipPosition="bottom"
              type="button">
      </button>
      <p-toast></p-toast>
      <p-fileUpload #uploadedFiles (onError)="onBasicUploadError($event,uploadedFiles)"
                    (onUpload)="onBasicUploadAuto($event,uploadedFiles)"
                    [auto]="true"
                    [maxFileSize]="10000000" accept=".xls, .xlsx, .csv"
                    chooseLabel="Загрузить из excel"
                    class="button-add-purchase mr-4 inline-block"
                    label="Import"
                    mode="basic"
                    name="file"
                    url="{{url_link}}data?action=upload&type=purchase&token={{token}}"
      ></p-fileUpload>
      <button (click)="addNewBuyout()" class="p-button-success mr-2" icon="pi pi-plus" label="Добавить 1 самовыкуп"
              pButton
              pRipple style="font-size:1rem"></button>
    </div>
  </div>
</div>
<div class="card">
  <div style="width: 100%;height: 75vh;">
    <ag-grid-angular
      (cellValueChanged)="onCellValueChanged($event)"
      [columnDefs]="columnDataForGrid"
      [defaultColDef]="defaultColDef"
      [enableRangeSelection]="true"
      [gridOptions]="gridOptions"
      [pagination]="true"
      [rowData]="rowData"
      [rowHeight]="60"
      class="ag-theme-alpine"
      style="width: 100%; height: 100%;">
    </ag-grid-angular>
  </div>
</div>
<p-confirmDialog [baseZIndex]="10000" [style]="{width: '30vw'}"></p-confirmDialog>

