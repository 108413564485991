import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Params} from '@angular/router';
import {IDefectGoodsList} from "../defect-goods.interface";

@Component({
  selector: 'app-btn-cell-renderer',
  template: `
    <button (click)="editRow()"
            class="icon-button"
            style="margin-right: 25px;">
      <i class="lnr-pencil"></i>
    </button>
    <button (click)="deleteRow()"
            class="icon-button">
      <i class="lnr-trash"></i>
    </button>
  `,
  styleUrls: ['../../../external-analysis/bloggers/bloggers.component.scss'],
})
export class DefectGoodsCellActionComponent implements ICellRendererAngularComp {
  componentParent: any;
  private params: Params;

  agInit(params: Params): void {
    this.params = params;
    this.componentParent = this.params.context.componentParent;
  }

  editRow() {
    this.componentParent.editModalEmitter(this.params.data);
  }

  deleteRow() {
    const defectGoodData = this.params.data as IDefectGoodsList;
    this.componentParent.deleteDefectGood(defectGoodData.id);
  }

  refresh() {
    return false;
  }
}
