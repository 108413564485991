import { OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { NouisliderModule } from 'ng2-nouislider';
import * as moment from 'moment';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { FormGroup, FormControl } from '@angular/forms';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faTh } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-allsellersdetails',
  templateUrl: './allsellersdetails.component.html',
  styleUrls: ['./allsellersdetails.component.sass'],
})
export class AllsellersdetailsComponent implements OnInit {
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faTh = faTh;
  faCheck = faCheck;
  faTrash = faTrash;
  faCalendarAlt = faCalendarAlt;

  data_agr: any;
  seller_name: any;

  SalesChartPlugins: any;

  alwaysShowCalendars: boolean;
  ranges: any = {
    // 'Сегодня': [moment(), moment()],
    Вчера: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'За 7 дней': [moment().subtract(6, 'days'), moment()],
    'За 30 дней': [moment().subtract(29, 'days'), moment()],
    'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
    'Прошлый Месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  vis_det: any = false;

  minDate = moment().add(-91, 'days');
  maxDate = moment().add(-1, 'days');

  selected: any = { startDate: moment().add(-7, 'days'), endDate: moment().add(-1, 'days') };
  //////////////////     ДЛЯ ГРАФИКОВ
  public lineChartData: any = [
    {
      data: [0],
      label: 'Продажи',
      type: 'line',
    },
    {
      data: [0],
      label: 'Остатки',
      type: 'bar',
    },
  ];
  public SalesChartData: any = [
    {
      data: [0],
      label: 'Выручка',
      type: 'line',
    },
    {
      data: [0],
      label: 'Цена',
      type: 'line',
      yAxisID: 'y-axis-1',
    },
  ];
  lineChartLabels: any;
  SalesChartLabels: any;
  public lineChartColors: any = [];
  public lineChartLegend = true;
  public lineChartType = 'bar';
  public lineChartPlugins = [pluginAnnotations];
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            //color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
          },
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno',
          },
        },
      ],
    },
  };
  body_date: any;
  localeText: any;
  rowData: any = [];
  arr_par: any = [];
  params_list: any;
  columnDefsSales: any;
  rowHeight: any = 40;
  rowSelection: any = 'multiple';
  rowModelType: any = 'infinite';
  cacheOverflowSize: any = 2;
  paginationPageSize: any = 100;
  maxConcurrentDatasourceRequests: any = 3;
  infiniteInitialRowCount: any = 1;
  maxBlocksInCache: any = 10;
  cacheBlockSize: any = 100;
  loadingCellRenderer: any;
  loadingCellRendererParams: any;
  private gridApi;
  private gridColumnApi;

  constructor(
    private _httpClient: HttpClient,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.localeText = {
      // Number Filter & Text Filter
      filterOoo: 'Фильтр...',
      equals: 'Равно',
      notEqual: 'не равно',
      empty: 'Выбери один',

      // Number Filter
      lessThan: 'Меньше чем',
      greaterThan: 'Больше чем',
      lessThanOrEqual: 'Меньше или равно',
      greaterThanOrEqual: 'Больше или равно',
      inRange: 'В диапазоне',
      inRangeStart: 'От',
      inRangeEnd: 'До',

      // Text Filter
      contains: 'Содержит',
      notContains: 'Не Содержит',
      startsWith: 'Начинается с',
      endsWith: 'Заканчиваеться на',

      // Date Filter
      dateFormatOoo: 'dd.mm.yyyy',

      // Filter Conditions
      andCondition: 'И',
      orCondition: 'ИЛИ',

      // Filter Buttons
      applyFilter: 'Apply',
      resetFilter: 'Reset',
      clearFilter: 'Clear',
      cancelFilter: 'Cancel',

      // Filter Titles
      textFilter: 'Text Filter',
      numberFilter: 'Number Filter',
      dateFilter: 'Date Filter',
      setFilter: 'Set Filter',
    };

    this.route.queryParams.subscribe((params) => {
      if (params.d1 != '' && params.d2 != '') {
        this.selected = { startDate: moment(params.d1), endDate: moment(params.d2) };
      }
    });
    this.route.params.subscribe((params) => (this.seller_name = params['id']));
  }

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  wbCat(params) {
    console.log(params);
    return '<a href="/wb/catlist?cat=' + params.value + '" target="_blank">' + params.value + '</a>';
  }

  wbItem(params) {
    console.log(params.value);
    if (typeof params.value == 'undefined') {
      let img = document.createElement('img');
      img.src = '/assets/images/loading.gif';
      img.width = 32;
      img.height = 32;
      img.referrerPolicy = 'no-referrer';
      return img;
    } else {
      return '<a href="/wb/item/' + params.value + '" target="_blank">' + params.value + '</a>';
    }
  }

  wbPrice(params) {
    return '599.  (-66%)   1.755.';
  }

  wbPhoto(params) {
    console.log(params.value);
    let img = document.createElement('img');
    if (typeof params.value == 'undefined') {
      img.src = '/assets/images/loading.gif';
    } else {
      img.src = params.value;
    }
    img.width = 32;
    img.height = 32;
    img.referrerPolicy = 'no-referrer';
    return img;
  }

  ngOnInit(): void {
    this.columnDefsSales = [
      {
        headerName: 'Фото',
        field: 'photo',
        sortable: false,
        filter: false,
        resizable: true,
        cellRenderer: this.wbPhoto,
        width: 80,
        maxWidth: 80,
        suppressSizeToFit: true,
        flex: 0,
        cellClass: ['d-flex', 'justify-content-center', 'align-items-center'],
      },
      {
        headerName: 'Название',
        field: 'name',
        sortable: false,
        resizable: true,
        filterParams: { filterOptions: ['contains', 'startsWith', 'endsWith'], defaultOption: 'contains' },
      },
      {
        headerName: 'SKU',
        field: 'sku',
        sortable: true,
        resizable: true,
        width: 100,
        cellRenderer: this.wbItem,
        filterParams: { filterOptions: ['contains', 'startsWith', 'endsWith'], defaultOption: 'contains' },
      },
      {
        headerName: 'Категория',
        field: 'qty_cat',
        sortable: true,
        resizable: true,
        width: 150,
        filterParams: { filterOptions: ['contains', 'startsWith', 'endsWith'], defaultOption: 'contains' },
      },
      //{ headerName: 'Продавец',     field: 'qty_selles',       sortable: true,  resizable: true, width:150, filterParams: {filterOptions: ['contains', 'startsWith', 'endsWith'],defaultOption: 'contains',},},
      //{ headerName: 'Бренд',        field: 'Brand',        sortable: true,  resizable: true, width:120, filterParams: {filterOptions: ['contains', 'startsWith', 'endsWith'],defaultOption: 'contains',},},
      {
        headerName: 'Наличие',
        field: 'qty_balance',
        sortable: true,
        resizable: true,
        width: 100,
        wrapText: true,
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: ['equals', 'notEqual', 'inRange'], defaultOption: 'equals' },
      },
      {
        headerName: 'Отзывов',
        field: 'qty_comments',
        sortable: true,
        resizable: true,
        width: 100,
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: ['equals', 'notEqual', 'inRange'], defaultOption: 'equals' },
      },
      {
        headerName: 'Рейтинг',
        field: 'rating',
        sortable: true,
        resizable: true,
        width: 100,
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: ['equals', 'notEqual', 'inRange'], defaultOption: 'equals' },
      },
      {
        headerName: 'Цена',
        field: 'price',
        sortable: true,
        resizable: true,
        width: 100,
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: ['equals', 'notEqual', 'inRange'], defaultOption: 'equals' },
      },
      {
        headerName: 'Скидка %',
        field: 'discount',
        sortable: true,
        resizable: true,
        width: 100,
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: ['equals', 'notEqual', 'inRange'], defaultOption: 'equals' },
      },
      {
        headerName: 'Цена продажи',
        field: 'price_sales',
        sortable: true,
        resizable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: ['equals', 'notEqual', 'inRange'], defaultOption: 'equals' },
      },
      {
        headerName: 'Продаж',
        field: 'sales',
        sortable: true,
        resizable: true,
        width: 100,
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: ['equals', 'notEqual', 'inRange'], defaultOption: 'equals' },
      },
      //{ headerName: 'Оборот',       field: 'Oborot',       sortable: true,  resizable: true, width:100, filter: 'agNumberColumnFilter',filterParams: {filterOptions: ['equals', 'notEqual', 'inRange'],defaultOption: 'equals',},},
      {
        headerName: 'Оборот',
        field: 'turnover',
        sortable: true,
        resizable: true,
        width: 100,
        filter: false,
        sort: 'desc',
      },
    ];

    this.getData();
    this.getDataChart(this.selected.startDate.format('YYYY-MM-DD'), this.selected.endDate.format('YYYY-MM-DD'));
  }

  onGridReady(params: any) {
    console.log(params);

    //console.log(this.selected);
    this.gridApi = params.api;

    this.gridColumnApi = params.columnApi;

    var datasource = {
      getRows: (params: any) => {
        console.log(params);
        this.getRowData(
          params.startRow,
          params.endRow,
          params.filterModel,
          params.sortModel,
          this.selected.startDate.format('YYYY-MM-DD'),
          this.selected.endDate.format('YYYY-MM-DD'),
          this.seller_name,
        ).subscribe((data: any) => params.successCallback(data.data, data.totalRecord));
      },
    };
    params.api.setDatasource(datasource);
  }

  public chartHovered({ event, active }: { event: MouseEvent; active: {}[] }): void {
    console.log(event, active);
  }

  public chartClicked({ event, active }: { event: MouseEvent; active: {}[] }): void {
    console.log(event, active);
  }

  getData() {
    let body = {
      type: 'widget',
      startDate: this.selected.startDate.format('YYYY-MM-DD'),
      endDate: this.selected.endDate.format('YYYY-MM-DD'),
      seller: this.seller_name,
    };
    return new Promise((resolve, reject) => {
      this.api.userRegisterClick('getDataSellerDetail', localStorage.getItem('token'), body).subscribe(
        (data: any) => {
          //console.log(data);
          this.data_agr = data;
          resolve(true);
        },
        (err) => {
          console.log(err);
          //reject(err);
        },
      );
    });
  }

  getDataChart(startDate_new: any, endDate_new: any) {
    if (startDate_new != '' && endDate_new != '') {
      this.body_date = { type: 'chart', startDate: startDate_new, endDate: endDate_new, seller: this.seller_name };
    } else {
      this.body_date = {
        type: 'chart',
        startDate: this.selected.startDate.format('YYYY-MM-DD'),
        endDate: this.selected.endDate.format('YYYY-MM-DD'),
        seller: this.seller_name,
      };
    }

    //console.log(this.body_date);

    return new Promise((resolve, reject) => {
      this.api.userRegisterClick('getDataSellerDetail', localStorage.getItem('token'), this.body_date).subscribe(
        (data: any) => {
          //console.log(data);
          //console.log(data.data.chartSales);
          this.lineChartLabels = data.data.chartLabel;
          this.lineChartData = [
            {
              data: data.data.chartSales,
              label: 'Продажи',
              type: 'line',
            },
            {
              data: data.data.Balance,
              label: 'Остатки',
              type: 'line',
              yAxisID: 'y-axis-1',
            },
          ];

          //console.log(this.lineChartData);
          this.SalesChartLabels = data.data.chartLabel;

          this.SalesChartData = [
            {
              data: data.data.cash,
              label: 'Выручка',
              type: 'line',
            },
            {
              data: data.data.avg_m,
              label: 'Цена',
              type: 'line',
              yAxisID: 'y-axis-1',
            },
          ];

          //console.log(this.SalesChartData);

          resolve(true);
        },
        (err) => {
          console.log(err);
          //reject(err);
        },
      );
    });
  }

  newValue() {
    //console.log(this.selected.startDate.format('YYYY-MM-DD'));
    //console.log(this.selected.endDate.format('YYYY-MM-DD'));

    this.router.navigate(['/dashboard/all/sellers/' + this.seller_name], {
      queryParams: {
        d1: this.selected.startDate.format('YYYY-MM-DD'),
        d2: this.selected.endDate.format('YYYY-MM-DD'),
      },
    });

    var datasource = {
      getRows: (params: any) => {
        console.log(params);
        this.getRowData(
          params.startRow,
          params.endRow,
          params.filterModel,
          params.sortModel,
          this.selected.startDate.format('YYYY-MM-DD'),
          this.selected.endDate.format('YYYY-MM-DD'),
          this.seller_name,
        ).subscribe((data: any) => params.successCallback(data.data, data.totalRecord));
      },
    };
    this.gridApi.setDatasource(datasource);

    this.getDataChart(this.selected.startDate.format('YYYY-MM-DD'), this.selected.endDate.format('YYYY-MM-DD'));
    this.getData();
  }

  private getRowData(
    startRow: number,
    endRow: number,
    filter: any,
    sort: any,
    dateBegin: any,
    dateEnd: any,
    seller: any,
  ) {
    //console.log(filter);
    console.log('getRowData');
    this.arr_par = {
      type: 'grid',
      params_list: this.params_list,
      startRow: startRow,
      endRow: endRow,
      filter: filter,
      sort: sort,
      dateBegin: dateBegin,
      dateEnd: dateEnd,
      seller: seller,
    };

    this.wbPhoto('test');

    return this.api.userRegisterClick('getDataSellerDetail', localStorage.getItem('token'), this.arr_par);
  }
}
