import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SettingsComponent } from './settings/settings.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TariffComponent } from './tariff/tariff.component';
import { ToastModule } from 'primeng/toast';
import { BillsComponent } from './bills/bills.component';
import { UsersComponent } from './users/users.component';
import { ModalModule } from '../../shared/common-components/modal/modal.module';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'tariff',
    component: TariffComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'bill',
    component: BillsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  declarations: [UsersComponent, BillsComponent, SettingsComponent, TariffComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    HttpClientModule,
    NgbNavModule,
    ToastModule,
    ModalModule,
    InputMaskModule,
    InputTextModule,
    RadioButtonModule,
    FormsModule,
    ProgressSpinnerModule,
  ],
})
export class ProfileModule {}
