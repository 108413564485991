export function OrdersAverageCheckByDayOfWeekPlotOptionsSerializer(data: any): any {
  const averageOrders = [];
  const maxInOrders = [];
  for (const averageOrder in data.avg.check) {
    maxInOrders.push(Math.max(...data.avg.check[averageOrder]));
    averageOrders.push(data.avg.check[averageOrder].reduce((a, b) => a + b) / data.avg.check[averageOrder].length);
  }
  const average = Math.ceil(averageOrders.reduce((a, b) => a + b) / averageOrders.length);

  let rangeChar = [];
  if (average === 0) {
    rangeChar = [{ from: 0, to: 0, color: '#808080' }];
  } else {
    rangeChar = [
      { from: 0, to: 0, color: '#808080' },
      { from: 1, to: average, color: '#d1eb75' },
      {
        from: average + 1,
        to: Math.max(...maxInOrders),
        color: '#78be37',
      },
    ];
    return {
      plotOptions: {
        heatmap: {
          radius: 0,
          enableShades: true,
          shadeIntensity: 0.5,
          colorScale: { ranges: rangeChar },
        },
      },
    };
  }
}
